/* eslint-disable camelcase */
import { featureFlags, isFeatureEnabled } from '@featureflags'
import theme from '@theme'
import dayjs from 'dayjs'
import {
  nodeLocaleToEnglishLanguage,
  timezone2tzid,
  removeStyleHTML,
} from '@utils'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

export const divideIntoNParts = (n, arr) => {
  if (!arr?.length) return arr
  const nParts = []
  let arrayLength = arr.length
  let current = 0
  for (let i = n; i > 0; i--) {
    const numOfItems = Math.ceil(arrayLength / i)
    const next = current + numOfItems
    nParts.push(arr.slice(current, next))
    arrayLength = arrayLength - numOfItems
    current = next
  }
  return nParts
}

export const handleBackgroundColor = (color, defaultColor = 'bgGray') => {
  switch (color) {
    case 'Light Gold':
      return 'goldLt'
    case 'White':
      return 'white'
    case 'Gray':
      return 'bgGray'
    default:
      return defaultColor
  }
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const handleContentfulTypename = typename => {
  if (!typename) return null
  typename = typename?.toLowerCase()

  if (typename?.substring(0, 10) === 'contentful') {
    typename = typename?.slice(10)
  }
  return typename
}

const capitalizeFirstLetter = str => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase()
}

export const capitalize = str => {
  if (typeof str !== 'string') return ''
  switch (str) {
    case 'youtube':
      return 'YouTube'
    default:
      return capitalizeFirstLetter(str) + str.slice(1)
  }
}

export const capitalizeFirstLetterOfString = string => {
  if (!string) return null
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const camelCase = str => {
  return str
    .replace(/\s(.)/g, function(a) {
      return a.toUpperCase()
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function(b) {
      return b?.toLowerCase()
    })
}

export const flatten = arr => {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    )
  }, [])
}

export const chunkArray = (myArray, chunkSize) => {
  let results = []
  let tempArray = [...myArray]
  while (tempArray.length) {
    results.push(tempArray.splice(0, chunkSize))
  }
  return results
}

export const findLastIndex = arr => {
  let index = null
  arr.forEach((e, i) => {
    if (e === ' ') index = i
  })
  return index
}

export const shortenTitle = (title, viewport) => {
  let sliceIndex = 105
  if (viewport && viewport.mobile) sliceIndex = 75

  const slicedTitle = title && title.split('').slice(0, sliceIndex)
  let findIndex = findLastIndex(slicedTitle)
  title = slicedTitle && slicedTitle.slice(0, findIndex).join('')

  return `${title}...`
}

export const textOverflowEllipses = (title, nChars = 105) => {
  if (title?.length < nChars) return title

  const truncatedTitle = title?.slice(0, nChars)
  const lastCompleteWordIndex = truncatedTitle?.lastIndexOf(' ')
  const truncatedTitleWithLastCompleteWord = truncatedTitle?.slice(
    0,
    lastCompleteWordIndex
  )
  return `${truncatedTitleWithLastCompleteWord}...`
}

export const findMaxChar = (title, subtitle, viewport) => {
  let shrinkSubtitle = subtitle
  if (title.length > 74) return ''
  if (subtitle && subtitle.length > 239)
    subtitle = subtitle
      .split(' ')
      .slice(0, subtitle.length - 2)
      .join('')
  let findIndex = null
  if (viewport && viewport.mobile) {
    let maxCharCount = 150
    let shrinkTitle = title.split('').length
    maxCharCount = maxCharCount - shrinkTitle
    const slicedSubtitle = subtitle && subtitle.split('').slice(0, maxCharCount)
    findIndex = slicedSubtitle && findLastIndex(slicedSubtitle)
    shrinkSubtitle =
      slicedSubtitle && findIndex && slicedSubtitle.slice(0, findIndex).join('')
    shrinkSubtitle = `${shrinkSubtitle}...`
  } else if (viewport && viewport.lg) {
    if (subtitle && subtitle.length > 185) {
      const sliceCount = subtitle.length - 185 > 0 && 185
      const lgShrinkSubtitle =
        sliceCount && subtitle.split('').slice(0, sliceCount)
      if (lgShrinkSubtitle) {
        findIndex = findLastIndex(lgShrinkSubtitle)
        shrinkSubtitle =
          findIndex && lgShrinkSubtitle.slice(0, findIndex).join('')
      }
      shrinkSubtitle = `${shrinkSubtitle}...`
    }
  }
  return shrinkSubtitle
}

export const deepClone = myArray => JSON.parse(JSON.stringify(myArray))

export const parseHTMLSpecialChars = text => {
  const decodeHtmlEntity = str => {
    return str.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec)
    })
  }
  if (typeof text === 'string') {
    return decodeHtmlEntity(text)
  }
  return text
}

export const removeNewsBasedOnType = module => {
  if (!module?.__typename) return module
  switch (module.__typename) {
    case 'ContentfulModuleCarousel':
    case 'moduleCarousel':
      return {
        ...module,
        slides: module?.slides?.filter(
          ({ contentDestination }) =>
            !contentDestination?.[0]?.hideOnHomeAndNewsPage
        ),
      }
    case 'ContentfulModuleHoverCta':
    case 'moduleHoverCta':
      return {
        ...module,
        ctaItems: module?.ctaItems?.filter(
          ({ linksTo }) => !linksTo?.[0]?.hideOnHomeAndNewsPage
        ),
      }
    case 'ContentfulModuleEntriesBlock':
    case 'moduleArticleBlock':
      return {
        ...module,
        entries: module?.entries?.filter(
          ({ hideOnHomeAndNewsPage }) => !hideOnHomeAndNewsPage
        ),
      }
    default:
      return module
  }
}

export const removeNewsWithHideField = modules => {
  return modules?.map(module => removeNewsBasedOnType(module))
}

export const trunc = (str, len = 100) => {
  if (typeof str !== 'string') {
    return ''
  }
  if (str.length <= len) {
    return str
  }
  return str.substr(0, len - 1) + '...'
}

// used to add a count property to footnotes. this will order the superscripts programmatically
export const orderSuperscript = arr => {
  if (arr?.length === 0) return null
  const filterRefs = arr?.filter(a => a?.__typename === 'ContentfulFootnote')
  const nonFootnotes = arr?.filter(a => a?.__typename !== 'ContentfulFootnote')
  const footNoteSuperScript = filterRefs?.map((a, i) => ({
    ...a,
    count: i + 1,
  }))
  if (nonFootnotes?.length > 0) {
    arr = nonFootnotes.concat(footNoteSuperScript)
  } else {
    arr = footNoteSuperScript
  }
  return arr
}

const createSteppers = (start, end) => {
  const currSteppers = []
  for (let i = start; i <= end; i++) {
    currSteppers.push(i)
  }
  return currSteppers
}

export const findStepperArr = (block, page) => {
  let start = 1
  let end = page?.totalSteppers

  if (end && page?.totalSteppers <= 10) {
    return createSteppers(start, end)
  }

  switch (block) {
    case 'leftBlock':
      end = 6
      return [...createSteppers(start, end), '...', page?.totalSteppers]
    case 'rightBlock':
      start = page?.totalSteppers - 5
      return [1, '...', ...createSteppers(start, end)]
    default:
      start = page?.currentPage - 2
      end = page?.currentPage + 2
      return [
        1,
        '...',
        ...createSteppers(start, end),
        '...',
        page?.totalSteppers,
      ]
  }
}

export const partitionList = list => {
  const cols = [[], [], []]
  if (!list) return cols
  let count = 0
  list.forEach(item => {
    if (item) {
      cols[count].push(item)
      if (count === 2) {
        count = 0
      } else {
        count += 1
      }
    }
  })
  return cols
}

export const shapeFeaturedImage = (
  featuredImage,
  caption,
  articleTitle,
  displayLocale
) => {
  if (!featuredImage) return null

  const contentType = featuredImage?.__typename

  const isProfessional =
    contentType === 'ContentfulProfessional' ||
    contentType === 'Professional' ||
    contentType === 'professional'

  const linksTo = isProfessional ? featuredImage : featuredImage?.linksTo

  const validLinkTo = !linksTo?.displayOnSites
    ? true
    : linksTo?.displayOnSites?.includes(displayLocale)

  const linkData = validLinkTo
    ? {
        category: linksTo?.category?.slug,
        contentType: linksTo?.__typename,
        slugId: linksTo?.id,
        to: linksTo?.slug,
        href: linksTo?.url,
      }
    : {}

  if (isProfessional && !featuredImage?.active) return null

  if (isProfessional) {
    const title = featuredImage?.title
    const name = featuredImage?.name
    caption = caption || `${name}${title ? `, ${title}` : ''} | ${articleTitle}`
  }

  const alt = caption || featuredImage?.image?.description
  return {
    image:
      featuredImage?.image?.file?.url ||
      featuredImage?.image?.url ||
      featuredImage?.image?.fluid?.src,
    linkData: linkData,
    alt,
    caption,
    contentType,
    isFeaturedProfessional: isProfessional,
  }
}

export const handleRawHtml = content => {
  return content?.useHTMLField === 'true' && content?.html
    ? content?.html
    : null
}

export const handleModuleText = ({
  content,
  accordionContent,
  backgroundColor,
  ...rest
}) => {
  return {
    ...rest,
    content: handleRawHtml(content),
    accordionContent: handleRawHtml(accordionContent),
    backgroundColor: handleBackgroundColor(backgroundColor, 'goldLt'),
  }
}

export const handleLinkData = (linksTo, displayLocale) => {
  if (!linksTo) return null
  const {
    displayOnSites,
    category,
    __typename,
    id,
    slug,
    url,
    active,
  } = linksTo
  if (!slug && !url) return null

  const validLinkTo =
    handleContentfulTypename(__typename) === 'professional' && !active
      ? false
      : !displayOnSites
      ? true
      : displayOnSites?.includes(displayLocale)

  const linkData = validLinkTo
    ? {
        category: category?.slug,
        contentType: __typename,
        slugId: id,
        to: slug,
        href: url,
        __typename,
      }
    : {}

  return linkData
}

export const handleComponentImage = (
  { image, linksTo, excerpt, ...rest },
  displayLocale
) => {
  return {
    ...rest,
    media: image?.file?.url || image?.fluid?.src,
    mediaType: 'image',
    altText: image?.description || image?.title,
    linkData: handleLinkData(linksTo, displayLocale),
    excerpt: excerpt?.excerpt || excerpt,
  }
}

export const handleIframe = ({ iframe, ...rest }) => {
  return {
    ...rest,
    media: iframe?.iframe || iframe,
    mediaType: 'iframe',
  }
}

export const handleMedia = ({ __typename, ...rest }, displayLocale) => {
  const contentType = handleContentfulTypename(__typename)
  if (contentType === 'componentimage') {
    return handleComponentImage({ __typename, ...rest }, displayLocale)
  } else return handleIframe({ __typename, ...rest })
}

export const handleImageGrid = ({ images, ...rest }, displayLocale) => {
  return {
    ...rest,
    images: images?.map(image => handleMedia(image, displayLocale)),
  }
}

export const handleColumn = (
  { description, media, backgroundColor, ...rest },
  displayLocale
) => {
  return {
    ...rest,
    description: description?.useHTMLField === 'true' ? description?.html : '',
    media: media && handleMedia(media, displayLocale),
    backgroundColor: handleBackgroundColor(backgroundColor),
  }
}

export const handleEmployeeQuote = (
  { image, quote, ...rest },
  displayLocale
) => {
  return {
    ...rest,
    image: handleMedia(image, displayLocale),
    quote: handleRawHtml(quote),
  }
}

export const handleSpotlights = ({ spotlights, ...rest }, displayLocale) => {
  return {
    ...rest,
    spotlights: spotlights?.map(spotlight =>
      handleColumn(spotlight, displayLocale)
    ),
  }
}

export const handleHeroCtaLink = (ctaLink, displayLocale) => {
  if (!ctaLink) return null
  const contentType = handleContentfulTypename(ctaLink?.__typename)
  switch (contentType) {
    case 'externallink':
      return handleLinkData(ctaLink, displayLocale)
    case 'componentiframe':
      return handleIframe(ctaLink)
    default:
      return null
  }
}

export const handleHero = (
  { backgroundImage, ctaLink, titleColor, subtitleColor, ...rest },
  displayLocale,
  imageArgs = ''
) => {
  return {
    ...rest,
    titleColor: titleColor?.toLowerCase() || 'white',
    subtitleColor: subtitleColor?.toLowerCase() || 'white',
    backgroundImage: handleImage(backgroundImage, imageArgs),
    ctaLink: handleHeroCtaLink(ctaLink, displayLocale),
  }
}

export const handleProfessionalsTable = (
  { professionals, ...rest },
  displayLocale
) => {
  const sanitizedProfessionals = professionals?.filter(({ active }) => active)
  return {
    ...rest,
    professionals: sanitizedProfessionals?.map(({ offices, ...rest }) => ({
      ...rest,
      offices: offices?.map(office => ({
        ...office,
        linkData: handleLinkData(office, displayLocale),
      })),
      linkData: handleLinkData(rest, displayLocale),
    })),
  }
}

export const handleAwardsTable = ({ rows, ...rest }, displayLocale) => {
  return {
    ...rest,
    rows: rows?.map(({ description, ctaLink, ...rest }) => ({
      ...rest,
      description: handleRawHtml(description),
      ctaLink: handleLinkData(ctaLink, displayLocale),
    })),
  }
}

export const removeSampleEntries = (entries = []) => {
  if (isFeatureEnabled(featureFlags.SAMPLE_PAGES)) {
    return entries
  }

  const regex = new RegExp(/^\[sample\]/i)
  return entries?.filter(({ name, title }) =>
    name ? !name?.match(regex) : !title?.match(regex)
  )
}

export const nodeLocaleToDisplayLocale = {
  'en-US': 'American English',
  'en-GB': 'British English',
  ko: 'Korean',
  ja: 'Japanese',
  fr: 'French',
  zh: 'Chinese',
  de: 'German',
}

export const displayOnSitesToNodeLocale = {
  'American English': 'en-US',
  'British English': 'en-GB',
  Korean: 'ko',
  Japanese: 'ja',
  French: 'fr',
  Chinese: 'zh',
  German: 'de',
}

export const getDisplayOnSitesAsNodeLocales = (displayOnSites = []) => {
  return displayOnSites?.map(
    displayLocale => displayOnSitesToNodeLocale[displayLocale]
  )
}

export const getDisplayOnSitesEntries = (entries = []) => {
  return entries?.filter(
    ({ displayOnSites, node_locale }) =>
      !displayOnSites ||
      displayOnSites.includes(nodeLocaleToDisplayLocale[node_locale])
  )
}

export const shouldDisplayOnSites = (entry, locale) =>
  entry?.displayOnSites.includes(nodeLocaleToEnglishLanguage[locale])

export const handleImage = (image, args = '') => {
  if (!image) return null

  const description = image?.description || image?.title

  image = image?.file?.url
  image = args ? image.concat(args) : image

  return {
    altText: description,
    image: image,
  }
}

export const handleColor = color => {
  switch (color) {
    case 'Stone':
      return theme?.colors?.primary?.stone
    case 'Blue':
      return theme?.colors?.secondary?.blue
    case 'Gold':
      return theme?.colors?.secondary?.gold
    default:
      return theme?.colors?.primary?.red
  }
}

export const handleBgColor = color => {
  switch (color) {
    case 'Red':
      return {
        bg: theme?.colors?.primary?.red,
        textColor: 'white',
        ctaColor: 'white',
      }
    case 'Stone':
      return { bg: theme?.colors?.primary?.stone20 }
    default:
      return { bg: 'transparent' }
  }
}

export const handleHtmlOrRichtext = (html, content) => {
  const useHtml =
    html?.html && html?.useHTMLField && html?.useHTMLField === 'true'
  if (!useHtml && !content) return null

  return useHtml
    ? { type: 'html', content: html?.html }
    : { type: 'richtext', content: content }
}

// export const formatDate = date => {
//   if (!date) return null
//   return dayjs(date).format('MMMM DD, YYYY')
// }

export const formatImage = (image, queryParamString) => {
  if (!image) return null

  if (typeof image === 'string') {
    return `${image}?${queryParamString}`
  }

  const url = image?.file?.url
  if (!url) return image

  return {
    ...image,
    file: {
      ...image?.file,
      url: `${url}?${queryParamString}`,
    },
  }
}

export const parseUrlToSlugAndHref = url => {
  const href = url?.match(/^(http|https|mailto|tel):/) ? url : null
  const formattedSlug = url?.match(/^\/.+/) ? url.slice(1) : url
  const slug = href ? null : formattedSlug

  return [slug, href]
}

export const getDefaultCategoryTitle = typename => {
  switch (typename) {
    case 'event':
    case 'ContentfulEvent':
      return 'Event'
    case 'insight':
    case 'ContentfulArticle':
      return 'Article'
    case 'news':
    case 'ContentfulNews':
      return 'News'
    default:
      return ''
  }
}

export const handleEventDurationStrings = (eventStart, eventEnd, timeZone) => {
  const eventStartParsed =
    eventStart &&
    dayjs(eventStart, 'MMMM DD, YYYY').tz(timezone2tzid?.[timeZone], true)

  const eventEndParse =
    eventEnd &&
    dayjs(eventEnd, 'MMMM DD, YYYY').tz(timezone2tzid?.[timeZone], true)

  if (eventStartParsed && eventEndParse) {
    const eventDurationString =
      eventStartParsed.format('dddd, MMMM DD, YYYY') +
      ' - ' +
      eventEndParse.format('dddd, MMMM DD, YYYY')

    const eventDurationTimeString =
      eventStartParsed.format('h:mm A') +
      ' - ' +
      eventEndParse.format('h:mm A z')

    return [eventDurationString, eventDurationTimeString]
  } else if (eventStartParsed) {
    const eventDurationString = eventStartParsed.format('dddd, MMMM DD, YYYY')

    const eventDurationTimeString = eventStartParsed.format('h:mm A z')

    return [eventDurationString, eventDurationTimeString]
  } else return [null, null]
}

export const handleRichtextOrHtml = (contentHtml, content) => {
  const html =
    contentHtml?.useHTMLField === 'true' && removeStyleHTML(contentHtml?.html)
  const richtext = (content?.raw && JSON.parse(content?.raw)) || content
  let plainTextDescription = ''

  if (html && typeof document !== 'undefined') {
    const tempHtml = document.createElement('div')
    tempHtml.innerHTML = html
    tempHtml.innerHTML =
      tempHtml?.innerHTML?.replace(/&nbsp;/g, ' ') || tempHtml?.innerHTML
    plainTextDescription =
      tempHtml?.querySelector('p')?.innerText || tempHtml?.innerText || ''
  }
  if (richtext && !html) {
    plainTextDescription = documentToPlainTextString(richtext)
  }

  return plainTextDescription?.replace(/[\n\r]/g, ' ')
}

export const handleExtraEventsBasedOnEventTimes = events => {
  if (!events) return []
  const eventsWithEventTimesToHandle = []
  let eventsWithExtraEventsFromEventTime = []

  events = events.filter(event => {
    if (event?.eventTimes?.length) {
      eventsWithEventTimesToHandle.push(event)
      return false
    }
    return true
  })

  eventsWithEventTimesToHandle.forEach(({ eventTimes, ...rest }) => {
    eventTimes.forEach(eventTime => {
      eventsWithExtraEventsFromEventTime.push({
        ...rest,
        ...eventTime,
      })
    })
  })

  events = events.concat(eventsWithExtraEventsFromEventTime)

  return events
}

export const handleEventTimeBasedOnFirstEventTimes = events => {
  if (!events?.length) return []

  return events?.map(({ eventTimes, ...rest }) => {
    if (eventTimes?.length) {
      const firstEventTime = eventTimes
        ?.sort(
          (a, b) =>
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        )
        .reverse()[0]
      return {
        ...rest,
        ...firstEventTime,
      }
    }
    return rest
  })
}
