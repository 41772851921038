import React, { useState } from 'react'
import { H1, H3 } from '../../Globals'
import EmbeddedModal from './../../Modal/component/EmbeddedModal'
import Video from './Video'
import { useVideoSrc } from '@hooks'
import Asset from './Asset'

const EmbeddedMediaContainer = ({
  align,
  width,
  wrapText,
  media,
  title,
  url,
  displayTitle,
  modal,
  file,
}) => {
  const externalResource = url
  const assetResource = media?.[0]?.file?.url || file?.url

  title = displayTitle && title
  let margin = null

  if (width && align) {
    if (align === 'Center' || width.includes('default')) {
      margin = '10px auto'
    } else {
      margin = 100 - Number(width.slice(0, width.length - 1)).toString()
    }
  } else {
    margin = '10px auto'
  }

  const [toggle, setToggle] = useState(false)
  const { videoSrc, thumbnail } = useVideoSrc(externalResource, assetResource)

  const handleModal = () => setToggle(toggle => !toggle)

  if (modal) {
    if (externalResource) {
      return (
        <>
          {
            <EmbeddedModal
              align={align}
              width={width}
              wrapText={wrapText}
              margin={margin}
              thumbnail={thumbnail}
              toggle={toggle}
              handleModal={handleModal}
              videoSrc={videoSrc}
              title={title}
            />
          }
          {title && (
            <>
              <H3 color="gold" marginTop={30} className="qtph-watch-txt">
                WATCH
              </H3>
              <H1 marginBottom={30} smallerText={2} color="purple">
                {title}
              </H1>
            </>
          )}
        </>
      )
    }
  } else {
    if (!externalResource) {
      // audio asset file component
      return <Asset videoSrc={assetResource} title={title} />
    } else {
      // if modal boolean is set to false in contentful, video based on url field
      return <Video videoSrc={videoSrc} />
    }
  }
  return null
}

export default EmbeddedMediaContainer
