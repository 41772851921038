import { useStaticQuery, graphql } from 'gatsby'

const usePageSlugs = (locale = 'en-US') => {
  const pageSlugs = useStaticQuery(graphql`
    query PageSlug {
      accolades: allContentfulPage(
        filter: { contentful_id: { eq: "3F36dwPsht6g6geBqMEsB3" } }
      ) {
        nodes {
          slug
          node_locale
          __typename
          contentful_id
        }
      }
      news: allContentfulPage(
        filter: { contentful_id: { eq: "43jJTUzlBaamDwaKfJcbZf" } }
      ) {
        nodes {
          slug
          node_locale
          __typename
          contentful_id
        }
      }
      offices: allContentfulPage(
        filter: { contentful_id: { eq: "4DxFkZfT33O7CBkHSXBIQF" } }
      ) {
        nodes {
          slug
          node_locale
          __typename
          contentful_id
        }
      }
      practiceAreas: allContentfulPage(
        filter: { contentful_id: { eq: "23hSKEtDWwHSV3Gjx8rCzU" } }
      ) {
        nodes {
          slug
          node_locale
          __typename
          contentful_id
        }
      }
      professionals: allContentfulPage(
        filter: { contentful_id: { eq: "1WMCfztCvBPa3ywF0FjyRi" } }
      ) {
        nodes {
          slug
          node_locale
          __typename
          contentful_id
        }
      }
      events: allContentfulPage(
        filter: { contentful_id: { eq: "1iNTN1YVOUMwz2QNZmudo3" } }
      ) {
        nodes {
          slug
          node_locale
          __typename
          contentful_id
        }
      }
      insights: allContentfulPage(
        filter: { contentful_id: { eq: "5if9xKprWvNrUyyfWPk9vc" } }
      ) {
        nodes {
          slug
          node_locale
          __typename
          contentful_id
        }
      }
      pages: allContentfulPage(
        filter: {
          contentful_id: {
            nin: [
              "43jJTUzlBaamDwaKfJcbZf"
              "4DxFkZfT33O7CBkHSXBIQF"
              "23hSKEtDWwHSV3Gjx8rCzU"
              "1WMCfztCvBPa3ywF0FjyRi"
              "1iNTN1YVOUMwz2QNZmudo3"
              "5if9xKprWvNrUyyfWPk9vc"
            ]
          }
        }
      ) {
        nodes {
          slug
          node_locale
          __typename
          contentful_id
          subpages {
            contentful_id
            slug
            node_locale
          }
        }
      }
    }
  `)

  const pagesWithSubpages = pageSlugs?.pages?.nodes.filter(
    page => page?.subpages?.length
  )

  const getLocalizedPage = (page, locale) => {
    return page?.nodes?.find(node => node?.node_locale === locale)
  }

  return {
    ...pageSlugs,
    pagesWithSubpages,
    localizedAccoladesIndexPage: getLocalizedPage(pageSlugs?.accolades, locale),
    localizedNewsIndexPage: getLocalizedPage(pageSlugs?.news, locale),
    localizedOfficesIndexPage: getLocalizedPage(pageSlugs?.offices, locale),
    localizedPracticeAreasIndexPage: getLocalizedPage(
      pageSlugs?.practiceAreas,
      locale
    ),
    localizedProfessionalsIndexPage: getLocalizedPage(
      pageSlugs?.professionals,
      locale
    ),
    localizedEventsIndexPage: getLocalizedPage(pageSlugs?.events, locale),
    localizedInsightsIndexPage: getLocalizedPage(pageSlugs?.insights, locale),
  }
}

export default usePageSlugs
