import React from 'react'
import { Accordion } from '@components/Accordion'
import renderRichText from '@richtext'

export const StyledAccordion = ({ content, ...rest }) => {
  return content?.map(({ id, accordionSummary, accordionDetails }) => (
    <Accordion
      summary={accordionSummary}
      id={id}
      key={id}
      iconOrder={1}
      defaultClose
      contentMargin="0 0 45px"
    >
      {renderRichText(accordionDetails)}
    </Accordion>
  ))
}
