import { colors, sizes } from '@theme'
import styled, { css } from 'styled-components'

const labelStyles = css`
  display: block;
  padding: 0.8125rem 1.125rem 0.5rem;
`

const labelCheckboxStyles = css`
  line-height: 1.6;
  padding: 0;
  padding-right: 10px;
  padding-left: 0.75rem;
  width: calc(100% - 1rem);
  font-size: 16px;
`

const inputTextareaStyles = css`
  display: block;
  width: 100%;
  height: auto;
  padding: 13px 20px;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.375rem;
  color: ${colors.text.black};
  background: ${colors.white};
  border: 1px solid ${colors.border};
  border-color: ${props => (!props.isValid ? colors.error : colors.border)};
  outline: none;
  transition: all 0.15s ease-in-out;

  ::placeholder {
    color: ${colors.text.placeholder};
  }

  &:focus {
    border-color: ${colors.gold};
    outline: none;
  }
`

const inputCheckboxStyles = css`
  margin: 1rem 0;
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: baseline;
  position: relative;

  [type='checked'] {
    padding: 2px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox-styled {
    display: inline-block;
    order: -1;
    width: 1rem;
    height: 1rem;
    flex-basis: 1rem;
    background-color: ${colors.white};
    border-radius: 3px;
    border: 1px solid ${colors.border};
  }

  &:hover input ~ .checkbox-styled {
    background-color: ${colors.white};
  }

  & input:checked ~ .checkbox-styled {
    background-color: ${colors.secondary.purple};
    border-color: ${colors.secondary.purple};
  }

  .checkbox-styled:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkbox-styled:after {
    display: block;
  }

  & .checkbox-styled:after {
    top: 1px;
    left: 0;
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
`

export const StyledInput = styled.input`
  ${inputTextareaStyles}
`

export const StyledTextArea = styled.textarea`
  ${inputTextareaStyles}
  resize: none;
`

export const StyledSubmit = styled.input`
  font-family: 'HelveticaNeueLTStd-Bd', sans-serif;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .8125rem 1.25rem;
  color: ${colors.text.white};
  background-color: ${colors.navy};
  border: none;
  border-radius: 3px;
  transition: background-color 0.15s ease-in-out;
  text-decoration: none;
  outline: none;
  text-transform: uppercase;

  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  margin: ${({ center }) => (center ? '0 auto' : null)};
  width: 100%;

  // @media only screen and (min-width: ${sizes.medium.min}) {
    cursor: pointer;

    &:hover {
      background-color: ${colors.navy};
    }
  // }
`

export const StyledIcon = styled.button`
  background: none;
  border: none;
  outline: none;

  @media only screen and (min-width: ${sizes.medium.min}) {
    cursor: pointer;
  }
`

export const StyledLabel = styled.label`
  ${props => (props.type === 'checkbox' ? inputCheckboxStyles : null)}
`

export const StyledLabelDisplay = styled.span`
  ${props => (props.type === 'checkbox' ? labelCheckboxStyles : labelStyles)}
`

export const StyledErrorMessage = styled.div`
  display: block;
  color: ${colors.error};
  font-size: 0.75rem;
  padding: 0.375rem 1.125rem 0.8125rem;
  padding-left: ${({ type }) => (type === 'checkbox' ? '1.75rem' : null)};
`

export const StyledFormStatus = styled.div`
  display: block;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  width: 100%;
  &.success {
    color: #fff;
    background-color: ${colors.secondary.purple};
  }
  &.error {
    color: #fff;
    background-color: ${colors.primary.burgundy};
  }
`
