import styled from 'styled-components'
import { colors, sizes } from '@theme'
import { Col } from '../Globals'
import search from './search.svg'

export const SearchInput = styled.input`
  font-size: 15px;
  border: none;
  border: 1px solid ${colors.bg.border};
  border-radius: 32px;
  padding: 8px 16px;
  color: ${colors.text.navy};
  font-weight: 600;
  width: 100%;
  max-width: 240px;
  transition: all 0.2s ease-in-out;

  &:focus {
    box-shadow: 0 0 0 2px ${colors.bg.default}; 0 0 0 4px ${colors.bg.border};
    transition: all 0.2s ease-in-out;
    outline: 0;
  }

  &:active {
    transition: box-shadow 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    outline: 0;
  }
`

export const AnimatedSearchForm = styled.form`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
`

export const SearchInputIconOnly = styled.input`
  width: 16px;
  appearance: none;
  background: white;
  border: 0;
  cursor: pointer;
  color: ${colors.gray};
  font-size: 16px;
  font-weight: 300;
  font-family: inherit;
  background-image: url(${search});
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 4px;
  transition: width 0.2s ease; padding 0.2s ease; border 0.2 ease;
  padding: 10px 15px;
  background-position: 90%;

   &:focus {
    padding-right: 34px;
    padding-left: 25px;
    width: 9rem;
    outline: none;
    border-radius: 5px;
    border: 1px solid ${colors.gold};
    box-shadow: none;
    cursor: text;
  };

  &::-webkit-search-cancel-button{
    position: relative;
    display: none;
  }
`

export const StyledMediumSearchForm = styled.form`
  color: #000000;
  display: flex;
  padding: 8px 0px;
  flex-flow: row wrap;
  align-items: center;
  transition: all 0.3s ease-out;

  input {
    width: 100%;
    padding-right: 40px;
    height: 38px;
    min-width: 260px;
    border-radius: ${({ noShadow }) => (noShadow ? '0px' : '3px')};
    border: 1px solid ${colors.border};
    ${({ noShadow }) =>
      !noShadow &&
      'box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.11), 0 1px 2px 0 rgba(0,0,0,0.09)'};
    padding: 13px 18px;
    color: #27251f;
    font-size: 16px;
    line-height: 22px;
    background-color: #ffffff;
  }
`

export const CategoryCol = styled(Col)`
  white-space: nowrap;
  margin-right: 0px;
  @media (min-width: ${sizes.large.min}) {
    margin-right: 10px;
  }
`
