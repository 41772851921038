import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableHead from './components/TableHead'
import { StyledTableContainer, StyledTable } from './style'
import { stableSort, sortOrder } from '@utils'
import InfiniteTableBody from './components/InfiniteTableBody'
import TableBody from './components/TableBody'

const Table = ({
  rows,
  columns,
  noDataMessage,
  noInfiniteLoad = false,
  customSortHandler = null,
  enableSorting = false,
  ...rest
}) => {
  const [order, setOrder] = useState('asc')
  let orderBy = columns?.[0]?.sort || columns?.[0]?.id
  if (!rows) return null

  const initialCol =
    orderBy === 'cellData1' && columns?.[0]?.val?.toLowerCase() === 'name'
  const orderByTitle =
    initialCol &&
    rows.map(r => {
      const getLastName = r?.cellData1?.split(' ')
      const parseLastName =
        getLastName && getLastName.length > 3
          ? getLastName[2]
          : getLastName[getLastName.length - 1]
      return { ...r, title: parseLastName }
    })

  orderBy = (orderByTitle && 'title') || orderBy

  const rowsToDisplay =
    enableSorting && rows
      ? (customSortHandler && customSortHandler(rows, order, orderBy)) ||
        stableSort(orderByTitle || rows, sortOrder(order, orderBy))
      : rows

  const handleRequestSort = () => {
    let newOrder = 'desc'

    if (order === 'desc') {
      newOrder = 'asc'
    }
    setOrder(newOrder)
  }

  const TableBodyComponent = noInfiniteLoad ? TableBody : InfiniteTableBody

  return (
    <StyledTableContainer>
      <StyledTable aria-labelledby="tableTitle">
        <TableHead
          rows={rows}
          columns={columns}
          sortHandler={handleRequestSort}
          order={order}
          orderBy={orderBy}
          rowCount={rows.length}
          enableSorting={enableSorting}
          {...rest}
        />
        <TableBodyComponent
          rows={rowsToDisplay}
          columns={columns}
          noDataMessage={noDataMessage}
          {...rest}
        />
      </StyledTable>
    </StyledTableContainer>
  )
}

Table.propTypes = {
  values: PropTypes.shape(Object),
  order: PropTypes.string,
  orderBy: PropTypes.string,
}

Table.defaultProps = {
  noDataMessage: 'No data available for selected paramaters',
}

export default Table
