import React from 'react'
import { StyledContentContainer } from '../style'

const StackedInsightsBanner = ({ children, ...otherProps }) => {
  if (children && children.length > 2 && !children[1]) {
    return (
      <>
        <div>
          <StyledContentContainer flexDirection {...otherProps}>
            {children[0]}
          </StyledContentContainer>
        </div>
        {children[2]}
      </>
    )
  }
  return (
    <>
      <div>
        <StyledContentContainer flexDirection {...otherProps}>
          {children[0]}
        </StyledContentContainer>
        {children[1]}
      </div>
      {children[2]}
    </>
  )
}

export default StackedInsightsBanner
