import { gql } from '@apollo/client'
import { image } from '../../utils/gatsby/config'

const id = `
  sys {
    id
    firstPublishedAt
  }
`
const updatedAtSys = `
  sys {
    publishedAt
  }
`

const baseQueryNoSlug = `
  id: title
  title
  __typename
`
const baseQuery = `
  ${baseQueryNoSlug}
  slug
`
const baseQueryId = `
  ${id}
  ${baseQueryNoSlug}
  slug
`

const imageQuery = `
image {
  title
  url
}
`
const imageQuerySmall = `
image {
  title
  url(transform: {
    width: 50,
      height: 100,
  })
}
`

const onTable = `
... on Table {
  sys {
    id
  }
  title
  displayTitle
  numberOfColumns
  rowHeader {
    title
    cellData1
    cellData2
    cellData3
    cellData4
    cellData5
    cellData6
    cellData7
    cellData8
    cellData9
    cellData10
  }
  rowsCollection{
    items {
      ... on ComponentRow {
        title
        cellData1
        cellData2
        cellData3
        cellData4
        cellData5
        cellData6
        cellData7
        cellData8
        cellData9
        cellData10
      }
    }
  }
}
`
const onMediaEmbed = `
... on ComponentMediaEmbed  {
  sys {
    id
  }
  type
  title
  displayTitle
  width
  align
  wrapText
  modal
  url
  mediaCollection(limit: 1) {
    items {
      url
    }
  }
}
`
const List = `
... on List {
  sys {
    id
  }
  title
  listAppearance
}
`
const Accordion = `
... on Accordion {
  sys {
    id
  }
  title
  contentCollection (limit: 10) {
    items {
      ... on AccordionContent {
        accordionDetails {
          json
        }
        accordionSummary
      }
    }
  }
}
`
/*
const Banner = `
... on ModuleHeroBanner {
  sys {
    id
  }
  title
  image {
    url
  }
}
`
*/

const hyperlinkQuery = `
hyperlink {
  ... on Office {
    sys {
        id
      }
    slug
    __typename
  }
  ... on ArticleGroup {
    slug
    sys {
        id
      }
    __typename
  }
  ... on Event {
    sys {
        id
      }
    slug
    __typename
  }
  ... on PracticeArea {
    sys {
        id
      }
    slug
    __typename
  }
  ... on Professional {
    sys {
        id
      }
    slug
    __typename
    active
    name
  }
  ... on FilterPage {
    sys {
        id
      }
    slug
    __typename
  }
  ... on Page {
    sys {
        id
      }
    slug
    __typename
  }
  ... on News {
    sys {
        id
      }
    slug
    __typename
  }
  ... on Article {
    sys {
        id
      }
    slug
    __typename
    category {
      slug
    }
  }
}
`

const richTextQuery = `
  content {
    json
  }
`

// Used for detail templates
const richTextWithLinksQuery = `
  content {
    json
    links {
      entries {
        block {
          ${Accordion}
          ${List}
          ${onTable}
          ${onMediaEmbed}
        }
        ${hyperlinkQuery}
      }
    }
  }
`

//  Office
const office = `
  ${baseQuery}
  region
`

const officeLarge = `
  ${baseQuery}
  region
  address
  street
  city
  state
  postalCode
  country
  phone
  fax
  officeChairsCollection(limit: 6){
    items {
      ... on Professional {
        name
        title 
        slug
        active
        ${imageQuerySmall}
        __typename
      }
      }
  }
  description {
    json
    links {
      entries {
        block {
          ${Accordion}
          ${List}
          ${onMediaEmbed}
        }
        ${hyperlinkQuery}
      }
    }
  }
  linkedFrom{
    newsCollection(limit: 12){
      items {
        ... on News {
          title
          slug
          id: slug
          displayOnSites
          publicationDate
          sys {
            firstPublishedAt
            
          }
        }
      }
      
    }
  }
`
const onOfficeLarge = `
... on Office {
  ${office}
  address
  street
  city
  state
  postalCode
  country
  phone
  fax
  slug
}
`

const onOffice = `
... on Office {
  ${office}
}

`

const onProfessional = `
... on Professional {
  sys {
    id
  }
  id: slug
  title
  slug
  firstName
  lastName
  name
  email
  phone
  active
  __typename
  linkToEntry: __typename
  ${imageQuery}
}
`

const professionalSmall = `
... on Professional {
  ${baseQuery}
  name
  email
  phone
  active
  linkToEntry: __typename
  ${imageQuerySmall}
}
`

const language = `
  ${baseQueryNoSlug}
`
const onLanguage = `
  ... on Locale {
    ${language}
  }
`

// Chair component
const ComponentChair = `
... on ComponentChair {
  chairTitle
  professionalCollection(limit: 1) {
    items {
      ... on Professional {
        name
        title
        active
      }
    }
  }
}
`
//  Staff
const contentType3G402RzTniwCtAAtQoJfAeSmall = `
... on ContentType3G402RzTniwCtAAtQoJfAe {
  ${id}
  __typename
  name
  email
  department
  phone
  linkToEntry: __typename
}
`
// Staff Collection
const professionalsAndStaffCollectionLimit6 = `
  professionalsCollection(limit: 15) {
    items {
      ${professionalSmall}
      ${contentType3G402RzTniwCtAAtQoJfAeSmall}
      ${ComponentChair}
    }
  }
`

/*
// Practice Area
const practiceGroupsCollection = `
  practiceGroupsCollection {
    items {
      title
      slug
    }
  }
`
*/

const legacyPracticeAreaFields = `
  isOnByRegion
  isOnByPractice
  isOnByValueCreation
  isOnByValueProtection
  isOnByIndustry
  isInNewsletterRegistration
`
// Practice Area

const onPracticeAreaSmall = `
... on PracticeArea {
  ${baseQuery}
  isOnByPractice
  isOnByIndustry
  isOnByRegion
  isOnByValueCreation
  isOnByValueProtection
  practiceGroupsCollection(limit: 6) {
    items {
      ... on PracticeArea {
        title
        slug
        id: title
      }
    }
  }
}
`

const practiceArea = `
  ${baseQuery}
  displayOnSites
  ${legacyPracticeAreaFields}
  ${richTextWithLinksQuery}
  descriptionHtml
`

const practiceAreaLarge = `
  ${practiceArea}
  linkedFrom{
    articleCollection(limit: 12){
      items{
          ... on Article {
            sys {
              firstPublishedAt
            }
            title
            slug
            id: slug
            publicationDate
            category{
              slug
              title
            }
          }
      }
    }
    professionalCollection(limit: 100){
      items {
        ... on Professional {
          title
          slug
          name
          email
          title
          phone
          active
          firstName
          lastName
          relatedTitle {
            title
            titleGroup {
              title
              sortOrder
            }
          }
          ${imageQuerySmall}
          offices: officesCollection(limit: 3) {
            items {
            ... on Office {
              title
              phone
              slug
              } 
            }
          }
        }
      }
    }
    newsCollection(limit: 12){
      items {
        ... on News {
          title
          slug
          id: slug
          publicationDate
          sys {
            firstPublishedAt
            
          }
        }
      }
      
    }
    eventCollection(limit: 12){
      items {
        ... on Event {
          title
          slug
          id: slug
          publicationDate
          eventStart
        }
      }
    }
  }

`

// tbd add not working rn
// practiceAreasCollection(limit: 10) {
//   items {
//     ${onPracticeAreaSmall}
//   }
// }

// Event
const event = `
  ${baseQuery}
  ${updatedAtSys}
    type
    timeZone
    eventStart
    eventEnd
    timeZone
    allDayEvent
    isRecurrent
    recurrenceExpression
    location
    locationLiteral
    street
    city
    state
    country
    contactName
    contactEmail
    contactPhone
    contactCell
    registrationLink
    isFeatured
    associatedProfessionalsCollection(limit: 5){
      items {
        ${onProfessional}
      }
    }
    associatedPracticeAreasCollection(limit: 5){
      items {
        slug
        __typename
        title
        displayOnSites
      }
    }
    contentHtml
  ${richTextWithLinksQuery}
`
const eventSmall = `
  ${baseQuery}
`
const onEvent = `
  ... on Event {
    ${eventSmall}
  }
`
const onRecruitingEvent = `
  id: slug
  title
  eventStart
  eventEnd
  location
  contactName
  contactEmail
  contactCell
`
const onEventLarge = `
  ... on Event {
    ${eventSmall}
    type
    slug
    eventStart
    eventEnd
  }
`

// News
const news = `
    ${baseQuery}
    content {
      json
      links {
        entries {
          block {
            ${Accordion}
            ${List}
            ${onTable}
            ${onMediaEmbed}
            ${onProfessional}
          }
          ${hyperlinkQuery}
        }
      }
    }
    publicationDate
    contentHtml
`
const onNewsList = `
  ... on News {
    ${baseQuery}
    publicationDate
  }
`

//  Article
const onArticleGroup = `
  ... on ArticleGroup {
    ${baseQuery}
  }
`

const onArticleGroupInsightsAll = `
... on ArticleGroup {
  ${baseQuery}
  ${id}
  linkedFrom {
    articleCollection(limit:8, locale:$locale){
      items {
      ... on Article {
        id: slug
        sys {
          createdAt: firstPublishedAt
          id
        }
        publicationDate
        slug
        title
        category {
          slug
          title
        }
      }
    }
  }
}
}
`
const category = `
  category {
    ${baseQuery}
    subscribeLink
  }
`
const articleSmall = `
  ${baseQuery}
`

const componentImage = `
  ... on ComponentImage {

    __typename
    title
    image {
      url
    }
  }
`

const smallProfessionalQuery = `
  ... on Professional {
    title
    name
    __typename
    active
    ${imageQuery}
  }
`

const article = `
    ${baseQueryId}
    content {
      json
      links {
        entries {
          block {
            ${Accordion}
            ${List}
            ${onTable}
            ${onMediaEmbed}
            ${onProfessional}
          }
          ${hyperlinkQuery}
        }
      }
    }
    ${category}
    publicationDate
    bylineHtml
    contentHtml
    displayOnSites
    featuredImageCaptionAndAltText
    authorsCollection(limit: 5) {
      items {
        ${onProfessional}
      }
    }
    associatedProfessionalsCollection(limit: 6){
      items {
        ${onProfessional}
      }
    }
    forMoreInformationCollection(limit: 6){
      items {
        ${onProfessional}
      }
    }
    practiceAreasCollection(limit: 5) {
      items {
        ${onPracticeAreaSmall}
      }
    }
`

const featuredImage = `
    ${baseQueryId}
    featuredImageCaptionAndAltText
    featuredImage {
      ${componentImage}
      ${smallProfessionalQuery}
    }
`

const onArticleSmall = `
... on Article {
  ${articleSmall}
  category {
    slug
    title
  }
}
`

// UI Modules

const Contacts = `
  ... on Contacts {
    ${baseQueryNoSlug}
    displayTitle
    displayDepartment
    displayContact
    displayProfessionalOrDisplayChair
    imageSize
    ${professionalsAndStaffCollectionLimit6}
  }

`

const Disclaimer = `
  ... on Disclaimer {
    title
    content
  }
`

const column = `
  ... on Column {
    ${baseQueryNoSlug}
    sys {
      id
    }
    columnWidth
    contentHtml
    content {
      json
    }
  }
`

const columnsCollection = `
  columnsCollection(limit: 12) {
    items {
        ${column}
    }
  }
`
const ModuleTextBlock = `
  ... on ModuleTextBlock {
    ${baseQueryNoSlug}
    id: title
    imageBackground {
      title
      url
    }
    ${columnsCollection}
  }
`

const sidebarItem = `
  sys {
    id
  }
  title
  __typename
  type
  slug
  ${richTextQuery}
`

const sidebarItemsCollection = `
sidebarItemsCollection(limit: 12) {
  items {
    ${sidebarItem}
  }
}
`
const Sidebar = `
  ... on Sidebar {
    ${baseQueryNoSlug}
    theme
    ${sidebarItemsCollection}
  }
`

const ModuleHeroBanner = `
  ... on ModuleHeroBanner {
    ${baseQueryNoSlug}
    subtitle
    ${imageQuerySmall}
  }
`

// UI - Modules
const onPage = `
... on Page {
  ${baseQuery}
}

`

/*
const contentDestinationCollection = `
  contentDestinationCollection(limit: 1){
    items {
      ${onPage}
      ${onArticleSmall}
      ${onEvent}
      ${onArticleGroup}
    }
  }
`
*/

/*
const slide = `
... on Slide {
  title
  linkTitle
  subtitle
  ${imageQuerySmall}
  ${contentDestinationCollection}
}
`
*/
const slideLarge = `
... on Slide {
  title
  linkTitle
  subtitle
  ${imageQuerySmall}
  contentDestinationCollection(limit: 1){
    items {
      ... on Article {
          ${baseQueryId}
          ${updatedAtSys}
          ${category}
          excerpt
          displayOnSites
        }
      ${onArticleGroup}
    }
  }
}
`

/*
const slidesCollection = `
  slidesCollection(limit: 3){
    items {
      ${slide}
    }
  }
`
*/

//  Module - Carousel
//  TODO add${category}
const ModuleCarousel = `
  ... on ModuleCarousel {
    ${baseQueryNoSlug}
    layout
  }
`
const ModuleCarouselLarge = `
  ... on ModuleCarousel {
    ${baseQueryNoSlug}
    layout
    slidesCollection(limit: 4){
      items {
        ${slideLarge}
      }
    }
  }
`
const entriesCollection = `
  entriesCollection(limit: 3) {
    items {
      ${onPage}
      ${onArticleGroup}
      ${onArticleSmall}
      ${onNewsList}
      ${onPage}
      ${onEvent}
      ... on Office {
        sys {
          id
        }
        slug
        title
        __typename
      }
      ... on PracticeArea {
        sys {
          id
        }
        slug
        title
        __typename
      }
      ... on Professional {
        sys {
          id
        }
        slug
        title
        __typename
      }          
    }
  }
`
const slugCollection = `
  slugCollection(limit: 1) {
    items {
      ${onPage}
      ${onArticleGroup}
    }
  }
`
//  Module - Entries Block
const ModuleArticleBlock = `
... on ModuleArticleBlock {
  ${baseQueryNoSlug}
  layout
  backgroundColor
  displayTitle
  displayArticleTag
  ${slugCollection}
  ${entriesCollection}
}
`
//  Module - List
//  Module - Map
const ModuleMap = `
... on ModuleMap {
  ${baseQueryNoSlug}
  officesCollection(limit: 24) {
    items {
        ${onOffice}
    }
  }
}
`

const insightsSidebarCollection = `
sidebarItemsCollection(limit: 12) {
    items {
      ${onArticleGroupInsightsAll}
    }
  }
`
//  Module - Insights Sidebar
const ModuleInsightsSidebar = `
... on ModuleInsightsSidebar {
  ${baseQueryNoSlug}
  ${insightsSidebarCollection}
}
`

//  Module - Text Block

// Component - CTA Item
// article
// article category
// event
// news
// office
// page
// professional

const linksToCollection = `
  linksToCollection(limit: 1) {
    items {
      ${onPage}
      ${onArticleGroup}
      ${onArticleSmall}
      ${onNewsList}
      ${onEvent}
      ... on Office {
        sys {
          id
        }
        slug
        title
        __typename
      }
      ... on FilterPage {
        sys {
          id
        }
        slug
        title
        __typename
      }
      ... on PracticeArea {
        sys {
          id
        }
        slug
        title
        __typename
      }
      ... on Professional {
        sys {
          id
        }
        slug
        title
        __typename
      } 
    }
  }
`
// images make the query too big, need to individually size each image

// ${imageQuerySmall}
const ComponentCtaItem = `
... on ComponentCtaItem {
  ${baseQueryNoSlug}
  ${linksToCollection}
  ${imageQuery}
}
`
const ctaItemsCollection = `
  ctaItemsCollection(limit: 3) {
    items {
      ${ComponentCtaItem}
    }
  }
`

// Module - Hover CTA
const ModuleHoverCta = `
... on ModuleHoverCta {
  ${baseQueryNoSlug}
  ${ctaItemsCollection}
}
`

const onComponentTab = `
... on ComponentTab {
  ${baseQuery}
    modulesCollection(limit: 4) {
      items {
        ${ModuleHeroBanner}
        ${ModuleTextBlock}
        ${Contacts}
        ${Sidebar}
        ${Disclaimer}
      }
    }
  }
`
const tabsCollection = `
  tabsCollection(limit: 6) {
    items {
      ${onComponentTab}
    }
  }
`

//  Module - Tabs
const Tabs = `
... on Tabs {
  ${baseQueryNoSlug}
  ${tabsCollection}
}
`

// Other modules
// ${ModuleForm}
// ${ModuleInsightsSidebar}

// 10698 sizes
// remove
// 2431 - biggest
// ${ModuleCarousel}
// 1291 - next biggest
// ${ModuleHoverCta}
// 267
// ${ModuleHeroBanner}
// 8325-8227
// Disclaimer
// 10,046
// Tabs
//
// Map

// Page UI Modules from top level page
/**
 * Module banner
 * module carousel
 * module disclaimer
 * module entries block
 * module hover cta
 * odule insights sidebar
 * module map
 * module professionals block
 * module sidebar
 * odule tabs
 * module text block
 */

const modulesCollectionOrder = `
  modulesCollection(limit: 6){
    items{
      ${ModuleHeroBanner}
      ${Sidebar}
      ... on ModuleTextBlock {
        sys {
          id
        }
      }
      ... on ModuleTextBlock {
        columnsCollection {
          items {
            sys {
              id
            }
            columnWidth
            title
            contentHtml
            content {
              json
            }
          }
        }
      }
      ${Contacts}
      ${ModuleArticleBlock}
      ${ModuleCarousel}
      ... on Tabs {
          sys {
            id
          }
          __typename
        }
      ${Disclaimer}
      ${ModuleMap}
      ... on ModuleHoverCta {
        ${baseQueryNoSlug}
      }
    }
  }
 `

const modulesCollectionInsights = `
 modulesCollection(limit: 12){
   items{
     ${ModuleHeroBanner}
     ${ModuleInsightsSidebar}
     ... on ModuleTextBlock {
       sys {
         id
       }
     }
     ${Contacts}
     ${ModuleArticleBlock}
     ${ModuleCarouselLarge}
     ... on Tabs {
         sys {
           id
         }
       }
     ${ModuleHoverCta}
   }
 }
`

const modulesCollectionTabs = `
  id: title
  modulesCollection(limit: 12) {
    items {
      ${Tabs}
    }
  }
`

const modulesCollectionTextBlock = `
  id: title
  modulesCollection(limit: 12){
    items{
      ${ModuleTextBlock}
    }
  }
`

// bigger queries
const professionalLarge = `
  ${baseQueryId}
  ${updatedAtSys}
  description
  firstName
  lastName
  name
  email
  company
  overrideCompany
  phone
  fax
  secondaryPhone
  secondaryFax
  linkToEntry: __typename
  speakingEngagements {
    json
  }
  involvement {
    json
  }
  recentRepresentations {
    json
  }
  overview: bio {
    json
    links {
      entries {
        ${hyperlinkQuery}
      }
    }
  }
  bioAccolades {
    json
  }
  bioEducation {
    json
  }
  speakingEngagementsHtml
  involvementHtml
  recentRepresentationsHtml
  overviewHtml: bioHtml
  bioAccoladesHtml
  bioEducationHtml
  languagesCollection(limit: 4) {
    items {
      ${onLanguage}
    }
  }
  practiceAreasCollection(limit: 10) {
    items {
      ${onPracticeAreaSmall}
    }
  }
  officesCollection(limit: 3){
    items {
      ... on Office {
        title
        address
        phone
        fax
        slug
        id: slug
        city
        street
        state
        postalCode
        country
      }
    }
  }
  educationCollection(limit: 5) {
    items {
      ... on ProfessionalEducation {
        ${id}
        schoolName
        degree
        year
      }
    }
  }
  admissionsCollection(limit: 5) {
    items {
      ... on ProfessionalAdmission {
        ${id}
        title
      }
    }
  }
  linksCollection(limit: 6){
    items {
      ... on Link {
        sys {
          id
        }
        type
        url
        display
        link
      }
    }
  }
  linkedFrom (allowedLocales: ["en-US","en-GB","ko","ja","fr","zh","de"]) {
    filteredNews: newsCollection(limit: 50){
      items {
        ... on News {
          sys {
            id
          }
          title
          ${updatedAtSys}
          slug
          displayOnSites
        }
      }
    }
    filteredEvents: eventCollection(limit: 50){
      items {
        ... on Event {
          sys {
            id
          }
          title
          slug
          eventStart
          timeZone
          displayOnSites
        }
      }
    }
    filteredInsights: articleCollection(limit: 50){
      items {
        ... on Article {
          sys {
            id
          }
          title
          slug
          publicationDate
          ${updatedAtSys}
          displayOnSites
          category {
            title
            slug
            shortDescription
            subscribeLink
          }
        }
      }
    }
  }
  ${imageQuery}
`

//  Page
const page = `
  ${baseQuery}
  ${modulesCollectionOrder}
  displayOnSites
`

const smallPageQuery = `
  ${baseQuery}
  modulesCollection(limit: 3){
    items{
      ${ModuleHeroBanner}
      ... on ModuleTextBlock {
        sys {
          id
        }
      }
      ${ModuleHoverCta}
    }
  }
  displayOnSites
`

const insightsPage = `
  ${baseQuery}
  ${modulesCollectionInsights}
  displayOnSites
`

// module queries
export const columnsQuery = `
query ColumnsQuery($locale: String!, $ids: [String!]) {
  preview: columnCollection(
    locale: $locale,
    preview: true,
    limit: 6,
    where: {
        sys: {
          id_in: $ids
          }, 
    }
    
    ){
  columns: items{
    ... on Column {
      title
      content {
        links{
          entries {
            inline {
              ... on ComponentMediaEmbed {
                title
                displayTitle
                width
                align
                wrapText
                caption
                modal
                url
                mediaCollection(limit:1, preview:true){
                  items{
                    ... on Asset {
                      url
                    }
                  }
                }
                
              }
            }
            block {
              ... on ComponentMediaEmbed {
                title
                displayTitle
                width
                align
                wrapText
                caption
                modal
                url
                mediaCollection(limit:1, preview:true){
                  items{
                    ... on Asset {
                      url
                    }
                  }
                }
                
              }
            }
          }
          assets {
            block {
              title
              url(transform: {
                width: 50,
                  height: 100,
              })
            }
          }
        }
        
      }
    }
  }
}
}
`

export const events = `
query($loc: String!, $display: String!) {
  page: pageCollection(
    filter: {contentful_id: { eq:"1iNTN1YVOUMwz2QNZmudo3"}}){
    nodes {
      slug
      node_locale
    }
  }
    events: eventCollection(filter: {
      node_locale: {eq: $loc},
      displayOnSites: {in: [$display]},
    },
    sort: {fields: [updatedAt], order: DESC},
    ){
      edges {
        node {
          displayOnSites
          ${baseQuery}
          updatedAt(formatString: "MMMM DD, YYYY")
          title
          type
          timeZone
          eventStart
          eventEnd
          location
          street
          city
          state
          country
          registrationLink
          content {
            json
          }
        }
      }
    }
  }
`

export const professionals = `
{
  page: pageCollection(filter: {
  contentful_id: { eq:"1WMCfztCvBPa3ywF0FjyRi"}
  }){
    nodes {
      slug
      node_locale
    }
  }
  professionals: professionalCollection(
   ) {
    edges {
      node {
        links {
          type
          url
          link
        }
      }
    }
  }
  events: eventCollection(
    sort: { fields: [eventStart], order: DESC }
  ) {
    edges {
      node {
        id: contentful_id
        title
        slug
        eventStart
        node_locale
        displayOnSites
      }
    }
  }
  insights: articleCollection(
    sort: { fields: [createdAt], order: DESC }
  ) {
    edges {
      node {
        title
        id: contentful_id
        updatedAt
        slug
        category {
          title
          slug
        }
        node_locale
        displayOnSites
        associatedProfessionals {
          contentful_id
        }
      }
    }
  }
  news: newsCollection(
    sort: { fields: [publicationDate], order: DESC }
  ) {
    edges {
      node {
        title
        id: contentful_id
        updatedAt
        publicationDate
        slug
        node_locale
        displayOnSites
        associatedProfessionals {
          contentful_id
        }
      }
    }
  }
}
`

const textBlockQuery = `
  query TextBlockQuery($locale: String!, $id: String!) {
    preview: page(id: $id, locale: $locale, preview: true){
        ${modulesCollectionTextBlock}
    }
  }
`
const tabsQuery = `
  query TabsQuery($locale: String!, $id: String!) {
    preview: page(id: $id, locale: $locale, preview: true){
      ${modulesCollectionTabs}
    }
  }
`

const CarouselHoverCtaQuery = `
query PageQuery($locale: String!, $id: String!) {
  preview: page(id: $id, locale: $locale, preview: true){
    ${baseQuery}
    modulesCollection(limit: 6){
      items {
        ${ModuleHoverCta}
        ... on ModuleCarousel {
          ${baseQueryNoSlug}
          id:title
          layout
          slidesCollection(limit: 3){
            items {
              ... on Slide {
                title
                linkTitle
                subtitle
                ${imageQuery}
                contentDestinationCollection(limit: 1){
                  items {
                    ${onPage}
                    ${onArticleSmall}
                    ${onEvent}
                    ${onArticleGroup}
                    ${onNewsList}
                    ... on Office {
                      sys {
                        id
                      }
                      slug
                      __typename
                    }
                    ... on Link {
                      url
                      displayText: link
                      type
                      isProfessionalLink
                      display
                    }
                    ... on PracticeArea {
                      sys {
                        id
                      }
                      slug
                      __typename
                    }
                    ... on Professional {
                      sys {
                        id
                      }
                      slug
                      __typename
                    }
                    ... on FilterPage {
                      sys {
                        id
                      }
                      slug
                      __typename
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    displayOnSites
  }
}

`

const pageQuery = `
  query PageQuery($locale: String!, $id: String!) {
    preview: page(id: $id, locale: $locale, preview: true){
        ${page}
    }
  }
`
const modulesQuery = `
  query ModulesQuery($locale: String!, $id:String!) {
    preview: page(
      locale: $locale,
      id: $id,
      preview: true,
      ){
        slug
        ${modulesCollectionOrder}
    }
  }
`

// detail queries

const insightQuery = `
  query ArticleQuery($locale: String!, $id: String!, $parentId: String!) {
    previewParent: page(id: $parentId, locale: $locale, preview: true){
      subscribeLink
      id: slug
      title
      slug
    }
    preview: article(
      id: $id,
      locale: $locale,
      preview: true,
    ){
      ${article}
    }
  }
`

const featuredImageQuery = `
query FeaturedImageQuery($locale: String!, $id: String!) {
  preview: article(
    id: $id,
    locale: $locale,
    preview: true,
  ){
    ${featuredImage}
  }
}

`

const previewInsightQuery = `
  query ArticleQuery($locale: String!, $id: String!, $parentId: String!) {
    previewParent: page(id: $parentId, locale: $locale, preview: true){
      subscribeLink
      id: title
      slug
    }
    preview: article(
      id: $id,
      preview: true,
      locale: $locale
    ){
      ${article}
    }
  }
`
const officeQuery = `
  query OfficeQuery($locale: String!, $id: String!) {
    preview: office(
      id: $id,
      locale: $locale,
      preview: true
    ){
      ${officeLarge}
    }
  }
`
const practiceAreaQuery = `
  query PracticeAreaQuery($locale: String!, $id: String!, $parentId: String!) {
    previewParent: page(id: $parentId, locale: $locale, preview: true){
      subscribeLink
      id: title
      slug
    }
    preview: practiceArea(
      id: $id,
      locale: $locale,
      preview: true
    ){
      ${practiceAreaLarge}
    }
  }
`
const newsQuery = `
  query NewsQuery($locale: String!, $id: String!, $parentId: String!) {
    previewParent: page(id: $parentId, locale: $locale, preview: true){
      subscribeLink
      id: slug
      title
      slug
      modulesCollection(limit: 2) {
        items {
          ... on Contacts {
            title
            professionalsCollection(limit: 5) {
              items {
                ... on ContentType3G402RzTniwCtAAtQoJfAe {
                  __typename
                  name
                  email
                  department
                  phone
                  linkToEntry: __typename
                }
              }
            }
          }
        }
      }
    }
    preview: news(
      id: $id,
      locale: $locale,
      preview: true
    ){
      ${news}
      sys {
        firstPublishedAt
      }
      associatedPracticeAreasCollection(limit: 5){
        items {
          slug
          __typename
          title
          displayOnSites
        }
      }
    }
  }
`
const eventQuery = `
  query EventQuery($locale: String!, $id: String!, $parentId: String!) {
    previewParent: page(id: $parentId, locale: $locale, preview: true){
      subscribeLink
      id: slug
      title
      slug
    }
    preview: event(
      id: $id,
      locale: $locale,
      preview: true
    ){
        ${event}
    }
  }
`

const professionalQuery = `
  query ProfessionalQuery($locale: String!, $id: String!, $parentId: String!) {
    previewParent: page(id: $parentId, locale: $locale, preview: true){
      subscribeLink
      id: title
      title
      slug
    }
    previewEnglish: professional(locale:"en-US", id: $id)
  	{
    	name
  	}
    preview: professional(
      id: $id,
      locale: $locale,
      preview: true
      ){
      ${professionalLarge}
    }
  }
`

// list queries
const officesQuery = `
  query OfficesQuery($locale: String!) {
    preview: officeCollection(
      locale: $locale,
      preview: true,
      limit: 30
    ){
      items {
        ${onOfficeLarge}
      }
    }
  }
`
const eventsQuery = `
  query EventsQuery($locale: String!, $display: String!) {
    preview: eventCollection(
      locale: $locale,
      preview: true,
      limit: 60,
      where: {
        displayOnSites_contains_some: [$display]
      }
      ){
      items {
        ${onEventLarge}
      }
    }
  }
`
const newsListQuery = `
  query NewsListQuery($locale: String!, $display: String!) {
    preview: newsCollection(
      locale: $locale,
      preview: true,
      limit: 60,
      where: {
        displayOnSites_contains_some: [$display]
      }
      ){
      items {
        ${onNewsList}
      }
    }
  }
`

const accoladesQuery = `
  query AccoladesQuery($locale: String!, $display: String!, $id: String!) {
    previewPage: page(id: $id, locale: $locale, preview: true){
      ${smallPageQuery}
    }
    preview: articleCollection(
      locale: $locale,
      preview: true,
      limit: 60,
      where: {
        category: {
          title_in: "Accolades"
        }
        displayOnSites_contains_some: [$display]
      }
      ){
        items {
            id: slug
            publicationDate
            slug
            title
            category {
              title
              slug
            }
        }
    }
  }
`

const practiceAreasQuery = `
  query PracticeAreasQuery($locale: String!, $id: String!) {
      preview: page(id: $id, locale: $locale, preview: true){
        ${smallPageQuery}
      }
  items: practiceAreaCollection(
      locale: $locale,
      preview: true,
      limit: 100,
      order: [title_ASC],
      ){
      items {
      ${onPracticeAreaSmall}
    }
  }
}
`

const professionalsQuery = `
  query PracticeAreasQuery($locale: String!, $id: String!) {
      preview: page(id: $id, locale: $locale, preview: true){
            ${page}
      }
items: professionalTitleGroupCollection(
      locale: $locale,
      preview: true,
      ){
      items {
      ... on ProfessionalTitleGroup {
        title
        sortOrder
      }
    }
  }
}
`

/**
 * get the page
 * --includes UI modules
 * get the sidebarInsights
 * if the article Catgeory is linked on insights sidebar
 * get those articles to display
 *
 * for search bar, display pageContext results
 */

const insightsListQuery = `
query InsightsAllQuery($locale: String!, $id: String!) {
  preview: page(id: $id, locale: $locale, preview: true){
    ${insightsPage}
  } 
}
`
const insightsBlogQuery = `
query BlogQuery($locale: String!, $id: String!, $parentId: String!) {
  previewParent: page(id: $parentId, locale: $locale, preview: true){
    subscribeLink
    title
    slug
    id: slug
  }
  preview: articleGroup(locale: $locale, id: $id, preview: true) {
    title
    shortDescription
    subscribeLink
    description {
      json
    }
    authorCollection(limit: 6, locale: $locale) {
      items {
        ... on Professional {
          title
          name
          email
          phone
          slug
          active
          image {
            url
          }
        }
      }
    }
    linkedFrom (allowedLocales: ["en-US","en-GB","ko","ja","fr","zh","de"]){
      articleCollection (limit: 500) {
        items {
          title
          id: slug
          publicationDate
          slug
          title
          bylineHtml
          excerpt
          displayOnSites
          authorsCollection(limit: 1) {
            items {
              ... on Professional {
                name
                slug
              }
            }
          }
          category {
            slug
            title
          }
        }
      }
    }
  }
}
`
const eventsRecruitingQuery = `
  query EventsRecruitingQuery($locale: String!, $display: String!, $id: String!) {
    preview: page(id: $id, locale: $locale, preview: true){
      ${page}
    }
    items: eventCollection(
      locale: $locale,
      where:{
        type: "Recruiting",
        displayOnSites_contains_some: [$display]
      }
      preview: true,
      order: [eventStart_DESC, title_ASC],
      limit: 60
      ){
      items {
        ${onRecruitingEvent}
      }
    }
  }
  `

// embedded rich text later queries
const assetQuery = `
query AssetQuery($id: String!) {
  preview: asset(id: $id, preview: true){
    sys {
      id
    }
    title
    url
  }
}
`
const mediaQuery = `
query MediaQuery($id: String!) {
  preview: componentMediaEmbed(id: $id, preview: true){
    sys {
      id
    }
    title
    displayTitle
    url
    slug
    width
    align
    originalPath
    modal
    caption
    mediaCollection(limit: 1){
      items {
        ... on Asset {
          url
        }
      }
    }
  }
}
`

// ${Disclaimer}
// ${ModuleHeroBanner}

const columnQuery = `
query ColumnQuery($id: String!) {
  preview: column(id: $id, preview: true) {
    content{
      links {
        entries {
          inline{
            __typename
            sys {
              id
            }
          }
          block {
            __typename
            sys {
              id
            }
            ${onTable}
            ${Accordion}
            ${Contacts}
            ${List}
            ${onMediaEmbed}
          }
          hyperlink {
            __typename
            sys {
              id
            }
            ... on Link {
              url
              display
              link
            }
            ... on Page {
              slug
            }
          }
        }
      }
    }
  }
}
`

const sidebarItemQuery = `
query SidebarItemQuery($id: String!) {
  preview: sidebarItem(id: $id, preview: true){
  content{
    links {
      entries {
        inline{
          __typename
          sys {
            id
          }
          ${Accordion}
          ${onMediaEmbed} 
        }
        block {
          __typename
          sys {
            id
          }
          ${onTable}
          ${Contacts}
          ${Accordion}
          ${List}
          ${onMediaEmbed}
        }
        hyperlink {
          __typename
          sys {
            id
          }
          ... on Page {
            slug
            sys {
              id
            }
          }
          ... on Link {
            url
            display
            link
          }
        }
      }
    }
  }
  }
}
`

const gridEntries = `
query	GridEntriesQuery($locale: String!, $id: String!) {
  preview: filterPage(id: $id,locale: $locale, preview: true) {
    gridEntriesCollection (limit: 1) {
      items {
        entriesCollection (limit: 150) {
          items {
            ... on Article {
              sys {
                id
              }
              title 
              publicationDate
              category {
                 __typename
                slug
              }
              tagsCollection (limit: 1){
                items {
              		title
              	}
              }
              content {
                json
                links {
                  entries {
                    block {
                      ... on Professional {
                        name
                        title
                        slug
                        __typename
                        image {
                          url
                        }
                        sys {
                          id
                        }
                      }
                      ... on ComponentMediaEmbed {
                        sys {
                          id
                        }
                        type
                        title
                        displayTitle
                        width
                        align
                        wrapText
                        modal
                        url
                        mediaCollection(limit: 1) {
                          items {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

const filterPageQuery = `
query	FilterPageQuery($locale: String!, $id: String!) {
  preview: filterPage(id: $id,locale: $locale, preview: true) {
    appearance
    title
    displaySidebar
    introductionModulesCollection (limit: 3) {
      items {
        ... on ModuleTextBlock {
          title
          columnsCollection (limit: 2) {
            items {
              title
              columnWidth
    					content {
      					json
    					}
            }
          }
        }
        ... on ModuleHeroBanner {
            title
          image {
  					title
  					url(transform: {
    						width: 50,
      					height: 100,
  					})
					}
        }
      }
    }
    bodyModulesCollection (limit: 3) {
      items {
        ... on ModuleHoverCta {
          layout
          title
          ctaItemsCollection (limit: 4) {
            items {
              title
              description
              externalLink
              linksToCollection (limit: 2) {
                items {
                  ... on Page {
                    slug
                    __typename
                  }
                  ... on ArticleGroup {
                    slug
                    __typename
                  }
                }
              }
              image {
                url
              }
            }
          }
        }
      }
    }
    filterCategoriesCollection(limit: 5) {
      items {
        displayLabel
        linksToCollection (limit: 10) {
          items {
            title
            externalLink
          }
        }
      }
    }
    professionalsCollection (limit: 25) {
      items {
        name
        slug
        email
        phone
        title
        active
        image {
          url
        }
      }
    }
    featuredCategory {
      displayLabel
      linksToCollection{
        items {
          title
        }
      }
    }
  }
}
`

const entriesWithTags = `
  query {
    eventCollection(where: {tagsCollection_exists: true}, limit: 300, order:publicationDate_DESC) {
      items {
        title
        slug
        contentfulType: __typename
        publicationDate
        tagsCollection(limit: 2) {
          items {
            title
          }
        }
      }
    }
    articleCollection(where: {tagsCollection_exists: true}, limit: 500, order:publicationDate_DESC) {
      items {
        title
        slug
        contentfulType: __typename
        publicationDate
        category {
          slug
          title
        }
        tagsCollection(limit: 2) {
          items {
            title
          }
        }
      }
    }
  }
`

const entriesQuery = `
  ${onOffice}
  ${onArticleGroup}
  ${onEvent}
  ${onPage}
  ${onNewsList}
  ... on PracticeArea {
    sys {
      id
    }
    slug
    __typename
  }
  ... on Professional {
    sys {
      id
    }
    slug
    __typename
  }
  ... on FilterPage {
    sys {
      id
    }
    slug
    __typename
  }
  ... on Article {
    sys {
      id
    }
    slug
    __typename
    category {
      slug
    }
  }
`

const embedQuery = `
query	MediaQuery($id: String!) {
  componentMediaEmbed(id: $id, preview: true) {
    internalLinkCollection(limit: 1) {
      items {
        __typename
        ${entriesQuery}
      }
    }
  }
}
`
const listQuery = `
query	ListQuery($id: String!) {
  list(id: $id, preview: true) {
    listItemsCollection(limit: 10) {
      items {
        title
        subtitle
        linksToCollection(limit: 1) {
          items {
            __typename
            ${entriesQuery}
          }
        }
      }
    }
  }
}
`

const practiceAreaSidebar = `
query PracticeAreaQuery($locale: String!, $id: String!) {
  preview: practiceArea(
    id: $id,
    locale: $locale,
    preview: true
  ){
    featuredCalloutsCollection(limit: 8) {
      items {
        ... on List {
          listAppearance
          listItemsCollection(limit: 10) {
            items {
              title
              subtitle
              linksToCollection(limit: 1) {
                items {
                  ${entriesQuery}
                }
              }
            }
          }
          sys {
            id
          }
          title
        }
        ... on ComponentMediaEmbed {
          type
          title
          displayTitle
          width
          align
          wrapText
          modal
          url
          internalLinkCollection(limit: 1) {
            items {
              ${entriesQuery}
            }
          }
          mediaCollection(limit: 1) {
            items {
              url
            }
          }
        }
      }
    }
    practiceChairTitle
    relatedPracticeAreasCollection(limit: 5) {
      items {
        title
        slug
        __typename
      }
    }
    practiceChairsCollection(limit: 5) {
      items {
        chairTitle
        professionalCollection(limit: 1) {
          items {
            __typename
            image {
              url
            }
            title
            slug
            name
            active
          }
        }
      }
    }
  }
}
`

const igniteCtaFragment = gql`
  fragment Ignite_CTA_Fragment on IgniteComponentCta {
    ctaText
    linksTo {
      ... on Article {
        category {
          slug
        }
        type: __typename
        slug
      }
      ... on Page {
        type: __typename
        slug
      }
      ... on PracticeArea {
        type: __typename
        slug
      }
    }
    externalUrl
  }
`

const igniteAssetFragment = gql`
  fragment Asset_Fragment on Asset {
    url
    title
    description
    contentType
  }
`

const igniteModuleCarouselFragment = gql`
  fragment Ignite_Module_Carousel_Fragment on IgniteModuleCarousel {
    type: __typename
    internalName
    slug
    slidesCollection(limit: 10) {
      items {
        ... on IgniteComponentSlide {
          title
          sys {
            id
          }
          slideText {
            json
          }
          background {
            ...Asset_Fragment
          }
          cta {
            ...Ignite_CTA_Fragment
          }
        }
      }
    }
  }
  ${igniteCtaFragment}
  ${igniteAssetFragment}
`

const igniteModuleArticleNewsSectionFragment = gql`
  fragment Ignite_Module_Article_News_Section_Fragment on IgniteModuleArticleNewsSection {
    type: __typename
    title
    slug
    subtitle
    backgroundImage {
      ...Asset_Fragment
    }
  }
  ${igniteAssetFragment}
`

const igniteModuleTextSectionFragment = gql`
  fragment Ignite_Module_Text_Section_Fragment on IgniteModuleTextSection {
    type: __typename
    title
    slug
    cta {
      ...Ignite_CTA_Fragment
    }
  }
  ${igniteCtaFragment}
`

const igniteModuleOfficesSectionFragment = gql`
  fragment Ignite_Module_Offices_Section_Fragment on IgniteModuleOfficesSection {
    type: __typename
    title
    slug
    subtitle
    image {
      ...Asset_Fragment
    }
    cta {
      ...Ignite_CTA_Fragment
    }
  }
  ${igniteAssetFragment}
  ${igniteCtaFragment}
`

const igniteComponentCardFragment = gql`
  fragment Ignite_Component_Card_Fragment on IgniteComponentCard {
    title
    body
    iconImage {
      ...Asset_Fragment
    }
    cta {
      ...Ignite_CTA_Fragment
    }
  }
  ${igniteAssetFragment}
  ${igniteCtaFragment}
`

const igniteComponentExpertiseShowcaseFragment = gql`
  fragment Ignite_Component_Expertise_Showcase_Fragment on IgniteComponentExpertiseShowcase {
    internalName
    headerText
    body
    type: __typename
    cardsCollection(limit: 20) {
      items {
        ...Ignite_Component_Card_Fragment
      }
    }
    cta {
      ...Ignite_CTA_Fragment
    }
  }
  ${igniteComponentCardFragment}
  ${igniteCtaFragment}
`

const igniteComponentProfessionalsShowcaseFragment = gql`
  fragment Ignite_Component_Professionals_Showcase_Fragment on IgniteComponentProfessionalsShowcase {
    internalName
    type: __typename
    professionalsCollection(limit: 10) {
      items {
        ... on Professional {
          name
          slug
          firstName
          middleName
          lastName
          title
          email
          image {
            ...Asset_Fragment
          }
        }
      }
    }
    cta {
      ...Ignite_CTA_Fragment
    }
  }
  ${igniteAssetFragment}
  ${igniteCtaFragment}
`

const igniteModuleImageBackgroundSectionFragment = gql`
  fragment Ignite_Module_Image_Background_Section_Fragment on IgniteModuleImageBackgroundSection {
    type: __typename
    title
    slug
    subtitle
    backgroundImage {
      ...Asset_Fragment
    }
    showcase {
      ...Ignite_Component_Expertise_Showcase_Fragment
      ...Ignite_Component_Professionals_Showcase_Fragment
    }
  }
  ${igniteAssetFragment}
  ${igniteComponentExpertiseShowcaseFragment}
  ${igniteComponentProfessionalsShowcaseFragment}
`

const igniteComponentModalItemFragment = gql`
  fragment Ignite_Component_Modal_Item_Fragment on IgniteComponentModalItem {
    sys {
      id
    }
    title
    body
    image {
      ...Asset_Fragment
    }
    cta {
      ...Ignite_CTA_Fragment
    }
  }
  ${igniteAssetFragment}
  ${igniteCtaFragment}
`

const igniteModuleModalSectionFragment = gql`
  fragment Ignite_Module_Modal_Section_Fragment on IgniteModuleModalSection {
    type: __typename
    headerText
    slug
    subtitle
    modalHeader
    modalItemsCollection {
      items {
        ...Ignite_Component_Modal_Item_Fragment
      }
    }
  }
  ${igniteComponentModalItemFragment}
`

const igniteQuery = gql`
  query igniteQuery($id: String!, $locale: String!) {
    ignitePage(id: $id, locale: $locale, preview: true) {
      sys {
        id
      }
      title
      pageModulesCollection(limit: 20) {
        items {
          ...Ignite_Module_Modal_Section_Fragment
          ...Ignite_Module_Carousel_Fragment
          ...Ignite_Module_Text_Section_Fragment
          ...Ignite_Module_Article_News_Section_Fragment
          ...Ignite_Module_Image_Background_Section_Fragment
          ...Ignite_Module_Offices_Section_Fragment
        }
      }
    }
  }
  ${igniteModuleModalSectionFragment}
  ${igniteModuleCarouselFragment}
  ${igniteModuleTextSectionFragment}
  ${igniteModuleArticleNewsSectionFragment}
  ${igniteModuleImageBackgroundSectionFragment}
  ${igniteModuleOfficesSectionFragment}
`

const igniteComponentNavigationItemFragment = gql`
  fragment Ignite_Component_Navigation_Item_Fragment on IgniteComponentNavigationItem {
    displayText
    navigationLink {
      ... on IgniteModuleCarousel {
        type: __typename
        slug
      }
      ... on IgniteModuleImageBackgroundSection {
        type: __typename
        slug
      }
      ... on IgniteModuleArticleNewsSection {
        type: __typename
        slug
      }
      ... on IgniteModuleModalSection {
        type: __typename
        slug
      }
      ... on IgniteModuleOfficesSection {
        type: __typename
        slug
      }
      ... on IgniteModuleTextSection {
        type: __typename
        slug
      }
      ... on Page {
        type: __typename
        slug
      }
    }
    externalUrl
  }
`

const igniteNavArticleNewsOfficeQuery = gql`
  query igniteNavArticleNewsOfficeQuery($locale: String!) {
    igniteModuleNavigationCollection(locale: $locale, limit: 1, preview: true) {
      items {
        type: __typename
        title
        navigationItemsCollection(limit: 10) {
          items {
            ...Ignite_Component_Navigation_Item_Fragment
          }
        }
      }
    }
    practiceAreaCollection(
      locale: $locale
      limit: 1
      preview: true
      where: { sys: { id: "4628ee69-2334-6428-811c-ff00004cbded" } }
    ) {
      items {
        linkedFrom {
          articleCollection(limit: 100) {
            total
            items {
              sys {
                id
                firstPublishedAt
              }
              title
              slug
              publicationDate
              displayOnSites
              category {
                slug
              }
              __typename
            }
          }
          newsCollection(limit: 100) {
            total
            items {
              sys {
                id
                firstPublishedAt
              }
              title
              slug
              publicationDate
              displayOnSites
              __typename
            }
          }
        }
      }
    }
    officeCollection(locale: $locale, limit: 50, preview: true) {
      items {
        title
      }
    }
    practiceArea(id: "4628ee69-2334-6428-811c-ff00004cbded", locale: $locale) {
      slug
    }
  }
  ${igniteComponentNavigationItemFragment}
`

export const COLUMN_QUERY = gql`
  ${columnQuery}
`

export const SIDEBAR_ITEM_QUERY = gql`
  ${sidebarItemQuery}
`

export const MEDIA_QUERY = gql`
  ${mediaQuery}
`

export const ASSET_QUERY = gql`
  ${assetQuery}
`

// detail templates
export const NEWS_LIST_QUERY = gql`
  ${newsListQuery}
`

export const EVENT_QUERY = gql`
  ${eventQuery}
`

export const OFFICE_QUERY = gql`
  ${officeQuery}
`

export const NEWS_QUERY = gql`
  ${newsQuery}
`

export const PRACTICE_AREA_QUERY = gql`
  ${practiceAreaQuery}
`

export const PRACTICE_AREA_SIDEBAR_QUERY = gql`
  ${practiceAreaSidebar}
`

export const ARTICLE_QUERY = gql`
  ${insightQuery}
`

export const FEATURED_IMAGE_QUERY = gql`
  ${featuredImageQuery}
`

export const PREVIEW_ARTICLE_QUERY = gql`
  ${previewInsightQuery}
`
export const PROFESSIONAL_QUERY = gql`
  ${professionalQuery}
`

// list templates
export const OFFICES_QUERY = gql`
  ${officesQuery}
`

export const PRACTICE_AREAS_QUERY = gql`
  ${practiceAreasQuery}
`

export const PROFESSIONALS_QUERY = gql`
  ${professionalsQuery}
`

export const EVENTS_QUERY = gql`
  ${eventsQuery}
`
export const EVENTS_RECRUITING_QUERY = gql`
  ${eventsRecruitingQuery}
`

export const INSIGHTS_ALL_QUERY = gql`
  ${insightsListQuery}
`

export const BLOG_QUERY = gql`
  ${insightsBlogQuery}
`

export const ACCOLADES_QUERY = gql`
  ${accoladesQuery}
`

// standalone templates
export const COLUMNS_QUERY = gql`
  ${columnsQuery}
`
export const CAROUSEL_HOVERCTA_QUERY = gql`
  ${CarouselHoverCtaQuery}
`

export const PAGE_QUERY = gql`
  ${pageQuery}
`

// modules queries
export const MODULES_QUERY = gql`
  ${modulesQuery}
`

export const TABS_QUERY = gql`
  ${tabsQuery}
`

export const TEXT_BLOCK_QUERY = gql`
  ${textBlockQuery}
`

export const FILTER_PAGE_QUERY = gql`
  ${filterPageQuery}
`

export const GRID_ENTRIES_QUERY = gql`
  ${gridEntries}
`

export const ENTRIES_WITH_TAGS = gql`
  ${entriesWithTags}
`

export const EMBED_QUERY = gql`
  ${embedQuery}
`

export const LIST_QUERY = gql`
  ${listQuery}
`
export const IGNITE_QUERY = gql`
  ${igniteQuery}
`

export const IGNITE_NAV_ARTICLE_NEWS_OFFICE_QUERY = gql`
  ${igniteNavArticleNewsOfficeQuery}
`
