/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { isProdPreview } from '@featureflags'
import NonPreviewLink from './NonPreviewLink'
import PreviewLink from './PreviewLink'
import { ToggleContainer } from './style'

const PreviewToggle = ({
  originalSlug,
  contentful_id,
  contentType,
  ...rest
}) => {
  if (isProdPreview) return null
  if (!originalSlug && !contentful_id) return null
  if (originalSlug && typeof originalSlug !== 'string') return null

  return (
    <ToggleContainer
      height="30px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      px="10px"
      bg="purple"
      color="white"
      fontSize="14px"
      fontWeight="700"
      borderRadius="5px"
    >
      {originalSlug ? (
        <NonPreviewLink
          originalSlug={originalSlug}
          contentType={contentType}
          subpageId={contentType === 'subpage' ? contentful_id : undefined}
          {...rest}
        />
      ) : (
        <PreviewLink
          contentful_id={contentful_id}
          contentType={contentType}
          {...rest}
        />
      )}
    </ToggleContainer>
  )
}

PreviewToggle.propTypes = {
  originalSlug: PropTypes.string,
  contentful_id: PropTypes.string,
}

export default PreviewToggle
