import React from 'react'
import {
  StyledImageDiv,
  StyledThumbnail,
  PlayButtonImage,
  Overlay,
} from '../style'
import hoverButton from '@assets/images/playbutton-hover.png'

const UntoggledModal = ({
  width,
  align,
  margin,
  wrapText,
  thumbnail,
  handleModal,
}) => {
  const hoverPlayButtonImage = {
    fluid: {
      src: hoverButton,
    },
  }
  return (
    <StyledImageDiv
      relative
      align={align}
      width={width}
      wrapText={wrapText}
      margin={margin}
      className="qtph-modal-video"
    >
      <StyledThumbnail
        src={thumbnail}
        align={align}
        width={width}
        wrapText={wrapText}
      />
      <PlayButtonImage
        src={hoverPlayButtonImage.fluid.src}
        onMouseDown={handleModal}
        aria-label="play"
        data-testid="play-modal"
      />
      <Overlay onMouseDown={handleModal} />
    </StyledImageDiv>
  )
}

export default UntoggledModal
