import styled from 'styled-components'
import { colors, sizes } from '@theme'
import { Col } from '../Globals'

export const StyledTableContainer = styled.div`
  width: 100%;
  margin-bottom: 35px;
`

export const StyledTable = styled.div`
  border-spacing: 0px;
  width: 100%;
`

export const StyledTableHead = styled.div`
  width: 100%;

  @media (max-width: ${sizes.small.max}) {
    display: none;
  }
`

export const StyledTh = styled(Col)`
  border-bottom: 1px solid #27251f;
  color: #27251f;
  font-size: 14px;
  letter-spacing: 1.31px;
  line-height: 22px;
  padding: 11px 0;
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
`

export const StyledTableSortLabel = styled.span`
  outline: none;
`

export const StyledThTitle = styled.span`
  color: '#212121';
`

export const StyledTd = styled.td`
  border-bottom: ${({ lastItem }) => !lastItem && '1px solid'};
  border-color: ${colors.grayMd};
  padding: 25px 25px 25px 0px;
  vertical-align: top;

  @media (max-width: ${sizes.small.max}) {
    border: none;
    padding: 0px 0px 10px;
  }
`

export const StyledCol = styled(Col)`
  border-bottom: ${({ lastItem }) => !lastItem && '1px solid'};
  border-color: ${colors.grayMd};
  padding: ${({ padding }) => padding || '25px 25px 25px 0px'};
  vertical-align: top;

  @media (max-width: ${sizes.small.max}) {
    border: none;
    padding: 0px 0px 10px;
  }
`
