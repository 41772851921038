import styled, { keyframes } from 'styled-components'
import { colors, sizes } from '@theme'
import Icon from '../Icon/index'
import { renderMargin } from '@utils'

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: ${({ backgroundVisible }) => (backgroundVisible ? 0 : 0.5)};
`
export const ModalWrapper = styled.div`
  transition: opacity 0.15s linear;
  position: fixed;
  top: ${({ alignItems }) => (alignItems === 'end' ? null : 0)};
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999999;
  display: none;
  overflow: hidden;
  outline: 0;
  display: block;
`

export const ModalContainer = styled.div`
  transform: translate(0, 0);
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  position: relative;
  pointer-events: none;
  ${({ fullWidth }) => (fullWidth ? FullWidthModal : NormalModal)}
`

const NormalModal = styled.div`
  width: auto;
  margin: 0.5rem;

  @media (min-width: 576px) {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  @media (min-width: 992px) {
    max-width: 800px;
  }
`

const FullWidthModal = styled.div`
  width: 100%;
  margin: -0.1rem;
`

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: ${({ color }) => (color ? colors[color] : colors.white)};
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: ${({ fullWidth }) => (fullWidth ? '0' : '.3rem')};
  outline: 0;
`

export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
`

export const ModalDismissButton = styled.button`
  cursor: pointer;
  background-color: ${colors.purple};
  border-radius: 100%;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: -10px;
  right: -15px;
  outline: none;
  opacity: 1;
  line-height: normal;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  z-index: 8;
  padding: 0;
  margin: 0;
  border: 0;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
`

export const modal = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  from {
    transform: translateY(-250%);
  }
  to {
    transform: translateY(0%);
  }
`
export const modalOut = keyframes`
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-500%);
  }
`

export const StyledHiddenVideoModal = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${modal} 0.5s ease-out;
  cursor: default;
`

export const StyledModalContent = styled.div`
  margin: 0 auto;
  margin-top: 10%;
  width: 798px;
  height: 462.88px;
  padding: 1rem;
  border-radius: 0.3rem;
  background-color: white;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  animation-name: ${modal};
  animation-duration: 1s;

  @media (max-width: 992px) {
    width: 492px;
    height: 294.13px;
    position: relative;
    top: 20px;
  }
  @media (max-width: ${sizes.small.min}) {
    width: 420px;
    height: 260.13px;
  }

  @media (max-width: 480px) {
    width: 90%;
    height: 220px;
  }
`

export const StyledIcon = styled(Icon)`
  float: right;
  top: -17px;
  right: -32px;
  color: white;
  cursor: pointer;

  div,
  svg {
    border: 0.5px transparent black;
    border-radius: 50%;
    background-color: #5a3462;
  }
`

export const StylediFrame = styled.iframe`
  width: 760px;
  height: 426px;
  position: relative;
  z-index: 20000;
  top: -32px;
  @media (max-width: 992px) {
    width: 455px;
    height: 258px;
  }
  @media (max-width: ${sizes.small.min}) {
    width: 386px;
    height: 227.13px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 182px;
  }
`

export const StyledImage = styled.img`
  max-width: 100%;
  width: ${({ width }) => width || 'auto'};
  float: ${({ align, wrapText }) => (wrapText === 'Tight' ? align : 'none')};
  margin: ${({ margin }) => margin};
  padding: 10px 10px 10px 0px;
  flex-shrink: 0;
`

export const StyledThumbnail = styled(StyledImage)`
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  &:hover {
    opacity: 0.5;
  }
`

export const StyledImageDiv = styled.div`
  display: ${({ wrapText, align }) =>
    wrapText !== 'Tight' || (wrapText === 'Tight' && align === 'Center')
      ? 'flex'
      : 'inline-flex'};
  float: ${({ align, wrapText }) => (wrapText === 'Tight' ? align : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${({ width }) => width || '100%'};
  width: fit-content;
  z-index: 0;
  cursor: pointer;
  position: relative;
  margin: ${({ wrapText, align, margin }) =>
    renderMargin(wrapText, align, margin)};
`

export const StyledModalImageDiv = styled(StyledImageDiv)`
  z-index: 100;
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 1;
  &:hover {
    background: rgba(90, 52, 98, 0.3);
  }
`

export const PlayButtonImage = styled.img`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:hover + ${Overlay} {
    background: rgba(90, 52, 98, 0.3);
  }
`
