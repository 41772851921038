import React from 'react'
import PropTypes from 'prop-types'
import { StyledTabMobile } from '../style'
import { FullWidthButton } from '../../Button'

const TabMobile = ({
  index,
  children,
  fontSize,
  tab,
  setTab,
  active,
  borderRadius,
  label,
  className,
}) => {
  return (
    <StyledTabMobile>
      <FullWidthButton
        fontSize={fontSize}
        active={active}
        id={`active-tab-${index}`}
        data-toggle="tab"
        role="tab"
        aria-labelledby={`${label}-tab`}
        aria-controls={`active-tab-${index}`}
        aria-selected={tab === index ? 'true' : 'false'}
        onClick={() => setTab(index)}
        borderRadius={borderRadius || '0'}
        className={className}
      >
        {children}
      </FullWidthButton>
    </StyledTabMobile>
  )
}

TabMobile.propTypes = {
  index: PropTypes.number.isRequired,
}

export default TabMobile
