import React from 'react'
import { StyledDropdownMenu, DropdownItem } from './style'

const Dropdown = ({ children, ariaLabelledBy, ...rest }) => (
  <StyledDropdownMenu ariaLabelledBy={ariaLabelledBy}>
    {children}
  </StyledDropdownMenu>
)

export default Dropdown
export { DropdownItem }
