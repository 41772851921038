import React from 'react'
import { StyledImage } from '../style'

const ImageTight = ({
  imageSrc,
  externalResource,
  title,
  internalSlug,
  align,
  width,
  wrapText,
  margin,
  LinkComponent,
  pageSlugs,
  typename,
  internalCategory,
}) => {
  if (LinkComponent) {
    return (
      <LinkComponent
        width={width}
        href={externalResource}
        target="_blank"
        rel="noopener noreferrer"
        to={internalSlug}
        contentType={typename}
        category={internalCategory}
      >
        <StyledImage
          src={imageSrc}
          alt={title}
          align={align}
          wrapText={wrapText}
          width={width}
          margin={margin}
        />
      </LinkComponent>
    )
  }

  return (
    <StyledImage
      src={imageSrc}
      alt={title}
      align={align}
      wrapText={wrapText}
      width={width}
      margin={margin}
    />
  )
}

export default ImageTight
