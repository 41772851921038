import styled from 'styled-components'
import { Container } from '../Globals'
import { sizes } from '@theme'

export const StyledDisclaimerContainer = styled(Container)`
  padding: 0px 0px 25px;
  @media (max-width: ${sizes.xsmall.max}) {
    padding: 25px;
  }
`

export const StyledDisclaimerDivContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`

export const StyledDisclaimer = styled.div`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: normal;
  line-height: 20px !important;
  margin-top: 0;
  font-weight: 400;
  color: black;
  font-style: italic;

  & p {
    font-size: 12px;
    letter-spacing: normal;
    margin-bottom: 15px;
    line-height: 20px !important;
    margin-top: 0;
    font-weight: 400;
    font-style: italic;
    color: inherit;
  }
`
