import { useEffect } from 'react'
import {
  featureFlags,
  isEnabledForClientReview,
  isFeatureEnabled,
} from '@featureflags'
import { LOCAL_STORAGE_KEYS, useLocalStorage } from '@hooks'

// only works in production
const useGtag = ({
  action,
  categories = [],
  label,
  eventCallback = () => {},
  skipEventEmission = false,
}) => {
  const [acceptedTermsState] = useLocalStorage(
    LOCAL_STORAGE_KEYS.ACCEPTED_TERMS,
    false
  )
  // TODO: update to use accepted prop

  useEffect(() => {
    if (!isFeatureEnabled(featureFlags.GTAG)) return
    if (!window?.gtag || !acceptedTermsState?.acceptedTerms) return
    if (!action || !label || skipEventEmission) return

    const logEventEmission = (trackingId, category) => {
      if (isEnabledForClientReview) {
        console.info('GTAG Event emitted:', {
          trackingId,
          action,
          category,
          label,
        })
      }
    }

    categories.forEach(category => {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        event_callback: trackingId => {
          // Note that the event is emitted for both `GOOGLE_ANALYTICS_ID` and `GOOGLE_TAGMANAGER_ID`
          // but the `GOOGLE_TAGMANAGER_ID` event is currently not being processed by GTM
          logEventEmission(trackingId, category)
          eventCallback(trackingId)
        },
      })
    })
  }, [
    action,
    categories,
    label,
    eventCallback,
    skipEventEmission,
    acceptedTermsState.acceptedTerms,
    acceptedTermsState,
  ])
}

export default useGtag
export { useGtag }
