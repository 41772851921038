import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledList,
  StyledListCol,
  StyledOl,
  StyledUl,
  StyledUlBulleted,
  StyledIconUl,
  StyledListGrid,
  StyledListGridItem,
  DotListItem,
  DotList,
  DotUl,
  IconLi,
  Li,
} from './style'
import InfiniteList from './components/InfiniteList'

const List = ({
  children,
  direction,
  timeline,
  ol,
  start,
  numberFormat,
  bulleted,
  grid,
  width,
  ul,
  icon,
  padding,
  border = true,
  xs,
  sm,
  md,
  lg,
  xl,
}) => {
  if (timeline) {
    return (
      <DotList>
        <DotUl>
          {children?.map((child, i) => (
            <DotListItem key={`dot-list-item-${i + 1}`}>{child}</DotListItem>
          ))}
        </DotUl>
      </DotList>
    )
  }
  if (ol) {
    return (
      <StyledOl type={numberFormat} start={start}>
        {children}
      </StyledOl>
    )
  }
  if (ul) {
    return (
      <StyledUl>
        <Li>{children}</Li>
      </StyledUl>
    )
  }
  if (icon) {
    return (
      <StyledIconUl>
        {React.Children.map(children, (child, i) => (
          <IconLi key={`icon-list-item-${i + 1}`}>{child}</IconLi>
        ))}
      </StyledIconUl>
    )
  }
  if (bulleted) {
    return <StyledUlBulleted>{children}</StyledUlBulleted>
  }
  if (grid) {
    if (padding) {
      return <StyledListGrid role="tablist">{children}</StyledListGrid>
    }
    return (
      <StyledListGrid role="tablist">
        {React.Children.map(children, (child, i) => (
          <StyledListGridItem
            border={border}
            xs={xs || 12}
            sm={sm || 12}
            md={md || 6}
            lg={lg || 4}
            xl={xl || 4}
            key={`list-grid-item-${i + 1}`}
          >
            {child}
          </StyledListGridItem>
        ))}
      </StyledListGrid>
    )
  }

  return (
    <StyledList direction={direction} width={width}>
      <StyledListCol direction={direction}>{children}</StyledListCol>
    </StyledList>
  )
}

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default List
export { InfiniteList }
