import React from 'react'
// import PropTypes from 'prop-types'
import { FooterNavCol } from '../style'
import FooterItem from './FooterItem'

const FooterCol = ({ items }) => {
  return (
    <>
      <FooterNavCol>
        {items?.slice(0, 3)?.map(({ id, navigationLink, ...rest }) => (
          <FooterItem key={id} {...navigationLink} {...rest} />
        ))}
      </FooterNavCol>
      {items?.slice(3)?.map(({ id, navigationLink, ...rest }) => (
        <FooterNavCol key={id}>
          <FooterItem {...navigationLink} {...rest} />
        </FooterNavCol>
      ))}
    </>
  )
}
FooterCol.propTypes = {}

export default FooterCol
