import React, { useRef, useEffect } from 'react'
import InfiniteLoader from 'react-window-infinite-loader'
import { VariableSizeList as List } from 'react-window'
import { useMediaQuery } from '@hooks'
import { sizes, colors } from '@theme'
import { BodyCopy } from '../../Globals'
import { generateRandomKey } from '@utils'

const DynamicInfiniteList = ({
  children,
  rowHeights,
  maxWidth = '100%',
  maxHeight = 300,
  noDataMessage,
  numItemsPerLoad = 100,
  disableHeightShrink = false,
  hasNextPage = false,
  insightsRow,
  loadMoreItems = () => {},
}) => {
  const listRef = useRef({})
  const rowRef = useRef({})
  const xs = useMediaQuery(`(max-width: ${sizes.xsmall.max})`)
  const sm = useMediaQuery(`(max-width: ${sizes.small.max})`)
  const md = useMediaQuery(`(max-width: ${sizes.medium.max})`)
  const lg = useMediaQuery(`(max-width: ${sizes.large.max})`)

  const rowHeight =
    (xs && rowHeights.xs) ||
    (sm && rowHeights.sm) ||
    (md && rowHeights.md) ||
    (lg && rowHeights.lg) ||
    rowHeights.xl

  const itemsTotalHeight = children.length * rowHeight
  const adjustedHeight =
    !disableHeightShrink && itemsTotalHeight < maxHeight
      ? itemsTotalHeight
      : maxHeight

  const isItemLoaded = index => index < children.length
  const itemCount = hasNextPage ? children.length + 1 : children.length

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0)
    rowRef.current = { ...rowRef.current, [index]: size + 20 }
  }

  const getRowHeight = index => rowRef.current[index] || rowHeight

  const Row = ({ index, style }) => {
    const rowDataRef = useRef({})

    useEffect(() => {
      if (rowDataRef.current) {
        const childOfRow =
          rowDataRef.current instanceof Element &&
          rowDataRef.current.querySelector('a')
        if (childOfRow) {
          setRowHeight(index, childOfRow.clientHeight)
        }
      }
    }, [rowDataRef])
    const loading = index === children.length
    return loading ? (
      <div key={generateRandomKey()} style={style}>
        <BodyCopy
          style={{
            paddingTop: '20px',
            textAlign: 'center',
            color: colors.grayMd,
          }}
        >
          Loading...
        </BodyCopy>
      </div>
    ) : (
      <div ref={rowDataRef} style={style}>
        {children[index]}
      </div>
    )
  }

  return children.length > 0 ? (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={Math.ceil(0.8 * numItemsPerLoad)}
      minimumBatchSize={numItemsPerLoad}
    >
      {({ onItemsRendered, ref }) => {
        return (
          <List
            className="List"
            height={adjustedHeight + 20}
            itemCount={itemCount}
            itemSize={getRowHeight}
            onItemsRendered={onItemsRendered}
            ref={list => {
              listRef.current = list
            }}
            width={maxWidth}
          >
            {Row}
          </List>
        )
      }}
    </InfiniteLoader>
  ) : (
    <BodyCopy style={{ textAlign: 'center', paddingTop: '20px' }}>
      {noDataMessage}
    </BodyCopy>
  )
}

export default DynamicInfiniteList
