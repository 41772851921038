/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import A from '@components/A'
import ContentfulLogo from '@assets/svg/usertools/contentful_logo.svg'
import { ToggleContainer } from './style'

const ContentfulLink = ({ contentful_id }) => {
  const spaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
  const env = process.env.GATSBY_CONTENTFUL_ENVIRONMENT
  const contentfulLink = `https://app.contentful.com/spaces/${spaceId}/environments/${env}/entries/${contentful_id}`

  return (
    <ToggleContainer
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="30px"
      bg="purple"
      color="white"
      fontSize="14px"
      fontWeight="700"
      borderRadius="5px"
      minWidth="32px"
    >
      <A
        href={contentfulLink}
        display="flex"
        justifyContent="center"
        alignItems="center"
        mx="6px"
        title="Contentful Link"
      >
        <ContentfulLogo height="20px" width="20px" />
      </A>
    </ToggleContainer>
  )
}

ContentfulLink.propTypes = {
  contentful_id: PropTypes.string,
}

export default ContentfulLink
