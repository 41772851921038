import React from 'react'
import { StyledCardsContainer, StyledCardsTitle, StyledCardCol } from './styles'
import { Divider } from '../Globals'
import { Row } from '@components/Common'
import { generateRandomKey } from '@utils'
import { sizes } from '@theme'
import { useMediaQuery } from '@hooks'

/**
 * Design choices to follow when using this component:
 * Always wrap to 1 card per row at xs and sm viewports
 * In the rare use case that the container is too small (notibly < 6/12 col units),
 *  use mdStack to force wrap to 1 card per row at md viewport
 */

const Cards = ({
  children,
  title,
  numCols = 1,
  titleDividerColor,
  gapWidth = 0,
  withoutDividers = false,
  mobilePadding,
  mdStack = false,
  titleMarginTop,
  titleMarginBottom,
  color,
  allColWrap = false,
  padding,
  emailLength = false,
  imageSize,
}) => {
  numCols = children.length === 1 ? 1 : numCols
  const md = useMediaQuery(`(max-width: ${sizes.medium.max})`)

  const renderMdCol = () => {
    let colSize = 6

    if (numCols === 1 || (md && mdStack)) {
      colSize = 12
    } else if (allColWrap) {
      colSize = 4
    }

    return colSize - gapWidth
  }
  const colWidth = imageSize && imageSize === 'Large'

  return (
    <StyledCardsContainer mobilePadding={mobilePadding} padding={padding}>
      <StyledCardsTitle
        color={color}
        titleDividerColor={titleDividerColor}
        titleMarginTop={titleMarginTop}
        titleMarginBottom={titleMarginBottom}
        className="qtph-insightspge-contributorstitle-txt"
      >
        {title}
      </StyledCardsTitle>
      <Row
        className={`${
          colWidth ? `justify-around` : `justify-between`
        } flex-row flex-wrap`}
      >
        {children?.map(c => (
          <StyledCardCol
            key={generateRandomKey()}
            xs={12}
            sm={colWidth && allColWrap ? 6 - gapWidth : 12}
            md={colWidth && renderMdCol()}
            lg={(numCols > 3 ? 3 : 12 / numCols) - gapWidth}
            width="50%"
          >
            {c}
            {!withoutDividers && <Divider fitToWidth color="grayMd" />}
          </StyledCardCol>
        ))}
      </Row>
    </StyledCardsContainer>
  )
}

export default Cards
