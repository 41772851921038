import React from 'react'
import A from '@components/A'
import { colors } from '@theme'

export const EntryHyperLink = ({ slug, __typename, category, children }) => {
  return (
    <A
      to={slug}
      contentType={__typename}
      category={category?.slug}
      color={colors.gold}
      fontWeight="normal"
      underline
    >
      {children}
    </A>
  )
}
