import styled from 'styled-components'
import { Col, H2 } from '../Globals'
import { sizes, colors } from '@theme'

export const StyledCardsContainer = styled.div`
  width: 100%;
  padding: ${({ padding }) => padding || '70px 0px'};

  @media (max-width: ${sizes.small.max}) {
    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`};
  }
`

export const StyledCardsTitle = styled(H2)`
  color: #27251f;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 26px;
  padding-bottom: 4px;
  border-bottom: 1px solid
    ${({ titleDividerColor }) => titleDividerColor || colors.grayMd};
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  ${({ titleMarginTop }) => titleMarginTop && `margin-top: ${titleMarginTop}`};
  ${({ titleMarginBottom }) =>
    titleMarginBottom && `margin-bottom: ${titleMarginBottom}`};
  color: ${({ color }) => color || 'black'};
`

export const StyledCardCol = styled(Col)`
  margin-bottom: -2px;
  position: relative;
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  min-height: 1px;
`
