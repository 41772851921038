import React from 'react'
import { StyledH4, StyledSecondSubNavContainer } from './style'
import A from '@components/A'

const secondLevelSubNavigationItemMobile = ({
  url,
  slug,
  displayText,
  __typename,
}) => {
  return (
    <StyledSecondSubNavContainer>
      <A to={slug} href={url} contentType={__typename}>
        <StyledH4>{displayText}</StyledH4>
      </A>
    </StyledSecondSubNavContainer>
  )
}

secondLevelSubNavigationItemMobile.propTypes = {}

export default secondLevelSubNavigationItemMobile
