import React from 'react'
import PropTypes from 'prop-types'
import { StyledControls } from '../style'
import Icon from '../../Icon'
import { CarouselIndicatorButton } from '../../Button'

const Controls = ({ prev, next, controlTop }) => (
  <StyledControls controlTop={controlTop}>
    <div>
      <CarouselIndicatorButton data-testid="left-caret" onClick={() => prev()}>
        <Icon glyph="left-caret" height={50} width={50} className="text-gray" />
      </CarouselIndicatorButton>
    </div>
    <div>
      <CarouselIndicatorButton data-testid="right-caret" onClick={() => next()}>
        <Icon
          glyph="right-caret"
          height={50}
          width={50}
          className="text-gray"
        />
      </CarouselIndicatorButton>
    </div>
  </StyledControls>
)

Controls.propTypes = {
  next: PropTypes.func,
  prev: PropTypes.func,
}

export default Controls
