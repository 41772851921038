import React from 'react'
import { StyledFormStatus } from '../style'

const FormStatus = ({ error, success, children }) => (
  <StyledFormStatus
    className={`${error ? 'error' : ''} ${success ? 'success' : ''}`}
  >
    {children}
  </StyledFormStatus>
)

export default FormStatus
