import React from 'react'
import ToggledModal from './ToggledModal'
import UntoggledModal from './UntoggledModal'

const EmbeddedModal = ({ toggle, align, width, wrapText, ...rest }) => {
  if (typeof document !== 'undefined') {
    if (toggle) document.body.style.overflow = 'hidden'
    if (!toggle) document.body.style.overflow = 'initial'
  }

  return toggle ? (
    <ToggledModal align={align} width={width} wrapText={wrapText} {...rest} />
  ) : (
    <UntoggledModal align={align} width={width} wrapText={wrapText} {...rest} />
  )
}

export default EmbeddedModal
