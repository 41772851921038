import { useState, useEffect, useRef, useCallback } from 'react'

export const usePortal = (selector = 'a, button, [role=button], iframe') => {
  const [isActive, setIsActive] = useState(false)
  const modalRef = useRef(null)
  const triggerRef = useRef(null)

  const closePortal = useCallback(() => {
    if (!isActive) return

    setIsActive(false)
  }, [isActive])

  const handleOutsideMouseClick = useCallback(
    e => {
      if (!isActive) return

      const root = modalRef?.current
      const isInsideModal = root?.contains(e.target)
      const isLeftClick = e.button && e.button === 0
      if (!root || isInsideModal || !isLeftClick) return

      closePortal()
    },
    [isActive, closePortal]
  )

  const handleKeydown = useCallback(
    e => {
      if ((e.key === 'Escape' || e.key === 'Esc') && isActive) {
        closePortal()
        if (triggerRef?.current) {
          triggerRef.current.focus()
        }
      }

      if (modalRef.current) {
        // Focus trap with all elements within modal
        if (e.key === 'Tab') {
          // Grab all elements within modal that user can tab through and are visible in UI
          const modalElements = Array.from(
            modalRef.current.querySelectorAll(selector)
          )?.filter(elem => elem.offsetParent)

          const firstElement = modalElements?.[0]
          const lastElement = modalElements[modalElements.length - 1]
          const iframe = document.getElementById('iframe')
          if (
            !e.shiftKey &&
            (e.target === lastElement || e.target === iframe)
          ) {
            e.preventDefault()
            firstElement.focus()
          } else if (e.shiftKey && e.target === firstElement) {
            e.preventDefault()
            lastElement.focus()
          }
        }
      }
    },
    [isActive, closePortal, selector]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('click', handleOutsideMouseClick)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('click', handleOutsideMouseClick)
    }
  }, [handleKeydown, handleOutsideMouseClick])

  const openPortal = (e, focusElementSelector) => {
    if (isActive) return
    if (e?.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation()
    }
    triggerRef.current = e?.target

    setTimeout(() => {
      if (focusElementSelector && modalRef.current) {
        const focusElement = modalRef.current.querySelector(
          focusElementSelector
        )

        if (focusElement) {
          focusElement.focus()
        }
      }
    }, 500)

    setIsActive(true)
  }

  return { openPortal, closePortal, isOpen: isActive, modalRef }
}

export default usePortal
