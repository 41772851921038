import React from 'react'
import {
  StyledHiddenVideoModal,
  StyledModalContent,
  StyledIcon,
  StylediFrame,
} from '../style'

const VideoModal = ({ image, toggle, url, ...rest }) => {
  return (
    <StyledHiddenVideoModal
      {...rest}
      role="dialog"
      aria-labelledby="videoModal"
      data-testid="modal-div"
    >
      <StyledModalContent>
        <StyledIcon glyph="view-close" aria-label="close" />
        <StylediFrame
          data-testid="iframe"
          src={url}
          frameBorder="0"
          allowscriptaccess="always"
          data-cy="iframe"
        />
      </StyledModalContent>
    </StyledHiddenVideoModal>
  )
}

export default VideoModal
