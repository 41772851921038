import Debug from '@components/Debug'
import React from 'react'
import HyperlinkSubContainer from './HyperlinkSubContainer'

const HyperlinkCDAContainer = ({ node, references, ...rest }) => {
  const targetId = node?.data?.target?.sys?.id
  const entryOrAsset = references?.find(({ id }) => id === targetId)
  const linkType = node?.data?.target?.sys?.linkType

  if (!references?.length) {
    return (
      <Debug
        label={`No references found in Embedded Entry CDA Container for targetId: ${targetId}`}
        jsonDump={{ node, entryOrAsset, linkType }}
      />
    )
  }

  if (!entryOrAsset) {
    return (
      <Debug
        label={`No entry or asset found in Embedded Entry CDA Container for targetId: ${targetId}`}
        jsonDump={{ node, references }}
      />
    )
  }

  return (
    <HyperlinkSubContainer {...rest} {...entryOrAsset} linkType={linkType} />
  )
}

export default HyperlinkCDAContainer
