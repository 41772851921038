import React from 'react'
import PropTypes from 'prop-types'
import { Div } from '@components/StyledTags'
import { renderRichText } from './renderRichText'

const RichTextBlock = ({ richtext, ...rest }) => {
  if (!richtext) return null

  return (
    <Div display="flex" flexDirection="column" gap="16px">
      {renderRichText(richtext, rest)}
    </Div>
  )
}

RichTextBlock.propTypes = {
  richtext: PropTypes.object,
}

export default RichTextBlock
