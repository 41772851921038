import { addLeadingSlash, generateRandomKey } from '@utils'
import { handleContentfulTypename } from './dataFormatting'

export const formatTabSlug = (slug, parentSlug) => {
  if (slug?.includes('preview?contentType=')) return slug
  let sanitizedSlug = addLeadingSlash(slug)
  sanitizedSlug = `${parentSlug}${sanitizedSlug}`

  return sanitizedSlug
}

export const formatTabsSubpage = (subpages, parentSlug) => {
  if (!subpages?.length) return []
  return subpages?.map(({ title, slug }) => ({
    title,
    slug: formatTabSlug(slug, parentSlug),
  }))
}

export const formatTabs = page => {
  if (!page) return [{ title: 'Overview', slug: page?.slug }]

  const subpageTabInfo = formatTabsSubpage(page?.subpages, page?.slug)
  return [{ title: 'Overview', slug: page?.slug }, ...subpageTabInfo]
}

export const handlePageWithSubpagesModules = modules => {
  return modules?.map(
    ({ jumpLinkTitle, __typename, professionals, ...rest }, i) => {
      const anchorWithoutSpace = jumpLinkTitle
        ? jumpLinkTitle?.replace(' ', '')
        : null

      const anchorId = anchorWithoutSpace
        ? `${anchorWithoutSpace}-${generateRandomKey()}-${i}`
        : null

      const disableJumplink =
        handleContentfulTypename(__typename) === 'moduleprofessionalstable' &&
        !professionals?.filter(({ active }) => active)?.length

      return {
        ...rest,
        anchorId: !disableJumplink ? anchorId : null,
        jumpLinkTitle: !disableJumplink ? jumpLinkTitle : null,
        __typename,
        professionals,
      }
    }
  )
}
