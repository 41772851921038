import React from 'react'
import {
  FooterCDAContainer,
  FooterCPAContainer,
} from '@containers/FooterContainer'

const Footer = ({ preview, ...rest }) => {
  const FooterContainer = preview ? FooterCPAContainer : FooterCDAContainer

  return <FooterContainer preview={preview} {...rest} />
}

export default Footer
