import React from 'react'

const Video = ({ videoSrc }) => {
  return (
    <div
      style={{
        maxWidth: '100%',
        height: 'auto',
        margin: '20px',
      }}
    >
      <iframe
        width="100%"
        frameBorder="0"
        style={{ display: 'block', minHeight: '290px' }}
        src={videoSrc}
        data-cy="iframe"
      />
    </div>
  )
}

export default Video
