import React, { useState, useEffect } from 'react'

export const useSelect = value => {
  const [selectType, setSelectType] = useState(value)
  useEffect(() => {}, [value])

  // useEffect(() => {
  //   setSelectType(value);
  // }, [value]);

  return [selectType, setSelectType]
}
