import styled from 'styled-components'
import { sizes, colors } from '@theme'
import { Row } from '../Globals'

export const HoverGalleryContainer = styled.div`
  float: left;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px;
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
`

export const StyledHoverGallery = styled.ul`
  display: flex;
  margin: 0px;
  padding: 0px;
  list-style: none;
  overflow: hidden;

  &:last-child {
    border-right: none;
    border-bottom: none;
  }

  @media (max-width: ${sizes.medium.max}) {
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    width: 100%;
  }
`

export const StyledHoverGalleryItem = styled.li`
  display: flex;
  height: 426px;
  width: ${({ width }) => width || '33.3%'};
  margin: 0px;
  padding: 0px;
  list-style: none;
  overflow: hidden;
  box-sizing: border-box;
  border-right: 2px solid #ffffff;
  align-self: stretch;
  justify-content: center;
  align-items: flex-end;

  & a {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    background: linear-gradient(180deg, rgba(0, 28, 84, 0) 66.08%, #001c54 100%),
      radial-gradient(
        50% 50% at 50% 50%,
        rgba(26, 100, 168, 0.6) 0%,
        rgba(10, 54, 95, 0.6) 100%
      );

    &:after {
      content: '';
      display: block;
      background: rgba(0, 0, 0, 0.33);
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    & img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      position: relative;
      z-index: -1;
      transition: all 0.3s ease-out;
      vertical-align: middle;
      border-style: none;
    }

    &:hover img {
      transform: scale(1.1);
    }

    &:last-child {
    border-right: none;
    border-bottom: none;
  }
  }
  @media (max-width: ${sizes.medium.max}) {
    width: 100vw;
    border-bottom: 2px solid #ffffff;
    border-right: none;
    height: 283px;
  }

  @media (max-width: ${sizes.small.min}) {
    height: 324px;
`

export const Caption = styled.span`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 64px;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 3;
`

export const CtaTitle = styled.span`
  display: block;
  margin: 0px;
  font-size: ${({ fontSize }) => fontSize || '32px'};
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  color: #ffffff;

  @media (max-width: ${sizes.medium.min}) {
    font-size: 28px;
  }
`

export const GalleryItemTitle = styled(CtaTitle)`
  line-height: 24px;
  padding: 20px;
  font-size: 20px;
`

export const HoverGalleryItem = styled.div`
  width: ${({ width }) => `calc(${width} - 15px)` || '33.3%'};
  display: block;
  float: left;
  margin: 10px 15px 10px 0;
  box-sizing: border-box;
  background: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  min-height: 150px;

  & a {
    display: block;
    position: relative;
    color: #b4a06e;
    text-decoration: none;
    background-color: transparent;
    height: 100%;

    & :after {
      content: '';
      display: block;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    & :hover:after {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: ${sizes.medium.min}) {
    width: 45%;
    margin: 10px 10px 10px 0;
  }
  @media (max-width: ${sizes.small.min}) {
    width: 100%;
  }
`
export const DescriptionContainer = styled(Row)`
  width: ${({ width }) => width || '33.3%'};

  @media (max-width: ${sizes.medium.min}) {
    width: 100%;
  }
`

export const Description = styled.div`
  margin: 25px 0px;
  padding: 0 15px 0 0;
  font-size: 16px;
  color: ${colors.text.gray};
  line-height: 1.5;
`
