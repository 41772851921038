import React from 'react'
import Icon from '../../Icon'
import {
  StyledTableHead,
  StyledThTitle,
  StyledTh,
  StyledTableSortLabel,
} from '../style'
import { capitalize } from '@utils'
import { Row } from '@components/Common'

const TableSortLabel = ({
  direction,
  handleClick,
  children,
  id,
  title,
  active,
}) => {
  const arrow = direction === 'desc' ? 'down-caret' : 'up-caret'

  return (
    <StyledTableSortLabel
      tabIndex="0"
      role="button"
      title={children}
      data-testid="sort-label"
      onClick={() => handleClick()}
      style={{ cursor: 'pointer' }}
    >
      <StyledThTitle>{title}</StyledThTitle>
      <Icon glyph={arrow} height={18} width={18} viewBox="0 0 32 20" />
    </StyledTableSortLabel>
  )
}
const TableLabel = ({ title }) => (
  <StyledTableSortLabel>
    <StyledThTitle>{title}</StyledThTitle>
  </StyledTableSortLabel>
)

const TableHead = ({
  order,
  orderBy,
  columns,
  sortHandler,
  enableSorting = false,
}) => {
  return (
    <StyledTableHead>
      <Row className="p-0">
        {columns.map(({ id, width, val }, i) => (
          <StyledTh
            xs={12}
            sm={12}
            md={width || 12 / columns.length}
            lg={width || 12 / columns.length}
            xl={width || 12 / columns.length}
            key={id}
            sortDirection={orderBy === id ? order : false}
          >
            {enableSorting && i === 0 ? (
              <TableSortLabel
                active={orderBy === id}
                direction={order}
                handleClick={sortHandler}
                id={id}
                title={capitalize(val || id)}
              />
            ) : (
              <TableLabel id={id} title={capitalize(val || id)} />
            )}
          </StyledTh>
        ))}
      </Row>
    </StyledTableHead>
  )
}

export default TableHead
