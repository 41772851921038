import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const accessToken = process.env.GATSBY_APOLLO_PREVIEW_ACCESS_TOKEN
const accessPath = `access_token=${accessToken}`
const entriesPath = '/entries'
const assetsPath = '/assets'

const transformQueryParamsToString = params => {
  const entries = Object.entries(params)
  return entries
    ?.map(([key, val]) => {
      if (typeof val === 'undefined' || val === '') return null
      if (key === 'id') key = 'sys.id'
      if (key === 'categoryIncludes') key = 'fields.category.sys.id[in]'
      if (key === 'categoryExcludes') key = 'fields.category.sys.id[nin]'
      if (key === 'tagIncludes') key = 'fields.tags.sys.id[in]'
      if (key === 'tagExcludes') key = 'fields.tags.sys.id[nin]'
      if (key === 'placement') key = 'fields.placement[match]'
      if (key === 'idIncludes') key = 'sys.id[in]'
      if (key === 'linksToEntry') key = 'links_to_entry'
      return `${key}=${val}`
    })
    ?.filter(entry => entry)
    ?.join('&')
}

const createQueryStringPath = ({ args }) => {
  const queryParamString = transformQueryParamsToString(args)
  return `${entriesPath}?${accessPath}&${queryParamString}`
}

// Query `entry` for single entry and `entries` for multiple entries
// This is important for the `getMappedCPAData()` in Apollo containers

export const GET_ASSET_BY_ID = gql`
  query getEntryById(
    $id: String!
    $locale: String!
    $include: Int!
    $createPath: any
  ) {
    asset(id: $id, locale: $locale, include: $include)
      @rest(type: "Asset", path: "${assetsPath}/{args.id}?${accessPath}") {
      sys
      fields
    }
  }
`

export const GET_ENTRY_BY_ID = gql`
  query getEntryById(
    $id: String!
    $locale: String!
    $include: Int!
    $createPath: any
  ) {
    entry(id: $id, locale: $locale, include: $include)
      @rest(type: "Entry", pathBuilder: $createPath) {
      items
      includes
    }
  }
`

export const GET_ENTRIES_BY_CONTENT_TYPE_ASSOCIATED_WITH_ID = gql`
  query getEntriesByContentTypeId(
    $id: String!
    $locale: String!
    $contentType: String!
    $include: Int!
    $limit: Int!
    $skip: Int!
    $order: String!
    $categoryExcludes: String!
    $categoryIncludes: String!
    $tagExcludes: String!
    $tagIncludes: String!
    $createPath: any
    $idIncludes: String!
  ) {
    entries(
      links_to_entry: $id
      locale: $locale
      content_type: $contentType
      include: $include
      limit: $limit
      skip: $skip
      order: $order
      categoryExcludes: $categoryExcludes
      categoryIncludes: $categoryIncludes
      tagExcludes: $tagExcludes
      tagIncludes: $tagIncludes
      idIncludes: $idIncludes
    ) @rest(type: "Entry", pathBuilder: $createPath) {
      items
      includes
      total
      limit
      skip
    }
  }
`

export const GET_NAVIGATION = gql`
  query getEntryById(
    $placement: String!
    $locale: String!
    $include: Int!
    $createPath: any
  ) {
    entry(
      placement: $placement
      locale: $locale
      include: $include
      content_type: "navigation"
    ) @rest(type: "Entry", pathBuilder: $createPath) {
      items
      includes
    }
  }
`

const usePreviewQuery = ({
  query,
  id,
  locale = 'en-US',
  include = 3,
  contentType,
  limit = 100,
  skip = 0,
  order = '-sys.updatedAt,sys.id',
  skipQuery,
  ...rest
}) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(query, {
    variables: {
      id,
      locale,
      include,
      contentType,
      limit,
      skip,
      order,
      createPath: createQueryStringPath,
      ...rest,
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    skip: skipQuery,
  })

  return { loading, error, data, refetch, networkStatus }
}

export default usePreviewQuery
export { usePreviewQuery }
