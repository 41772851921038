import React from 'react'
import PropTypes from 'prop-types'
import { StyledInput } from '../style'

const HoneyPot = ({ ...props }) => (
  <>
    <StyledInput type="text" autocomplete="off" tabindex="-1" {...props} />
  </>
)

HoneyPot.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default HoneyPot
