import React from 'react'
import {
  StyledSliderContainer,
  StyledSlide,
  StyledSlideLink,
  StyledSlideTag,
  StyledSlideTitle,
  StyledSlideSubtitle,
} from '../style'
import { generateRandomKey, capitalize } from '@utils'

const typeObj = {
  ContentfulNews: 'NEWS',
  ContentfulEvent: 'EVENT',
  ContentfulPracticeArea: 'PRACTICE AREA',
  ContentfulOffice: 'OFFICE',
  ContentfulArticleCategory: 'BLOG',
  ContentfulProfessional: 'PROFESSIONAL',
}

const LinkWrapper = ({ content, pageSlugs, children }) => {
  const linkType =
    content?.__typename === 'ContentfulLink' || content?.__typename === 'Link'
  return linkType ? (
    <a href={content?.url}>{children}</a>
  ) : (
    <StyledSlideLink
      to={content?.slug}
      contentType={content.__typename}
      category={content?.category?.slug}
    >
      {children}
    </StyledSlideLink>
  )
}

const IndividualSlide = ({ slide, ...props }) => {
  const getEntryTag = entry => {
    let type = entry.__typename
    const isPreview = !entry?.__typename?.includes('Contentful')
    if (isPreview) {
      type =
        (entry.__typename === 'ArticleGroup' && 'ContentfulArticleCategory') ||
        `Contentful${capitalize(entry?.__typename)}`
    }
    const articleCategory = type === 'ContentfulArticle'
    const contentTag = entry.tag || typeObj[type] || ''

    return (
      (articleCategory && entry?.category?.title) || contentTag || 'ARTICLE'
    )
  }
  let tag =
    slide?.contentDestination?.[0] &&
    getEntryTag(slide?.contentDestination?.[0])
  return (
    <StyledSlide>
      <LinkWrapper content={slide?.contentDestination?.[0]}>
        {tag && <StyledSlideTag>{tag}</StyledSlideTag>}
        {slide && slide.subtitle && (
          <StyledSlideTag>{slide.subtitle}</StyledSlideTag>
        )}
        {slide && slide.title && (
          <StyledSlideTitle
            color={slide?.fontColorTitle}
            fontSize={slide?.fontSizeTitle}
          >
            {slide.title}
          </StyledSlideTitle>
        )}
        {slide && slide.linkTitle && (
          <StyledSlideSubtitle
            color={slide?.fontColorLinkTitle}
            fontSize={slide?.fontSizeLinkTitle}
          >
            {slide.linkTitle}
          </StyledSlideSubtitle>
        )}
      </LinkWrapper>
    </StyledSlide>
  )
}
const TwoThirdsSlide = ({ slides, slide, ...props }) => {
  return (
    <StyledSliderContainer>
      {slide &&
        slide.map(
          s => s && <IndividualSlide slide={s} key={generateRandomKey()} />
        )}
    </StyledSliderContainer>
  )
}

export default TwoThirdsSlide
