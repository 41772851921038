import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from './components/Checkbox'
import Email from './components/Email'
import Password from './components/Password'
import Search from './components/Search'
import Tel from './components/Tel'
import Text from './components/Text'
import TextArea from './components/TextArea'
import Url from './components/Url'
import HoneyPot from './components/HoneyPot'
import Submit from './components/Submit'
import Icon from './components/Icon'

import { StyledLabel, StyledLabelDisplay, StyledErrorMessage } from './style'

const CHECKBOX = 'checkbox'
const EMAIL = 'email'
const PASSWORD = 'password'
const SEARCH = 'search'
const TEL = 'tel'
const TEXT = 'text'
const TEXTAREA = 'textarea'
const URL = 'url'
const HONEY_POT = 'honeyPot'
const SUBMIT = 'submit'

const ICON = 'icon'

const Input = ({ inputType, field, label, form, ...props }) => {
  const isValid = () => !form.touched[field.name] || !form.errors[field.name]

  const getInput = () => {
    if (inputType === EMAIL)
      return (
        <Email
          {...field}
          {...props}
          isValid={isValid()}
          disabled={form.isSubmitting}
        />
      )
    if (inputType === PASSWORD)
      return (
        <Password
          {...field}
          {...props}
          isValid={isValid()}
          disabled={form.isSubmitting}
        />
      )
    if (inputType === SEARCH)
      return (
        <Search
          {...field}
          {...props}
          isValid={isValid()}
          disabled={form.isSubmitting}
        />
      )
    if (inputType === TEL)
      return (
        <Tel
          {...field}
          {...props}
          isValid={isValid()}
          disabled={form.isSubmitting}
        />
      )
    if (inputType === TEXT)
      return (
        <Text
          {...field}
          {...props}
          isValid={isValid()}
          disabled={form.isSubmitting}
        />
      )
    if (inputType === URL)
      return (
        <Url
          {...field}
          {...props}
          isValid={isValid()}
          disabled={form.isSubmitting}
        />
      )
    if (inputType === TEXTAREA)
      return (
        <TextArea
          {...field}
          {...props}
          isValid={isValid()}
          disabled={form.isSubmitting}
        />
      )
    if (inputType === CHECKBOX)
      return (
        <Checkbox
          {...field}
          {...props}
          isValid={isValid()}
          disabled={form.isSubmitting}
        />
      )
    if (inputType === HONEY_POT)
      return <HoneyPot {...field} {...props} disabled={form.isSubmitting} />
    if (inputType === SUBMIT)
      return <Submit {...field} {...props} disabled={form.isSubmitting} />
    if (inputType === ICON)
      return <Icon {...field} {...props} disabled={form.isSubmitting} />
  }

  return (
    <StyledLabel
      htmlFor={props.id}
      type={inputType}
      style={{ display: inputType === HONEY_POT ? 'none' : null }}
    >
      {label && (
        <StyledLabelDisplay type={inputType}>{label}</StyledLabelDisplay>
      )}
      {getInput()}
      {form.touched[field.name] && form.errors[field.name] && (
        <StyledErrorMessage type={inputType}>
          {form.errors[field.name]}
        </StyledErrorMessage>
      )}
    </StyledLabel>
  )
}

Input.propTypes = {
  inputType: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  form: PropTypes.object.isRequired,
}

export default Input
