import React from 'react'
import locales from '../../../config/i18n'
import {
  StyledLanguageWrapper,
  LanguageTitle,
  LanguageLink,
  NavH2,
  StyledDownCaret,
} from './style'
import Icon from '../Icon'
import Dropdown, { DropdownItem } from '../Dropdown'
import DownCaret from '@assets/svg/down-caret.svg'
import { useTranslatedTerms } from '@hooks'

const LanguagePicker = ({ homeSlug = '/' }) => {
  const { getTranslatedTerm } = useTranslatedTerms()
  let languages = Object.values(locales)

  const prefix = homeSlug === '/' ? '' : '/'

  return (
    <StyledLanguageWrapper>
      <NavH2 id="qtph-globalmenu-sites-sitesmenu">
        {getTranslatedTerm('Sites')}
      </NavH2>
      <StyledDownCaret className="caret">
        <DownCaret
          width="20px"
          height="100%"
          style={{ justifyContent: 'center', alignItems: 'center' }}
        />
      </StyledDownCaret>
      <Dropdown>
        {languages?.map(
          ({
            siteLanguage,
            path,
            locale: loc,
            langPicker,
            navTestingClassName,
          }) => (
            <DropdownItem key={`language-${siteLanguage}`}>
              <LanguageLink
                id={navTestingClassName}
                href={
                  path === 'en'
                    ? `${prefix}${homeSlug}`
                    : `/${path}${prefix}${homeSlug}`
                }
                hrefLang={siteLanguage}
              >
                <Icon glyph={loc} />
                <LanguageTitle>{langPicker}</LanguageTitle>
              </LanguageLink>
            </DropdownItem>
          )
        )}
      </Dropdown>
    </StyledLanguageWrapper>
  )
}

export default LanguagePicker
