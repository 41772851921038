import React from 'react'
import { H1, H3 } from '../../Globals'
import { AssetContainer } from '../style'

const Asset = ({ videoSrc, title }) => {
  let urlString = videoSrc && videoSrc.split('.')
  urlString = urlString && urlString.slice(0, urlString.length - 1).join('.')
  return (
    <AssetContainer>
      <video style={{ width: '100%' }} controls>
        <source src={`https:${urlString}.webm`} type="video/webm" />
        <source src={`https:${urlString}.mp4`} type="video/mp4" />
        <source src={`https:${urlString}.ogv`} type="video/ogg" />
        <source src={`https:${videoSrc}`} />
      </video>
      {title && (
        <>
          <H3 color="gold" marginTop={30}>
            WATCH
          </H3>
          <H1 marginBottom={30} smallerText={2} color="purple">
            {title}
          </H1>
        </>
      )}
    </AssetContainer>
  )
}

export default Asset
