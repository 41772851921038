import React, { useEffect, useState } from 'react'
import EmbeddedImage from './components/EmbeddedImage'
import EmbeddedImageContainer from './components/EmbeddedImageContainer'
import EmbeddedMediaContainer from './components/EmbeddedMediaContainer'
import { EMBED_QUERY } from '../../apollo/queries/contentful.apollo.queries'
import { useQuery } from '@apollo/client'
import { deepClone } from '@utils'

export const PreviewEmbeddedAsset = ({
  title,
  align,
  width,
  wrapText,
  internalLinkCollection,
  mediaCollection,
  url,
  modal,
  displayTitle,
  type,
  file,
  linkId,
}) => {
  const [data, setData] = useState(null)

  const { data: previewData } = useQuery(EMBED_QUERY, {
    variables: { id: linkId },
    skip: !linkId,
  })

  useEffect(() => {
    if (previewData) setData(deepClone(previewData))
  }, [previewData])

  const slug =
    internalLinkCollection?.items?.[0]?.slug ||
    data?.componentMediaEmbed?.internalLinkCollection?.items?.[0]?.slug

  const typename =
    internalLinkCollection?.items?.[0]?.__typename ||
    data?.componentMediaEmbed?.internalLinkCollection?.items?.[0]?.__typename

  const internalCategory =
    internalLinkCollection?.items?.[0]?.category?.slug ||
    data?.componentMediaEmbed?.internalLinkCollection?.items?.[0]?.category
      ?.slug

  const imageObj = {
    imageSrc: mediaCollection?.items?.[0]?.url || file,
    slug: {
      internalSlug: slug,
      typename,
      internalCategory,
    },
    externalResource: url,
    title,
    width,
    align,
    wrapText,
    displayTitle,
  }
  const previewVideoSrc = mediaCollection?.items?.[0]?.url || file
  const videoObj = {
    url,
    align,
    width,
    displayTitle,
    modal,
    media: [
      {
        file: {
          url: previewVideoSrc?.substring(6, previewVideoSrc.length),
          contenType: mediaCollection?.items?.[0]?.contentType || type,
        },
      },
    ],
  }
  return type === 'Image' ? (
    <EmbeddedImage {...imageObj} />
  ) : (
    <EmbeddedMediaContainer {...videoObj} />
  )
}

const EmbeddedAsset = ({ node, references, moduleData }) => {
  const asset = references?.find(r => r.id === node?.data?.target?.sys?.id)
  // ContentfulAsset
  const assetType = asset?.file?.url?.includes('image')
  // ContentfulImageVideo Embed
  const type = moduleData?.type

  return type === 'Image' || assetType ? (
    <EmbeddedImageContainer {...asset} {...moduleData} />
  ) : (
    <EmbeddedMediaContainer {...moduleData} {...asset} />
  )
}

export default EmbeddedAsset
