import styled from 'styled-components'
import { Container } from '../Globals'
import worldMap from '@assets/images/Homepage_Map_23_Offices_041024_Transparent.webp'
import { sizes } from '@theme'

export const MapSection = styled.section`
  padding: 100px 0px;
  background: #ffffff;
  min-height: 750px;
  position: relative;
  overflow: hidden;

  @media (max-width: ${sizes.small.min}) {
    padding: 50px 15px;
  }
`

export const StyledOfficeContainer = styled(Container)`
  position: relative;
  z-index: 3;
`

export const StyledOfficesMap = styled.div`
  display: block;
  padding: 115px 0px 0px;
  float: left;
  width: 100%;
  max-width: 1000px;

  @media (max-width: ${sizes.large.min}) {
    display: flex;
    padding: 300px 0px 0px;
  }
`

export const OfficesCol = styled.div`
  display: block;
  float: left;
  margin: 10px 190px 0px 0px;
  max-width: 208px;
  width: 100%;

  &:first-child {
    padding: 45px 0px 0px;
    @media (max-width: ${sizes.large.min}) {
      padding: 0;
    }
  }

  &:last-child {
    padding-top: 45px;
    margin: 0px;
    float: right;
    width: 100px;

    @media (max-width: ${sizes.large.min}) {
      float: none;
      padding: 0px;
      max-width: 100%;
    }
  }

  @media (max-width: ${sizes.small.min}) {
    margin: 25px 0px 0px;
    padding: 0px;
    max-width: 100%;
    width: 100%;
  }

  @media (max-width: ${sizes.small.max}) {
    max-width: 160px;
    margin: 0px;
  }

  @media (min-width: ${sizes.large.min}) {
    margin: 0px 115px 0px 0px;
  }
`

export const RegionTitle = styled.h2`
  margin: 0px 0px 10px;
  padding: 0px;
  font-size: 24px;
  line-height: 22px;
  color: #b4a06e;
  text-transform: capitalize;
`

export const MapTitle = styled.h2`
  margin: 0px 0px 30px;
  font-size: 34px;
  line-height: 42px;
  color: #27251f;
`
export const OfficeTitle = styled.li`
  text-transform: none;
  font-size: 14px;
  font-weight: 300;
  line-height: 28px;
  & :hover {
    text-transform: underline !important;
  }
`

export const MapContainer = styled.div`
  width: 100%;
  background: url(${worldMap}) no-repeat 40px 0px;
  min-height: 450px;
  background-size: contain;

  @media (max-width: ${sizes.large.min}) {
    background: url(${worldMap}) no-repeat 0px 0px;
    background-size: contain;
    min-height: 440px;
    width: 100%;
  }
`
