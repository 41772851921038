import moment from 'moment'

export const handleIgnitePreviewInsights = data => {
  let previewInsights = []
  if (
    data?.practiceAreaCollection?.items?.[0]?.linkedFrom?.articleCollection
      ?.items
  )
    previewInsights = previewInsights.concat(
      data?.practiceAreaCollection?.items?.[0]?.linkedFrom?.articleCollection?.items
        .filter(insight =>
          insight?.displayOnSites?.includes('American English')
        )
        .sort((l, r) => {
          if (l?.publicationDate === r?.publicationDate)
            return l?.sys?.firstPublishedAt > r?.sys?.firstPublishedAt ? -1 : 1
          return l?.publicationDate > r?.publicationDate ? -1 : 1
        })
        .slice(0, 10)
    )

  if (
    data?.practiceAreaCollection?.items?.[0]?.linkedFrom?.newsCollection?.items
  )
    previewInsights = previewInsights.concat(
      data?.practiceAreaCollection?.items?.[0]?.linkedFrom?.newsCollection?.items
        .filter(insight =>
          insight?.displayOnSites?.includes('American English')
        )
        .sort((l, r) => {
          if (l?.publicationDate === r?.publicationDate)
            return l?.sys?.firstPublishedAt > r?.sys?.firstPublishedAt ? -1 : 1
          return l?.publicationDate > r?.publicationDate ? -1 : 1
        })
        .slice(0, 10)
    )

  previewInsights = previewInsights.map(
    ({ sys, publicationDate, __typename, ...rest }) => ({
      ...rest,
      publicationDate:
        publicationDate && moment(publicationDate).format('MMMM DD, YYYY'),
      id: sys?.id,
      __typename: `Contentful${__typename}`,
    })
  )

  return previewInsights
}

const formatPreviewAsset = ({ contentType, url, ...rest }) => {
  return {
    ...rest,
    file: {
      contentType: contentType,
      url: url,
    },
  }
}

const formatPreviewCta = ({ linksTo, ...rest }) => {
  return {
    ...rest,
    linksTo: linksTo
      ? {
          ...linksTo,
          type: `Contentful${linksTo?.type}`,
        }
      : undefined,
  }
}

const formatPreviewCarousel = (type, { slidesCollection, slug }) => {
  return {
    type: `Contentful${type}`,
    slug: slug,
    slides: slidesCollection?.items?.map(
      ({ background, cta, sys, ...rest }) => ({
        ...rest,
        background: background && formatPreviewAsset(background),
        cta: cta && formatPreviewCta(cta),
        id: sys?.id,
      })
    ),
  }
}

const formatPreviewTextSection = (type, { cta, ...rest }) => {
  return {
    ...rest,
    type: `Contentful${type}`,
    cta: cta && formatPreviewCta(cta),
  }
}

const formatPreviewModalSelection = (
  type,
  { modalItemsCollection, ...rest }
) => {
  return {
    ...rest,
    type: `Contentful${type}`,
    modalItems: modalItemsCollection?.items?.map(
      ({ body, cta, image, sys, ...rest }) => ({
        ...rest,
        body: { body: body },
        cta: cta && formatPreviewCta(cta),
        image: image && formatPreviewAsset(image),
        id: sys?.id,
      })
    ),
  }
}

const formatPreviewArticleNewsSection = (
  type,
  { backgroundImage, ...rest }
) => {
  return {
    ...rest,
    type: `Contentful${type}`,
    backgroundImage: backgroundImage && formatPreviewAsset(backgroundImage),
  }
}

const formatPreviewProfessionalShowcase = (
  type,
  { cta, professionalsCollection, ...rest }
) => {
  return {
    ...rest,
    type: `Contentful${type}`,
    cta: cta && formatPreviewCta(cta),
    professionals: professionalsCollection?.items.map(({ image, ...rest }) => ({
      ...rest,
      image: image && formatPreviewAsset(image),
    })),
  }
}

const formatPreviewExpertiseShowcase = (
  type,
  { body, cardsCollection, cta, ...rest }
) => {
  return {
    ...rest,
    cta: cta && formatPreviewCta(cta),
    type: `Contentful${type}`,
    body: { body: body },
    cards: cardsCollection?.items?.map(({ cta, iconImage, ...rest }) => ({
      ...rest,
      cta: cta && formatPreviewCta(cta),
      iconImage: iconImage && formatPreviewAsset(iconImage),
    })),
  }
}

const formatPreviewShowcase = ({ type, ...rest }) => {
  switch (type) {
    case 'IgniteComponentExpertiseShowcase':
      return formatPreviewExpertiseShowcase(type, rest)
    case 'IgniteComponentProfessionalsShowcase':
      return formatPreviewProfessionalShowcase(type, rest)
    default:
      return { type, ...rest }
  }
}

const formatPreviewImageBackgroundSection = (
  type,
  { backgroundImage, showcase, ...rest }
) => {
  return {
    ...rest,
    type: `Contentful${type}`,
    backgroundImage: backgroundImage && formatPreviewAsset(backgroundImage),
    showcase: showcase && formatPreviewShowcase(showcase),
  }
}

const formatPreviewOfficesSection = (type, { cta, image, ...rest }) => {
  return {
    ...rest,
    cta: cta && formatPreviewCta(cta),
    image: image && formatPreviewAsset(image),
    type: `Contentful${type}`,
  }
}

const formatPreviewNavigation = (
  type,
  { navigationItemsCollection, ...rest }
) => {
  return {
    ...rest,
    type: `Contentful${type}`,
    navigationItems: navigationItemsCollection?.items?.map(
      ({ __typename, navigationLink, ...rest }) => ({
        ...rest,
        type: `Contentful${__typename}`,
        navigationLink: {
          ...navigationLink,
          type: `Contentful${navigationLink?.type}`,
        },
      })
    ),
  }
}

export const formatPreviewModule = ({ type, ...rest }) => {
  switch (type) {
    case 'IgniteModuleCarousel':
      return formatPreviewCarousel(type, rest)
    case 'IgniteModuleTextSection':
      return formatPreviewTextSection(type, rest)
    case 'IgniteModuleModalSection':
      return formatPreviewModalSelection(type, rest)
    case 'IgniteModuleArticleNewsSection':
      return formatPreviewArticleNewsSection(type, rest)
    case 'IgniteModuleImageBackgroundSection':
      return formatPreviewImageBackgroundSection(type, rest)
    case 'IgniteModuleOfficesSection':
      return formatPreviewOfficesSection(type, rest)
    case 'IgniteModuleNavigation':
      return formatPreviewNavigation(type, rest)
    default:
      return {
        type,
        ...rest,
      }
  }
}
