import React from 'react'
import ProfessionalsBlock from '@components/Cards/components/ProfessionalsBlock'

export const Contacts = ({ layout, ...rest }) => {
  return (
    <ProfessionalsBlock
      padding="0px 0px 70px"
      layout={layout === 'Single Column'}
      {...rest}
    />
  )
}
