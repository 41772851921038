import styled, { css } from 'styled-components'
import { colors } from '@theme'
import { InlineA } from '@components/Button'
import { color } from 'styled-system'

export const fontStack = css`
  font-family: 'Helvetica Neue', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit;
  text-transform: none;
  text-align: inherit;
`

export const BodyCopy = styled.p`
  ${fontStack}
  ${color}
  font-size: '18px';
  line-height: '1.6';
  letter-spacing: normal;
  margin-bottom: 35px;
  word-break: break-word;

  &:empty {
    display: none;
  }
`

export const Heading = styled.h1`
  ${fontStack};
  font-weight: 600;
  letter-spacing: 0.4px;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 20px;
`

export const HeadingH2 = styled.h2`
  ${fontStack};
  font-weight: 600;
  letter-spacing: 0.4px;
  letter-spacing: normal;
  line-height: 1.2;
  margin-bottom: 20px;
`

export const H1 = styled(Heading)`
  font-size: 48px;

  @media (max-width: 576px) {
    font-size: 34px;
  }

  @media (max-width: 375px) {
    font-size: 30px;
  }
`

export const H2 = styled(HeadingH2)`
  font-size: 28px;
`

export const StyledH2 = styled(H2)`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '2px'};
  line-height: 26px;
  padding: ${({ padding }) => padding || '0 0 4px'};
  ${({ noDivider }) =>
    !noDivider && 'border-bottom: 1px solid rgba(39, 37, 31, 0.2)'};
  text-transform: ${({ textCase }) => textCase || 'uppercase'};
  width: 100%;
  box-sizing: border-box;
  margin: ${({ margin }) => margin || '30px 0px 30px'};
  color: ${({ color }) => color || 'black'};

  &:hover {
    text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
    cursor: ${({ underline }) => (underline ? 'pointer' : 'default')};
  }
`

export const H3 = styled(Heading)`
  font-size: 24px;
`

export const H4 = styled(Heading)`
  font-size: 20px;
`

export const H5 = styled(Heading)`
  font-size: 16px;
`

export const H6 = styled(StyledH2)`
  margin-top: 0px;
`

export const Quote = styled.blockquote`
  color: ${colors.gray};
  margin: 30px;
`

export const HR = styled.hr`
  margin-bottom: 30px;
`

export const StyledUl = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  padding-left: 20px;

  li,
  p {
    padding: 0;
    margin-bottom: 5px;
  }

  li ul {
    list-style-type: circle;
  }

  li ul li ul {
    list-style-type: square;
  }
`

export const StyledOl = styled.ol`
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  padding-left: 20px;

  li,
  p {
    padding: 0;
    margin-bottom: 5px;
  }

  li ol {
    list-style-type: upper-alpha;
  }

  li ol li ol {
    list-style-type: lower-roman;
  }

  li ol li ol li ol {
    list-style-type: lower-alpha;
  }
`

export const StyledSubtitle = styled.div`
  font-style: italic;
  font-size: 12px;
  padding-left: 16px;
`

export const StyledInlineA = styled(InlineA)`
  color: ${({ color }) => color || `${colors.gold}`};
  cursor: pointer;
  display: inline !important;

  &:hover {
    color: ${({ color }) => color || `${colors.gold}`};
    text-decoration: ${({ noUnderline }) => noUnderline || 'underline'};
  }
`

export const StyledInlineButton = styled(InlineA)`
  border: 1px solid #b4a06e;
  background-color: #b4a06e;
  padding: 13px 20px;
  border-radius: 3px;
  font-size: 14px;
  letter-spacing: 1.5px;
  font-weight: 700;
  line-height: 22px;
  &:hover {
    background-color: #a48c51;
    color: ${({ color }) => color || `${colors.gold}`};
    text-decoration: ${({ noUnderline }) => noUnderline || 'underline'};
  }
`

export const StyledFullWidthButton = styled(StyledInlineButton)`
  width: 100%;
  text-align: center;
`

export const ListH2 = styled(StyledH2)`
  padding: 12px 0px 12px 16px;
  &:before {
    content: '';
    background-color: #b4a06e;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: 0px;
    left: -4px;
    height: 8px;
    width: 8px;
    z-index: 0;
  }
`

export const StyledDisclaimer = styled.div`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: normal;
  line-height: 20px !important;
  margin-top: 0;
  font-weight: 400;
  color: black;
  font-style: italic;
  word-break: break-all;

  & p {
    font-size: 12px;
    letter-spacing: normal;
    margin-bottom: 15px;
    line-height: 20px !important;
    margin-top: 0;
    font-weight: 400;
    font-style: italic;
    color: inherit;
  }
`
