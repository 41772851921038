import React from 'react'
import { StyledH4 } from './style'
import A from '@components/A'

const SecondLevelSubNavigationItem = ({
  displayText,
  slug,
  href,
  __typename,
}) => {
  return (
    <div className="flex flex-col ml-2.5 mt-[15px]">
      <A to={slug} href={href} contentType={__typename}>
        <StyledH4>{displayText}</StyledH4>
      </A>
    </div>
  )
}

SecondLevelSubNavigationItem.propTypes = {}

export default SecondLevelSubNavigationItem
