import { useState } from 'react'
import { useMeasure, useDelayedUnmounting, usePrevious } from '@hooks'
import { useSpring } from 'react-spring'

export const useDropdown = (
  transformFrom = '',
  transformTo = '',
  duration = 250
) => {
  const [visible, setVisible] = useState(false)
  const delayedVisible = useDelayedUnmounting(visible, duration)
  const previous = usePrevious(visible)
  const [bind, { height: viewHeight }] = useMeasure()
  const { height, opacity, transform } = useSpring({
    config: { duration: duration },
    from: { height: 0, opacity: 0, transform: transformFrom },
    to: {
      height: visible ? viewHeight : 0,
      opacity: visible ? 1 : 0,
      transform: transformTo,
    },
  })

  const toggleVisible = () => {
    setVisible(!visible)
  }

  return {
    bind,
    toggleVisible,
    height,
    opacity,
    transform,
    visible: visible,
    previous: previous,
    delayedVisible: delayedVisible,
  }
}

export default useDropdown
