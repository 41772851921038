import React from 'react'
import Table from '@components/Table'
import { H5 } from '../style'

export const StyledTable = ({
  rowHeader,
  rows,
  numberOfColumns,
  displayTitle,
  title = '',
  ...rest
}) => {
  if (!rows || !rowHeader) return null

  const newRows = rows?.map(row => {
    const filtered = {}
    Object.keys(row).forEach(key => {
      if (!key.includes('cellData')) filtered[key] = row[key]
      if (parseInt(key.split('cellData')?.[1]) <= numberOfColumns) {
        filtered[key] = row[key]
      }
    })
    return filtered
  })

  const newColumns = Object.entries(rowHeader)
    .filter(
      ([key, val]) =>
        key.includes('cellData') &&
        parseInt(key.split('cellData')?.[1]) <= numberOfColumns
    )
    .map(([key, val]) => ({ id: key, val }))

  return (
    <>
      {displayTitle && <H5>{title}</H5>}
      <Table
        rows={newRows}
        columns={newColumns}
        noInfiniteLoad
        maxHeight={500}
        displayTitle={displayTitle}
        {...rest}
      />
    </>
  )
}
