import React from 'react'
import A from '@components/A'
import { colors } from '@theme'

export const AssetHyperLink = ({ file, title, description, children }) => {
  return (
    <A href={file?.url} color={colors.gold} fontWeight="normal" underline>
      {children}
    </A>
  )
}
