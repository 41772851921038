import React, { useState } from 'react'
import { Tabs, TabPanel, Tab } from '../index'
import { renderContainerFromContentful } from '../../../utils/gatsby/reactFromContentful'

const TabsBlock = ({ tabs, activeTab, id }) => {
  const [visibleTab, setVisibleTab] = useState(activeTab)
  return (
    <div style={{ backgroundColor: 'white' }} key={id}>
      <Tabs
        aria-label="Tabbed Content Navigation"
        key={id}
        tab={visibleTab}
        setTab={setVisibleTab}
      >
        {tabs &&
          tabs.map((tab, index) => (
            <Tab
              label={tab.title}
              key={tab.id}
              index={index}
              tab={tab}
              setTab={setVisibleTab}
            />
          ))}
      </Tabs>
      {tabs &&
        tabs.map((tab, index) => (
          <TabPanel
            index={index}
            key={tab.id}
            tab={visibleTab}
            setTab={setVisibleTab}
          >
            {tab &&
              tab.modules &&
              tab.modules?.map(m => renderContainerFromContentful(m))}
          </TabPanel>
        ))}
    </div>
  )
}

export default TabsBlock
