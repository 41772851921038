import React from 'react'
import {
  StyledLanguageWrapper,
  LanguageTitle,
  StyledDownCaret,
} from '../../LanguagePicker/style'
import { NavH2 } from '../style'
import Dropdown, { DropdownItem } from '../../Dropdown'
import DownCaret from '@assets/svg/down-caret.svg'
import { useTranslatedTerms } from '@hooks'

const LoginPickerMobile = props => {
  const { getTranslatedTerm } = useTranslatedTerms()

  const items = [
    {
      id: 'login-item-1',
      title: 'Alumni',
      slug: 'https://alumni.paulhastings.com/login/',
    },
    {
      id: 'login-item-3',
      title: 'Paul Hastings',
      slug: '/mlogin',
    },
  ]

  return (
    <Dropdown>
      {items.map(({ title, id, slug, testingClassName }) => (
        <DropdownItem key={id}>
          <a href={slug} id={testingClassName}>
            <LanguageTitle>{getTranslatedTerm(title)}</LanguageTitle>
          </a>
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

const LoginPicker = props => {
  const { getTranslatedTerm } = useTranslatedTerms()
  const items = [
    {
      id: 'login-item-1',
      title: 'Alumni',
      slug: 'https://alumni.paulhastings.com/login/',
      testingClassName: 'qtph-globalmenu-login-alumni-link',
    },
    {
      id: 'login-item-3',
      title: 'Paul Hastings',
      slug: '/mlogin',
      testingClassName: 'qtph-globalmenu-login-paulhastingslogin-link',
    },
  ]

  return (
    <StyledLanguageWrapper>
      <NavH2 id="qtph-globalmenu-login-loginmenu">
        {getTranslatedTerm('Login')}
      </NavH2>
      <StyledDownCaret className="caret">
        <DownCaret
          width="20px"
          height="100%"
          style={{ justifyContent: 'center', alignItems: 'center' }}
        />
      </StyledDownCaret>
      <Dropdown>
        {items.map(({ id, title, slug, testingClassName }) => (
          <DropdownItem key={id}>
            <a href={slug} id={testingClassName}>
              <LanguageTitle>{getTranslatedTerm(title)}</LanguageTitle>
            </a>
          </DropdownItem>
        ))}
      </Dropdown>
    </StyledLanguageWrapper>
  )
}

export default LoginPicker
export { LoginPickerMobile }
