import React, { useState, useEffect } from 'react'
import { components } from 'react-select'
import { SimpleSelect, AsyncSimpleSelect } from '../Select'
import { BodyCopy, Col } from '../Globals'
import { Column, Row } from '@components/Common'
import { FullWidthButton } from '../Button'
import { colors, sizes } from '@theme'
import Icon from '../Icon'
import { useMediaQuery, useTranslatedTerms } from '@hooks'

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.bgColor || colors.purple,
      borderRadius: '3px',
    }
  },
  multiValueLabel: (styles, { data }) => {
    return {
      ...styles,
      color: data.color || 'white',
      padding: '5px',
    }
  },
  multiValueRemove: (styles, { data }) => {
    return {
      ...styles,
      color: data.color || 'white',
      ':hover': {
        backgroundColor: data.hoverBgColor || colors.purple,
        color: data.hoverColor || 'white',
        cursor: 'pointer',
      },
    }
  },
}

const ClearIndicator = mobile => props => (
  <components.ClearIndicator {...props}>
    {!mobile ? 'Reset Filters' : 'Reset'}
  </components.ClearIndicator>
)

const DropdownIndicator = () => null

const Placeholder = props => {
  const { getTranslatedTerm } = useTranslatedTerms()
  return (
    <components.Placeholder {...props}>
      {getTranslatedTerm('Search By Keyword')}
    </components.Placeholder>
  )
}

const filterGroupedOption = (inputValue, groupedOption) => {
  const newOptions = groupedOption.options.filter(
    option =>
      option.label &&
      option.label?.toLowerCase().includes(inputValue?.toLowerCase())
  )
  return { ...groupedOption, options: newOptions }
}

const filterOptions = (inputValue, groupedOptions) =>
  groupedOptions.map(groupedOption =>
    filterGroupedOption(inputValue, groupedOption)
  )

const promiseOptions = (options, delay = 1000) => inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterOptions(inputValue, options))
    }, delay)
  })

const SelectBoxOne = ({
  data,
  placeholder,
  dropdownClassName,
  filterByField,
  absolute,
}) => (
  <SimpleSelect
    widthPercentage={100}
    closeMenuOnSelect
    components={{ ClearIndicator }}
    isSearchable
    options={data || null}
    placeholder={placeholder}
    onChange={filterByField}
    dropdownClassName={dropdownClassName}
    absolute={absolute}
  />
)

const SearchAndFilterDisplay = ({
  handleSelect,
  data,
  filters,
  onInputChange,
  mobile,
}) => {
  return (
    <AsyncSimpleSelect
      components={{
        Placeholder,
        ClearIndicator: ClearIndicator(mobile),
        DropdownIndicator,
      }}
      isMulti
      cacheOptions
      defaultOptions={[]}
      loadOptions={promiseOptions(data, 500)}
      styles={colourStyles}
      placeholder="Search"
      value={filters}
      onChange={handleSelect}
      classNamePrefix="react-select"
      className="react-select-container"
      staticIndicators
      isValid
    />
  )
}

const KeyUpSearch = ({
  groupedOptions,
  mobile,
  handleRerender,
  filterAlignBetween,
  xs = 6,
  sm = 6,
  md = 4,
  lg = 4,
  xl = 2,
  insights = false,
  handleSearchButton,
  resetFilters,
  startingFilter,
}) => {
  const [filters, setFilters] = useState(startingFilter ? [startingFilter] : [])
  const { getTranslatedTerm } = useTranslatedTerms()
  const filterByField = newFilter => {
    const filterCurrentIndex = filters.findIndex(
      filter => filter.value === newFilter.value
    )
    const filterHasBeenAlreadyApplied = filterCurrentIndex !== -1

    if (filterHasBeenAlreadyApplied) {
      return
    }
    setFilters([...filters, newFilter])
  }

  useEffect(() => {
    handleRerender(filters)
  }, [filters, handleRerender])

  useEffect(() => {
    if (resetFilters) setFilters([])
  }, [resetFilters])

  const filterByAll = filtersArray => {
    const noFiltersApplied =
      !Array.isArray(filtersArray) || filtersArray.length === 0

    if (noFiltersApplied) {
      setFilters([])
    } else {
      setFilters(filtersArray)
    }
  }

  const insightsLg = useMediaQuery(`(min-width: ${sizes.large.min})`)
  const filterMarginRight =
    insights && insightsLg && filterAlignBetween ? '10px' : '0px'
  const alignBetween = !insights || !insightsLg

  return (
    <>
      <Row className="justify-center">
        <Column>
          <SearchAndFilterDisplay
            handleSelect={filterByAll}
            data={groupedOptions}
            filters={filters}
            mobile={mobile}
          />
        </Column>
      </Row>
      <Row className={`${alignBetween ? 'justify-between' : null}`}>
        {!mobile &&
          groupedOptions
            .filter(field => field.label !== 'Name')
            .map(field => {
              const placeholder =
                field.label.length > 13
                  ? `${field.label.slice(
                      0,
                      field.label.length / 2
                    )} ${field.label.slice(field.label.length / 2)}`
                  : field.label

              return (
                <Col
                  xs={xs - 0.1}
                  sm={sm - 0.1}
                  md={md - 0.1}
                  lg={lg - 0.1}
                  xl={xl - 0.1}
                  marginTop={10}
                  key={field.label}
                  style={{ marginRight: filterMarginRight }}
                >
                  <SelectBoxOne
                    filterByField={filterByField}
                    data={field.options}
                    filters={filters}
                    placeholder={placeholder}
                    key={field.label}
                    style={{ marginRight: '10px' }}
                    dropdownClassName={field.dropdownClassName}
                    absolute="true"
                  />
                </Col>
              )
            })}
        <Col
          xs={xs - 0.1}
          sm={sm - 0.1}
          md={md - 0.1}
          lg={lg - 0.1}
          xl={xl - 0.1}
          marginTop={10}
        >
          <FullWidthButton
            className="qtph-prof-search-btn items-center justify-center flex cursor-pointer"
            active
            style={{
              width: '100%',
              height: '100%',
              fontSize: '14px',
              borderRadius: '3px',
            }}
            color="white"
            onClick={handleSearchButton || (() => {})}
          >
            <Icon glyph="search" height={25} width={25} />
            <BodyCopy
              fontSize={14}
              style={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                paddingRight: '10px',
              }}
            >
              {getTranslatedTerm('Submit')}
            </BodyCopy>
          </FullWidthButton>
        </Col>
      </Row>
    </>
  )
}

export default KeyUpSearch
