/* eslint-disable func-names */
import styled, { css, createGlobalStyle } from 'styled-components'
import { colors, sizes } from '@theme'
import { StyledLink, StyledNakedLink } from '../Button/style'
import { space, typography } from 'styled-system'

export const fontStack = css`
  font-family: 'Helvetica Neue', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 18px;
`

export const printStyles = css`
  @media print {
    body {
      margin: 0;
      padding: 0;
      color: #000;
      background-color: #fff;
    }

    p,
    p * {
      font-size: 12pt !important;
    }

    header {
      position: relative !important;
      box-shadow: none !important;
    }

    main {
      margin-top: 0 !important;
    }

    [class*='LayoutWrapper'],
    main [class*='Globals__Flex'] {
      display: block !important;
    }

    nav#primary-nav,
    [class*='StyledTabsContainer'],
    [class*='StyledTabsContainer'] ul,
    [class*='StyledTabsMobileContainer'],
    section:first-child,
    section:last-child,
    .acsb-trigger,
    footer {
      display: block !important;
      display: none !important;
    }

    @page {
      margin: 1cm;
      size: letter;
    }

    [class*='StyledNavContainer'],
    [class*='NavContainer'] {
      width: 100% !important;
      max-width: 8.5in !important;
      padding: 0 !important;
    }
  }
`

export const GlobalStyle = createGlobalStyle`
  html {
    ${fontStack};
    width: 100%; 
    height: 100%;
  }
  body {
    color: ${props => (props.whiteColor ? 'white' : 'black')};
    background-color: ${colors.bgGray};
  }
  p {
    color: ${colors.text.default};
    font-size: 18px;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  ${({ template }) => template === 'professional-detail' && printStyles}
`

export const BG = styled.div`
  background-color: ${({ color }) => colors[color] || colors.bgGray};
`

export const Block = styled.div`
  background-color: ${({ color }) => colors[color] || colors.bgGray};
  background-image: ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : 'none')};
  background-size: ${({ imgUrl }) => (imgUrl ? '565px' : 'none')};
  background-position: ${({ imgUrl }) => (imgUrl ? 'right top' : 'none')};
  background-repeat: ${({ imgUrl }) => (imgUrl ? 'no-repeat' : 'none')};
  width: 100%;
  padding: ${({ padding }) => padding || '1em 0em 1em 0em'};
  margin: ${({ margin }) => margin || '0 auto'};

  @media (max-width: ${sizes.small.max}) {
    padding: 50px 0px;
  }
`

export const HomeSection = styled.section``

export const zIndex = new (function() {
  this.base = 1 // z-index: auto content will go here or inherit z-index from a parent

  this.background = this.base - 1 // content that should always be behind other things (e.g. textures/illos)
  this.hidden = this.base - 2 // this content should be hidden completely

  this.card = this.base + 1 // all cards should default to one layer above the base content
  this.loading = this.card + 1 // loading elements should never appear behind cards
  this.avatar = this.card + 1 // avatars should never appear behind cards
  this.form = this.card + 1 // form elements should never appear behind cards
  this.search = this.form // search is a type of form and should appear at the same level

  this.navBar = 3000 // navBar should be visible in modal contexts
  this.dropDown = this.navBar + 1 // dropDowns shouldn't appear behind the navBar

  this.fullscreen = 4000 // fullscreen elements should cover all screen content except toasts

  this.modal = 5000 // modals should completely cover base content and drawer as well
  this.gallery = this.modal + 1 // gallery should never appear behind a modal

  this.toast = 6000 // toasts should be visible in every context
  this.tooltip = this.toast + 1 // tooltips should always be on top
})()

export const H1 = styled.h1`
  ${fontStack};
  color: ${({ color }) => colors[color] || 'inherit'};
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  font-size: ${({ smallerText }) =>
    smallerText ? `${smallerText}rem` : '3rem'};
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: ${({ lineHeight }) => lineHeight || '1.3'};
  padding: 0;
  text-align: inherit;
  margin: ${({ margin }) => margin || '0px'};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};

  @media (max-width: 576px) {
    font-size: ${({ smallerText }) =>
      smallerText ? `${smallerText}rem` : '3rem'};
    line-height: 1.2;
  }

  ${StyledLink}:hover & {
    color: ${({ hover }) => (hover ? colors.text[hover] : colors.text.default)};
  }
  ${StyledNakedLink}:hover & {
    color: ${({ hover }) => (hover ? colors.text[hover] : colors.text.default)};
  }
`
export const OfficeH1 = styled(H1)`
  letter-spacing: 0;
  line-height: 52px;
  @media (max-width: ${sizes.xsmall.max}) {
    font-size: 32px;
    padding: 5px 0px 0px;
  }
  @media (min-width: ${sizes.small.min}) {
    font-size: 40px;
  }
`

export const InsightsH1 = styled(H1)`
  font-size: 40px;
  line-height: 42px;
  letter-spacing: 0px;

  @media (max-width: ${sizes.small.min}) {
    font-size: 34px;
    line-height: 38px;
  }

  @media (max-width: 375px) {
    font-size: 30px;
    line-height: 34px;
  }
`

// SUB-HEADINGS
export const H2 = styled.h2`
  ${fontStack};
  color: ${({ color }) => (color ? colors.text[color] : 'inherit')};
  text-transform: ${({ normalCase }) => (normalCase ? 'none' : 'uppercase')};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize} !important;`}
  font-weight: 600;
  line-height: ${({ lineHeight }) => lineHeight || '1.4rem'};
  margin: 0;
  padding: 0;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  letter-spacing: ${({ letterSpacing }) => letterSpacing && '2.29px'};
`
export const SidebarH2 = styled.h2`
  ${fontStack};
  color: ${({ color }) => (color ? colors.text[color] : 'inherit')};
  text-transform: none;
  font-weight: 600;
  padding: 0;
  font-size: 30px;
  line-height: 34px;
    letter-spacing: normal;
    display: block;
    margin-bottom: 20px;
}

`
export const H3 = styled.h3`
  ${fontStack};
  color: ${({ color }) => (color ? colors.text[color] : 'inherit')};
  text-transform: ${({ normalCase }) => (normalCase ? 'none' : 'uppercase')};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-weight: 600;
  line-height: ${({ lineHeight }) => lineHeight || '22px'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '1.13px'};
  margin: 0;
  margin-bottom: 0.2rem;
  padding: ${({ padding }) => padding || '0px'};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : '0px'};
`

export const NewsH3 = styled(H3)`
  padding: 0 0 0 20px;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 2px;

  @media (min-width: 768px) {
    padding: 0 0 0 30px;
  }
  @media (min-width: 1200px) {
    padding: 0 0 0 70px;
  }
`

export const H4 = styled.h4`
  ${fontStack};
  color: ${({ color }) => (color ? colors.text[color] : colors.text.default)};
  font-size: ${({ fontSize }) => fontSize || '.78rem'};
  text-transform: ${({ normalCase }) => (normalCase ? 'none' : 'uppercase')};
  line-height: 1.2rem;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
`

export const H6 = styled.h6`
  ${fontStack};
  color: ${({ color }) => (color ? colors.text[color] : colors.text.default)};
  text-transform: ${({ normalCase }) => (normalCase ? 'none' : 'uppercase')};
  font-size: 0.78rem;
  line-height: 1.2rem;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
`

export const H5 = styled.h5`
  ${fontStack};
  color: ${({ color }) => (color ? colors.text[color] : colors.text.default)};
  text-transform: ${({ normalCase }) => (normalCase ? 'none' : 'uppercase')};
  font-size: 0.78rem;
  line-height: 1.2rem;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
`

export const BodyCopy = styled.p`
  ${fontStack};
  color: ${({ color }) => (color ? colors.text[color] : 'inherit')};
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '18px')};
  line-height: ${({ lineHeight }) => lineHeight || '1.6'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '0px'};
  margin: 0;
  padding: 0;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0'};
  word-wrap: break-word;

  &:hover {
    ${({ hoverColor }) => hoverColor && `color:${hoverColor}`}
  }
  ${space}
  ${typography}
`
export const BodyCopyP = styled.p`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  margin-top : 0;
  margin-bottom:0;
  letter-spacing:0px;
  line-height:1.6;
  color: inherit;
  font-size:18px;
  ${space}
  ${typography}

  ${({ filter, transform, cursor, textTransform }) => ({
    filter,
    transform,
    cursor,
    textTransform,
  })}
  &:hover {
    ${({ hoverColor }) => hoverColor && `color:${hoverColor}`}
  }
`

export const ProBodyCopy = styled(BodyCopy)`
  letter-spacing: 0.57px;
  line-height: 22px;
  font-weight: normal;
  font-size: 16px;
  &:hover {
    text-decoration: ${({ color }) => (color ? 'underline' : 'none')};
  }
`

export const ProTitle = styled.h2`
  ${fontStack};
  color: ${({ color }) => (color ? colors.text[color] : 'inherit')};
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '18px')};
  line-height: ${({ lineHeight }) => lineHeight || '1.6'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '0px'};
  margin: 0;
  padding: 0;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0'};
  word-wrap: break-word;
  &:hover {
    ${({ hoverColor }) => hoverColor && `color:${hoverColor}`}
  }
  letter-spacing: 0.57px;
  line-height: 22px;
  font-weight: normal;
  font-size: 16px;
  &:hover {
    text-decoration: ${({ color }) => (color ? 'underline' : 'none')};
  }
`

export const Bold = styled.strong`
  line-height: 26px;
  letter-spacing: normal;
  display: block;
`

export const Italic = styled.i`
  line-height: 26px;
  letter-spacing: normal;
  display: block;
`

export const Underlined = styled.u`
  font-size: 22px;
  line-height: 26px;
  letter-spacing: normal;
  display: block;
`

export const Logo = styled.img`
  max-width: 160;
  height: auto;
`

export const Divider = styled.hr`
  height: 1px;
  margin: ${({ fitToWidth }) => (fitToWidth ? '0px' : '0px -80px')};
  border: none;
  flex-shrink: 0;
  background-color: ${({ color }) =>
    color ? colors[color] : 'rgba(0, 0, 0, 0.20)'};
`

// grid system
export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  ${({ wrap }) => wrap && { 'flex-wrap': 'wrap' }};
  flex-grow: ${({ grow }) => grow || 0};
  flex-shrink: ${({ shrink }) => shrink || 1};
  flex-basis: ${({ basis }) => basis || 'auto'};
  justify-content: ${props =>
    props.direction === 'row' ? props.hAlign : props.vAlign};
  align-items: ${props =>
    props.direction === 'row' ? props.vAlign : props.hAlign};
`

export const Container = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${({ outerMargin }) =>
    outerMargin ? `${outerMargin}px` : '0px'};
  padding-left: ${({ outerMargin }) =>
    outerMargin ? `${outerMargin}px` : '0px'};
  padding-top: ${({ topPadding }) => topPadding || '0px'};
  padding-bottom: ${({ bottomPadding }) => bottomPadding || '0px'};
  width: ${({ fluid }) =>
    !fluid && sizes.grid.maxWidth ? sizes.grid.maxWidth : '100%'};
  ${({ color }) => color && `background-color: ${colors[color]}`};

  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};

  @media (max-width: ${sizes.medium.max}) {
    max-width: ${({ fluid }) => (fluid ? '100%' : '540px')};
    width: 100%;
    ${({ mobilePadding }) => mobilePadding && 'padding-left: 15px'};
    ${({ mobilePadding }) => mobilePadding && 'padding-right: 15px'};
  }

  @media (min-width: ${sizes.medium.min}) {
    max-width: ${({ fluid }) => (fluid ? '100%' : '720px')};
  }

  @media (min-width: ${sizes.large.min}) {
    max-width: ${({ fluid }) => (fluid ? '100%' : '960px')};
  }

  @media (min-width: ${sizes.large.max}) {
    max-width: ${({ fluid }) => (fluid ? '100%' : '1200px')};
  }
`

export const StyledStackedBannerContainer = styled(Container)`
  width: 100%;
`

export const StyledProfessionalBlockContainer = styled(Container)`
  width: ${({ width }) => width || '100%'};
`

export const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ nowrap }) => nowrap && `flex-flow: nowrap;`}
  ${({ rowwrap }) => rowwrap && `flex-flow: row wrap;`}
  padding: ${({ padding }) => padding || '0px'};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0')};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : '0'};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : '0px')};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `${paddingBottom}px` : '0px'};

  @media screen and (min-width: ${sizes.small.min}) {
    ${({ smjc }) => 'justify-content:' + smjc};
  }
  @media screen and (min-width: ${sizes.medium.min}) {
    ${({ mdjc }) => 'justify-content:' + mdjc};
  }
  @media screen and (min-width: ${sizes.large.min}) {
    ${({ lgjc }) => 'justify-content:' + lgjc};
  }
  @media screen and (min-width: ${sizes.large.max}) {
    ${({ xljc }) => 'justify-content:' + xljc};
  }
  

  ${({ reverse }) =>
    reverse &&
    `
    flex-direction: row-reverse;
  `}
  ${({ start }) =>
    start &&
    `
    justify-content: flex-start;
  `}
  ${({ center }) =>
    center &&
    `
    justify-content: center;
  `}
  ${({ end }) =>
    end &&
    `
    justify-content: flex-end;
    width: 100%;
  `}
  ${({ top }) =>
    top &&
    `
    align-items: flex-start;
  `}
  ${({ middle }) =>
    middle &&
    `
    align-items: center;
  `}
  ${({ bottom }) =>
    bottom &&
    `
    align-items: flex-end;
  `}
  ${({ around }) =>
    around &&
    `
    justify-content: space-around;
  `}
  ${({ between }) =>
    between &&
    `
    justify-content: space-between;
  `}
  ${({ first }) =>
    first &&
    `
    order: 1;
  `}
  ${({ last }) =>
    last &&
    `
    order: -1;
  `}
`

const getWidthString = colSize => {
  if (!colSize) return
  const width = (colSize / sizes.grid.gridSize) * 100
  return `${width}%`
}

export const Col = styled.div`
  box-sizing: border-box;
  float: left;
  border: ${({ border }) => (border ? '1px solid #DDE0E3' : null)};
  border-width: ${({ border }) => (border ? '0px 1px 1px 0px' : null)};
  border-radius: ${({ border }) => (border ? '0px' : null)};
  padding: ${({ padding }) => padding || '0px'};
  width: ${({ xs }) => (xs ? getWidthString(xs) : '100%')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : '0px'};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0px')};

  @media screen and (min-width: ${sizes.small.min}) {
    width: ${({ sm }) => sm && getWidthString(sm)};
  }
  @media screen and (min-width: ${sizes.medium.min}) {
    width: ${({ md }) => md && getWidthString(md)};
  }
  @media screen and (min-width: ${sizes.large.min}) {
    width: ${({ lg }) => lg && getWidthString(lg)};
  }
  @media screen and (min-width: ${sizes.large.max}) {
    width: ${({ xl }) => xl && getWidthString(xl)};
  }
`

export const PaddedCard = styled.div`
  background-color: #001c54;
  color: #ffffff;
  display: block;
  font-size: 16px;
  line-height: 26px;
  margin: 0px 0px 40px;
  padding: 45px;
`
