import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledSlideTagDefault,
  StyledSlideTitleDefault,
  StyledSlideBodyDefault,
  StyledSlideButtonDefault,
} from '../style'
import { Column } from '@components/Common'
import A from '@components/A'
import { findMaxChar } from '@utils'
import { useMediaQuery } from '@hooks'
import { sizes } from '@theme'

const InsightsSlide = ({
  slide,
  tag,
  slidePadding,
  mobileSlidePadding,
  ...props
}) => {
  const mobile = useMediaQuery(`(max-width: 510px)`)
  const lg = useMediaQuery(`(max-width: ${sizes.large.max})`)

  const categorySlug = slide?.contentDestination?.[0]?.category?.slug

  const viewport = {
    mobile: mobile,
    lg: lg,
  }
  const shrinkSubtitle =
    slide &&
    slide.title &&
    slide.subtitle &&
    findMaxChar(slide.title, slide.subtitle, viewport)

  return (
    <Column
      className={`md:w-5/6 lg:w-7/12 xl:w-1/2 ${slidePadding} ${mobileSlidePadding}`}
    >
      <StyledSlideTagDefault>{tag}</StyledSlideTagDefault>
      <StyledSlideTitleDefault
        fontSize={slide?.fontSizeTitle}
        color={slide?.fontColorTitle}
      >
        <A
          to={slide?.contentDestination?.[0]?.slug}
          contentType={slide?.contentDestination?.[0]?.__typename}
          category={categorySlug}
        >
          {slide.title}
        </A>
      </StyledSlideTitleDefault>
      {shrinkSubtitle && (
        <StyledSlideBodyDefault color="white">
          {shrinkSubtitle}
        </StyledSlideBodyDefault>
      )}

      <StyledSlideButtonDefault
        fontSize={slide?.fontSizeLinkTitle}
        color={slide?.fontColorLinkTitle}
        to={slide?.contentDestination?.[0]?.slug}
        contentType={slide?.contentDestination?.[0]?.__typename}
        category={categorySlug}
      >
        {slide && slide.linkTitle ? slide.linkTitle : 'READ MORE'}
      </StyledSlideButtonDefault>
    </Column>
  )
}

InsightsSlide.propTypes = {
  slide: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    slug: PropTypes.string,
  }),
  tag: PropTypes.string,
  slidePadding: PropTypes.string,
  mobileSlidePadding: PropTypes.string,
}

InsightsSlide.defaultProps = {
  tag: 'Featured Insight',
}

export default InsightsSlide
