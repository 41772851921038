import React, { useEffect } from 'react'
import { Button, Div, P } from '@components/StyledTags'
import { LOCAL_STORAGE_KEYS, useLocalStorage, useTranslatedTerms } from '@hooks'
import { isSSR } from '@utils'
import { colors } from '@theme'

const INITIAL_COOKIE_STATE = {
  answeredPrompt: false,
  acceptedTerms: false,
}

const Cookies = () => {
  const { getTranslatedTerm } = useTranslatedTerms()
  const [cookieState, setCookieState] = useLocalStorage(
    LOCAL_STORAGE_KEYS.ACCEPTED_TERMS,
    INITIAL_COOKIE_STATE
  )

  const acceptTerms = () => {
    setCookieState({ answeredPrompt: true, acceptedTerms: true })
  }

  const rejectTerms = () => {
    setCookieState({ answeredPrompt: true, acceptedTerms: false })
  }

  useEffect(() => {
    if (!cookieState.acceptedTerms) {
      // null or false will disable gtag. window[gtag-id] = true TURNS OFF cookie!
      window['ga-disable-UA-7438605-14'] = true
      window['ga-disable-UA-44972839-3'] = true
    } else {
      window['ga-disable-UA-7438605-14'] = false
      window['ga-disable-UA-44972839-3'] = false
    }
  }, [cookieState.acceptedTerms])

  if (isSSR()) return null
  if (cookieState.answeredPrompt) return null

  return (
    <Div
      position="fixed"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      flexDirection={['column', null, null, 'row']}
      justifyContent="space-between"
      p="18px"
      bg={colors.grayLt}
      // needs to be above userway
      zIndex="9999999999"
    >
      <Div width={['100%', null, null, '78%']}>
        <P
          fontSize="14px"
          fontFamily="quaternary"
          mb="15px"
          color={colors.black}
        >
          {getTranslatedTerm('cookieLine1')}
        </P>
        <P
          fontSize="14px"
          fontFamily="quaternary"
          mb="15px"
          color={colors.black}
        >
          {getTranslatedTerm('cookieLine2')}
        </P>
      </Div>
      <Div
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="flex-end"
      >
        <Div display="flex" flexDirection="row" mt={[3, null, null, 0]}>
          <Button
            dim85Hover
            py="13px"
            px="25px"
            fontSize="14px"
            bg={colors.navy}
            color={colors.white}
            lineHeight="22px"
            onClick={acceptTerms}
          >
            {getTranslatedTerm('I ACCEPT')}
          </Button>
          <Button
            enableHoverUnderline
            py="13px"
            px="25px"
            fontSize="14px"
            fontWeight="normal"
            color={colors.navy}
            lineHeight="22px"
            onClick={rejectTerms}
          >
            {getTranslatedTerm('I Refuse')}
          </Button>
        </Div>
      </Div>
    </Div>
  )
}

export default Cookies
