import React from 'react'
import { useHover, useLocale } from '@hooks'
import { StyledNavItem, StyledNavItemLink, NavH2 } from '../style'
import SubNavDropdownDesktop from './SubNavDropdownDesktop'

const NavItemDesktop = ({
  subNavigationGroups,
  featuredSection,
  displayText,
  url,
  slug,
  shrink,
  __typename,
}) => {
  const [hoverRef, isHovered, setHover] = useHover()

  const { displayLocale } = useLocale()
  const filteredSubNavigationGroups = subNavigationGroups?.filter(
    ({ displayOnSites }) => displayOnSites?.includes(displayLocale)
  )
  const shouldDisplayFeaturedSection =
    featuredSection &&
    featuredSection?.displayOnSites &&
    featuredSection?.displayOnSites.includes(displayLocale)

  const hasDropdown =
    (filteredSubNavigationGroups && filteredSubNavigationGroups?.length > 0) ||
    shouldDisplayFeaturedSection

  return (
    <>
      <StyledNavItemLink to={slug} href={url} contentType={__typename}>
        <StyledNavItem
          subNav={hasDropdown}
          ref={hoverRef}
          isHovered={isHovered}
        >
          <NavH2 subNav={hasDropdown}>{displayText}</NavH2>
        </StyledNavItem>
      </StyledNavItemLink>
      <SubNavDropdownDesktop
        setHover={setHover}
        isHoveredParent={hasDropdown && isHovered}
        subNavigationGroups={filteredSubNavigationGroups}
        featuredSection={shouldDisplayFeaturedSection ? featuredSection : ''}
        shrink={shrink}
      />
    </>
  )
}

export default NavItemDesktop
