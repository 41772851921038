import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Div, Pre } from '@components/StyledTags'
import { featureFlags, isFeatureEnabled } from '@featureflags'

const Debug = ({ label, jsonDump, children }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (!isFeatureEnabled(featureFlags.DEBUG)) return null

  return (
    <Div mb="16px" wordBreak="break-word">
      <Pre
        p="16px"
        color="red"
        bgHover={children || jsonDump ? 'lightGray' : null}
        transition="all 0.2s ease-in-out"
        onClick={() => setIsExpanded(expand => !expand)}
      >
        Debug [Dev Only]: {label}
      </Pre>

      {isExpanded && (
        <>
          {children && (
            <Pre p="16px" color="red">
              {children}
            </Pre>
          )}

          {jsonDump && (
            <Pre p="16px" color="red">
              JSON Dump: <br />
              {JSON.stringify(jsonDump, null, 2)}
            </Pre>
          )}
        </>
      )}
    </Div>
  )
}

Debug.propTypes = {
  children: PropTypes.node,
}

export default Debug
