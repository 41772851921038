import React from 'react'
import { useMediaQuery } from '@hooks'
import TabsMobile from './TabsMobile'
import List from '../../List'
import Box from '@components/Box'

const Tabs = ({
  tab = 0,
  setTab,
  children,
  color,
  padding,
  maxWidth = false,
  pro,
  className,
  ...rest
}) => {
  const matches = useMediaQuery('(max-width: 764px)')

  if (matches) {
    return (
      <TabsMobile {...rest} className={className} tab={tab} setTab={setTab}>
        {children}
      </TabsMobile>
    )
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box
        maxWidth={['90%', '540px', '720px', '960px', '1200px']}
        width="100%"
        py="25px"
        bg={color}
        className={className}
      >
        <List grid padding>
          {children}
        </List>
      </Box>
    </Box>
  )
}

export default Tabs
