import styled from 'styled-components'
import { sizes } from '@theme'
import { renderMargin, renderJustifyContent } from '@utils'
import defaultThumbnail from '@assets/images/paul-hastings-audio-thumbnail.jpg'
import A from '@components/A'

export const StyledImage = styled.img`
  max-width: 100%;
  width: ${({ width }) => width || 'auto'};
  float: ${({ align, wrapText }) => (wrapText === 'Tight' ? align : 'none')};
  margin: ${({ margin }) => margin};
  padding: 10px 10px 10px 0px;
  flex-shrink: 0;
`

export const StyledImageDiv = styled.div`
  display: ${({ wrapText, align }) =>
    wrapText !== 'Tight' || (wrapText === 'Tight' && align === 'Center')
      ? 'flex'
      : 'inline-flex'};
  float: ${({ align, wrapText }) => (wrapText === 'Tight' ? align : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${({ width }) => width || '100%'};
  width: fit-content;
  z-index: 0;
  cursor: pointer;
  position: relative;
  margin: ${({ wrapText, align, margin }) =>
    renderMargin(wrapText, align, margin)};
`

export const ImageComponentContainer = styled.div`
  display: flex;
  justify-content: ${({ align }) => renderJustifyContent(align)};
  width: 100%;
  align-items: flex-start;

  @media (max-width: ${sizes.small.max}) {
    justify-content: center;
  }
  a {
    max-width: 100%;
  }
`

export const ImageAssetContainer = styled.div`
  display: inline-block;
  width: ${({ width }) => width || '100%'};
  margin: 20px;
`

export const AssetContainer = styled.div`
  max-width: '100%';
  height: 'auto';
  background-image: url(${defaultThumbnail});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 20px auto;
`

export const LinkWrapper = styled(A)`
  width: ${({ width }) => width || '100%'};
`

export const ATagWrapper = styled.a`
  width: ${({ width }) => width || '100%'};
`
