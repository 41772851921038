import { ProfileCard } from '@components/Card'
import React from 'react'

export const ProfessionalCard = ({ person, ...rest }) => {
  return (
    <ProfileCard
      person={person || { ...rest }}
      displayName
      displayTitle
      displayAvatar
      defaultFontSize={16}
      float="left"
      height="auto"
      contactUs
    />
  )
}
