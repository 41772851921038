import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  shadow,
  grid,
} from 'styled-system'

export const RawHtml = styled.div`
  max-width: 100%;
  img,
  embed,
  p,
  table,
  iframe {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }

  hr {
    margin-bottom: 1rem;
  }

  img {
    margin-bottom: 1rem;
  }

  embed {
    margin-bottom: 1rem;
  }

  iframe {
    margin-bottom: 1rem;
  }

  ol {
    list-style: decimal;
    color: ${props => (props.professional ? '#000' : '#5f6062')};
    margin-bottom: 1rem;
    line-height: 28.8px;
  }

  ul {
    list-style: initial;
    line-height: 28.8px;
    margin-bottom: 1rem;
  }

  ul ul li {
    list-style: circle;
  }

  ul ul {
    margin-bottom: 0;
  }

  ul ul li {
    list-style: circle;
  }

  ul ul {
    margin-bottom: 0;
  }

  td {
    border: 1px solid #999;
    padding: 3px;
  }

  th {
    border: 1px solid #999;
    padding: 3px;
  }

  table {
    border: 1px solid #999;
    border-spacing: 0px;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    color: ${props => (props.professional ? '#000' : '#5f6062')};
    line-height: 28.8px;
  }

  div {
    color: ${props => (props.professional ? '#000' : '#5f6062')};
  }

  li {
    margin-left: 1rem;
    color: ${props => (props.professional ? '#000' : '#5f6062')};
  }

  a {
    color: rgb(180, 160, 110);
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: rgb(180, 160, 110);
    text-decoration: underline;
  }

  .paddedAnchor {
    position: relative;
  }

  .paddedAnchor > a:first-child {
    position: absolute;
    top: -75px;
  }
`

export const RawHtmlSpan = styled.span`
  display: inline;
`

export const StyledPracticeAreaDetailHtml = styled.div`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}
  ${grid}
  width: 100%;
  img,
  embed,
  p,
  table,
  iframe {
    max-width: 100%;
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    ${({ overview }) =>
      overview && `color: #5A3462; font-family: "Times New Roman";`}
  }

  hr {
    margin-bottom: 1rem;
  }

  img {
    margin-bottom: 1rem;
  }

  embed {
    margin-bottom: 1rem;
  }

  iframe {
    margin-bottom: 1rem;
  }

  ol {
    list-style: decimal;
    color: 'black';
    margin-bottom: 1rem;
  }

  ul {
    list-style: initial;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  ul ul li {
    list-style: circle;
  }

  ul ul {
    margin-bottom: 0;
  }

  ul ul li {
    list-style: circle;
  }

  ul ul {
    margin-bottom: 0;
  }

  td {
    border: 1px solid #999;
    padding: 3px;
  }

  th {
    border: 1px solid #999;
    padding: 3px;
  }

  table {
    border: 1px solid #999;
    border-spacing: 0px;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    color: black;
  }

  div {
    color: black;
  }

  li {
    margin-left: 1rem;
    color: black;
  }

  a {
    color: rgb(180, 160, 110);
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: rgb(180, 160, 110);
    text-decoration: underline;
  }

  .paddedAnchor {
    position: relative;
  }

  .paddedAnchor > a:first-child {
    position: absolute;
    top: -75px;
  }
`

export const StyledPageWithSubpagesHtml = styled.div`
  ${space}
  ${position}
  ${color}
  ${typography}
  ${border}
  ${layout}
  ${flexbox}
  ${shadow}
  ${grid}
  width: 100%;
  img,
  embed,
  p,
  table,
  iframe {
    max-width: 100%;
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }

  hr {
    margin-bottom: 1rem;
  }

  img {
    margin-bottom: 1rem;
  }

  embed {
    margin-bottom: 1rem;
  }

  iframe {
    ${({ fillIframe }) => fillIframe && `height: 100%; width: 100%;`}
    margin-bottom: 1rem;
  }

  ol {
    list-style: decimal;
    color: 'black';
    margin-bottom: 1rem;
  }

  ul {
    list-style: initial;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  ul ul li {
    list-style: circle;
  }

  ul ul {
    margin-bottom: 0;
  }

  ul ul li {
    list-style: circle;
  }

  ul ul {
    margin-bottom: 0;
  }

  td {
    border: 1px solid #999;
    padding: 3px;
  }

  th {
    border: 1px solid #999;
    padding: 3px;
  }

  table {
    border: 1px solid #999;
    border-spacing: 0px;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    color: black;
  }

  div {
    color: black;
  }

  li {
    margin-left: 1rem;
    color: black;
  }

  a {
    color: rgb(180, 160, 110);
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: rgb(180, 160, 110);
    text-decoration: underline;
  }

  .paddedAnchor {
    position: relative;
  }

  .paddedAnchor > a:first-child {
    position: absolute;
    top: -75px;
  }
`
