import React from 'react'
import PropTypes from 'prop-types'
import { StyledPracticeAreaDetailHtml } from './style'

const PracticeAreaDetailHtml = ({ html, overview }) => {
  return (
    <StyledPracticeAreaDetailHtml
      overview={overview}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

PracticeAreaDetailHtml.propTypes = {}

export default PracticeAreaDetailHtml
