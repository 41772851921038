import React from 'react'
import PropTypes from 'prop-types'
import { StyledTabsMobileContainer, MobileTabHeader } from '../style'
import { Accordion } from '../../Accordion'
import List from '../../List'

const TabsMobile = ({
  children,
  label,
  border,
  background,
  maxWidth = false,
  tab,
  setTab,
  width,
  className,
}) => {
  return (
    <StyledTabsMobileContainer
      style={{ border: '.25px solid #B4A06E' }}
      border={border}
      background={background}
      maxWidth={maxWidth}
      width={width}
      className={className}
    >
      <Accordion
        summary={<MobileTabHeader>{children[tab].props.label}</MobileTabHeader>}
        id="tabs-toggle-one"
        border="tBorder"
        expandGlyph="down-caret"
        collapseGlyph="up-caret"
        iconOrder={2}
        defaultOpen
      >
        <List width="100%">{children}</List>
      </Accordion>
    </StyledTabsMobileContainer>
  )
}

TabsMobile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default TabsMobile
