/* eslint-disable camelcase */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// import HeaderLayout from '@components/Header/HeaderLayout'
import AppBar from '@components/AppBar'

const HeaderCDAContainer = ({ node_locale, ...props }) => {
  const data = useStaticQuery(graphql`
    query HeaderQueryV2 {
      allContentfulModuleNavigation(filter: { placement: { eq: "Header" } }) {
        nodes {
          ...navigationFields
        }
      }
    }
  `)

  const navLinks = data?.allContentfulModuleNavigation?.nodes?.find(
    nav => nav.node_locale === node_locale
  )?.navItems

  return <AppBar items={navLinks} {...props} />
}

export default HeaderCDAContainer
