import React from 'react'
import PropTypes from 'prop-types'
import { StyledTextArea } from '../style'

const TextArea = ({ isValid, ...props }) => (
  <>
    <StyledTextArea isValid={isValid} {...props} />
  </>
)

TextArea.propTypes = {
  isValid: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
}

export default TextArea
