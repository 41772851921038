import React from 'react'
import PropTypes from 'prop-types'
import { InfiniteList } from '../../List'
import { generateRandomKey } from '@utils'

const InfiniteTableBody = ({
  rows,
  columns,
  rowHeights,
  RowComponent,
  ...props
}) => {
  return (
    <InfiniteList rowHeights={rowHeights} {...props}>
      {rows &&
        rows.map(item => (
          <RowComponent
            key={generateRandomKey()}
            columns={columns}
            item={item}
            rowHeights={rowHeights}
          />
        ))}
    </InfiniteList>
  )
}

InfiniteTableBody.propTypes = {
  values: PropTypes.shape(Object),
  order: PropTypes.string,
  orderBy: PropTypes.string,
}

export default InfiniteTableBody
