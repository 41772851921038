import { featureFlags, isFeatureEnabled } from '@featureflags'
import { nodeLocaleToEnglishLanguage } from './mappings'

export const getSubpageSlug = (subpageSlug, subpageId, pagesWithSubpages) => {
  if (!subpageSlug || !pagesWithSubpages) return null

  // only gets the first parent currently
  const parent = pagesWithSubpages?.find(({ subpages }) =>
    subpages?.find(
      ({ id, slug }) => (subpageId ? subpageId === id : subpageSlug === slug) // match the id if present otherwise match the slug
    )
  )

  const parentSlug = parent?.slug

  return `${parentSlug}/${subpageSlug}`
}

export const getLocalizedParentPath = (
  pageSlugs,
  typename,
  slug,
  optionalCategory,
  optionalSubpageId,
  pagesWithSubpages
) => {
  if (slug?.includes('preview?contentType=')) return slug
  typename = typename?.toLowerCase()

  if (typename?.substring(0, 10) === 'contentful') {
    typename = typename?.slice(10)
  }

  if (typename === 'subpage') {
    return getSubpageSlug(slug, optionalSubpageId, pagesWithSubpages)
  }

  if (typename === 'articlegroup') {
    typename = 'articlecategory'
  }

  if (
    typename === 'filterpage' ||
    typename === 'page' ||
    typename === 'componenttab'
  ) {
    return slug
  }

  let finalSlug = slug
  let parentPath = ''

  if (
    isFeatureEnabled(featureFlags.NEW_PRACTICE_AREA_DETAIL_PAGE) &&
    typename === 'new-practice-areas'
  ) {
    parentPath = 'new-practice-areas'
  }

  try {
    const pageSlug = pageSlugs.find(
      s => s?.typename?.toLowerCase().slice(10) === typename
    )
    if (pageSlug) {
      parentPath = pageSlug.parentPath
    }
  } catch (error) {
    console.error('parentPath error: ', error)
  }

  if (optionalCategory) {
    // a page is an insight with a category
    finalSlug = `${parentPath}/${optionalCategory}/${slug}`
  } else {
    if (typename === 'professional') {
      finalSlug = `${parentPath}/${slug?.toLowerCase()}`
    } else {
      finalSlug = parentPath ? `${parentPath}/${slug}` : slug
    }
  }

  return finalSlug
}

export const addLeadingSlash = slug =>
  slug && slug?.[0] !== '/' ? `/${slug}` : slug

export const renderLocalizedSlug = (
  slug,
  nodeLocale,
  parentPath,
  parentCategory
) => {
  const defaultLocale = 'en-US'
  const homePageSlug = 'home'
  slug = slug === homePageSlug ? '' : slug

  if (parentPath) {
    const parentPathAndCategory = parentCategory
      ? `${parentPath}/${parentCategory}`
      : `${parentPath}`

    slug = parentPathAndCategory ? `${parentPathAndCategory}/${slug}` : slug
  }

  const localeIsDefaultLocale = nodeLocale === defaultLocale
  slug = slug.toLowerCase()
  const localizedSlug = localeIsDefaultLocale
    ? `/${slug}`
    : `/${nodeLocale}/${slug}`

  return localizedSlug
}

export const renderHrefLangTagsEntriesStrictLocalization = (
  displayOnSitesAsNodeLocales,
  localizedSlugsComponents,
  localizedSlugs
) =>
  displayOnSitesAsNodeLocales
    .map(locale => ({
      slug: localizedSlugs.filter(x => x.locale === locale)[0].slug,
      ...localizedSlugsComponents.filter(x => x.node_locale === locale)[0],
    }))
    .map(x => ({
      lang: x.node_locale,
      href: renderLocalizedSlug(
        x.slug,
        x.node_locale,
        x.parentPath,
        x.category
      ),
    }))

export const getExternalOrInternalLink = (navLink, fullLocale) => {
  if (!navLink) return {}
  if (!navLink?.__typename && navLink?.slug) {
    return navLink
  }

  if (
    (navLink?.__typename === 'ContentfulExternalLink' ||
      navLink?.__typename === 'externalLink') &&
    navLink?.url
  )
    return { href: navLink?.url }

  const displaynavLink =
    (navLink && !navLink?.displayOnSites) ||
    (navLink?.displayOnSites?.includes(
      nodeLocaleToEnglishLanguage[fullLocale]
    ) &&
      navLink?.slug)

  return displaynavLink
    ? { slug: navLink?.slug, slugContentType: navLink?.__typename }
    : {}
}

export const getSlugOrHref = url => {
  let to = null
  let href = null
  const isSlug =
    url &&
    !url.includes('https:') &&
    !url.includes('http:') &&
    !url.includes('mailto:') &&
    !url.includes('tel:')
  if (isSlug) {
    to = url[0] === '/' ? url.slice(1) : url
  } else {
    href = url
  }
  return [to, href]
}
