import { useState } from 'react'

const useLoadMore = (items = [], numItemsPerPage = 6) => {
  const [numItemsToDisplay, setNumItemsToDisplay] = useState(numItemsPerPage)

  const hasMoreItems = numItemsToDisplay < items.length

  const loadMore = () => {
    if (hasMoreItems) setNumItemsToDisplay(numItemsToDisplay + numItemsPerPage)
  }
  return { loadMore, hasMoreItems, numItemsToDisplay }
}
export default useLoadMore
export { useLoadMore }
