/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-indent */
import React from 'react'
import Breadcrumbs from '../../Breadcrumbs'
import {
  StyledStackedBanner,
  StyledStackedContainer,
  StyledSecondContainer,
  StyledFullWidthDiv,
  StyledContentContainer,
  StackedBackgroundImage,
} from '../style'
import {
  StyledProFullWidthDiv,
  StyledProContentContainer,
  StyledProStackedContainer,
} from '../../ProfessionalDetail/style'
import StackedBlogBanner from './StackedBanner.blogs'
import StackedInsightsBanner from './StackedBanner.insights'

const StackedBanner = ({
  children,
  to,
  breadcrumbs,
  flexDirection,
  crumbLabel,
  crumbClassName,
  height,
  marginLeft,
  marginRight,
  heroImage,
  fit,
  section,
  gradient,
  ...otherProps
}) => {
  let child = null
  let previewChildren = children && children.props && children.props.children
  children = previewChildren ? previewChildren : children

  if (children && children.length === 4) {
    child = children[3]
    children = children.slice(0, 3)
  }
  return (
    <>
      <StyledStackedBanner gradient={gradient}>
        <StackedBackgroundImage
          fluid={heroImage && heroImage.fluid}
          height={height}
          alt="banner-image"
        />
        <StyledStackedContainer {...otherProps}>
          {breadcrumbs && (
            <div style={{ color: 'white', width: '150px' }}>
              <Breadcrumbs
                crumbLabel={crumbLabel}
                to={to}
                crumbClassName={crumbClassName}
              />
            </div>
          )}
        </StyledStackedContainer>
      </StyledStackedBanner>
      <StyledSecondContainer section={section}>
        <StyledFullWidthDiv marginRight={marginRight} marginLeft={marginLeft}>
          {child ? (
            <StackedBlogBanner child={child} {...otherProps}>
              {children}
            </StackedBlogBanner>
          ) : children.length === 3 || previewChildren ? (
            <StackedInsightsBanner {...otherProps}>
              {children}
            </StackedInsightsBanner>
          ) : (
            <StyledContentContainer flexDirection {...otherProps}>
              {children}
            </StyledContentContainer>
          )}
        </StyledFullWidthDiv>
      </StyledSecondContainer>
    </>
  )
}

export const WideStackedBanner = ({
  children,
  breadcrumbs,
  flexDirection,
  crumbLabel,
  marginLeft,
  marginRight,
  to,
  gradient,
  crumbClassName,
  previousQuery,
  previousRefinements,
  ...otherProps
}) => (
  <>
    <StyledStackedBanner gradient={gradient}>
      <StyledProStackedContainer {...otherProps}>
        {breadcrumbs && (
          <div style={{ color: 'white', float: 'left', width: '150px' }}>
            <Breadcrumbs
              crumbLabel={crumbLabel}
              to={to}
              crumbClassName={crumbClassName}
              previousQuery={previousQuery}
              previousRefinements={previousRefinements}
            />
          </div>
        )}
      </StyledProStackedContainer>
    </StyledStackedBanner>
    <StyledSecondContainer>
      <StyledProFullWidthDiv marginRight={marginRight} marginLeft={marginLeft}>
        <StyledProContentContainer flexDirection {...otherProps}>
          {children}
        </StyledProContentContainer>
      </StyledProFullWidthDiv>
    </StyledSecondContainer>
  </>
)

StackedBanner.propTypes = {}

export default StackedBanner
