import A from '@components/A'
import { Div } from '@components/StyledTags'
import React from 'react'

const PreviewSamplesLink = () => {
  return (
    <Div
      height="30px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      px="10px"
      bg="purple"
      color="white"
      fontSize="14px"
      fontWeight="700"
      borderRadius="5px"
    >
      <A to="preview" color="white">
        Preview Samples
      </A>
    </Div>
  )
}

export default PreviewSamplesLink
