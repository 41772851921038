import React from 'react'
import Spinner from '@assets/svg/Spinner2.svg'
import { Div } from '@components/StyledTags'

const Loader = ({ message, width = '200px', height = '100%', ...rest }) => {
  return (
    <Div
      my={5}
      mx="auto"
      width="100%"
      display="flex"
      justifyContent="center"
      {...rest}
    >
      <Spinner width={width} height={height} />
      <Div
        position="absolute"
        left="50%"
        top="0%"
        transform="translate(-50%, -50%)"
        color="gray"
        zIndex={1}
      >
        {message}
      </Div>
    </Div>
  )
}

export default Loader
