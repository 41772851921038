import React from 'react'
import { StyledContentContainer } from '../style'

const StackedBlogBanner = ({ children, child, ...otherProps }) => {
  return (
    <>
      <div>
        <StyledContentContainer flexDirection {...otherProps}>
          {children}
        </StyledContentContainer>
      </div>
      {child}
    </>
  )
}

export default StackedBlogBanner
