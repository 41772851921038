import React from 'react'
import PropTypes from 'prop-types'
import { StyledLabel, StyledSelect, StyledErrorMessage } from './style'
import SimpleSelect from './components/SimpleSelect'
import AsyncSimpleSelect from './components/AsyncSimpleSelect'

const Select = ({
  options,
  form,
  field,
  label,
  id,
  placeholder,
  disabled,
  autoFocus,
}) => {
  const isValid = () => !form.touched[field.name] || !form.errors[field.name]

  return (
    <div>
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      <StyledSelect
        classNamePrefix="react-select"
        className="react-select-container"
        noShadow
        isSearchable={false}
        placeholder={placeholder}
        onChange={option => form.setFieldValue(field.name, option)}
        onBlur={() => form.setFieldTouched(field.name, true)}
        value={field.value}
        options={options}
        isValid={isValid()}
        isDisabled={disabled}
        autoFocus={autoFocus}
      />
      {form.touched[field.name] && form.errors[field.name] && (
        <StyledErrorMessage>{form.errors[field.name]}</StyledErrorMessage>
      )}
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
}

export default Select
export { SimpleSelect, AsyncSimpleSelect, Select }
