import React from 'react'
import PropTypes from 'prop-types'
import Glyph from '../../Icon'
import { StyledIcon } from '../style'

const Icon = ({ glyph, fill, size, viewBox, ...props }) => (
  <StyledIcon type="submit">
    <Glyph glyph={glyph} fill={fill} size={size} viewBox={viewBox} />
  </StyledIcon>
)

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  dataCy: PropTypes.string,
  fullWidth: PropTypes.bool,
  center: PropTypes.bool,
  disabled: PropTypes.bool,
  glyph: PropTypes.string.isRequired,
  size: PropTypes.string,
  viewBox: PropTypes.string,
}

export default Icon
