import React, { useState, useEffect } from 'react'
import { useWindowScrollPosition } from '@hooks'
import StyledAppBar from './components/AppBar'
import { StyledNavHeader } from './style'

const AppBar = props => {
  const [shrink, setShrink] = useState(false)
  const { y } = useWindowScrollPosition()

  useEffect(() => {
    if (y > 64 && !shrink) {
      setShrink(true)
    }
    if (y < 64 && shrink) {
      setShrink(false)
    }
  }, [y])

  return (
    <StyledNavHeader id="navigation">
      <StyledAppBar shrink={shrink} {...props} />
    </StyledNavHeader>
  )
}

export default AppBar
