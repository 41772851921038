module.exports = {
  siteTitle: 'Paul Hastings',
  siteTitleAlt: 'Paul Hastings LLP',
  publisher: 'Paul Hastings',
  siteDescription:
    'We encourage people to think creatively about how we can make a difference in our communities and in the world, and how we can leverage the talents of our people to effect positive change.',
  siteUrl: 'https://www.paulhastings.com', // do not include trailing forwardslash,
  postsPerHomePage: 7,
  postsPerPage: 6,
  authorDefaultEmail: 'contact@paulhastings.com',
  author: 'Paul Hastings', // author for RSS / SEO schema
  authorURL: '', // url for author/publisher schema
  twitterHandle: '@paulhastings', // for twitter cards
  shortTitle: 'Paul Hastings', // for app manifest
  image:
    'https://images.ctfassets.net/t0ydv1wnf2mi/3tBWFAfsj5J0DaAYCIZ9og/4b1b97b221423c90403a15a3a810286f/share-logo.jpg', // OG default share image, 1200 x 1200 recommended
  imageWidth: 1200, // change to actual width of share image
  imageHeight: 630, // ditto actual height
  siteLogo: 'logo.png', // logo for SEO, RSS, and app manifest
  siteLogoAlt: 'Paul Hastings',
  backgroundColor: '#e9e9e9', // offline manifest
  themeColor: '#121212', // offline manigest
  copyright: 'Copyright © 2021 Paul Hastings', // copyright for RSS feed
  hashtags: 'paulhastings', // twitter hashtags.
}
