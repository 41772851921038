import React from 'react'
import { LinkWrapper, ATagWrapper } from '../style'
import { capitalizeFirstLetterOfString } from '@utils'
import ImageWithContainer from './ImageWithContainer'
import ImageTight from './ImageTight'

const EmbeddedImage = ({
  externalResource,
  slug: { internalSlug, typename, internalCategory },
  wrapText,
  ...rest
}) => {
  const capitalizeTypename = typename && capitalizeFirstLetterOfString(typename)
  const LinkComponent =
    (externalResource && ATagWrapper) || (internalSlug && LinkWrapper)

  return wrapText === 'Top and Bottom' ? (
    <ImageWithContainer
      typename={capitalizeTypename}
      LinkComponent={LinkComponent}
      internalSlug={internalSlug}
      internalCategory={internalCategory}
      externalResource={externalResource}
      wrapText={wrapText}
      {...rest}
    />
  ) : (
    <ImageTight
      typename={capitalizeTypename}
      LinkComponent={LinkComponent}
      internalSlug={internalSlug}
      internalCategory={internalCategory}
      externalResource={externalResource}
      wrapText={wrapText}
      {...rest}
    />
  )
}

export default EmbeddedImage
