import React from 'react'
import Icon from '../Icon'
import {
  StepsContainer,
  Container,
  Step,
  CircleStep,
  IconLinkWrapper,
  CircleContainer,
} from './style'
import { OutlineButton } from '../Button'

const InfiniteLoad = ({
  activeStep,
  numberofSteps,
  color,
  activeColor,
  backgroundColor,
}) => (
  <OutlineButton center color={color}>
    Load More
  </OutlineButton>
)

const Stepper = ({
  activeStep,
  numberOfSteps = 5,
  infinite,
  circle,
  color = 'gold',
  activeColor = 'gray',
  backgroundColor = 'white',
  setIndex,
  stepperColor,
}) => {
  if (infinite) {
    return <InfiniteLoad color={color} />
  }

  if (numberOfSteps === 1) {
    return (
      <Container>
        <StepsContainer>
          {circle && <CircleStep active color={color} />}
          {!circle && (
            <Step active color={color}>
              1
            </Step>
          )}
        </StepsContainer>
      </Container>
    )
  }
  const steps = new Array(numberOfSteps).fill(0)

  if (circle) {
    return (
      <CircleContainer role="tablist">
        {steps.map((step, i) => (
          <li key={`tab-${i}`}>
            <CircleStep
              onClick={() => setIndex(i)}
              active={activeStep === i + 1}
              data-target="#pageCarousel"
              data-slide-to={activeStep === i + 1}
              backgroundColor={backgroundColor}
              color={color}
              stepperColor={stepperColor}
            >
              {i + 1}
            </CircleStep>
          </li>
        ))}
      </CircleContainer>
    )
  }

  return (
    <Container>
      <StepsContainer>
        {activeStep > 1 && (
          <IconLinkWrapper>
            <Icon
              glyph="left-caret"
              height={20}
              width={20}
              onClick={() => setIndex(activeStep - 2)}
            />
          </IconLinkWrapper>
        )}
        {steps.map((step, i) => (
          <Step
            active={activeStep === i + 1}
            key={i}
            onClick={() => setIndex(i)}
            color={color}
            activeColor={activeColor}
            backgroundColor={backgroundColor}
          >
            {i + 1}
          </Step>
        ))}
        {activeStep < numberOfSteps && (
          <IconLinkWrapper>
            <Icon
              glyph="right-caret"
              height={20}
              width={20}
              onClick={() => setIndex(activeStep)}
            />
          </IconLinkWrapper>
        )}
      </StepsContainer>
    </Container>
  )
}

export default Stepper
