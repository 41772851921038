import React from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

const Column = ({ children, className, border }) => {
  return (
    <div
      className={twMerge(
        'flex box-border float-left w-full font-default flex-col',
        className,
        border && 'border-solid border-r-1 border-b-1 rounded-none'
      )}
    >
      {children}
    </div>
  )
}

export default Column

Column.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  border: PropTypes.bool,
}
