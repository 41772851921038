import React from 'react'
import { Container } from '@components/Common'
import ImageV2 from '@components/ImageV2'
import A from '@components/A'
import * as styles from './FullWidthHeroMedia.module.css'

const FullWidthHeroMedia = ({
  title,
  contentDestination,
  ctaTitle,
  backgroundImage,
}) => {
  const slug = contentDestination?.slug
  const href = contentDestination?.url
  const contentType = contentDestination?.__typename
  const category = contentDestination?.category?.slug
  return (
    <div className={`relative w-full ${styles.height}`}>
      {backgroundImage && (
        <>
          <ImageV2
            src={backgroundImage}
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
          <div className={styles.heroGradient} />
        </>
      )}
      <Container className="relative flex items-center justify-center w-full h-full px-6 md:px-0 md:max-w-[calc(100%-160px)]">
        <div className="text-center text-white">
          {contentDestination && (
            <A
              href={href}
              to={slug}
              contentType={contentType}
              category={category}
              className="text-white"
            >
              <h1 className="text-[32px] leading-[36px] md:text-[40px] md:leading-[44px] font-bold my-6">
                {title || contentDestination?.title}
              </h1>
              {ctaTitle && (
                <span className="text-[18px] font-bold hover:underline">
                  {ctaTitle}
                </span>
              )}
            </A>
          )}
        </div>
      </Container>
    </div>
  )
}

export default FullWidthHeroMedia
