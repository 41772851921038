import React from 'react'
import A from '@components/A'
import { parseUrlToSlugAndHref } from '@utils'
import { colors } from '@theme'

export const HyperLink = ({ node, children }) => {
  const uri = node?.data?.uri
  const [slug, href] = parseUrlToSlugAndHref(uri)

  return (
    <A to={slug} href={href} color={colors.gold} fontWeight="normal" underline>
      {children}
    </A>
  )
}
