import React from 'react'
import ALink from '@components/A'
import {
  StyledButton,
  StyledIconButton,
  StyledLink,
  ResetLinkStyle,
  StyledPrimaryButton,
  StyledOutlineButton,
  StyledNakedLink,
  StyledFullWidthButton,
  ActuallyPlain,
  StyledInlineLink,
  StyledCarouselButton,
} from '../Button/style'

// footer main button link
const handleLinkWrapping = (Component, props) => {
  const {
    href,
    to,
    target,
    children,
    disabled,
    isLoading,
    noFlex,
    id,
    download,
    contentType,
    category,
    slugId,
    className,
    ...rest
  } = props
  const button = (
    <Component disabled={disabled || isLoading} className={className} {...rest}>
      {children}
    </Component>
  )
  if (href) {
    return (
      <ResetLinkStyle
        href={href}
        download={download}
        target={target || '_blank'}
        rel={!target ? 'noopener noreferrer' : undefined}
        id={id}
        className={className}
      >
        {button}
      </ResetLinkStyle>
    )
  }

  if (to) {
    const inlineLinkStyle = {
      display: 'flex',
      flex: 'none',
      alignItems: 'left',
    }
    return noFlex ? (
      <ALink
        id={id}
        to={to}
        category={category}
        contentType={contentType}
        slugId={slugId}
        className={className}
      >
        {button}
      </ALink>
    ) : (
      <ALink
        to={to}
        category={category}
        style={inlineLinkStyle}
        contentType={contentType}
        slugId={slugId}
        className={className}
      >
        {button}
      </ALink>
    )
  }
  return button
}

export const Button = props => handleLinkWrapping(StyledButton, props)

export const FullWidthButton = props =>
  handleLinkWrapping(StyledFullWidthButton, props)

export const IconButton = props => handleLinkWrapping(StyledIconButton, props)

export const CarouselIndicatorButton = props =>
  handleLinkWrapping(StyledCarouselButton, props)

export const PrimaryButton = props =>
  handleLinkWrapping(StyledPrimaryButton, props)

export const OutlineButton = props =>
  handleLinkWrapping(StyledOutlineButton, props)

export const SocialButton = props => handleLinkWrapping(StyledIconButton, props)

export const A = props => handleLinkWrapping(StyledLink, props)

export const NakedA = props => handleLinkWrapping(StyledNakedLink, props)

// has underline
export const NoFormattingA = props => handleLinkWrapping(ActuallyPlain, props)

export const InlineA = props => handleLinkWrapping(StyledInlineLink, props)
