import React from 'react'
import {
  StyledCopyright,
  FooterRow,
  SitemapTerms,
  CopyrightText,
  RightBorderLink,
  NoBorderLink,
} from '../style'
import { Container } from '../../Globals'

const Copyright = ({
  data: { globalPrivacy, termsAndConditions },
  title = 'Paul Hastings, LLP',
}) => {
  return (
    <StyledCopyright>
      <Container>
        <FooterRow>
          <CopyrightText>
            {`Copyright © ${new Date().getFullYear()} ${title}. All rights reserved.`}
          </CopyrightText>
          <SitemapTerms>
            <RightBorderLink to="sitemap">Sitemap</RightBorderLink>
            {termsAndConditions && (
              <RightBorderLink to={termsAndConditions.slug}>
                {termsAndConditions.title}
              </RightBorderLink>
            )}
            {globalPrivacy && (
              <NoBorderLink to={globalPrivacy.slug}>
                {globalPrivacy.title}
              </NoBorderLink>
            )}
          </SitemapTerms>
        </FooterRow>
      </Container>
    </StyledCopyright>
  )
}

Copyright.propTypes = {}

export default Copyright
