import React from 'react'
import EmbeddedImage from './EmbeddedImage'

const EmbeddedImageContainer = ({
  align,
  displayTitle,
  media,
  width,
  wrapText,
  title,
  locale,
  internalLink,
  url,
  us,
  file,
}) => {
  const internalRef = internalLink
  const internalSlug = internalRef?.[0]?.slug
  const internalCategory = internalRef?.[0]?.category?.slug
  const type = internalRef?.[0]?.__typename

  const slug = { internalSlug, typename: type, internalCategory }

  const mediaObj = {
    align: align,
    displayTitle: displayTitle,
    imageSrc: media?.[0]?.file?.url || file?.url,
    title: title,
    width: width,
    wrapText: wrapText,
    externalResource: url,
    slug,
  }
  const margin =
    mediaObj.wrapText === 'Tight' && mediaObj.align === 'Right'
      ? '0px 0px 10px 10px'
      : mediaObj.align === 'Left'
      ? '0px 10px 10px 0px'
      : '0px'
  mediaObj.margin = margin

  return <EmbeddedImage {...mediaObj} />
}

export default EmbeddedImageContainer
