import React from 'react'
import A from '@components/A'
import { GalleryItemTitle } from './style'

const CtaLink = ({ title, linksTo, externalLink, pageSlugs }) => {
  return (
    <>
      {externalLink ? (
        <a href={externalLink}>
          <GalleryItemTitle>{title}</GalleryItemTitle>
        </a>
      ) : (
        <A to={linksTo?.[0]?.slug} contentType={linksTo?.[0]?.__typename}>
          <GalleryItemTitle>{title}</GalleryItemTitle>
        </A>
      )}
    </>
  )
}

export default CtaLink
