import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  position,
  space,
  layout,
  color,
  border,
  typography,
  flexbox,
  grid,
  system,
} from 'styled-system'
import { colors } from '@theme'

const commonCustomProps = system({
  gap: true,
  filter: true,
  transform: true,
  cursor: true,
  textTransform: true,
  transition: true,
  pointerEvents: true,
  visibility: true,
  wordBreak: true,
  whiteSpace: true,
  textDecoration: true,
  textUnderlinePosition: true,
})

export const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
`

export const StyledBaseA = styled.a`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${grid}
  ${commonCustomProps}

  &:hover {
    ${({ underline }) =>
      underline &&
      `
    text-decoration: underline;
  `}
  }
`

export const StyledLink = styled(Link)`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  ${space}
  ${color}
  ${typography}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${grid}
  ${commonCustomProps}
  
  &:hover {
    ${({ underline }) =>
      underline &&
      `
      text-decoration: underline;
    `}
    ${({ hoverColor }) =>
      hoverColor &&
      `
      color: ${colors.gold};
    `}
  }
`
