import React from 'react'
import { Row } from '../../Globals'
import { useMediaQuery } from '@hooks'
import { colors, sizes } from '@theme'
import Cell from './Cell'

const CardRow = ({
  columns,
  middle = false,
  item,
  rowHeights,
  noMobileBorder,
  ...props
}) => {
  const xs = useMediaQuery(`(max-width: ${sizes.xsmall.max})`)
  const sm = useMediaQuery(`(max-width: ${sizes.small.max})`)
  const md = useMediaQuery(`(max-width: ${sizes.medium.max})`)
  const lg = useMediaQuery(`(max-width: ${sizes.large.max})`)

  const rowHeight =
    (xs && rowHeights.xs) ||
    (sm && rowHeights.sm) ||
    (md && rowHeights.md) ||
    (lg && rowHeights.lg) ||
    rowHeights.xl

  const padding = xs || sm ? 20 : 0
  const borderBottom =
    !noMobileBorder && (xs || sm) ? `1px solid ${colors.grayMd}` : 'none'

  return (
    <Row
      paddingTop={padding}
      paddingBottom={padding}
      style={{ borderBottom, height: `${rowHeight}px` }}
      middle={middle}
    >
      {columns?.map(column => (
        <Cell
          cellContent={item}
          key={column.id}
          id={column.id}
          width={column.width}
          numColumns={columns.length}
          {...props}
        />
      ))}
    </Row>
  )
}

export default CardRow
