import theme from '@theme'
import useMediaQuery from './useMediaQuery'

const useBreakpoints = () => {
  const sm = useMediaQuery(`(min-width: ${theme.breakpoints[0]})`)
  const md = useMediaQuery(`(min-width: ${theme.breakpoints[1]})`)
  const lg = useMediaQuery(`(min-width: ${theme.breakpoints[2]})`)
  const xl = useMediaQuery(`(min-width: ${theme.breakpoints[3]})`)
  const xxl = useMediaQuery(`(min-width: ${theme.breakpoints[4]})`)

  if (xxl) return 'xxl'
  if (xl) return 'xl'
  if (lg) return 'lg'
  if (md) return 'md'
  if (sm) return 'sm'
  return 'xs'
}

export default useBreakpoints
