import React, { useEffect } from 'react'
import { RawHtml, RawHtmlSpan } from './style'

const Html = props => {
  useEffect(() => {
    if (document) {
      const links = document.querySelectorAll('a')
      const footnotes =
        links?.length > 0 &&
        Array.from(links)?.filter(
          link => link?.name?.length > 0 && link?.href?.includes('#_')
        )
      if (footnotes) {
        footnotes.forEach(footnote => {
          footnote.id = footnote.name
        })
      }
    }
  }, [])

  return props.inline ? (
    <RawHtmlSpan
      professional={props.professional}
      dangerouslySetInnerHTML={{
        __html: props?.contentHtml?.html,
      }}
    />
  ) : (
    <RawHtml
      professional={props.professional}
      dangerouslySetInnerHTML={{
        __html: props?.contentHtml?.html,
      }}
    />
  )
}

export default Html
