import React from 'react'
// import PropTypes from 'prop-types'
import {
  StyledH2,
  StyledGroupsContainer,
  StyledGroupContainer,
  StyledH3Featured,
} from './style'
import SubNavigationItemMobile from './SubNavigationItemMobile'
import A from '@components/A'

const SubNavigationGroupsMobile = ({
  featuredSection,
  subNavigationGroups,
}) => {
  return (
    <StyledGroupsContainer className="qtph-globalmenu-navigation-dropdown">
      {subNavigationGroups?.map(({ title, subNavigationItems, id }) => (
        <StyledGroupContainer key={id}>
          <StyledH2>{title}</StyledH2>
          {subNavigationItems?.map(({ id, navigationLink, ...rest }) => (
            <SubNavigationItemMobile key={id} {...navigationLink} {...rest} />
          ))}
        </StyledGroupContainer>
      ))}
      {featuredSection &&
        (featuredSection?.ctaLink?.slug || featuredSection?.ctaLink?.url) && (
          <A
            contentType={featuredSection?.ctaLink?.__typename}
            to={featuredSection?.ctaLink?.slug}
            href={featuredSection?.ctaLink?.url}
          >
            <StyledH3Featured>{featuredSection?.title}</StyledH3Featured>
          </A>
        )}
    </StyledGroupsContainer>
  )
}

SubNavigationGroupsMobile.propTypes = {}

export default SubNavigationGroupsMobile
