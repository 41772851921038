import React from 'react'
import PropTypes from 'prop-types'
import { useLocale } from '@hooks'
import { StyledLink } from './styles'

const InternalLink = ({
  to,
  contentType,
  category,
  subpageId,
  children,
  skipLocalization = false,
  ...rest
}) => {
  const { getLocalizedSlug } = useLocale()

  const nonLocalizedSlug = to?.startsWith('/') ? to : `/${to || ''}`

  const localizedSlug = getLocalizedSlug({
    slug: to,
    contentType,
    category,
    subpageId,
  })

  return (
    <StyledLink
      to={skipLocalization ? nonLocalizedSlug : localizedSlug}
      {...rest}
    >
      {children}
    </StyledLink>
  )
}

InternalLink.propTypes = {
  to: PropTypes.string,
}

export default InternalLink
