import React from 'react'

import { StyledDisclaimer } from '../style'
import Box from '@components/Box'

export const Disclaimer = ({ content }) => {
  return (
    <Box width="100%" margin="0 auto">
      <StyledDisclaimer>{content}</StyledDisclaimer>
    </Box>
  )
}
