import { useState, useCallback, useEffect } from 'react'
import { useTransition } from 'react-spring'
import useInterval from './useInterval'

const useCarousel = (
  slidesLength,
  numberOfSteps,
  autoPlay = false,
  initialIndex = 0,
  interval = 5000,
  slidesPerView = 1
) => {
  const [currIndex, setCurrIndex] = useState(initialIndex)
  const [direction, setDirection] = useState(1)
  const [fromTransform, setFromTransform] = useState(`translate3d(0%,0,0)`)

  useEffect(() => {
    setFromTransform(null)
  }, [])

  const setIndex = newIndex => {
    if (currIndex < newIndex) setDirection(1)
    if (currIndex > newIndex) setDirection(-1)
    setCurrIndex(newIndex)
  }

  const next = useCallback(() => {
    setDirection(1)
    setCurrIndex(state => {
      const nextIndex = state + slidesPerView
      return nextIndex >= slidesLength ? 0 : nextIndex
    })
  }, [slidesLength, slidesPerView])

  const prev = () => {
    setDirection(-1)
    setCurrIndex(state => {
      const prevIndex = state - slidesPerView
      return (prevIndex + slidesLength) % slidesLength
    })
  }

  useInterval(() => {
    if (autoPlay) {
      if (currIndex + slidesPerView >= slidesLength) {
        setIndex(0)
      } else {
        setIndex(currIndex + slidesPerView)
      }
    }
  }, interval)

  const transitions = useTransition(currIndex, p => p, {
    from: {
      opacity: 0,
      transform:
        fromTransform ||
        `translate3d(${(direction * 100) / numberOfSteps}%,0,0)`,
    },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: `translate3d(${direction * -50}%,0,0)` },
  })

  return [currIndex, setIndex, next, prev, transitions]
}

export default useCarousel
export { useCarousel }
