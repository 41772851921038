import styled, { css } from 'styled-components'

export const StyledH4 = styled.h4`
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  &:hover {
    color: #b4a06e;
  }
`

export const StyledH3 = styled.h3`
  font-size: 15px;
  font-weight: normal;
  &:hover {
    color: #b4a06e;
  }
`

export const StyledH2 = styled.h2`
  font-size: 16px;
  text-transform: initial;
`

export const StyledGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding-left: 8px;
  padding-right: 8px;
`

export const StyledGroupContainer = styled.div`
  padding-bottom: 10px;
`

export const StyledNavigationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledSecondSubNavContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`

export const StyledH3Featured = styled.h3`
  font-size: 16px;
  font-weight: normal;
  &:hover {
    color: #b4a06e;
  }
`
