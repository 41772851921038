import React from 'react'
import { Column } from '@components/Common'

const SidebarPanel = ({ sidebarLinkId, theme, children, ...props }) => {
  const bg = theme === 'transparent' ? 'bg-transparent' : 'bg-white'
  const visible = sidebarLinkId === 0 ? 'opacity-100' : 'opacity-0'

  return (
    <Column className={`w-2/3 ${bg} ${visible}`} visible={sidebarLinkId === 0}>
      {children}
    </Column>
  )
}

export default SidebarPanel
