import React from 'react'
import { navigate } from '@reach/router'
import { SidebarTitleText, SidebarTitle, SidebarTitleIcon } from '../style'

const SidebarLink = ({
  id,
  title,
  type = 'internal',
  slug,
  active,
  setActiveSidebarLinkId,
  theme,
}) => {
  const routeToExternalOrInternalLink = url => {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      var win = window.open(url, '_blank')
      win.focus()
    } else {
      navigate(`/${url}`)
    }
  }

  const handleClick = () =>
    type?.toLowerCase() === 'link'
      ? slug && routeToExternalOrInternalLink(slug)
      : setActiveSidebarLinkId(id)

  return (
    <SidebarTitle
      active={active}
      onClick={handleClick}
      className="qtph-insights-sidebar-link"
      activeColor={theme === 'transparent' ? 'black' : 'white'}
      theme={theme}
    >
      <SidebarTitleText theme={theme} role="tab">
        {title}
      </SidebarTitleText>
      <SidebarTitleIcon
        glyph="right-caret"
        active={active}
        height={20}
        width={20}
        theme={theme}
      />
    </SidebarTitle>
  )
}

export default SidebarLink
