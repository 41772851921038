export const getVimeoSrc = obj => {
  let string = obj.html.split(' ')
  const html = ''
  string = string[1].split('')
  string = string.slice(5, string.length - 1).join('')
  return html + string
}

export const getYoutubeId = video => video.split('=')[1]
export const getYoutubeEmbed = video => video.replace('watch?v=', 'embed/')
