import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@hooks'
import { sizes } from '@theme'
import { Row } from '@components/Common'
import Cell from '../../Row/components/Cell'

const TableBody = ({ rows, columns, noDataMessage, ...props }) => {
  const xs = useMediaQuery(`(max-width: ${sizes.xsmall.max})`)
  const sm = useMediaQuery(`(max-width: ${sizes.small.max})`)

  const borderBottom = xs || sm ? `border border-grayMd` : 'md:border-none'
  const padding = xs || sm ? 'py-5 px-0' : null

  return rows.map(row => (
    <Row
      key={row.id}
      className={`${padding} ${borderBottom}`}
      data-cy="table-row"
      data-testid="table-row"
    >
      {columns.map(column => (
        <Cell
          {...props}
          cellContent={row}
          key={column.id}
          id={column.id}
          numColumns={columns.length}
        />
      ))}
    </Row>
  ))
}
TableBody.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cellContentType: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brandId: PropTypes.number,
      brandName: PropTypes.string,
      postCount: PropTypes.number,
      engagementCount: PropTypes.number,
      engagementAvg: PropTypes.number,
      emvTotal: PropTypes.number,
      emvAvg: PropTypes.number,
      viewAvg: PropTypes.number,
      viewCount: PropTypes.number,
    })
  ),
  rowsPerPage: PropTypes.number,
}

export default TableBody
