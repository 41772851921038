import styled from 'styled-components'
import { Container, Row, Col } from '../Globals'

export const StyledContainer = styled(Container)`
  padding: 65px 0 55px;
`

export const StyledRow = styled(Row)`
  > ${Col}:nth-child(odd) {
    padding: 40px 0px 0px 0px;
  }

  > ${Col}:nth-child(even) {
    padding: 40px 0px 0px 0px;
  }

  > ${Col}:first-child, > ${Col}:nth-child(2) {
    padding: 0px;
  }
`
