import {
  EmbeddedImageContainer,
  EmbeddedMediaContainer,
} from '@components/EmbeddedAsset.js'
import React from 'react'

export const EmbeddedAsset = ({ type, file, ...rest }) => {
  const isContentfulAssetImage = file?.url?.includes('image')
  const isMediaEmbedImage = type === 'Image'
  const isImage = isContentfulAssetImage || isMediaEmbedImage

  return isImage ? (
    <EmbeddedImageContainer type={type} file={file} {...rest} />
  ) : (
    <EmbeddedMediaContainer type={type} {...rest} />
  )
}

export default EmbeddedAsset
