import React from 'react'
import { Crumb, CrumbLink } from './style'
import Icon from '../Icon'
import { navigate } from 'gatsby'
import { useLocale } from '@hooks'
import { constructBreadcrumbUrl } from '@utils'
import PropTypes from 'prop-types'

const Breadcrumbs = ({
  to,
  crumbLabel,
  crumbClassName,
  previousQuery,
  previousRefinements,
  ...rest
}) => {
  const { locale } = useLocale()
  var re = /(\b[a-z](?!\s))/g
  let defaultCrumbLabel = ''
  if (to) {
    defaultCrumbLabel = to && to.replace(/-/g, ' ')
    defaultCrumbLabel = defaultCrumbLabel.replace(re, function(x) {
      return x.toUpperCase()
    })
  }

  const handleOnClick = () => {
    const url = constructBreadcrumbUrl(
      to,
      previousQuery,
      previousRefinements,
      locale
    )
    navigate(url)
  }

  return (
    <Crumb {...rest}>
      <Icon glyph="left-caret" height={15} width={20} viewBox="0 0 15 24" />
      <CrumbLink
        onClick={handleOnClick}
        {...rest}
        className={`${crumbClassName} cursor-pointer hover:text-ph-gold`}
      >
        {crumbLabel || defaultCrumbLabel}
      </CrumbLink>
    </Crumb>
  )
}

Breadcrumbs.propTypes = {
  to: PropTypes.string,
  crumbLabel: PropTypes.string,
  crumbClassName: PropTypes.string,
  previousQuery: PropTypes.string,
}

export default Breadcrumbs
