import React from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Input from '../../Input/index'
import Select from '../../Select/index'
import FormStatus from '../../Input/components/FormStatus'
import {
  StyledContactForm,
  StyledContactFormGrid,
  StyledDisclaimer,
} from '../style'

import axios from 'axios'

const options = [
  { value: 'alumni', label: 'Alumni' },
  { value: 'general', label: 'General' },
  { value: 'media', label: 'Media' },
  { value: 'pro-bono', label: 'Pro Bono' },
  { value: 'recruiting', label: 'Recruiting' },
]

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('This Field is Required'),
  email: Yup.string().required('This Field is Required'),
  phone: Yup.string().matches(
    /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/,
    'Please Enter a valid Phone Number'
  ),
  company: Yup.string(),
  title: Yup.string(),
  area: Yup.object().nullable(),
  message: Yup.string(),
  terms: Yup.boolean()
    .test(
      'terms',
      'Please agree with our Terms and Conditions.',
      value => value === true
    )
    .required('Required'),
})

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      success: false,
    }
  }
  render() {
    return (
      <Formik
        initialValues={{
          justForBots: '',
          name: '',
          email: '',
          phone: '',
          company: '',
          title: '',
          area: null,
          message: '',
          terms: false,
          submit: 'Submit',
        }}
        validationSchema={ValidationSchema}
        validateOnBlur
        onSubmit={(values, actions) => {
          let config = {
            headers: {
              'x-api-key': 'VXSMw3JNlh8KUXGyTuYRB6aXnjNzoMSd5doSi5fP',
            },
          }

          let data = {
            name: values.name,
            phone: values.phone,
            email: values.email,
            company: values.company,
            area: values.area ? values.area.label : '',
            message: values.message,
            justForBots: values.justForBots,
          }

          axios
            .post(
              'https://gafzei06c4.execute-api.us-west-2.amazonaws.com/default/contactus-mailfwd',
              data,
              config
            )
            .then(response => {
              this.setState({ error: false, success: true })
              actions.resetForm()
            })
            .catch(error => {
              this.setState({ error, success: false })
            })
          /*
          setTimeout(() => {
            actions.setSubmitting(false)
          }, 3000)
          */
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <StyledContactForm>
            <StyledContactFormGrid>
              <Field
                inputType="text"
                name="name"
                id="name"
                placeholder="Full Name*"
                component={Input}
              />
              <Field
                inputType="email"
                name="email"
                id="email"
                placeholder="Email Address*"
                component={Input}
              />
              <Field
                inputType="tel"
                name="phone"
                id="phone"
                placeholder="Phone"
                component={Input}
              />
              <Field
                inputType="text"
                name="company"
                id="company"
                placeholder="Company"
                component={Input}
              />
              <Field
                inputType="text"
                name="title"
                id="title"
                placeholder="Job Title"
                component={Input}
              />
              <Field
                name="area"
                id="area"
                placeholder="Area of Inquiry"
                component={Select}
                options={options}
              />
              <Field
                inputType="textarea"
                rows="5"
                name="message"
                id="message"
                placeholder="Message"
                component={Input}
              />
            </StyledContactFormGrid>
            <StyledDisclaimer>
              <strong>PLEASE NOTE:</strong> Communications sent to us via email
              through our website are not secure and will not be regarded by us
              as confidential. Do not send information that you believe is
              confidential without first speaking with one of our attorneys. You
              understand that we may represent clients adverse to you in
              connection with the subject of your communication. Unsolicited
              communication with Paul Hastings or our attorneys through this
              site does not create an attorney-client relationship between Paul
              Hastings and you or any other person.
            </StyledDisclaimer>
            <Field
              inputType="checkbox"
              name="terms"
              id="terms"
              label="I have read and agree to the terms of this disclaimer."
              component={Input}
            />
            <Field
              inputType="honeyPot"
              name="justForBots"
              id="justForBots"
              component={Input}
            />
            <Field
              inputType="submit"
              name="submit"
              id="submit"
              fullWidth
              component={Input}
            />
            {this.state.success && (
              <FormStatus success={this.state.success}>
                Thank you! Your message has been received.
              </FormStatus>
            )}
            {this.state.error && (
              <FormStatus error={this.state.error}>
                Sorry, an error occurred. Please try again later.
              </FormStatus>
            )}
          </StyledContactForm>
        )}
      </Formik>
    )
  }
}

export default Contact
