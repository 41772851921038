import defaultBadge from '@assets/images/default-accolades.png'
import moment from 'moment-timezone'
import defaultBG from '../assets/images/default_practice_area_banner.jpg'
import { eventDuration } from './time'
import { handleColumn } from './dataFormatting'
import { handleRichtextOrHtml, timezone2tzid } from '@utils'

const contactUsIdMapping = [
  { title: 'careers', id: 'rlxdtr255ZOHlKlJJ9Ts3' },
  { title: 'press', id: '57vJPmQ5Vn2Fd3APpQSzED' },
  { title: 'professionals', id: '1WMCfztCvBPa3ywF0FjyRi' },
  { title: 'contact', id: '6yjRT47C0DKdMhCVVzuP3b' },
]

export const removeStyleHTML = html => {
  if (!html) return html
  return html.replace(/<style([\s\S]*?)>([\s\S]*?)<\/style>/gm, '')
}

export const handleEntry = (entry, isRecognition = false) => {
  return entry?.map(entryItem => {
    const {
      contentHtml,
      content,
      slug,
      featuredImage,
      category,
      __typename,
      featuredImageCaptionAndAltText,
      publicationDate,
    } = entryItem
    const plainTextDescription = handleRichtextOrHtml(contentHtml, content)
    const contentType = __typename
    const featuredImageContentType = featuredImage?.__typename
    const isFeaturedImageProfessional =
      featuredImageContentType === 'ContentfulProfessional' ||
      featuredImageContentType === 'Professional' ||
      featuredImageContentType === 'professional'

    const featuredImageSrc =
      featuredImage?.image?.file?.url || featuredImage?.image?.fluid?.src

    const validFeaturedImage = isFeaturedImageProfessional
      ? featuredImage?.active && featuredImageSrc
      : featuredImageSrc

    const altText =
      featuredImageCaptionAndAltText || featuredImage?.image?.description

    const defaultAltText = isRecognition ? 'Lauren Badge' : ''

    let image = null
    if (isRecognition && validFeaturedImage) image = featuredImageSrc

    if (isRecognition && !validFeaturedImage) image = defaultBadge

    const finalAltText = image ? altText : defaultAltText

    // TODO: need to find a way to make preview slugs work for localization
    // currently not necessary for new practice areas (only for en-US)
    return {
      ...entryItem,
      slugContentType: contentType,
      slug,
      slugCategory: category?.slug,
      plainTextDescription,
      altText: finalAltText,
      image,
      categoryTitle: category?.title,
      publicationDate: moment(publicationDate).format('MMMM DD, YYYY'),
    }
  })
}

export const handleEntries = (
  override,
  entries,
  displayLocale,
  isRecognition = false
) => {
  // filter out displayOnSites from manually inputted entries
  const filteredOverride =
    override?.filter(({ id, displayOnSites }) =>
      displayOnSites?.includes(displayLocale)
    ) || []

  // filter out automatically pulled entries that has been manually inputted through experienceOverride
  const filteredEntries =
    entries?.filter(
      ({ id }) =>
        !filteredOverride.some(({ id: overrideId }) => overrideId === id)
    ) || []

  return handleEntry(
    filteredOverride.concat(filteredEntries), // concat the two, experienceOverride taking precedant
    isRecognition
  )
}

const eventsSortFunc = (a, b) => {
  const aTime = moment(a?.currentTimezoneDate ? a?.currentTimezoneDate : 0)
  const bTime = moment(b?.currentTimezoneDate ? b?.currentTimezoneDate : 0)
  const aTitle = a?.title || 'z'
  const bTitle = b?.title || 'z'

  if (aTime === bTime) return aTitle.localeCompare(bTitle)

  return aTime.isBefore(bTime) ? -1 : 1
}

export const handleEvents = (override, events, displayLocale) => {
  const autoUpcomingEventsWithLocalTZ = events
    ?.map(events => ({
      ...events,
      currentTimezoneDate:
        events?.eventStart &&
        events?.timeZone &&
        moment
          .tz(
            events?.eventStart ? events?.eventStart : 0,
            timezone2tzid[events?.timeZone]
          )
          ?.local(), // get date to our timezone
    }))
    ?.filter(({ currentTimezoneDate }) => currentTimezoneDate?.isAfter()) // filter out everything not after today
    ?.sort(eventsSortFunc) // sort it, page query sort does not account for timezone so can still be out of order

  const upcomingEventsOverrideWithLocalTZ = override?.map(events => ({
    ...events,
    currentTimezoneDate:
      events?.eventStart &&
      events?.timeZone &&
      moment.tz(events?.eventStart, timezone2tzid[events?.timeZone])?.local(),
  }))

  return handleEntries(
    upcomingEventsOverrideWithLocalTZ,
    autoUpcomingEventsWithLocalTZ,
    displayLocale
  )?.map(({ eventStart, eventEnd, location, ...rest }) => ({
    ...rest,
    eventStart,
    eventEnd,
    location,
    eventDurationString: `${eventDuration(eventStart, eventEnd)}${
      location ? `, ${location}` : ''
    }`,
  }))
}

const newsAndInsightsSortFunc = (a, b) => {
  const aPublicationDate = moment(a?.publicationDate ? a?.publicationDate : 0) // moment 0 is unix epoch time
  const bPublicationDate = moment(b?.publicationDate ? b?.publicationDate : 0)
  const aTitle = a?.title || 'z'
  const bTitle = b?.title || 'z'

  if (aPublicationDate.isSame(bPublicationDate) && b?.priority === a?.priority)
    // if same date and priority sort by title
    return aTitle.localeCompare(bTitle)
  else if (aPublicationDate.isSame(bPublicationDate)) {
    // if same date sort by priority
    return b?.priority - a?.priority
  }

  return bPublicationDate.isAfter(aPublicationDate) ? 1 : -1
}

export const handleNewsAndInsights = (
  override,
  clientAlerts,
  attorneyAuthored,
  practiceAreaArticles,
  news,
  blog,
  displayLocale
) => {
  clientAlerts = clientAlerts?.map(article => ({
    ...article,
    priority: 5,
  }))
  attorneyAuthored = attorneyAuthored?.map(article => ({
    ...article,
    priority: 4,
  }))
  practiceAreaArticles = practiceAreaArticles?.map(article => ({
    ...article,
    priority: 3,
  }))
  news = news?.map(article => ({
    ...article,
    priority: 2,
  }))
  blog = blog?.map(article => ({
    ...article,
    priority: 1,
  }))

  const newsAndInsights = []
    .concat(clientAlerts, attorneyAuthored, practiceAreaArticles, news, blog)
    ?.filter(entry => entry)
    ?.sort(newsAndInsightsSortFunc)

  return handleEntries(override, newsAndInsights, displayLocale)
}

export const handleExperiences = (
  experienceOverride,
  autoNews,
  displayLocale
) => {
  autoNews = autoNews?.sort(newsAndInsightsSortFunc)
  return handleEntries(experienceOverride, autoNews, displayLocale)
}

export const handleRecognitions = (
  recognitionsOverride,
  autoRecognitions,
  displayLocale
) => {
  autoRecognitions = autoRecognitions?.sort(newsAndInsightsSortFunc) // gatsby put null sort targets to the front array, we have to sort them to end
  return handleEntries(
    recognitionsOverride,
    autoRecognitions,
    displayLocale,
    true
  )
}

export const handleTeam = chairs => {
  return chairs
    ?.filter(({ professional }) => professional?.[0]?.active)
    ?.map(({ chairTitle, professional }) => {
      professional = professional?.[0]
      return {
        ...professional,
        slugContentType: professional?.__typename,
        image:
          professional?.image?.file?.url || professional?.image?.fluid?.src,
        chairTitle: chairTitle,
      }
    })
}

export const handleRelatedSolutions = (practiceAreas, displayLocale) => {
  if (!practiceAreas) return practiceAreas
  practiceAreas = practiceAreas?.filter(practiceArea => practiceArea)
  return practiceAreas
    ?.filter(({ displayOnSites }) => displayOnSites?.includes(displayLocale))
    ?.map(({ slug, __typename, ...rest }) => ({
      ...rest,
      __typename: __typename,
      slug,
      slugContentType: __typename,
    }))
}

export const handleQuotes = quotes => {
  if (!quotes?.length) return quotes

  return quotes?.map(quote => {
    const splitByAttribution = quote?.split('--')
    const quoteOnly = splitByAttribution?.[0]
    let attributionOnly = splitByAttribution?.[1]
    attributionOnly =
      attributionOnly?.[0] === ' '
        ? attributionOnly.substring(1)
        : attributionOnly

    return {
      quote: quoteOnly,
      attribution: attributionOnly,
    }
  })
}

export const handleBackgroundImage = bg => {
  return bg?.fluid?.src || bg?.file?.url || defaultBG
}

// titles are easier to change than id, check for id and manually assign title so that contentful users can change the title without affecting our mapping
export const handleContactUsSlugs = slugs => {
  return slugs?.map(({ id: currentId, slug, __typename, ...rest }) => ({
    ...rest,
    slug: slug,
    slugContentType: __typename,
    title: contactUsIdMapping?.find(({ id }) => currentId === id)?.title,
  }))
}

export const handleHtml = contactHtml => {
  if (contactHtml?.useHTMLField === 'false' || !contactHtml?.html) return null
  const html = contactHtml?.html

  const firstParagraphRegex = /<p>(.*?)<\/p>/
  const firstParagraphHtml = html?.match(firstParagraphRegex)?.[0]

  let firstParagraphSanitized = null

  if (typeof document !== 'undefined') {
    let tempHtml = document.createElement('div')
    tempHtml.innerHTML = firstParagraphHtml
    firstParagraphSanitized = tempHtml?.innerText

    // remove non breaking spaces
    firstParagraphSanitized = firstParagraphSanitized?.replace(/\u00a0/g, ' ')
  }

  return { html: contactHtml?.html, firstParagraph: firstParagraphSanitized }
}

export const handleTestimonialSection = (columns, displayLocale) => {
  if (!columns?.length) return null

  return columns?.map(column => columns && handleColumn(column, displayLocale))
}
