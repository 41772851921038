/* eslint-disable camelcase */
import A from '@components/A'
import { formatPreviewSlug } from '@utils'
import React from 'react'
import PropTypes from 'prop-types'

const PreviewLink = ({ contentful_id, contentType }) => {
  const formattedSlug = formatPreviewSlug(contentful_id, contentType)

  return <A to={formattedSlug}>View Preview</A>
}

PreviewLink.propTypes = {
  contentful_id: PropTypes.string.isRequired,
  contentType: PropTypes.string,
}

export default PreviewLink
