import React from 'react'
import Date from '../../Date'
import { StyledTag, Title, StyledDate, StyledCard } from '../style'
import bell from './icon_bell.png'

const GridCard = ({
  title,
  item,
  titleColor,
  theme = 'light',
  to = '',
  slugContentType,
  slugCategory,
  showDate,
  ...rest
}) => {
  const icon = item?.tags?.map(t => t?.icon?.fluid?.src)?.[0] || bell
  if (!item) return null
  let dateStart = item.eventStart || item.publicationDate || ''
  if (item.sys) {
    dateStart = item.sys.firstPublishedAt
  }
  const dateEnd = item.eventEnd || ''
  const tag =
    item?.label || item?.tags?.[0]?.title || item?.categorySlug || item?.type
  return (
    <StyledCard to={to} category={slugCategory} contentType={slugContentType}>
      {tag && <StyledTag titleColor="purple">{tag}</StyledTag>}
      <Title normalCase titleColor={theme === 'dark' ? 'white' : titleColor}>
        {title}
      </Title>
      <StyledDate theme={theme} fontSize={14}>
        {showDate ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <img src={icon} />
            <Date {...rest} dateStart={dateStart} dateEnd={dateEnd} />
          </div>
        ) : (
          <StyledTag titleColor="purple">Read More</StyledTag>
        )}
      </StyledDate>
    </StyledCard>
  )
}

export default GridCard
