import { useEffect } from 'react'

const useDelayedElementVisibility = ({
  ref,
  shouldShowElement,
  showDelay = 0,
  hideDelay = 0,
}) => {
  useEffect(() => {
    if (!ref?.current) return null

    let timer

    const showElement = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        ref.current.style.visibility = 'visible'
      }, showDelay)
    }

    const hideElement = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        ref.current.style.visibility = 'hidden'
      }, hideDelay)
    }

    shouldShowElement ? showElement() : hideElement()

    return () => clearTimeout(timer)
  }, [hideDelay, ref, shouldShowElement, showDelay])
}

export default useDelayedElementVisibility
