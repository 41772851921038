import React from 'react'
import PropTypes from 'prop-types'
import FeaturedSection from './FeaturedSection'
import SubNavigationGroupsSection from './SubNavigationGroupsSection'
import { Container } from '@components/Common'

const SubNavigationDesktop = ({ subNavigationGroups, featuredSection }) => {
  return (
    <Container className="flex flex-row h-full">
      <FeaturedSection featuredSection={featuredSection} />
      {subNavigationGroups && (
        <SubNavigationGroupsSection subNavigationGroups={subNavigationGroups} />
      )}
    </Container>
  )
}

SubNavigationDesktop.propTypes = {
  subNavigationGroups: PropTypes.object,
  featuredSection: PropTypes.object,
}

export default SubNavigationDesktop
