export const translatedJson = {
  About: {
    de: 'Über',
    'en-GB': 'About',
    'en-US': 'About',
    fr: 'À propos',
    ja: '約',
    ko: '약',
    zh: '关于',
  },
  Accept: {
    de: 'Ich nehme an',
    'en-GB': 'I Accept',
    'en-US': 'I Accept',
    fr: "J'accepte",
    ja: '承諾します',
    ko: '동의 함',
    zh: '我接受',
  },
  'Add To Calendar': {
    de: 'Zum Kalender hinzufügen',
    'en-GB': 'Add To Calendar',
    'en-US': 'Add To Calendar',
    fr: 'Ajouter au calendrier',
    ja: 'カレンダーに追加',
    ko: '캘린더에 추가',
    zh: '添加到日历',
  },
  'There are no Events to display at this time.': {
    de: 'Zurzeit sind keine Ereignisse zum Anzeigen vorhanden.',
    'en-GB': 'There are no Events to display at this time.',
    'en-US': 'There are no Events to display at this time.',
    fr: 'Il n’y a aucun événement à afficher pour le moment.',
    ja: '現時点では表示するイベントはありません。',
    ko: '현재 표시할 이벤트가 없습니다.',
    zh: '目前沒有可顯示的事件。',
  },
  Admissions: {
    de: 'Admissions',
    'en-GB': 'Admissions',
    'en-US': 'Admissions',
    fr: 'Admissions',
    ja: 'Admissions',
    ko: 'Admissions',
    zh: '法律招生',
  },
  All: {
    de: 'Alle',
    'en-GB': 'All',
    'en-US': 'All',
    fr: 'Voir tout',
    ja: '全て',
    ko: '모든 아이템',
    zh: '所有项目',
  },
  'All Accolades': {
    de: 'Alle Auszeichnungen',
    'en-GB': 'All Accolades',
    'en-US': 'All Accolades',
    fr: 'Toutes les distinctions',
    ja: 'すべての称賛',
    ko: '모든 영예',
    zh: '所有荣誉',
  },
  'All Events': {
    de: 'Alle Veranstaltungen',
    'en-GB': 'All Events',
    'en-US': 'All Events',
    fr: 'Tous les évènements',
    ja: 'すべてのイベント',
    ko: '모든 이벤트',
    zh: '所有活动',
  },
  'All Insights': {
    de: 'Alle Einblicke',
    'en-GB': 'All Insights',
    'en-US': 'All Insights',
    fr: 'Toutes les informations',
    ja: 'すべての洞察',
    ko: '모든 인사이트',
    zh: '所有见解',
  },
  'All News': {
    de: 'Alle Nachrichten',
    'en-GB': 'All News',
    'en-US': 'All News',
    fr: 'Toutes les nouvelles',
    ja: 'すべてのニュース',
    ko: '모든 뉴스',
    zh: '所有新闻',
  },
  'All Professionals': {
    de: 'Alle Profis',
    'en-GB': 'All Professionals',
    'en-US': 'All Professionals',
    fr: 'Tous les professionnels',
    ja: 'すべての専門家',
    ko: '모든 전문가',
    zh: '所有专业人士',
  },
  Professionals: {
    'en-US': 'Professionals',
    'en-GB': 'Professionals',
    ko: '전문가',
    ja: 'プロフェッショナル',
    fr: 'Avocats',
    zh: '专业人员',
    de: 'Anwälte',
  },
  Alumni: {
    de: 'Ehemalige',
    'en-GB': 'Alumni',
    'en-US': 'Alumni',
    fr: 'Anciens',
    ja: 'アルムナイ',
    ko: '동문',
    zh: '普衡旧友',
  },
  Americas: {
    de: 'Amerika',
    'en-GB': 'Americas',
    'en-US': 'Americas',
    fr: 'Amériques',
    ja: 'アメリカ大陸',
    ko: '미주',
    zh: '美洲',
  },
  Apply: {
    de: 'Sich bewerben',
    'en-GB': 'Apply',
    'en-US': 'Apply',
    fr: 'Appliquer',
    ja: '申し込み',
    ko: '적용하다',
    zh: '申请',
  },
  'Area of Inquiry': {
    de: 'Untersuchungsbereich',
    'en-GB': 'Area of Inquiry',
    'en-US': 'Area of Inquiry',
    fr: "Domaine d'enquête",
    ja: 'お問い合わせの分野',
    ko: '문의분야',
    zh: '咨询领域',
  },
  Asia: {
    de: 'Asien',
    'en-GB': 'Asia',
    'en-US': 'Asia',
    fr: 'Asie',
    ja: 'アジア',
    ko: '아시아',
    zh: '亚洲',
  },
  'Blog Contributors': {
    de: 'Blog-Beitragende',
    'en-GB': 'Blog Contributors',
    'en-US': 'Blog Contributors',
    fr: 'Contributeurs de blog',
    ja: 'ブログ投稿者',
    ko: '블로그 기여자',
    zh: '博客贡献者',
  },
  'Blog Editor': {
    de: 'Blog-Editor',
    'en-GB': 'Blog Editor',
    'en-US': 'Blog Editor',
    fr: 'Éditeur de blog',
    ja: 'ブログエディタ',
    ko: '블로그 편집자',
    zh: '博客编辑',
  },
  Blogs: {
    de: 'Blogs',
    'en-GB': 'Blogs',
    'en-US': 'Blogs',
    fr: 'Blogues',
    ja: 'Blogs',
    ko: 'Blogs',
    zh: '博客',
  },
  'Business Professionals': {
    de: 'Geschäftsleute',
    'en-GB': 'Business Professionals',
    'en-US': 'Business Professionals',
    fr: "Professionnels de l'entreprise",
    ja: 'ビジネスプロフェッショナル',
    ko: '비즈니스 전문가',
    zh: '商务人士',
  },
  By: {
    de: 'Nach',
    'en-GB': 'By',
    'en-US': 'By',
    fr: 'Par',
    ja: '別',
    ko: '별',
    zh: '按',
  },
  'By Industry': {
    de: 'Nach Branche',
    'en-GB': 'By Industry',
    'en-US': 'By Industry',
    fr: 'Par secteur',
    ja: '業界別',
    ko: '산업별',
    zh: '按行业',
  },
  'By Region': {
    de: 'Nach Region',
    'en-GB': 'By Region',
    'en-US': 'By Region',
    fr: 'Par région',
    ja: '地域別',
    ko: '지역별',
    zh: '按地区',
  },
  'By Solution': {
    de: 'Nach Lösung',
    'en-GB': 'By Solution',
    'en-US': 'By Solution',
    fr: 'Par solution',
    ja: 'ソリューション別',
    ko: '솔루션별',
    zh: '按解决方案',
  },
  Category: {
    de: 'Kategorie',
    'en-GB': 'Category',
    'en-US': 'Category',
    fr: 'Catégorie',
    ja: 'カテゴリー',
    ko: '범주',
    zh: '类别',
  },
  'Clear Search': {
    de: 'Suche löschen',
    'en-GB': 'Clear Search',
    'en-US': 'Clear Search',
    fr: 'Effacer la recherche',
    ja: '検索をクリア',
    ko: '검색 지우기',
    zh: '清除搜索',
  },
  'Click here for a PDF of the full text': {
    de: 'Click here for a PDF of the full text',
    'en-GB': 'Click here for a PDF of the full text',
    'en-US': 'Click here for a PDF of the full text',
    fr: 'Click here for a PDF of the full text',
    ja: 'Click here for a PDF of the full text',
    ko: 'Click here for a PDF of the full text',
    zh: 'Click here for a PDF of the full text',
  },
  Company: {
    de: 'Unternehmen',
    'en-GB': 'Company',
    'en-US': 'Company',
    fr: 'Entreprise',
    ja: '会社',
    ko: '회사',
    zh: '公司',
  },
  Contact: {
    de: 'Kontakt',
    'en-GB': 'Contact',
    'en-US': 'Contact',
    fr: 'Contact',
    ja: 'コンタクト',
    ko: '연락하다',
    zh: '联系我们',
  },
  'Contact Card': {
    de: 'Kontaktkarte',
    'en-GB': 'Contact Card',
    'en-US': 'Contact Card',
    fr: 'Carte de contact',
    ja: '連絡先カード',
    ko: '연락처 카드',
    zh: '联络卡',
  },
  'Contact Us': {
    de: 'Kontaktiere uns',
    'en-GB': 'Contact Us',
    'en-US': 'Contact Us',
    fr: 'Nous contacter',
    ja: '詳細はこちら',
    ko: '문의하기',
    zh: '联系我们',
  },
  Contributors: {
    de: 'Mitwirkende',
    'en-GB': 'Contributors',
    'en-US': 'Contributors',
    fr: 'Contributeurs',
    ja: 'コントリビューター',
    ko: '기여자',
    zh: '贡献者',
  },
  'Sort By Date': {
    'en-US': 'Sort by date',
    'en-GB': 'Sort by date',
    ko: '날짜별 정렬',
    ja: '過去のイベント',
    fr: 'Trier par Date',
    zh: '按日期排序',
    de: 'nach Datum sortieren',
  },
  Education: {
    de: 'Ausbildung',
    'en-GB': 'Education',
    'en-US': 'Education',
    fr: 'Education',
    ja: '教育',
    ko: '교육',
    zh: '教育',
  },
  Email: {
    de: 'Email',
    'en-GB': 'Email',
    'en-US': 'Email',
    fr: 'E-mail',
    ja: 'Eメール',
    ko: '이메일',
    zh: '电子邮件地址',
  },
  'Email Address': {
    de: 'E-Mail-Addresse',
    'en-GB': 'Email Address',
    'en-US': 'Email Address',
    fr: 'Adresse e-mail',
    ja: '電子メールアドレス',
    ko: '이메일 주소',
    zh: '电子邮件地址',
  },
  'Email Me': {
    de: 'Email senden',
    'en-GB': 'Email Me',
    'en-US': 'Email Me',
    fr: 'Envoyez-moi un courriel',
    ja: 'メール',
    ko: '이메일',
    zh: '电子邮件',
  },
  'Engagement & Publications': {
    de: 'Engagements & Veröffentlichungen',
    'en-GB': 'Engagement & Publications',
    'en-US': 'Engagement & Publications',
    fr: 'Engagements et publications',
    ja: 'Engagements & Publications',
    ko: 'Engagement & Publications',
    zh: '参与和出版物',
  },
  Europe: {
    de: 'Europa',
    'en-GB': 'Europe',
    'en-US': 'Europe',
    fr: "L'Europe",
    ja: 'ヨーロッパ',
    ko: '유럽',
    zh: '欧洲',
  },
  Events: {
    de: 'Veranstaltungen',
    'en-GB': 'Events',
    'en-US': 'Events',
    fr: 'Événements',
    ja: 'イベント',
    ko: '이벤트',
    zh: '活动',
  },
  'Experienced Lawyers': {
    de: 'Erfahrene Anwälte',
    'en-GB': 'Experienced Lawyers',
    'en-US': 'Experienced Lawyers',
    fr: 'Avocats expérimentés',
    ja: '経験豊富な弁護士',
    ko: '경험이 풍부한 변호사',
    zh: '资深律师',
  },
  Explore: {
    de: 'Explore',
    'en-GB': 'Explore',
    'en-US': 'Explore',
    fr: 'Explore',
    ja: 'Explore',
    ko: 'Explore',
    zh: 'Explore',
  },
  'Explore All': {
    de: 'Alles erkunden',
    'en-GB': 'Explore All',
    'en-US': 'Explore All',
    fr: 'Tout explorer',
    ja: 'すべてを調べる',
    ko: '모두 탐색',
    zh: '探索所有',
  },
  Fax: {
    de: 'Fax',
    'en-GB': 'Fax',
    'en-US': 'Fax',
    fr: 'Fax',
    ja: 'Fax',
    ko: 'Fax',
    zh: '传真',
  },
  'FedAction: Financial Regulatory Updates': {
    de: 'FedAction: Financial Regulatory Updates',
    'en-GB': 'FedAction: Financial Regulatory Updates',
    'en-US': 'FedAction: Financial Regulatory Updates',
    fr: 'FedAction: Financial Regulatory Updates',
    ja: 'FedAction: Financial Regulatory Updates',
    ko: 'FedAction: Financial Regulatory Updates',
    zh: 'FedAction: Financial Regulatory Updates',
  },
  Filter: {
    de: 'Filter',
    'en-GB': 'Filter',
    'en-US': 'Filter',
    fr: 'Filtrer',
    ja: 'フィルター',
    ko: '필터',
    zh: '筛选',
  },
  'Filter by practice': {
    de: 'Nach Praxis filtern',
    'en-GB': 'Filter by practice',
    'en-US': 'Filter by practice',
    fr: 'Filtrer par pratique',
    ja: '取扱業務',
    ko: '연습으로 필터링',
    zh: '按实践过滤',
  },
  'Find your next opportunity at Paul Hastings': {
    de: 'Finden Sie Ihre nächste Chance bei Paul Hastings',
    'en-GB': 'Find your next opportunity at Paul Hastings',
    'en-US': 'Find your next opportunity at Paul Hastings',
    fr: 'Trouvez votre prochaine opportunité chez Paul Hastings',
    ja: 'ポール・ヘイスティングスで次の機会を見つけましょう',
    ko: 'Paul Hastings에서 다음 기회를 찾으십시오.',
    zh: '在 Paul Hastings 找到您的下一个机会',
  },
  'For More Information': {
    de: 'Für weitere Informationen',
    'en-GB': 'For More Information',
    'en-US': 'For More Information',
    fr: 'Pour plus d’informations',
    ja: '詳細情報',
    ko: '추가 정보',
    zh: '更多信息',
  },
  'Full Name': {
    de: 'Vollständiger Name',
    'en-GB': 'Full Name',
    'en-US': 'Full Name',
    fr: 'Nom complet',
    ja: 'フルネーム',
    ko: '성명',
    zh: '全名',
  },
  Name: {
    'en-US': 'Name',
    'en-GB': 'Name',
    ko: '이름',
    ja: '名前',
    fr: 'Nom',
    zh: '名称',
    de: 'Name',
  },
  'Get Directions': {
    de: 'Anweisungen bekommen',
    'en-GB': 'Get Directions',
    'en-US': 'Get Directions',
    fr: 'Obtenir des directions',
    ja: 'Get Directions',
    ko: 'Get Directions',
    zh: '获取路线',
  },
  'Get In Touch With Us': {
    'en-US': 'Get In Touch With Us',
    'en-GB': 'Get In Touch With Us',
    ko: '저희에게 연락해주세요',
    ja: 'お問い合わせ',
    fr: 'Nous contacter',
    zh: '与我们联系',
    de: 'Nehmen Sie Kontakt mit uns auf',
  },
  Contributor: {
    'en-US': 'Contributor',
    'en-GB': 'Contributor',
    ko: '기고자',
    ja: 'コントリビューター',
    fr: 'Contributeur / Contributrice',
    zh: '贡献者',
    de: 'Beitragender / Beitragende',
  },
  'Get access to our latest articles, videos and webcasts.': {
    de:
      'Erhalten Sie Zugang zu unseren neuesten Artikeln, Videos und Webcasts.',
    'en-GB': 'Get access to our latest articles, videos and webcasts.',
    'en-US': 'Get access to our latest articles, videos and webcasts.',
    fr: 'Accédez à nos derniers articles, vidéos et webémissions.',
    ja: '最新の記事、ビデオ、および Web キャストにアクセスできます。',
    ko: '최신 기사, 비디오 및 웹캐스트에 액세스하십시오.',
    zh: '访问我们的最新文章、视频和网络广播。',
  },
  'Growth through Purpose and Innovation': {
    de: 'Wachstum durch Sinn und Innovation',
    'en-GB': 'Growth through Purpose and Innovation',
    'en-US': 'Growth through Purpose and Innovation',
    fr: "Croissance par le but et l'innovation",
    ja: '目的と革新による成長',
    ko: '목적과 혁신을 통한 성장',
    zh: '通过目标和创新实现增长',
  },
  'I have read and agree to the terms of this disclaimer.': {
    de:
      'Ich habe die Bedingungen dieses Haftungsausschlusses gelesen und stimme ihnen zu.',
    'en-GB': 'I have read and agree to the terms of this disclaimer.',
    'en-US': 'I have read and agree to the terms of this disclaimer.',
    fr:
      "J'ai lu et j'accepte les termes de cette clause de non-responsabilité.",
    ja: '私はこの免責事項を読み、同意します。',
    ko: '본인은 이 면책 조항을 읽었으며 이에 동의합니다.',
    zh: '我已阅读并同意本免责声明的条款。',
  },
  Insights: {
    de: 'Einblicke',
    'en-GB': 'Insights',
    'en-US': 'Insights',
    fr: 'Publications',
    ja: 'インサイト',
    ko: '통찰력',
    zh: '见解',
  },
  'International Regulatory Enforcement (PHIRE)': {
    de: 'International Regulatory Enforcement (PHIRE)',
    'en-GB': 'International Regulatory Enforcement (PHIRE)',
    'en-US': 'International Regulatory Enforcement (PHIRE)',
    fr: 'Connaissances',
    ja: 'International Regulatory Enforcement (PHIRE)',
    ko: 'International Regulatory Enforcement (PHIRE)',
    zh: 'International Regulatory Enforcement (PHIRE)',
  },
  Involvement: {
    de: 'Beteiligung',
    'en-GB': 'Involvement',
    'en-US': 'Involvement',
    fr: 'Participation',
    ja: '関与',
    ko: '참여',
    zh: '参与',
  },
  'Job Title': {
    de: 'Berufsbezeichnung',
    'en-GB': 'Job Title',
    'en-US': 'Job Title',
    fr: "Titre d'emploi",
    ja: '職名',
    ko: '직위',
    zh: '职称',
  },
  Korea: {
    de: '',
    'en-GB': 'Korea',
    'en-US': 'Korea',
    fr: 'Corée',
    ja: '韓国',
    ko: '한국',
    zh: '韩国',
  },
  Languages: {
    de: 'Sprachen',
    'en-GB': 'Languages',
    'en-US': 'Languages',
    fr: 'Langages',
    ja: 'Languages',
    ko: 'Languages',
    zh: '语言',
  },
  'Latin America': {
    de: 'Lateinamerika',
    'en-GB': 'Latin America',
    'en-US': 'Latin America',
    fr: 'Amérique latine',
    ja: 'ラテンアメリカ',
    ko: '라틴 아메리카',
    zh: '拉丁美洲',
  },
  'Law Students': {
    de: 'Jurastudenten',
    'en-GB': 'Law Students',
    'en-US': 'Law Students',
    fr: 'Étudiants en droit',
    ja: '法学生',
    ko: '법대생',
    zh: '法学院学生',
  },
  'Learn More': {
    de: 'Lern mehr',
    'en-GB': 'Learn More',
    'en-US': 'Learn More',
    fr: 'Apprendre encore plus',
    ja: 'Learn More',
    ko: 'Learn More',
    zh: '学到更多',
  },
  "Learn more about our firm's 70-year history.": {
    de:
      'Erfahren Sie mehr über die 70-jährige Geschichte unseres Unternehmens.',
    'en-GB': "Learn more about our firm's 70-year history.",
    'en-US': "Learn more about our firm's 70-year history.",
    fr: "Apprenez-en plus sur les 70 ans d'histoire de notre cabinet.",
    ja: '当事務所の70年の歴史をご覧ください。',
    ko: '우리 회사의 70년 역사에 대해 자세히 알아보십시오.',
    zh: '详细了解我们公司 70 年的历史。',
  },
  'Legal Notices': {
    de: 'Rechtliche Hinweise',
    'en-GB': 'Legal Notices',
    'en-US': 'Legal Notices',
    fr: 'Mentions légales',
    ja: '法的通知',
    ko: '법적 고지',
    zh: '法律声明',
  },
  Links: {
    de: 'Verknüpfungen',
    'en-GB': 'Links',
    'en-US': 'Links',
    fr: 'Liens',
    ja: 'リンク',
    ko: '연결',
    zh: '链接',
  },
  Location: {
    de: 'Ort',
    'en-GB': 'Location',
    'en-US': 'Location',
    fr: 'Emplacement',
    ja: '位置',
    ko: '위치',
    zh: '地点',
  },
  Login: {
    de: 'Anmelden',
    'en-GB': 'Login',
    'en-US': 'Login',
    fr: 'Connexion',
    ja: 'ログイン',
    ko: '로그인',
    zh: '登入',
  },
  Message: {
    de: 'Nachricht',
    'en-GB': 'Message',
    'en-US': 'Message',
    fr: 'Message',
    ja: 'メッセージ',
    ko: '메시지',
    zh: 'Message',
  },
  'More Events': {
    de: 'Mehrere Veranstaltungen',
    'en-GB': 'More Events',
    'en-US': 'More Events',
    fr: 'Plus d’événements',
    ja: 'さらにイベント',
    ko: '더 많은 이벤트',
    zh: '更多活动',
  },
  News: {
    de: 'Nachrichten',
    'en-GB': 'News',
    'en-US': 'News',
    fr: 'Actualités',
    ja: 'ニュース',
    ko: '소식',
    zh: '新闻',
  },
  'No options found': {
    de: 'Keine Optionen gefunden Deutsch',
    'en-GB': 'No options found',
    'en-US': 'No options found',
    fr: 'Aucune option trouvée anglais',
    ja: 'オプションが見つかりません',
    ko: '옵션을 찾을 수 없습니다',
    zh: '没有找到选项英语',
  },
  Office: {
    de: 'Büro',
    'en-GB': 'Office',
    'en-US': 'Office',
    fr: 'Bureau',
    ja: 'オフィス',
    ko: '사무실',
    zh: '办公室',
  },
  'Office Chair': {
    de: 'Bürostuhl',
    'en-GB': 'Office Chair',
    'en-US': 'Office Chair',
    fr: 'Chaise de bureau',
    ja: 'オフィスチェア',
    ko: '사무용 의자',
    zh: '办公椅',
  },
  'Office Chairs': {
    de: 'Bürostühle',
    'en-GB': 'Office Chairs',
    'en-US': 'Office Chairs',
    fr: 'Chaises de bureau',
    ja: 'オフィスチェア',
    ko: '사무실 의자',
    zh: '办公椅',
  },
  Offices: {
    de: 'Büros',
    'en-GB': 'Offices',
    'en-US': 'Offices',
    fr: 'Bureaux',
    ja: 'オフィス',
    ko: '진력',
    zh: '办公室',
  },
  'Our people are our greatest assets.': {
    de: 'Unsere Mitarbeiter sind unser größtes Kapital.',
    'en-GB': 'Our people are our greatest assets.',
    'en-US': 'Our people are our greatest assets.',
    fr: 'Nos employés sont nos plus grands atouts.',
    ja: '私たちの人材は私たちの最大の資産です。',
    ko: '우리의 사람들은 우리의 가장 큰 자산입니다.',
    zh: '我们的员工是我们最大的资产。',
  },
  Overview: {
    de: 'Überblick',
    'en-GB': 'Overview',
    'en-US': 'Overview',
    fr: 'Aperçu',
    ja: '概要',
    ko: '개요',
    zh: '概述',
  },
  PDF: {
    de: 'PDF',
    'en-GB': 'PDF',
    'en-US': 'PDF',
    fr: 'PDF',
    ja: 'PDF',
    ko: 'PDF',
    zh: 'PDF',
  },
  'Past Events': {
    de: 'Vergangene Ereignisse',
    'en-GB': 'Past Events',
    'en-US': 'Past Events',
    fr: 'Past Events',
    ja: '過去のイベント',
    ko: '지난 일들',
    zh: '过去的事件',
  },
  'Paul Hastings': {
    de: 'Paul Hastings',
    'en-GB': 'Paul Hastings',
    'en-US': 'Paul Hastings',
    fr: 'Paul Hastings',
    ja: 'Paul Hastings',
    ko: 'Paul Hastings',
    zh: 'Paul Hastings',
  },
  'Paul Hastings Team': {
    de: 'Paul Hastings Team',
    'en-GB': 'Paul Hastings Team',
    'en-US': 'Paul Hastings Team',
    fr: 'Paul Hastings Team',
    ja: 'Paul Hastings Team',
    ko: 'Paul Hastings Team',
    zh: 'Paul Hastings Team',
  },
  Phone: {
    de: 'Telefon',
    'en-GB': 'Phone',
    'en-US': 'Phone',
    fr: 'Téléphone',
    ja: '電話',
    ko: '핸드폰',
    zh: '电话',
  },
  'Please Enter a valid Phone Number': {
    de: 'Bitte geben Sie eine gültige Telefonnummer ein',
    'en-GB': 'Please Enter a valid Phone Number',
    'en-US': 'Please Enter a valid Phone Number',
    fr: "S'il vous plaît entrer un numéro de téléphone valide",
    ja: '有効な電話番号を入力して下さい',
    ko: '유효한 전화 번호를 입력하세요',
    zh: '请输入一个有效的电话号码',
  },
  'Please agree with our Terms and Conditions.': {
    de: 'Bitte stimmen Sie unseren Allgemeinen Geschäftsbedingungen zu.',
    'en-GB': 'Please agree with our Terms and Conditions.',
    'en-US': 'Please agree with our Terms and Conditions.',
    fr: 'Veuillez accepter nos Termes et Conditions.',
    ja: '利用規約に同意してください。',
    ko: '이용약관에 동의해 주십시오.',
    zh: '请同意我们的条款和条件。',
  },
  Practice: {
    'en-GB': 'Practice',
    'en-US': 'Practice',
    ko: '관행',
    ja: 'プラクティス',
    fr: "Domaines D'Activités",
    zh: '业务领域',
    de: 'Rechtsgebiete',
  },
  Practices: {
    de: 'Rechtsgebiete',
    'en-GB': 'Practices',
    'en-US': 'Practices',
    fr: `Domaine d'activité`,
    ja: 'プラクティス',
    ko: 'Practices',
    zh: '业务领域',
  },
  'Practice Areas': {
    de: 'Praxisbereiche',
    'en-GB': 'Practice Areas',
    'en-US': 'Practice Areas',
    fr: 'Domaines de pratique',
    ja: '練習場',
    ko: '실습 분야',
    zh: '执业领域',
  },
  Professional: {
    de: 'Geschäftsfachmann',
    'en-GB': 'Professional',
    'en-US': 'Professional',
    fr: "Professionnel d'affaires",
    ja: '著者',
    ko: '비즈니스 전문가',
    zh: '商务专业',
  },
  'Featured Insight': {
    'en-US': 'Featured Insights',
    ja: '特集',
  },
  'Read More': {
    de: 'Weiterlesen',
    'en-GB': 'Read More',
    'en-US': 'Read More',
    fr: 'Lire la suite',
    ja: '続きを読む',
    ko: '더 읽기',
    zh: '阅读更多',
  },
  'Read Next': {
    de: 'Lesen Sie weiter',
    'en-GB': 'Read Next',
    'en-US': 'Read Next',
    fr: 'Lire la suite',
    ja: '次を読む',
    ko: '다음 읽기',
    zh: '阅读下一篇',
  },
  'Recent Representation': {
    de: 'Aktuelle Vertretung',
    'en-GB': 'Recent Representation',
    'en-US': 'Recent Representation',
    fr: 'Représentation récente',
    ja: '最近の代表',
    ko: '최근 대표',
    zh: '最近的代表',
  },
  Recognitions: {
    de: 'Anerkennungen',
    'en-GB': 'Recognitions',
    'en-US': 'Recognitions',
    fr: 'Reconnaissances',
    ja: '表彰',
    ko: '인정',
    zh: '认可',
  },
  'Register For The Event': {
    de: 'Registrieren Sie sich für die Veranstaltung',
    'en-GB': 'Register For The Event',
    'en-US': 'Register For The Event',
    fr: "Inscrivez-vous à l'événement",
    ja: 'イベントに登録する',
    ko: '이벤트 등록',
    zh: '注册活动',
  },
  'Register Now': {
    de: 'Jetzt registrieren',
    'en-GB': 'Register Now',
    'en-US': 'Register Now',
    fr: 'Inscrivez-vous maintenant',
    ja: '今すぐ登録',
    ko: '지금 등록하세요',
    zh: '立即注册',
  },
  Reject: {
    de: 'Ich lehne ab',
    'en-GB': 'I Reject',
    'en-US': 'I Reject',
    fr: 'Je rejette',
    ja: 'お断りします',
    ko: '나는 거절한다',
    zh: '我拒绝',
  },
  'Related Events': {
    de: 'Verwandte Veranstaltungen',
    'en-GB': 'Related Events',
    'en-US': 'Related Events',
    fr: 'Événements connexes',
    ja: '関連イベント',
    ko: '관련 이벤트',
    zh: '相关活动',
  },
  School: {
    de: 'Schule',
    'en-GB': 'School',
    'en-US': 'School',
    fr: 'École',
    ja: '学校',
    ko: '학교',
    zh: '学校',
  },
  Search: {
    de: 'Suche',
    'en-GB': 'Search',
    'en-US': 'Search',
    fr: 'Chercher',
    ja: '探す',
    ko: '검색',
    zh: '搜索',
  },
  'SEARCH BY KEYWORD': {
    de: 'Suche nach Stichwort',
    'en-GB': 'SEARCH BY KEYWORD',
    'en-US': 'SEARCH BY KEYWORD',
    fr: 'Rechercher par mot-clé',
    ja: 'キーワードで検索',
    ko: '키워드로 검색',
    zh: '按关键字搜索',
  },
  'SEARCH BY NAME': {
    de: 'SUCHE MIT NAME',
    'en-GB': 'SEARCH BY NAME',
    'en-US': 'SEARCH BY NAME',
    fr: 'RECHERCHE PAR NOM',
    ja: '名前で検索',
    ko: '이름으로 검색',
    zh: '按名稱搜尋',
  },
  'Search Jobs': {
    de: 'Arbeit suchen',
    'en-GB': 'Search Jobs',
    'en-US': 'Search Jobs',
    fr: 'Recherche de travail',
    ja: '求人を探す',
    ko: '채용정보 검색',
    zh: '搜索工作',
  },
  'See More': {
    de: 'Mehr sehen',
    'en-GB': 'See More',
    'en-US': 'See More',
    fr: 'Voir plus',
    ja: '続きを見る',
    ko: '더보기',
    zh: '看更多',
  },
  'Show:': {
    de: 'Show:',
    'en-GB': 'Show:',
    'en-US': 'Show:',
    fr: 'Show:',
    ja: 'Show:',
    ko: 'Show:',
    zh: 'Show:',
  },
  Sitemap: {
    de: 'Seitenverzeichnis',
    'en-GB': 'Sitemap',
    'en-US': 'Sitemap',
    fr: 'Plan du site',
    ja: 'サイトマップ',
    ko: '사이트맵',
    zh: '网站地图',
  },
  Sites: {
    de: 'Webseiten',
    'en-GB': 'Sites',
    'en-US': 'Sites',
    fr: 'Sites',
    ja: 'サイト',
    ko: 'Sites',
    zh: '相关链接',
  },
  'Sorry, no results were found. Please try a different query.': {
    de:
      'Es wurden leider keine Ergebnisse gefunden. Bitte versuchen Sie es mit einer anderen Abfrage.',
    'en-GB': 'Sorry, no results were found. Please try a different query.',
    'en-US': 'Sorry, no results were found. Please try a different query.',
    fr:
      "Désolé, aucun résultat n'a été trouvé. Veuillez essayer une autre requête.",
    ja:
      '申し訳ありませんが、結果が見つかりませんでした。別のクエリを試してください。',
    ko: '죄송합니다. 검색된 결과가 없습니다. 다른 쿼리를 시도하십시오.',
    zh: '很抱歉，找不到任何结果。请尝试不同的查询。',
  },
  'Sorry, no results were found.': {
    de: 'Es wurden leider keine Ergebnisse gefunden.',
    'en-GB': 'Sorry, no results were found.',
    'en-US': 'Sorry, no results were found.',
    fr: "Désolé, aucun résultat n'a été trouvé.",
    ja: '申し訳ありませんが、結果が見つかりませんでした。',
    ko: '죄송합니다. 검색된 결과가 없습니다.',
    zh: '很抱歉，找不到任何结果。',
  },
  'Please try a different query.': {
    de: 'Bitte versuchen Sie es mit einer anderen Abfrage.',
    'en-GB': 'Please try a different query.',
    'en-US': 'Please try a different query.',
    fr: 'Veuillez essayer une autre requête.',
    ja: '別のクエリを試してください。',
    ko: '다른 쿼리를 시도하십시오.',
    zh: '请尝试不同的查询。',
  },
  Speakers: {
    de: 'Sprecher',
    'en-GB': 'Speakers',
    'en-US': 'Speakers',
    fr: 'Orateurs',
    ja: 'スピーカー',
    ko: '스피커',
    zh: '演讲者',
  },
  Submit: {
    de: 'Senden',
    'en-GB': 'Submit',
    'en-US': 'Submit',
    fr: 'Soumettre',
    ja: '送信',
    ko: '검색',
    zh: '提交',
  },
  Subscribe: {
    de: 'Abonnieren',
    'en-GB': 'Subscribe',
    'en-US': 'Subscribe',
    fr: 'Subscribe',
    ja: '申し込む',
    ko: '구독하다',
    zh: '订阅',
  },
  'Subscribe to': {
    de: 'Abonnieren',
    'en-GB': 'Subscribe to',
    'en-US': 'Subscribe to',
    fr: 'S’abonner à',
    ja: '購読',
    ko: '구독',
    zh: '订阅',
  },
  Team: {
    de: 'Mannschaft',
    'en-GB': 'Team',
    'en-US': 'Team',
    fr: 'Équipe',
    ja: 'チーム',
    ko: '팀',
    zh: '团队',
  },
  "The page you were looking for doesn't exist.": {
    de: 'Die gesuchte Seite existiert nicht.',
    'en-GB': "The page you were looking for doesn't exist.",
    'en-US': "The page you were looking for doesn't exist.",
    fr: "La page que vous recherchez n'existe pas.",
    ja: 'お探しのページは存在しません。',
    ko: '찾고 있던 페이지가 존재하지 않습니다.',
    zh: '您要查找的页面不存在。',
  },
  'This Field is Required': {
    de: 'Dieses Feld ist erforderlich',
    'en-GB': 'This Field is Required',
    'en-US': 'This Field is Required',
    fr: 'Ce champ est requis',
    ja: 'この項目は必須です',
    ko: '이 필드는 필수 항목입니다.',
    zh: '这是必填栏',
  },
  Title: {
    de: 'Titel',
    'en-GB': 'Title',
    'en-US': 'Title',
    fr: 'Titre',
    ja: 'タイトル',
    ko: '제목',
    zh: '標題',
  },
  'United States': {
    de: 'Vereinigte Staaten',
    'en-GB': 'United States',
    'en-US': 'United States',
    fr: 'États-Unis',
    ja: 'United States',
    ko: '미국',
    zh: '美国',
  },
  'Upcoming Events': {
    de: 'Kommende Veranstaltungen',
    'en-GB': 'Upcoming Events',
    'en-US': 'Upcoming Events',
    fr: 'Évènements à venir',
    ja: '今後開催のイベント',
    ko: '다가오는 이벤트',
    zh: '活动预告',
  },
  'View All': {
    de: 'Alle ansehen',
    'en-GB': 'View All',
    'en-US': 'View All',
    fr: 'Voir tout',
    ja: 'すべて見る',
    ko: '모두보기',
    zh: '查看全部',
  },
  'View All Insights': {
    de: 'Alle Einblicke anzeigen',
    'en-GB': 'View All Insights',
    'en-US': 'View All Insights',
    fr: 'Afficher toutes les informations',
    ja: 'すべてのインサイトを表示',
    ko: '모든 인사이트 보기',
    zh: '查看全部',
  },
  'View All Practice Insights': {
    de: 'Alle Einblicke in die Praxis anzeigen',
    'en-GB': 'View All Practice Insights',
    'en-US': 'View All Practice Insights',
    fr: 'Afficher toutes les informations sur la pratique',
    ja: 'すべてのプラクティス インサイトを表示',
    ko: '모든 실습 인사이트 보기',
    zh: '查看所有实践见解',
  },
  'View All Practice News': {
    de: 'Alle Praxis-News anzeigen',
    'en-GB': 'View All Practice News',
    'en-US': 'View All Practice News',
    fr: 'Voir toutes les nouvelles de la pratique',
    ja: 'すべての練習ニュースを見る',
    ko: '연습 뉴스 모두 보기',
    zh: '查看所有实践新闻',
  },
  'View All Practice Recognitions': {
    de: 'Alle Praxisanerkennungen anzeigen',
    'en-GB': 'View All Practice Recognitions',
    'en-US': 'View All Practice Recognitions',
    fr: 'Voir toutes les reconnaissances de pratique',
    ja: 'すべての実践表彰を見る',
    ko: '모든 실습 인정 보기',
    zh: '查看所有实践认可',
  },
  'View Full Story': {
    de: 'Geschichte vollständig anzeigen',
    'en-GB': 'View Full Story',
    'en-US': 'View Full Story',
    fr: 'Voir l’histoire complète',
    ja: '完全なストーリーを見る',
    ko: '전체 스토리 보기',
    zh: '查看完整故事',
  },
  'View More': {
    de: 'Mehr sehen',
    'en-GB': 'View More',
    'en-US': 'View More',
    fr: 'Voir plus',
    ja: 'もっと見る',
    ko: '더보기',
    zh: '查看更多',
  },
  'View Opportunities': {
    de: 'Chancen anzeigen',
    'en-GB': 'View Opportunities',
    'en-US': 'View Opportunities',
    fr: 'Voir les opportunités',
    ja: '機会を見る',
    ko: '기회 보기',
    zh: '查看机会',
  },
  'View The Team': {
    de: 'Sehen Sie sich das Team an',
    'en-GB': 'View The Team',
    'en-US': 'View The Team',
    fr: "Voir l'équipe",
    ja: 'チームを見る',
    ko: '팀 보기',
    zh: '查看团队',
  },
  'Visit our Homepage or explore our site:': {
    de: 'Besuchen Sie unsere Homepage oder erkunden Sie unsere Website:',
    'en-GB': 'Visit our Homepage or explore our site:',
    'en-US': 'Visit our Homepage or explore our site:',
    fr: "Visitez notre page d'accueil ou explorez notre site :",
    ja:
      '私たちのホームページにアクセスするか、私たちのサイトを探索してください:',
    ko: '당사 홈페이지를 방문하거나 당사 사이트를 탐색하십시오:',
    zh: '访问我们的主页或浏览我们的网站：',
  },
  'Watch PH: Culture': {
    de: 'Watch PH: Culture',
    'en-GB': 'Watch PH: Culture',
    'en-US': 'Watch PH: Culture',
    fr: 'Watch PH: Culture',
    ja: 'Watch PH: Culture',
    ko: 'Watch PH: Culture',
    zh: 'Watch PH: Culture',
  },
  contactFormDescription: {
    de:
      'Bitte füllen Sie das folgende Formular aus, um uns eine Frage oder einen Kommentar zu senden. Achten Sie darauf, den entsprechenden Untersuchungsbereich aus dem Dropdown-Menü auszuwählen.',
    'en-GB':
      'Please complete the form below to send us a question or comment. Be sure to select the appropriate Area of Inquiry from the drop-down menu.',
    'en-US':
      'Please complete the form below to send us a question or comment. Be sure to select the appropriate Area of Inquiry from the drop-down menu.',
    fr:
      "Veuillez remplir le formulaire ci-dessous pour nous envoyer une question ou un commentaire. Assurez-vous de sélectionner le domaine d'enquête approprié dans le menu déroulant.",
    ja:
      '質問やコメントを送信するには、以下のフォームに記入してください。ドロップダウンメニューから適切なお問い合わせ分野を選択してください。',
    ko:
      '아래 양식을 작성하여 질문이나 의견을 보내주십시오. 드롭다운 메뉴에서 적절한 문의 영역을 선택해야 합니다.',
    zh:
      '请填写下面的表格向我们发送问题或评论。请务必从下拉菜单中选择适当的查询区域.',
  },
  contactFormDisclaimer: {
    de:
      'BITTE BEACHTEN SIE: Mitteilungen, die über unsere Website per E-Mail an uns gesendet werden, sind nicht sicher und werden von uns nicht als vertraulich betrachtet. Senden Sie keine Informationen, die Sie für vertraulich halten, ohne vorher mit einem unserer Anwälte gesprochen zu haben. Sie verstehen, dass wir im Zusammenhang mit dem Thema Ihrer Mitteilung möglicherweise Ihnen gegenüber ungünstige Mandanten vertreten. Die unaufgeforderte Kommunikation mit Paul Hastings oder unseren Anwälten über diese Website begründet kein Mandatsverhältnis zwischen Paul Hastings und Ihnen oder einer anderen Person.',
    'en-GB':
      'PLEASE NOTE: Communications sent to us via email through our website are not secure and will not be regarded by us as confidential. Do not send information that you believe is confidential without first speaking with one of our attorneys. You understand that we may represent clients adverse to you in connection with the subject of your communication. Unsolicited communication with Paul Hastings or our attorneys through this site does not create an attorney-client relationship between Paul Hastings and you or any other person.',
    'en-US':
      'PLEASE NOTE: Communications sent to us via email through our website are not secure and will not be regarded by us as confidential. Do not send information that you believe is confidential without first speaking with one of our attorneys. You understand that we may represent clients adverse to you in connection with the subject of your communication. Unsolicited communication with Paul Hastings or our attorneys through this site does not create an attorney-client relationship between Paul Hastings and you or any other person.',
    fr:
      "VEUILLEZ NOTER : Les communications qui nous sont envoyées par e-mail via notre site Web ne sont pas sécurisées et ne seront pas considérées par nous comme confidentielles. N'envoyez pas d'informations que vous jugez confidentielles sans avoir d'abord parlé avec l'un de nos avocats. Vous comprenez que nous pouvons représenter des clients qui vous sont opposés en rapport avec le sujet de votre communication. Une communication non sollicitée avec Paul Hastings ou nos avocats via ce site ne crée pas de relation avocat-client entre Paul Hastings et vous ou toute autre personne.",
    ja:
      '注意: 当社の Web サイトから電子メールで送信された通信は安全ではなく、機密情報とは見なされません。最初に弁護士に相談することなく、機密であると思われる情報を送信しないでください。あなたは、あなたのコミュニケーションの主題に関連して、私たちがあなたに不利なクライアントを代表する可能性があることを理解しています.このサイトを介したポール ヘイスティングスまたは当社の弁護士との未承諾の通信によって、ポール ヘイスティングスとお客様またはその他の人物との間に弁護士とクライアントの関係が生じることはありません。',
    ko:
      '참고: 당사 웹사이트를 통해 이메일을 통해 당사에 전송된 커뮤니케이션은 안전하지 않으며 기밀로 간주되지 않습니다. 먼저 당사 변호사와 상의하지 않고 기밀이라고 생각되는 정보를 보내지 마십시오. 귀하는 당사가 귀하의 커뮤니케이션 주제와 관련하여 귀하에게 불리한 고객을 대리할 수 있음을 이해합니다. 이 사이트를 통한 Paul Hastings 또는 우리 변호사와의 원치 않는 의사 소통은 Paul Hastings와 귀하 또는 다른 사람 사이에 변호사-고객 관계를 생성하지 않습니다.',
    zh:
      '请注意：通过我们的网站通过电子邮件发送给我们的通信不安全，我们不会将其视为机密。在未事先与我们的一位律师交谈之前，请勿发送您认为属于机密的信息。您了解我们可能代表与您的通信主题相关的对您不利的客户。通过本网站主动与 Paul Hastings 或我们的律师进行交流不会在 Paul Hastings 与您或任何其他人之间建立律师-客户关系。',
  },
  contactFormError: {
    de:
      'Entschuldigung, ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    'en-GB': 'Sorry, an error occurred. Please try again later.',
    'en-US': 'Sorry, an error occurred. Please try again later.',
    fr: "Désolé, une erreur s'est produite. Veuillez réessayer plus tard.",
    ja: 'エラーが発生しました。後でもう一度やり直してください。',
    ko: '죄송합니다. 오류가 발생했습니다. 나중에 다시 시도 해주십시오.',
    zh: '抱歉，发生错误。请稍后再试。',
  },
  contactFormSuccess: {
    de: 'Vielen Dank! Deine Nachricht wurde erhalten.',
    'en-GB': 'Thank you! Your message has been received.',
    'en-US': 'Thank you! Your message has been received.',
    fr: 'Merci! Votre message a été reçu.',
    ja: 'ありがとうございました！メッセージを受け取りました。',
    ko: '고맙습니다! 귀하의 메시지가 수신되었습니다.',
    zh: '谢谢！您的消息已收到。',
  },
  contactFormTerms: {
    de:
      'Ich habe die Bedingungen dieses Haftungsausschlusses gelesen und stimme ihnen zu.',
    'en-GB': 'I have read and agree to the terms of this disclaimer.',
    'en-US': 'I have read and agree to the terms of this disclaimer.',
    fr:
      "J'ai lu et j'accepte les termes de cette clause de non-responsabilité.",
    ja: '私はこの免責事項を読み、同意します。',
    ko: '본인은 이 면책 조항을 읽었으며 이에 동의합니다.',
    zh: '我已阅读并同意本免责声明的条款。',
  },
  cookieLine1: {
    de:
      'Wir verwenden Cookies auf unserer Website, um Ihr Surferlebnis zu verbessern. Um mehr darüber zu erfahren, wie wir Cookies verwenden, oder um Ihre Präferenzen und Browsereinstellungen zu ändern, lesen Sie bitte unsere globale Datenschutzerklärung. Sie können die Verwendung von Cookies auf unserer Website akzeptieren oder ablehnen, indem Sie auf Ich akzeptiere Cookies oder Ich lehne Cookies ab klicken.',
    'en-GB':
      'We use cookies on our website to enhance your browsing experience. To understand more about how we use cookies, or change your preference and browser settings, please see our Global Privacy Statement. You can choose to accept or reject the use of cookies on our website by clicking I accept cookies or I refuse cookies.',
    'en-US':
      'We use cookies on our website to enhance your browsing experience. To understand more about how we use cookies, or change your preference and browser settings, please see our Global Privacy Statement. You can choose to accept or reject the use of cookies on our website by clicking I accept cookies or I refuse cookies.',
    fr:
      "Nous utilisons des cookies sur notre site Web pour améliorer votre expérience de navigation. Pour en savoir plus sur la façon dont nous utilisons les cookies ou modifier vos préférences et les paramètres de votre navigateur, veuillez consulter notre déclaration de confidentialité mondiale. Vous pouvez choisir d'accepter ou de refuser l'utilisation de cookies sur notre site en cliquant sur J'accepte les cookies ou Je refuse les cookies.",
    ja:
      '当社のウェブサイトでは、ブラウジング体験を向上させるために Cookie を使用しています。 Cookie の使用方法、または設定やブラウザ設定の変更方法の詳細については、グローバル プライバシー ステートメントをご覧ください。 「Cookie を受け入れる」または「Cookie を拒否する」をクリックして、当社の Web サイトでの Cookie の使用を受け入れるか拒否するかを選択できます。',
    ko:
      '당사는 귀하의 검색 경험을 향상시키기 위해 당사 웹사이트에서 쿠키를 사용합니다. 당사가 쿠키를 사용하는 방법에 대해 자세히 알아보거나 기본 설정 및 브라우저 설정을 변경하려면 글로벌 개인정보 보호정책을 참조하십시오. 쿠키 허용 또는 쿠키 거부를 클릭하여 당사 웹사이트에서 쿠키 사용을 수락하거나 거부할 수 있습니다.',
    zh:
      '我们在我们的网站上使用 cookie 来增强您的浏览体验。要了解有关我们如何使用 cookie 或更改您的偏好和浏览器设置的更多信息，请参阅我们的全球隐私声明。您可以通过单击我接受 cookie 或我拒绝 cookie 来选择接受或拒绝在我们的网站上使用 cookie。',
  },
  cookieLine2: {
    de:
      'Bitte beachten Sie, dass einige Funktionen unserer Website beeinträchtigt werden können, wenn Sie Cookies ablehnen.',
    'en-GB':
      "Please note that if you choose to reject cookies, doing so may impair some of our website's functionality.",
    'en-US':
      "Please note that if you choose to reject cookies, doing so may impair some of our website's functionality.",
    fr:
      'Veuillez noter que si vous choisissez de refuser les cookies, cela peut altérer certaines fonctionnalités de notre site Web.',
    ja:
      'Cookie を拒否することを選択した場合、当社の Web サイトの機能の一部が損なわれる可能性があることに注意してください。',
    ko: '쿠키를 거부하는 경우 웹사이트의 일부 기능이 손상될 수 있습니다.',
    zh: '请注意，如果您选择拒绝 cookie，这样做可能会损害我们网站的某些功能。',
  },
  copyright: {
    de: 'Copyright 2022 Paul Hastings LLP. Alle Rechte vorbehalten.',
    'en-GB': 'Copyright 2022 Paul Hastings LLP. All rights reserved.',
    'en-US': 'Copyright 2022 Paul Hastings LLP. All rights reserved.',
    fr: 'Copyright 2022 Paul Hastings LLP. Tous les droits sont réservés.',
    ja: '著作権 2022 ポール・ヘイスティングス LLP。全著作権所有。',
    ko: '저작권 2022 Paul Hastings LLP. 판권 소유.',
    zh: '版权所有 2022 Paul Hastings LLP。版权所有。',
  },
  "doesn't exist": {
    de: 'existiert nicht',
    'en-GB': "doesn't exist",
    'en-US': "doesn't exist",
    fr: "n'existe pas",
    ja: '存在しません',
    ko: '존재하지 않습니다',
    zh: '不存在',
  },
  footerSubscribeCopy: {
    de:
      'Bleiben Sie auf dem Laufenden mit den neuesten Erkenntnissen und Ressourcen von Anwälten und Geschäftsleuten von Paul Hastings',
    'en-GB':
      'Stay current with the latest insights and resources from Paul Hastings lawyers and business professionals',
    'en-US':
      'Stay current with the latest insights and resources from Paul Hastings lawyers and business professionals',
    fr:
      'Restez au courant des dernières informations et ressources des avocats et des professionnels des affaires de Paul Hastings',
    ja:
      'ポール・ヘイスティングスの弁護士やビジネス専門家からの最新の洞察とリソースを入手して最新の状態を保ちましょう',
    ko:
      'Paul Hastings 변호사 및 비즈니스 전문가가 제공하는 최신 통찰력과 리소스를 확인하십시오.',
    zh: '随时了解 Paul Hastings 律师和商业专业人士的最新见解和资源',
  },
}
