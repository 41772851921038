import React from 'react'
import { useQuery } from '@tanstack/react-query'
import Debug from '@components/Debug'
import { useLocale } from '@hooks'
import { fetchCPAEntry } from '@utils'
import Loader from '../PreviewPageContainer/Loader'
import EmbeddedEntrySubContainer from './EmbeddedEntrySubContainer'

const EmbeddedEntryCPAContainer = ({ node, ...rest }) => {
  const id = node?.data?.target?.sys?.id
  const { locale } = useLocale()

  const { isLoading, error, data } = useQuery(
    [`Embedded Entry ${id}`, id, locale],
    () => fetchCPAEntry({ id, locale, include: 2 }),
    {
      enabled: !!id && !!locale,
    }
  )

  if (isLoading) return <Loader />
  if (error) {
    return (
      <Debug
        label="Fetch or Sanitize Error"
        jsonDump={{ id, error: error.toString(), data }}
      />
    )
  }

  const entryData = data?.items?.[0]
  if (!entryData) {
    return (
      <Debug
        label={`Data could not be fetched for id "${id}"`}
        jsonDump={{ node }}
      />
    )
  }

  return <EmbeddedEntrySubContainer {...entryData} {...rest} />
}

export default EmbeddedEntryCPAContainer
