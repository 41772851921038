import { useEffect, useState } from 'react'
import { throttle } from '@utils'

const useWindowScrollPosition = () => {
  let throttleMs = 300
  const [scroll, setScroll] = useState({})

  useEffect(() => {
    setScroll({
      x: window.pageXOffset,
      y: window.pageYOffset,
    })
  }, [])

  const handle = throttle(() => {
    if (typeof window !== 'undefined') {
      setScroll({
        x: window.pageXOffset,
        y: window.pageYOffset,
      })
    }
  }, throttleMs)

  useEffect(() => {
    window.addEventListener('scroll', handle)

    return () => window.removeEventListener('scroll', handle)
  }, [])

  return scroll
}

export default useWindowScrollPosition
export { useWindowScrollPosition }
