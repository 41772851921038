import React from 'react'
import { StyledImage, ImageComponentContainer } from '../style'

const ImageWithContainer = ({
  imageSrc,
  externalResource,
  title,
  internalSlug,
  align,
  width,
  wrapText,
  margin,
  LinkComponent,
  pageSlugs,
  typename,
  internalCategory,
}) => {
  if (LinkComponent) {
    return (
      <ImageComponentContainer align={align}>
        <LinkComponent
          width={width}
          href={externalResource}
          target="_blank"
          rel="noopener noreferrer"
          to={internalSlug}
          contentType={typename}
          category={internalCategory}
        >
          <StyledImage
            src={imageSrc}
            alt={title}
            align={align}
            wrapText={wrapText}
            width={width}
            margin={margin}
          />
        </LinkComponent>
      </ImageComponentContainer>
    )
  }
  return (
    <ImageComponentContainer align={align}>
      <StyledImage
        src={imageSrc}
        alt={title}
        align={align}
        wrapText={wrapText}
        width={width}
        margin={margin}
      />
    </ImageComponentContainer>
  )
}

export default ImageWithContainer
