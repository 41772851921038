import React, { useState } from 'react'
import { Container } from '../../Globals'
import { Column, Row } from '@components/Common'
import {
  StyledSidebar,
  StyledSidebarPanel,
  StyledSidebarPanelBody,
} from '../style'
import SidebarLink from './SidebarLink'
import { useMediaQuery } from '@hooks'
import SidebarMobile from './SidebarMobile'
import SidebarTitle from './SidebarTitle'
import Card from '../../Card'
import { richTextToComponents } from '../../../utils/gatsby/richTextToComponents'

const Sidebar = ({
  children,
  sidebarLinks,
  entriesBased = false,
  theme = 'default',
  defaultOpen = 0,
  id,
  title,
  previewMode,
  fadeInContent = true,
}) => {
  const [activeSidebarLinkId, setActiveSidebarLinkId] = useState(defaultOpen)
  const matches = useMediaQuery('(max-width: 768px)')
  if (matches) {
    return (
      <SidebarMobile
        sidebarLinks={sidebarLinks}
        activeSidebarLinkId={activeSidebarLinkId}
        setActiveSidebarLinkId={setActiveSidebarLinkId}
        theme={theme}
        title={title}
        previewMode={previewMode}
      />
    )
  }
  const bg = theme === 'transparent' ? 'bg-transparent' : 'bg-ph-purple'
  const padding = entriesBased ? 'p-0' : 'py-15 px-0'

  return (
    <>
      <Container fluid={theme === 'default'}>
        {theme === 'transparent' && <SidebarTitle title={title} />}
        <Row>
          <Column className={`w-1/3 ${bg} opacity-100`}>
            <StyledSidebar
              aria-controls={`${id}-content`}
              id={`${id}-sidebar`}
              theme={theme}
              role="tablist"
              data-cy="sidebar"
            >
              {sidebarLinks &&
                sidebarLinks.map(sidebarLink => (
                  <SidebarLink
                    key={previewMode ? sidebarLink?.sys?.id : sidebarLink?.id}
                    id={previewMode ? sidebarLink?.sys?.id : sidebarLink?.id}
                    type={sidebarLink?.type}
                    slug={sidebarLink?.slug}
                    title={sidebarLink?.title}
                    active={
                      previewMode
                        ? activeSidebarLinkId === sidebarLink?.sys?.id
                        : activeSidebarLinkId === sidebarLink?.id
                    }
                    setActiveSidebarLinkId={setActiveSidebarLinkId}
                    theme={theme}
                  />
                ))}
            </StyledSidebar>
          </Column>
          <Column className={`w-2/3 ${bg} opacity-100 ${padding}`}>
            {sidebarLinks &&
              sidebarLinks.map((sidebarLink, index) =>
                entriesBased ? (
                  <StyledSidebarPanel
                    visible={
                      previewMode
                        ? activeSidebarLinkId === sidebarLink.sys.id
                        : activeSidebarLinkId === sidebarLink.id
                    }
                    theme={theme}
                    key={previewMode ? sidebarLink.sys.id : sidebarLink.id}
                    role="sidebarpanel"
                  >
                    <StyledSidebarPanelBody entriesBased={entriesBased}>
                      {sidebarLink?.insights?.slice(0, 9).map((insight, i) => (
                        <Column
                          key={i}
                          className="md:1/2 py-[50px] px-[70px] border-solid border-r-1 border-b-1 rounded-none"
                        >
                          <Card
                            tag={insight.category}
                            title={insight.title}
                            item={insight}
                            id={insight.id}
                          />
                        </Column>
                      ))}
                    </StyledSidebarPanelBody>
                  </StyledSidebarPanel>
                ) : (
                  sidebarLink?.type === 'Content' && (
                    <StyledSidebarPanel
                      visible={
                        previewMode
                          ? activeSidebarLinkId === sidebarLink?.sys?.id
                          : activeSidebarLinkId === sidebarLink?.id
                      }
                      theme={theme}
                      key={previewMode ? sidebarLink?.sys?.id : sidebarLink?.id}
                      role="sidebarpanel"
                    >
                      <StyledSidebarPanelBody fadeInContent={fadeInContent}>
                        {richTextToComponents(sidebarLink?.content, {
                          optionalId: sidebarLink?.sys?.id,
                          optionalType: sidebarLink?.__typename,
                        })}
                      </StyledSidebarPanelBody>
                    </StyledSidebarPanel>
                  )
                )
              )}
          </Column>
        </Row>
      </Container>
    </>
  )
}

export default Sidebar
