/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useSpring } from 'react-spring'
import { Accordion } from '../../Accordion'
import List from '../../List'
import { StyledEventButton, StyledNoEventButton } from '../../Button/style'
import { colors } from '@theme'
import { FullWidthButton, A } from '../../Button'
import {
  StyledSidebarMobileContainer,
  StyledSidebarMobileTitle,
  StyledSidebarMobileBody,
  StyledSidebarDropdownMobileContainer,
  StyledSidebarDropdownMobileHeader,
  StyledSidebarDropdownMobile,
} from '../style'
import { Column } from '@components/Common'
import Card from '../../Card'
import { richTextToComponents } from '../../../utils/gatsby/richTextToComponents'
import { useMeasure } from '@hooks'
import SidebarTitle from './SidebarTitle'
import SlugContext from '@context/slugs'
import { generateRandomKey } from '@utils'
import { PreviewContext } from '@context'

const MobileTitle = ({ children }) => (
  <StyledSidebarMobileTitle>{children}</StyledSidebarMobileTitle>
)

const MobileBody = ({ children, theme }) => (
  <StyledSidebarMobileBody theme={theme}>{children}</StyledSidebarMobileBody>
)

const ViewMoreButton = ({ content, pageSlugs, id, slug }) => {
  const dontDisplay = id === 'c-All'
  if (dontDisplay) {
    return <div></div>
  }
  return (
    <Column className="md:1/2 py-[25px] px-[20px] border-b border-r border-ph-border">
      {content.length < 1 ? (
        <StyledNoEventButton
          width="250px"
          height="62px"
          marginBottom="10px"
          active
        >
          No posts available
        </StyledNoEventButton>
      ) : (
        <A to={slug} contentType="ContentfulArticleCategory" none>
          <StyledEventButton
            width="250px"
            height="62px"
            marginBottom="10px"
            active
          >
            View more
          </StyledEventButton>
        </A>
      )}
    </Column>
  )
}

const SidebarMobile = ({
  sidebarLinks,
  activeSidebarLinkId,
  setActiveSidebarLinkId,
  theme,
  content,
  title,
  active = false,
  slug,
  previewMode,
}) => {
  const [bind, { height: viewHeight }] = useMeasure()
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      height: activeSidebarLinkId ? viewHeight : 0,
      opacity: activeSidebarLinkId ? 1 : 0,
      transform: `translate3d(${activeSidebarLinkId ? 0 : 20}px,0,0)`,
    },
  })
  if (content) {
    return (
      <>
        <StyledSidebarDropdownMobileContainer border background={colors.navy}>
          <Accordion
            summary={
              <StyledSidebarDropdownMobileHeader>
                {title}
              </StyledSidebarDropdownMobileHeader>
            }
            defaultOpen
            id="toggle-one"
            border="tBorder"
            expandGlyph="down-caret"
            collapseGlyph="up-caret"
            iconOrder={2}
            iconFill="white"
          >
            <List width="100%">
              {sidebarLinks &&
                sidebarLinks.map((sidebarLink, i) => (
                  <StyledSidebarDropdownMobile
                    key={generateRandomKey()}
                    label={sidebarLink?.title}
                  >
                    <FullWidthButton
                      active={active}
                      id={`active-tab-${i}`}
                      data-toggle="tab"
                      role="tab"
                      aria-controls={`active-tab-${i}`}
                      aria-selected={
                        activeSidebarLinkId === sidebarLink?.id
                          ? 'true'
                          : 'false'
                      }
                      onClick={() => setActiveSidebarLinkId(sidebarLink?.id)}
                      hoverColor="white"
                      color={colors.gold}
                      style={{ justifyContent: 'flex-start' }}
                    >
                      {sidebarLink?.title}
                    </FullWidthButton>
                  </StyledSidebarDropdownMobile>
                ))}
            </List>
          </Accordion>
        </StyledSidebarDropdownMobileContainer>
        {content &&
          content.map((insight, i) => (
            <Column
              key={generateRandomKey()}
              className="md:1/2 py-[25px] px-[20px] border-b border-r border-ph-border"
            >
              <Card
                tag={insight?.category}
                title={insight?.title}
                item={insight}
                id={insight?.id}
                to={insight?.slug}
                slugContentType="ContentfulArticle"
                slugCategory={insight?.categorySlug}
              />
            </Column>
          ))}
        {content && (
          <ViewMoreButton
            content={content}
            slug={slug}
            id={activeSidebarLinkId}
          />
        )}
      </>
    )
  }
  return (
    <>
      <StyledSidebarMobileContainer theme={theme}>
        {theme === 'transparent' && <SidebarTitle title={title} />}
        {sidebarLinks.map((sidebarLink, i) => (
          <Accordion
            key={generateRandomKey()}
            summary={sidebarLink?.title}
            SummaryComponent={MobileTitle}
            DetailsComponent={MobileBody}
            defaultOpenId={sidebarLinks && sidebarLinks?.[0]?.id}
            id="toggle-one"
            defaultOpen={sidebarLinks && i === 0}
            border
            expandGlyph="plus"
            collapseGlyph="minus"
            iconOrder={2}
            theme={theme}
            linkAccordion={sidebarLink?.type === 'Link' && sidebarLink?.slug}
          >
            {sidebarLink?.type === 'Content' &&
              richTextToComponents(sidebarLink?.content)}
          </Accordion>
        ))}
      </StyledSidebarMobileContainer>
    </>
  )
}

SidebarMobile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default SidebarMobile
