import React, { memo } from 'react'
import { a } from 'react-spring'
import { useDropdown } from '@hooks'
import {
  StyledNavListItem,
  StyledNavLink,
  DropdownContent,
  StyledNavLinkMobileTitle,
} from '../../style'

const MobileDropdown = memo(
  ({ children, title, to, url, __typename, className }) => {
    const {
      bind,
      toggleVisible,
      height,
      opacity,
      visible,
      previous,
      transform,
    } = useDropdown('translate3d(20px,0,0)', `translate3d(0px,0,0)`)

    return (
      <StyledNavListItem onClick={toggleVisible}>
        <StyledNavLink
          className={className}
          dropdown={visible ? 'open' : 'closed'}
        >
          <StyledNavLinkMobileTitle to={to} href={url} contentType={__typename}>
            {title}
          </StyledNavLinkMobileTitle>
        </StyledNavLink>
        <DropdownContent
          style={{
            opacity,
            height: visible ? 'auto' : height,
          }}
          onClick={toggleVisible}
          dropdown={visible && previous === visible ? 'open' : 'closed'}
        >
          <a.div style={{ transform }} {...bind}>
            {children}
          </a.div>
        </DropdownContent>
      </StyledNavListItem>
    )
  }
)

MobileDropdown.displayName = 'MobileDropdown'

export default MobileDropdown
