import React from 'react'
import { useLocale } from '@hooks'
import {
  StyledNavListItem,
  StyledNavLink,
  StyledNavLinkMobileTitle,
} from '../style'
import MobileDropdown from './SubNavMobileComponents/MobileDropdown'
import SubNavigationGroupsMobile from './SubNavMobileComponents/SubNavigationGroupsMobile'

// dropdown, title, to, children
const NavItemMobile = ({
  displayText,
  slug,
  url,
  featuredSection,
  subNavigationGroups,
  __typename,
  id,
}) => {
  const { displayLocale } = useLocale()
  const filteredSubNavigationGroups = subNavigationGroups?.filter(
    ({ displayOnSites }) => displayOnSites?.includes(displayLocale)
  )

  const shouldDisplayFeaturedSection =
    featuredSection &&
    featuredSection?.displayOnSites &&
    featuredSection?.displayOnSites.includes(displayLocale)

  if (
    (filteredSubNavigationGroups && filteredSubNavigationGroups?.length > 0) ||
    shouldDisplayFeaturedSection
  ) {
    return (
      <MobileDropdown
        to={slug}
        href={url}
        title={displayText}
        __typename={__typename}
      >
        <SubNavigationGroupsMobile
          featuredSection={shouldDisplayFeaturedSection ? featuredSection : ''}
          subNavigationGroups={filteredSubNavigationGroups}
        />
      </MobileDropdown>
    )
  }

  return (
    <StyledNavListItem>
      <StyledNavLinkMobileTitle
        to={slug}
        href={url}
        contentType={__typename}
        subpageId={id}
      >
        <StyledNavLink>{displayText}</StyledNavLink>
      </StyledNavLinkMobileTitle>
    </StyledNavListItem>
  )
}

NavItemMobile.propTypes = {}

export default NavItemMobile
