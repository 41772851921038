import { nodeLocaleToEnglishLanguage } from './mappings'
import { getExternalOrInternalLink } from './links'

export const shapeFeaturedSection = (featuredSection, fullLocale) => {
  if (
    !featuredSection?.displayOnSites?.includes(
      nodeLocaleToEnglishLanguage[fullLocale]
    )
  )
    return undefined

  return {
    ...featuredSection,
    description: featuredSection?.description?.description,
    ...getExternalOrInternalLink(featuredSection?.ctaLink, fullLocale),
  }
}

export const shapeLastSubNavigationitem = (subNavigationItem, fullLocale) => {
  const link = getExternalOrInternalLink(
    subNavigationItem?.navigationLink,
    fullLocale
  )
  if (Object.keys(link).length === 0) return undefined

  return {
    ...subNavigationItem,
    ...link,
  }
}

export const shapeSecondLevelSubNavigation = (subNavigation, fullLocale) => {
  if (
    !subNavigation ||
    !subNavigation?.displayOnSites?.includes(
      nodeLocaleToEnglishLanguage[fullLocale]
    )
  )
    return null

  return {
    ...subNavigation,
    subNavigationItems: subNavigation?.subNavigationItems
      ?.map(item => shapeLastSubNavigationitem(item, fullLocale))
      .filter(item => item !== undefined),
  }
}

export const shapeSubNavItems = (subNavigationItems, fullLocale) => {
  if (!subNavigationItems) return null
  return subNavigationItems?.map(
    ({ externalLink, navigationLink, subNavigation, ...rest }) => ({
      ...rest,
      ...getExternalOrInternalLink(navigationLink, fullLocale),
      subNavigation: shapeSecondLevelSubNavigation(subNavigation, fullLocale),
    })
  )
}

export const shapeSubNavGroups = (subNavigationGroups, fullLocale) => {
  subNavigationGroups = subNavigationGroups?.filter(({ displayOnSites }) =>
    displayOnSites?.includes(nodeLocaleToEnglishLanguage[fullLocale])
  )
  if (subNavigationGroups?.length === 0) return undefined

  return subNavigationGroups?.map(subNavigationGroup => ({
    ...subNavigationGroup,
    subNavigationItems: shapeSubNavItems(
      subNavigationGroup?.subNavigationItems,
      fullLocale
    ).filter(({ href, slug }) => slug || href),
  }))
}

export const shapeNavigation = (items, fullLocale) => {
  return items?.map(
    ({ featuredSection, navigationLink, subNavigationGroups, ...rest }) => ({
      ...rest,
      ...getExternalOrInternalLink(navigationLink, fullLocale),
      featuredSection: shapeFeaturedSection(featuredSection, fullLocale),
      subNavigationGroups: shapeSubNavGroups(subNavigationGroups, fullLocale),
    })
  )
}
