/**
 * Feature flags for both build time and run time
 * Remove flags when they are released as official features
 *
 * General Rules for checking functions:
 *  - If feature is only for developers, enable only on dev
 *  - If feature is ready for client review, disable only on master
 *  - By default, enable if flag is set to true
 *
 */

const featureFlags = {
  NEW_PRACTICE_AREA_DETAIL_PAGE: 'NEW_PRACTICE_AREA_DETAIL_PAGE',
  PREVIEW_REFACTOR: 'PREVIEW_REFACTOR',
  USER_TOOLS: 'USER_TOOLS',
  BUNDLE_7: 'BUNDLE_7',
  GTAG: 'GTAG',
  DEBUG: 'DEBUG',
}

const variants = {
  PRACTICE_AREA_SLUG_CHANGE: 'PRACTICE_AREA_SLUG_CHANGE',
}

const isEnvironment = env => {
  return process.env.GATSBY_CONTENTFUL_ENVIRONMENT === env
}

// Process Flags
const isEnabledForDevelopersOnly = isEnvironment('dev')
const isEnabledForClientReview = !isEnvironment('master')
const isEnabledForProdPreview = process.env.GATSBY_PROD_PREVIEW === 'true'

// Helper functions
const isExperimentalPracticeAreaDetailPageEnabled = variant => {
  switch (variant) {
    case variants.PRACTICE_AREA_SLUG_CHANGE:
      return (
        isEnabledForDevelopersOnly ||
        process.env.GATSBY_ENABLE_PRACTICE_AREA_SLUG_CHANGE === 'true'
      )
    default: {
      return (
        isEnabledForClientReview ||
        process.env.GATSBY_ENABLE_EXPERIMENTAL_PRACTICE_AREA_DETAIL_PAGE ===
          'true'
      )
    }
  }
}

const isExperimentalBundle7 = () => {
  return (
    isEnabledForClientReview ||
    process.env.GATSBY_ENABLE_EXPERIMENTAL_BUNDLE_7 === 'true'
  )
}

const isPreviewRefactor = () => {
  return (
    isEnabledForClientReview ||
    process.env.GATSBY_ENABLE_PREVIEW_REFACTOR === 'true'
  )
}

const isUserToolsEnabled = () => {
  if (!isPreviewRefactor()) return false
  return (
    isEnabledForClientReview || process.env.GATSBY_ENABLE_USER_TOOLS === 'true'
  )
}

const isGtagEnabled = () => {
  return process.env.GATSBY_ENABLE_GTAG === 'true'
}

const isDebugEnabled = () => {
  return (
    process.env.GATSBY_ENABLE_DEBUG === 'true' || isEnabledForDevelopersOnly
  )
}

// Feature Selector
const isFeatureEnabled = (feature, variant) => {
  switch (feature) {
    case featureFlags.NEW_PRACTICE_AREA_DETAIL_PAGE:
      return isExperimentalPracticeAreaDetailPageEnabled(variant)
    case featureFlags.PREVIEW_REFACTOR:
      return isPreviewRefactor()
    case featureFlags.BUNDLE_7:
      return isExperimentalBundle7()
    case featureFlags.USER_TOOLS:
      return isUserToolsEnabled()
    case featureFlags.GTAG:
      return isGtagEnabled()
    case featureFlags.DEBUG:
      return isDebugEnabled()
    default:
      return false
  }
}

// Need to use this form for node (gatsby createPages)
module.exports = {
  isFeatureEnabled,
  isEnvironment,
  featureFlags,
  variants,
  isEnabledForDevelopersOnly,
  isEnabledForClientReview,
  isEnabledForProdPreview,
}
