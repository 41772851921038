import React from 'react'
import { RootElementWrapper } from './src/components/Layout'

// extender for dayjs
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export const wrapRootElement = ({ element }) => {
  return <RootElementWrapper element={element} />
}
