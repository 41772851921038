import React from 'react'
import { pageGutterMargins } from '@theme'
import {
  StyledButton,
  StyledDiv,
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledInput,
  StyledP,
  StyledSpan,
  StyledHTML,
  StyledUl,
  StyledLi,
  StyledTextArea,
  StyledLabel,
  StyledPre,
  StyledForm,
  StyledResetUl,
  StyledResetLi,
  StyledOl,
} from './styles'

export const Div = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledDiv ref={ref} {...props}>
      {children}
    </StyledDiv>
  )
})
Div.displayName = 'Div'

export const Button = React.forwardRef(
  ({ children, variant, disabled = false, ...props }, ref) => {
    const variantStyles = {
      primary: {
        color: 'white',
        bg: 'primary.red',
        svgPathFill: 'white',
        svgFill: 'primary.red',
        colorHover: !disabled ? 'white' : null,
        bgHover: !disabled ? 'primary.red' : null,
        svgPathFillHover: !disabled ? 'white' : null,
        svgFillHover: !disabled ? 'primary.red' : null,
        cursor: !disabled ? 'pointer' : 'default',
      },
      secondary: {
        color: 'black',
        bg: 'primary.stone40',
        svgPathFill: 'black',
        svgFill: 'primary.stone40',
        colorHover: !disabled ? 'white' : null,
        bgHover: !disabled ? 'primary.red' : null,
        svgPathFillHover: !disabled ? 'white' : null,
        svgFillHover: !disabled ? 'primary.red' : null,
        cursor: !disabled ? 'pointer' : 'default',
      },
      tertiary: {
        color: 'primary.red',
        bg: 'primary.stone20',
        borderColor: 'primary.red',
        border: '1px solid',
        svgPathFill: 'black',
        svgFill: 'primary.stone40',
        colorHover: !disabled ? 'white' : null,
        bgHover: !disabled ? 'primary.red' : null,
        svgPathFillHover: !disabled ? 'white' : null,
        svgFillHover: !disabled ? 'primary.red' : null,
        cursor: !disabled ? 'pointer' : 'default',
      },
      careersActionBar: {
        color: 'white',
        bg: 'secondary.blue80',
        svgPathFill: 'white',
        svgFill: 'secondary.blue80',
        colorHover: !disabled ? 'white' : null,
        bgHover: !disabled ? 'secondary.blue60' : null,
        svgPathFillHover: !disabled ? 'white' : null,
        svgFillHover: !disabled ? 'secondary.blue80' : null,
        cursor: !disabled ? 'pointer' : 'default',
      },
      pagination: {
        color: 'black',
        bg: 'transparent',
        svgPathFill: 'black',
        svgFill: 'black',
        colorHover: !disabled ? 'white' : null,
        bgHover: !disabled ? 'primary.red' : null,
        svgPathFillHover: !disabled ? 'white' : null,
        svgFillHover: !disabled ? 'white' : null,
        cursor: !disabled ? 'pointer' : 'default',
      },
      paginationActive: {
        color: 'white',
        bg: 'primary.red80',
        svgPathFill: 'black',
        svgFill: 'black',
        colorHover: !disabled ? 'white' : null,
        bgHover: !disabled ? 'primary.red' : null,
        svgPathFillHover: !disabled ? 'white' : null,
        svgFillHover: !disabled ? 'white' : null,
        cursor: !disabled ? 'pointer' : 'default',
      },
      paginationPrevNext: {
        color: 'black',
        bg: 'secondary.blue80',
        svgPathFill: 'white',
        svgFill: 'primary.stone40',
        colorHover: !disabled ? 'white' : null,
        bgHover: !disabled ? 'secondary.blue' : null,
        svgPathFillHover: !disabled ? 'white' : null,
        svgFillHover: !disabled ? 'white' : null,
        cursor: !disabled ? 'pointer' : 'default',
      },
    }

    return (
      <StyledButton
        fontSize="button"
        fontWeight="bold"
        border="none"
        transition="color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out"
        {...(variantStyles?.[variant] || {})}
        {...props}
      >
        {children}
      </StyledButton>
    )
  }
)
Button.displayName = 'Button'

export const H1 = ({ children, ...props }) => {
  return <StyledH1 {...props}>{children}</StyledH1>
}

export const H2 = ({ children, ...props }) => {
  return <StyledH2 {...props}>{children}</StyledH2>
}

export const H3 = ({ children, ...props }) => {
  return <StyledH3 {...props}>{children}</StyledH3>
}

export const H4 = ({ children, ...props }) => {
  return <StyledH4 {...props}>{children}</StyledH4>
}

export const P = ({ children, ...props }) => {
  return <StyledP {...props}>{children}</StyledP>
}

export const Span = ({ children, ...props }) => {
  return <StyledSpan {...props}>{children}</StyledSpan>
}

export const Label = ({ children, ...props }) => {
  return (
    <StyledLabel fontSize="body" {...props}>
      {children}
    </StyledLabel>
  )
}

export const Form = ({ children, ...props }) => {
  return <StyledForm {...props}>{children}</StyledForm>
}

export const Input = ({ children, ...props }) => {
  return (
    <StyledInput height="64px" p="20px 24px" fontSize="body" {...props}>
      {children}
    </StyledInput>
  )
}

export const TextArea = ({ children, ...props }) => {
  return (
    <StyledTextArea p="20px 24px" fontSize="body" {...props}>
      {children}
    </StyledTextArea>
  )
}

export const DivWithGutterMargins = ({ children, ...props }) => {
  return (
    <StyledDiv
      px={[
        pageGutterMargins[0],
        null,
        pageGutterMargins[1],
        pageGutterMargins[2],
        null,
        pageGutterMargins[3],
      ]}
      {...props}
    >
      {children}
    </StyledDiv>
  )
}

export const VisiblilityWrapperDesktop = ({ children, ...props }) => {
  return (
    <StyledDiv display={['none', null, 'block']} {...props}>
      {children}
    </StyledDiv>
  )
}

export const VisiblilityWrapperMobile = ({ children, ...props }) => {
  return (
    <StyledDiv display={['block', null, 'none']} {...props}>
      {children}
    </StyledDiv>
  )
}

export const HTML = ({ children, ...props }) => {
  return <StyledHTML {...props}>{children}</StyledHTML>
}

export const Ul = ({ children, ...props }) => {
  return <StyledUl {...props}>{children}</StyledUl>
}

export const Ol = ({ children, ...props }) => {
  return <StyledOl {...props}>{children}</StyledOl>
}

export const Li = ({ children, ...props }) => {
  return <StyledLi {...props}>{children}</StyledLi>
}

export const NakedUl = ({ children, ...props }) => {
  return <StyledResetUl {...props}>{children}</StyledResetUl>
}

export const NakedLi = ({ children, ...props }) => {
  return <StyledResetLi {...props}>{children}</StyledResetLi>
}

export const Pre = ({ children, ...props }) => {
  return (
    <StyledPre
      fontFamily="monospace"
      fontSize="body"
      lineHeight="body"
      whiteSpace="pre-wrap"
      p="16px"
      color="red"
      borderRadius="0"
      {...props}
    >
      {children}
    </StyledPre>
  )
}

export default Div
