import React from 'react'
import { Div } from '@components/StyledTags'
import { featureFlags, isFeatureEnabled } from '@featureflags'
import { LOCAL_STORAGE_KEYS, useLocalStorage } from '@hooks'
import BreakpointDisplay from './components/BreakpointDisplay'
import ContentfulLink from './components/ContentfulLink'
import PreviewToggle from './components/PreviewToggle'
import Toggler from './Toggler'
import { isPreviewPage, isSSR } from '@utils'
import PreviewSamplesLink from './components/PreviewSamplesLink'

const UserTools = props => {
  const [expanded, setExpanded] = useLocalStorage(
    LOCAL_STORAGE_KEYS.USER_TOOLS_EXPANDED,
    false
  )

  if (isSSR()) return null
  if (!isFeatureEnabled(featureFlags.USER_TOOLS)) return null

  return (
    <Div
      position="fixed"
      bottom={['20px', null, '22px']}
      display="flex"
      right="72px"
      zIndex="900"
      flexDirection={['column', null, 'row']}
      justifyContent="center"
      alignItems="flex-end"
    >
      <Div pr={[0, null, expanded ? 2 : 0]} my={[1, null, 0]}>
        <Toggler
          expanded={expanded}
          onClick={() => setExpanded(expand => !expand)}
        />
      </Div>
      {expanded && (
        <>
          <Div pr={[0, null, 2]} my={[1, null, 0]}>
            <BreakpointDisplay />
          </Div>
          {props.contentful_id && (
            <Div pr={[0, null, 2]} my={[1, null, 0]}>
              <ContentfulLink {...props} />
            </Div>
          )}
          {isPreviewPage() && (
            <Div pr={[0, null, 2]} my={[1, null, 0]}>
              <PreviewSamplesLink />
            </Div>
          )}
          <Div my={[1, null, 0]}>
            <PreviewToggle {...props} />
          </Div>
        </>
      )}
    </Div>
  )
}

export default UserTools
