import React from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

const Row = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        'font-default border-box flex flex-row flex-initial flex-wrap p-0 m-0',
        className
      )}
    >
      {children}
    </div>
  )
}

export default Row

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  alignBetween: PropTypes.boolean,
  center: PropTypes.boolean,
}
