import React, { useEffect } from 'react'
import StyledFooter from './components/Footer'
import { useLocale } from '@hooks'
import { isEnvironment, isEnabledForProdPreview } from '@featureflags'

const FooterLayout = ({ items, globalPrivacy, termsAndConditions }) => {
  const [displaySRA, setDisplaySRA] = React.useState(false)
  const { locale } = useLocale()

  useEffect(() => {
    // redirect from cloudfront domains
    if (window.location.host === 'd2yuc5f4504j8l.cloudfront.net') {
      window.location.host = 'dev.paulhastings.com'
    }
    if (window.location.host === 'd2emnlk0wnwute.cloudfront.net') {
      window.location.host = 'qa.paulhastings.com'
    }
    if (window.location.host === 'd33l674dklnw8n.cloudfront.net') {
      window.location.host = 'www.paulhastings.com'
    }
    if (isEnvironment('master') && !isEnabledForProdPreview) {
      if (locale === 'en-GB') {
        setDisplaySRA(true)
      }
    }
  }, [locale])

  return (
    <StyledFooter
      items={items}
      displaySRA={displaySRA}
      copyRightData={{ globalPrivacy, termsAndConditions }}
    />
  )
}

export default FooterLayout
