/* eslint-disable camelcase */
import { CONTENTFUL_PAGE_IDS } from '@constants'
import { isSSR, isPreviewPage } from '@utils'

export const getUrlQueryParams = () => {
  if (isSSR()) return {}
  const params = new URLSearchParams(window.location.search)
  return Object.fromEntries(params.entries())
}

export const updateHistoryQueryParams = newUrlQueryParams => {
  if (isSSR() || isPreviewPage()) return

  const newPath = newUrlQueryParams
    ? `${window.location.pathname}?${newUrlQueryParams}`
    : window.location.pathname

  history.pushState(null, null, newPath)
}

export const getSearchPageWithFilters = (searchPage, filters) => {
  if (!searchPage) return null
  if (isPreviewPage()) return searchPage

  if (!filters?.length) return searchPage

  return `${searchPage}?${filters
    .map(({ filter, value }) => `${filter}=${encodeURIComponent(value)}`)
    .join('&')}`
}

export const PROFESSIONALS_FILTERS = {
  offices: 'offices',
  practiceAreas: 'practiceAreas',
  languages: 'languages',
  education: 'education',
  titleGroup: 'titleGroup',
  name: 'name',
}

export const INSIGHTS_FILTERS = {
  practiceAreas: 'practiceAreas',
  category: 'category',
  associatedAuthorsAndProfessionals: 'associatedAuthorsAndProfessionals',
  title: 'title',
}

export const NEWS_FILTERS = {
  practiceAreas: 'practiceAreas',
}

export const ACCOLADES_FILTERS = {
  practiceAreas: 'practiceAreas',
}

export const EVENTS_FILTERS = {
  practiceAreas: 'practiceAreas',
  offices: 'offices',
}

export const parseQueryString = queryVariable => {
  const query = window.location.search.substring(1)
  const variables = query.split('&')
  for (let i = 0; i < variables.length; i++) {
    let pair = variables[i].split('=')

    if (decodeURIComponent(pair[0]) === queryVariable) {
      return decodeURIComponent(pair[1])
    }
  }
}

export const hrefGetLocalizedSlug = (
  slug,
  node_locale,
  parentPath,
  category
) => {
  const versionSlug = ''
  const localizedSlug = node_locale === 'en-US' ? '' : `${node_locale}/`
  const parentPathSlug = parentPath ? `${parentPath}/` : ''
  const categorySlug = category ? `${category}/` : ''
  const sanitizedSlug = slug === 'home' ? '' : slug
  return `/${versionSlug}${localizedSlug}${parentPathSlug}${categorySlug}${sanitizedSlug}`
}

export const getHrefLangUrls = (pageWithCurrentId, parentPages) => {
  const urls = []

  if (!pageWithCurrentId?.length) return urls

  pageWithCurrentId.forEach(({ slug, node_locale, category }) =>
    urls.push({
      href: hrefGetLocalizedSlug(
        slug,
        node_locale,
        parentPages ? parentPages?.[node_locale] : undefined,
        category?.slug
      ),
      lang: node_locale,
    })
  )

  return urls
}

export const isHomePage = (currLocale = 'en-US') => {
  if (isSSR()) return

  const slug = window?.location?.pathname

  if (slug === '/preview') {
    return window?.location?.search?.includes(CONTENTFUL_PAGE_IDS.home)
  }
  return slug === '/' || slug === `/${currLocale}/`
}

export const getUrl = () => {
  const environment = process.env.GATSBY_CONTENTFUL_ENVIRONMENT
  const protocol = 'https://'
  const domainName = 'paulhastings.com'
  const contentfulUrl = {
    master: `${protocol}www.${domainName}`,
    uat: `${protocol}uat.${domainName}`,
    qa: `${protocol}qa.${domainName}`,
    dev: `${protocol}dev.${domainName}`,
    preview: `${protocol}preview.${domainName}`,
  }

  return contentfulUrl[environment]
}

export const removeTrailingSlash = slug =>
  slug && slug !== '/' ? slug.replace(/\/$/, '') : slug

export const constructBreadcrumbUrl = (
  baseUrl,
  previousQuery,
  previousRefinements,
  locale
) => {
  let url = locale !== 'en-US' ? `/${locale}/${baseUrl}` : `/${baseUrl}`

  const queryParams = []
  if (previousQuery) {
    queryParams.push(`q=${encodeURIComponent(previousQuery)}`)
  }
  if (previousRefinements && Object.keys(previousRefinements).length > 0) {
    Object.entries(previousRefinements).forEach(([key, values]) => {
      values.forEach(value => {
        queryParams.push(
          `refinementList[${encodeURIComponent(key)}][]=${encodeURIComponent(
            value
          )}`
        )
      })
    })
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`
  }

  return url
}

export const removeLocalesFromUrl = url => {
  const locales = ['ko', 'jp', 'de', 'fr', 'en-GB', 'zh']
  const localeRegex = new RegExp(`^/${locales.join('|')}/`)
  return url.replace(localeRegex, '')
}

export const mapHreflangTags = lang => {
  switch (lang) {
    case 'ja':
      return 'ja-JP'
    case 'ko':
      return 'ko-KR'
    case 'de':
      return 'de-DE'
    case 'fr':
      return 'fr-FR'
    case 'en-GB':
      return 'en-GB'
    case 'zh':
      return 'zh-CN'
    default:
      return 'en-US'
  }
}
