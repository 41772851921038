import React from 'react'
import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  grid,
  variant,
  shadow,
  background,
} from 'styled-system'
import { sizes } from '@theme'

export const StyledBox = styled.div`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${({ filter, transform, cursor, textTransform }) => ({
    filter,
    transform,
    cursor,
    textTransform,
  })}

  
  // lines max width up with nav if applyMaxWidth is passed in as prop
  @media (max-width: ${sizes.medium.max}) {
    ${({ applyMaxWidth }) => applyMaxWidth && 'max-width: 540px'};
    ${({ applyMaxWidth }) => applyMaxWidth && 'padding-left: 15px'};
    ${({ applyMaxWidth }) => applyMaxWidth && 'padding-right: 15px'};
  }


  @media (min-width: ${sizes.medium.min}) {
    ${({ applyMaxWidth }) => applyMaxWidth && 'max-width: 720px'};
  }

  @media (min-width: ${sizes.large.min}) {
    ${({ applyMaxWidth }) => applyMaxWidth && 'max-width: 960px'};
  }

  @media (min-width: ${sizes.large.max}) {
    ${({ applyMaxWidth }) => applyMaxWidth && 'max-width: 1200px'};
  }
`

const Box = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledBox ref={ref} {...props}>
      {children}
    </StyledBox>
  )
})

Box.displayName = 'Box'

export default Box
export { Box }
