import PropTypes from 'prop-types'
import { formatDate } from '@utils'

const FormattedDate = ({ dateStart, dateEnd, format = 'MMMM DD, YYYY' }) => {
  let displayDate = ''
  if (dateStart) {
    displayDate = formatDate(dateStart, format)
  }
  if (dateEnd) {
    if (dateStart) {
      const start = formatDate(dateStart, format)
      const end = formatDate(dateEnd, format)
      displayDate = `${start} - ${end}`
    } else {
      const end = formatDate(dateEnd, format)
      displayDate = `TBD - ${end}`
    }
  }

  return displayDate
}

FormattedDate.propTypes = {
  dateEnd: PropTypes.string,
  dateStart: PropTypes.string,
  format: PropTypes.string,
}

export default FormattedDate
