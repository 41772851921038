import React from 'react'
import PropTypes from 'prop-types'
import { StyledInput } from '../style'

const Search = ({ isValid, ...props }) => (
  <>
    <StyledInput type="search" isValid={isValid} {...props} />
  </>
)

Search.propTypes = {
  isValid: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
}

export default Search
