import styled from 'styled-components'
import { Form } from 'formik'
import { sizes, colors } from '@theme'

export const StyledEmailForm = styled(Form)`
  position: relative;

  ${({ insightDetailEmailForm }) => insightDetailEmailForm && { width: '90%' }};

  input[type='email'] {
    padding-right: 1.875rem;
    font-size: 16px;
    padding-left: 0.5rem;
  }

  label[for='submit'] {
    position: absolute;
    display: flex;
    align-items: center;
    padding-right: 0;
    margin-top: 4px;
    top: 7px;
    right: 0;
  }
`

export const StyledContactForm = styled(Form)`
  label[for='terms'] {
    margin-bottom: 1.5rem;
    display: flex;

    .checkbox-styled,
    & input:checked ~ .checkbox-styled {
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.11),
        0 1px 2px 0 rgba(0, 0, 0, 0.09);
    }
  }

  ::placeholder {
    color: ${colors.text.placeholder};
  }

  @media (max-width: ${sizes.medium.min}) {
    margin-bottom: 3rem;
  }
`

export const StyledContactFormGrid = styled.div`
  margin: 2.5rem 0;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.11),
    0 1px 2px 0 rgba(0, 0, 0, 0.09);

  @media only screen and (min-width: ${sizes.medium.min}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    label {
      &:nth-child(1),
      &:nth-child(2),
      &:last-child {
        grid-column: 1/3;
      }
    }
  }
`

export const StyledSelect = styled(Form)`
  width: ${({ widthPercentage }) =>
    widthPercentage ? `${widthPercentage}%` : '15%'};
  min-width: 200px;
`

export const StyledDisclaimer = styled.p`
  font-size: 12px;
  color: black;
  margin: 0px 0px 1rem;
  line-height: 1.25rem;
  text-align: justify;
`
