import styled from 'styled-components'
import { Col } from '../Globals/index'
import { colors, sizes } from '@theme'
import { StyledSummary } from '../Accordion/style'
import { typography } from 'styled-system'

export const StyledCol = styled(Col)`
  border-bottom: ${({ lastItem }) => !lastItem && '1px solid'};
  border-color: ${colors.grayMd};
  padding: ${({ padding }) => padding || '25px 25px 25px 0px'};
  vertical-align: top;
  ${({ noBorder }) => noBorder && 'border: none'};

  @media (max-width: ${sizes.small.max}) {
    border: none;
    padding: 0px 0px 10px;
  }
  ${typography}
`

export const MasonryContainer = styled.div`
  width: 100%;
  ${StyledSummary} {
    display: flex;
    align-items: center;
    color: ${colors.gold};
  }
`

export const CardContainer = styled.div``

export const TgbTopic = styled.div`
  text-transform: uppercase;
  color: #666;
  font-size: 14px;
  letter-spacing: 1.71px;
  padding-bottom: 10px;
`
