import React from 'react'
import Debug from '@components/Debug'
import { useLocale } from '@hooks'
import { useQuery } from '@tanstack/react-query'
import { fetchCPAAsset, fetchCPAEntry } from '@utils'
import Loader from '../PreviewPageContainer/Loader'
import HyperlinkSubContainer from './HyperlinkSubContainer'

const HyperlinkCPAContainer = ({ node, ...rest }) => {
  const id = node?.data?.target?.sys?.id
  const linkType = node?.data?.target?.sys?.linkType

  const { locale } = useLocale()

  const { isLoading, error, data } = useQuery(
    [`Embedded Entry ${id}`, id, locale],
    () =>
      linkType === 'Entry'
        ? fetchCPAEntry({ id, locale, include: 2 })
        : fetchCPAAsset({ id, locale }),
    {
      enabled: !!id && !!locale,
    }
  )

  if (isLoading) return <Loader />
  if (error) {
    return (
      <Debug
        label="Fetch or Sanitize Error"
        jsonDump={{ id, error: error.toString(), data }}
      />
    )
  }

  const entryOrAsset = linkType === 'Entry' ? data?.items?.[0] : data
  if (!entryOrAsset) {
    return (
      <Debug
        label={`Data could not be fetched for id "${id}"`}
        jsonDump={{ node }}
      />
    )
  }

  return (
    <HyperlinkSubContainer {...rest} {...entryOrAsset} linkType={linkType} />
  )
}

export default HyperlinkCPAContainer
