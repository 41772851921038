import React from 'react'
import PropTypes from 'prop-types'
import { StyledH2 } from './style'
import SubNavigationItem from './SubNavigationItem'

const SubNavigationGroupsSection = ({ subNavigationGroups }) => {
  return (
    <div className="flex flex-row w-full pl-[100px]">
      {subNavigationGroups?.map(({ title, subNavigationItems, id }) => (
        <div className="flex flex-col w-1/3 mr-10" key={id}>
          <StyledH2>{title}</StyledH2>
          {subNavigationItems?.map(({ id, navigationLink, ...rest }) => (
            <SubNavigationItem key={id} {...navigationLink} {...rest} />
          ))}
        </div>
      ))}
    </div>
  )
}

SubNavigationGroupsSection.propTypes = {
  subNavigationGroups: PropTypes.object,
}

export default SubNavigationGroupsSection
