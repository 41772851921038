import React, { useState, useEffect } from 'react'
import KeyUpSearch from './KeyUpSearch'
import {
  SearchInput,
  SearchInputIconOnly,
  AnimatedSearchForm,
  StyledMediumSearchForm,
} from './style'
import { useTranslatedTerms } from '@hooks'

const Search = ({
  small,
  medium,
  withFilters,
  groupedOptions,
  handleSubmit,
  handleRerender,
  value = '',
  mobile = false,
  filterAlignBetween = false,
  ...rest
}) => {
  const [clientSearchQuery, setClientSearchQuery] = useState('')
  const { getTranslatedTerm } = useTranslatedTerms()
  const handleClientSearch = e => {
    setClientSearchQuery(e.target.value)
  }

  useEffect(() => {
    setClientSearchQuery(value)
  }, [value])

  if (small) {
    return (
      <AnimatedSearchForm onSubmit={handleSubmit}>
        <SearchInputIconOnly
          placeholder={getTranslatedTerm('Search')}
          type="search"
          aria-label="Search"
          value={clientSearchQuery}
          onChange={handleClientSearch}
          id="qtph-globalmenu-search-btn"
          spellcheck="false"
        />
      </AnimatedSearchForm>
    )
  }
  if (medium) {
    return (
      <StyledMediumSearchForm onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder={getTranslatedTerm('Search By Keyword')}
          aria-label="Search"
          name="search"
          value={clientSearchQuery}
          onChange={handleClientSearch}
          className="qtph-globalmenu-search-txtfield"
          spellCheck="false"
        />
      </StyledMediumSearchForm>
    )
  }
  if (withFilters) {
    return (
      <KeyUpSearch
        groupedOptions={groupedOptions}
        handleRerender={handleRerender}
        filterAlignBetween={filterAlignBetween}
        mobile={mobile}
        {...rest}
        className="qtph-globalmenu-search-txtfield"
        spellcheck="false"
      />
    )
  }
  return (
    <SearchInput
      onChange={handleClientSearch}
      type="search"
      placeholder={getTranslatedTerm('Search')}
      value={clientSearchQuery}
      className="qtph-globalmenu-search-txtfield"
      spellcheck="false"
    />
  )
}

export default Search
