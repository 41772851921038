/* eslint-disable camelcase */
import React, { useContext } from 'react'
import { TermsContext } from '@context/index'

export const useTranslatedTerms = () => {
  return useContext(TermsContext)
}

export const TranslatedTermsProvider = ({
  children,
  locale,
  translatedJson,
}) => {
  const getTranslatedTerm = term => {
    return (
      translatedJson?.[term]?.[locale] ||
      translatedJson?.[term]?.['en-US'] ||
      term
    )
  }

  return (
    <TermsContext.Provider value={{ getTranslatedTerm }}>
      {children}
    </TermsContext.Provider>
  )
}
