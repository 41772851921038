import React from 'react'
import { StyledH3, StyledNavigationItemContainer } from './style'
import A from '@components/A'

import SecondLevelSubNavigationItemMobile from './SecondLevelSubNavigationItemMobile'

const SubNavigationItemMobile = ({
  subNavigation,
  slug,
  url,
  displayText,
  __typename,
  id,
}) => {
  const subNavigationItems = subNavigation?.subNavigationItems || []

  return (
    <StyledNavigationItemContainer>
      <A to={slug} href={url} contentType={__typename} subpageId={id}>
        <StyledH3>{displayText}</StyledH3>
      </A>
      {subNavigationItems?.map(({ id, navigationLink, ...rest }) => (
        <SecondLevelSubNavigationItemMobile
          key={id}
          {...navigationLink}
          {...rest}
        />
      ))}
    </StyledNavigationItemContainer>
  )
}

SubNavigationItemMobile.propTypes = {}

export default SubNavigationItemMobile
