import React from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Input from '../../Input/index'
import { StyledEmailForm } from '../style'

const ValidationSchema = Yup.object().shape({
  email: Yup.string().required('This Field is Required'),
})

const EmailForm = props => {
  return (
    <Formik
      initialValues={{
        justForBots: '',
        email: '',
        submit: 'Submit',
      }}
      validationSchema={ValidationSchema}
      validateOnBlur
      onSubmit={(values, actions) => {
        setTimeout(() => {
          actions.setSubmitting(false)
        }, 3000)
      }}
    >
      <StyledEmailForm>
        <Field
          inputType="email"
          name="email"
          id="email"
          placeholder="Enter email address"
          component={Input}
        />
        <Field
          inputType="honeyPot"
          name="justForBots"
          id="justForBots"
          component={Input}
        />
        <Field
          inputType="icon"
          name="submit"
          id="submit"
          glyph="right-caret"
          fill="gold"
          size="20"
          component={Input}
        />
      </StyledEmailForm>
    </Formik>
  )
}

export default EmailForm
