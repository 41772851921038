import {
  List,
  Contacts,
  ProfessionalCard,
  EmbeddedEntryHyperLink,
  Accordion,
  EmbeddedAsset,
  Table,
  Disclaimer,
} from '@richtext'
import { ContactForm } from '@components/Forms'
import BannerBlock from '@components/Banner/components/BannerBlock'
import React from 'react'
import Debug from '@components/Debug'

const EmbeddedEntrySubContainer = ({
  __typename,
  layout,
  linkType,
  ...rest
}) => {
  const forwardProps = { __typename, ...rest }
  if (linkType === 'Asset') return <EmbeddedAsset {...forwardProps} />

  switch (__typename) {
    case 'accordion':
    case 'ContentfulModuleAccordion':
      return <Accordion {...forwardProps} />
    case 'componentMediaEmbed':
    case 'ContentfulModuleImageAudioVideoEmbed':
      return <EmbeddedAsset {...forwardProps} />
    case 'contacts':
    case 'ContentfulModuleProfessionalsBlock':
      return <Contacts {...forwardProps} />
    case 'list':
    case 'ContentfulModuleList':
      return <List {...forwardProps} />
    case 'moduleForm':
    case 'ContentfulModuleForm':
      return <ContactForm {...forwardProps} />
    case 'table':
    case 'ContentfulModuleTable':
      return <Table {...forwardProps} />
    case 'professional':
    case 'ContentfulProfessional':
      return layout === 'professionalCard' ? (
        <ProfessionalCard {...forwardProps} />
      ) : (
        <EmbeddedEntryHyperLink {...forwardProps} />
      )
    case 'moduleHeroBanner':
    case 'ContentfulModuleBanner':
      return <BannerBlock {...forwardProps} />
    case 'disclaimer':
    case 'ContentfulModuleDisclaimer': {
      // TODO: fix fragment componentColumn
      // cannot gatsby query both moduleDisclaimer and moduleAccordion due to schema conflict
      // preview is fine
      return <Disclaimer {...forwardProps} />
    }
    default: {
      return (
        <Debug
          label={`Embedded Entry not yet handled for content type: "${__typename}"`}
          jsonDump={rest}
        />
      )
    }
  }
}

export default EmbeddedEntrySubContainer
