/* eslint-disable react/no-children-prop */
import React, { useState, memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSpring, a } from 'react-spring'
import { useMeasure, usePrevious } from '@hooks'
import { getSlugOrHref } from '@utils'
import {
  AccordionPanel,
  AccordionContent,
  AccordionSummary,
  StyledIcon,
  StyledSummary,
} from '../style'
import { Divider } from '../../Globals'
import { NakedA } from '../../Button/index'
import Icon from '../../Icon/index'

const Accordion = memo(
  ({
    children,
    SummaryComponent,
    DetailsComponent,
    summary,
    id,
    defaultOpen,
    isList = false,
    iconXl = false,
    divider = false,
    border,
    expandGlyph = 'plus',
    collapseGlyph = 'minus',
    iconOrder = 1,
    fluid = true,
    title,
    smallerIcon = false,
    slug,
    maxWidth = false,
    theme,
    iconFill,
    defaultClose = false,
    isActive,
    setActive,
    singleAccordion = false,
    defaultPro,
    linkAccordion = false,
    contentMargin,
    className,
    iconClassName,
  }) => {
    if (defaultClose) defaultOpen = false
    let [isOpen, setOpen] = useState(defaultPro || defaultOpen)
    if (singleAccordion) [isOpen, setOpen] = useState(false)
    const previous = usePrevious(isOpen)
    let [bind, { height: viewHeight }] = useMeasure()
    const { height, opacity, transform } = useSpring({
      from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
      to: {
        height: isOpen ? viewHeight : 0,
        opacity: isOpen ? 1 : 0,
        transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
      },
    })

    useEffect(() => {
      if (singleAccordion) {
        if (id === isActive) {
          setOpen(!isOpen)
        } else {
          setOpen(false)
        }
      }
    }, [isActive, id])

    const activateAccordion = id => {
      if (id === isActive) {
        id = 'not active'
      }
      setActive(id)
    }

    if (smallerIcon) {
      return (
        <AccordionPanel
          style={{ paddingBottom: '20px' }}
          whiteSpace
          border={border}
          role="accordionpanel"
        >
          <AccordionSummary
            aria-controls={`${id}-content`}
            id={`${id}-header`}
            active={isOpen && isList}
            iconOrder={iconOrder}
            iconXl={iconXl}
            fluid={fluid}
          >
            <StyledSummary iconOrder={iconOrder} className={className}>
              {SummaryComponent ? (
                <SummaryComponent children={title} />
              ) : (
                <NakedA
                  style={{ letterSpacing: '.64px', lineHeight: '25px' }}
                  fontSize={18}
                  to={slug}
                  contentType="ContentfulPracticeArea"
                >
                  {title}
                </NakedA>
              )}
            </StyledSummary>
            <Icon
              onClick={() => setOpen(!isOpen)}
              className={`${iconClassName}-${slug}`}
              iconOrder={iconOrder}
              glyph={isOpen ? collapseGlyph : expandGlyph}
              isOpen={isOpen}
              width={20}
              height={25}
              fill={iconFill}
              style={{ paddingTop: '8px', cursor: 'pointer' }}
            />
          </AccordionSummary>

          {divider && <Divider />}

          <AccordionContent
            style={{
              opacity,
              height: isOpen && previous === isOpen ? 'auto' : height,
            }}
          >
            <a.div
              style={{ transform }}
              {...bind}
              children={
                DetailsComponent ? (
                  <DetailsComponent children={children} />
                ) : (
                  children
                )
              }
            />
          </AccordionContent>
        </AccordionPanel>
      )
    }

    const [to, href] = getSlugOrHref(linkAccordion)

    return (
      <AccordionPanel border={border} maxWidth={maxWidth} role="accordionpanel">
        {linkAccordion ? (
          <NakedA href={href} to={to}>
            <AccordionSummary
              aria-controls={`${id}-content`}
              id={`${id}-header`}
              iconOrder={iconOrder}
              fluid={fluid}
            >
              <StyledSummary iconOrder={iconOrder}>
                {<SummaryComponent children={summary} />}
              </StyledSummary>
            </AccordionSummary>
          </NakedA>
        ) : (
          <AccordionSummary
            aria-controls={`${id}-content`}
            onClick={() =>
              singleAccordion ? activateAccordion(id) : setOpen(!isOpen)
            }
            id={`${id}-header`}
            active={isOpen && isList}
            iconOrder={iconOrder}
            iconXl={iconXl}
            fluid={fluid}
            maxWidth={maxWidth}
            data-testid="accordionpanel"
          >
            <StyledSummary iconOrder={iconOrder}>
              {SummaryComponent ? (
                <SummaryComponent children={summary} />
              ) : (
                summary
              )}
            </StyledSummary>

            <StyledIcon
              className={iconClassName}
              iconOrder={iconOrder}
              glyph={isOpen ? collapseGlyph : expandGlyph}
              iconXl={iconXl}
              isOpen={isOpen}
              maxWidth={maxWidth}
              fill={iconFill}
            />
          </AccordionSummary>
        )}
        {divider && <Divider fitToWidth />}

        <AccordionContent
          role="accordioncontent"
          isOpen={isOpen}
          contentMargin={contentMargin}
          style={{
            opacity,
            height: isOpen && previous === isOpen ? 'auto' : height,
          }}
          data-testid="accordioncontent"
        >
          <a.div
            style={{ transform }}
            {...bind}
            children={
              DetailsComponent ? (
                <DetailsComponent children={children} theme={theme} />
              ) : (
                children
              )
            }
          />
        </AccordionContent>
      </AccordionPanel>
    )
  }
)

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  summary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object,
  ]),
  id: PropTypes.string,
  defaultOpen: PropTypes.bool,
  isList: PropTypes.bool,
}

export default Accordion
