/* eslint-disable camelcase */
import { useState, useEffect } from 'react'
import { getVimeoSrc, getYoutubeEmbed, getYoutubeId } from '@utils'
import defaultThumbnail from '@assets/images/PH_Logo_BLACK.jpg'
import axios from 'axios'

export const useVideoSrc = (url, image) => {
  const [videoSrc, setVideoSrc] = useState({
    videoSrc: url,
    thumbnail: defaultThumbnail,
  })
  useEffect(() => {
    const source = axios.CancelToken.source()
    if (url?.includes('vimeo')) {
      axios
        .get(`https://vimeo.com/api/oembed.json?url=${url}`, {
          cancelToken: source.token,
        })
        .then(res => {
          setVideoSrc({
            videoSrc: getVimeoSrc(res.data),
            thumbnail: res.data.thumbnail_url,
          })
        })
        .catch(err => err)
    }

    if (url?.includes('youtube')) {
      setVideoSrc({
        videoSrc: getYoutubeEmbed(url),
        thumbnail: `https://img.youtube.com/vi/${getYoutubeId(url)}/0.jpg`,
      })
    }

    if (url?.includes('brightcove')) {
      setVideoSrc({ videoSrc: url, thumbnail: image || defaultThumbnail })
    }

    if (url?.includes('soundcloud')) {
      axios
        .get(`https://soundcloud.com/oembed?format=json&url=${url}`, {
          cancelToken: source.token,
        })
        .then(res => {
          setVideoSrc({
            videoSrc: res?.data?.html?.split(`src=`)[1].split('"')[1],
            thumbnail: res?.data?.thumbnail_url,
          })
        })
        .catch(err => err)
    }

    return () => {
      source.cancel()
    }
  }, [url, image])

  return videoSrc
}
