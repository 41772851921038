import React from 'react'
import { BodyCopy, StyledInlineA } from '../style'
import { useLocale } from '@hooks'
import { featureFlags, isFeatureEnabled, variants } from '@featureflags'

export const EmbeddedEntryHyperLink = ({
  slug,
  __typename,
  name,
  children,
  category,
  displayOnSites,
  locale,
}) => {
  const { displayLocale } = useLocale()

  const notInLocale = !displayOnSites?.includes(displayLocale)

  const contentType =
    !isFeatureEnabled(
      featureFlags.NEW_PRACTICE_AREA_DETAIL_PAGE,
      variants.PRACTICE_AREA_SLUG_CHANGE
    ) &&
    __typename === 'ContentfulPracticeArea' &&
    locale === 'en-US'
      ? 'new-practice-area'
      : __typename

  if (notInLocale || !slug)
    return <BodyCopy color="#B4A06E">{children}</BodyCopy>

  if (
    __typename === 'professional' ||
    __typename === 'ContentfulProfessional'
  ) {
    return (
      <StyledInlineA
        noFlex
        to={slug}
        contentType={contentType}
        category={category?.slug}
      >
        {name}
      </StyledInlineA>
    )
  }

  return (
    <StyledInlineA
      noFlex
      to={slug}
      contentType={contentType}
      category={category?.slug}
    >
      {children}
    </StyledInlineA>
  )
}
