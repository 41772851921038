import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledDisclaimerContainer,
  StyledDisclaimerDivContainer,
  StyledDisclaimer,
} from './style'
import { generateRandomKey } from '@utils'

const Disclaimer = ({ disclaimer, children, useDiv }) => {
  if (!Array.isArray(disclaimer)) {
    return useDiv ? (
      <StyledDisclaimerDivContainer>
        <StyledDisclaimer>{disclaimer?.content}</StyledDisclaimer>
      </StyledDisclaimerDivContainer>
    ) : (
      <StyledDisclaimerContainer mobilePadding>
        <StyledDisclaimer>{children}</StyledDisclaimer>
      </StyledDisclaimerContainer>
    )
  }
  return (
    <StyledDisclaimerContainer mobilePadding>
      {children &&
        children.map((child, i) => (
          <StyledDisclaimer key={generateRandomKey()}>{child}</StyledDisclaimer>
        ))}
    </StyledDisclaimerContainer>
  )
}

Disclaimer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Disclaimer
