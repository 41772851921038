import styled from 'styled-components'
import { colors } from '@theme'

export const SvgWrapper = styled.div`
  display: inline-block;
  // transition: fill 0.25s;
  flex: 0 0 ${props => (props.size ? `${props.size}px` : '28px')};
  width: ${({ width }) => (width ? `${width}px` : '28px')};
  height: ${({ height }) => (height ? `${height}px` : '28px')};
  min-width: ${({ width }) => (width ? `${width}px` : '28px')};
  min-height: ${({ height }) => (height ? `${height}px` : '28px')};
  position: relative;
  color: inherit;
  margin-top: ${({ marginTop }) => marginTop || '0'};
  cursor: ${({ pointer }) => pointer && 'pointer'};
`

export const InlineSvg = styled.svg`
  position: absolute;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: inherit;
  fill: ${({ fill, active }) => active || colors[fill] || 'currentColor'};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  ${({ pointer }) => pointer && 'cursor: pointer'};
`
export const GoldInlineSvg = styled(InlineSvg)`
  position: absolute;
  top: ${({ goldSocial }) => (goldSocial === 'facebook' ? '-3px' : '0')};
  width: ${({ goldSocial }) => (goldSocial === 'facebook' ? '35px' : '100%')};
  height: ${({ goldSocial }) => (goldSocial === 'facebook' ? '35px' : '100%')};
`
export const GoldA = styled.a`
  width: ${({ glyph }) =>
    glyph === 'vcard' || glyph === 'blog' ? 'fit-content' : '26px'};
  height: 20px;
  margin-right: 15px;
  text-align: ${({ glyph }) => glyph === 'vcard' && 'center'};
  cursor: pointer;
`
