import { isPreviewPage } from '@utils'
import React from 'react'
import HyperlinkCDAContainer from './HyperlinkCDAContainer'
import HyperlinkCPAContainer from './HyperlinkCPAContainer'

const HyperlinkContainer = props => {
  return isPreviewPage() ? (
    <HyperlinkCPAContainer {...props} />
  ) : (
    <HyperlinkCDAContainer {...props} />
  )
}

export default HyperlinkContainer
