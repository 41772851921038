import styled from 'styled-components'
import { colors, sizes } from '@theme'
import { zIndex, Col, H3 } from '../Globals'
import { FixedSizeList } from 'react-window'

export const StyledOl = styled.ol``
export const StyledList = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.direction === 'landscape' ? 'row' : 'column'};
  align-items: flex-start;
  width: ${({ width }) => width || '50%'};
`

export const StyledListCol = styled.ul`
  display: inline-flex;
  width: 100%;
  padding: 0px;
  margin: 0px;
  flex-direction: ${props =>
    props.direction === 'landscape' ? 'row' : 'column'};
  height: ${props => (props.direction === 'landscape' ? '100%' : 'auto')};
`

export const StyledListGrid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  counter-reset: item;
  list-style: none;
  margin: 0px auto;
  padding: 0px;
  width: 100%;
`

export const ListGroup = styled(StyledListGrid)`
  margin: -10px;
  width: calc(100% + 20px);
`

export const StyledListGridItem = styled(Col)`
  padding: 25px 20px;

  @media (min-width: ${sizes.medium.min}) {
    padding: 30px 20px;
  }
  @media (min-width: ${sizes.large.min}) {
    padding: 40px;
  }
  @media (min-width: ${sizes.xlarge.min}) {
    padding: 50px 70px;
  }
`

export const BorderStyledGridItem = styled(Col)`
  border: 1px solid #dde0e3;
  padding: 25px 20px;
  margin: 0 0 25px;
  @media (min-width: ${sizes.medium.min}) {
    margin: 0 25px 25px 0;
  }
`

const rowGrid = colSize => {
  if (!colSize) return
  const gridSize = 12 / colSize
  return gridSize
}
export const StyledFullGridItem = styled(StyledListGridItem)`
  border-left: ${({ gridIndex }) => gridIndex >= 0 && '1px solid #DDE0E3'};
  border-top: ${({ gridIndex, sm }) =>
    gridIndex < rowGrid(sm) ? '1px solid #DDE0E3' : '0px'};

  @media screen and (min-width: ${sizes.small.min}) {
    border-right: ${({ gridIndex }) =>
      gridIndex % 2 !== 0 && '1px solid #DDE0E3'};
    border-left: ${({ gridIndex }) =>
      gridIndex >= 0 ? '1px solid #DDE0E3' : '0px'};
    border-top: ${({ gridIndex }) =>
      gridIndex === 0 ? '1px solid #DDE0E3' : '0px'};
  }
  @media screen and (min-width: ${sizes.medium.min}) {
    border-right: ${({ gridIndex }) =>
      gridIndex % 2 !== 0 && '1px solid #DDE0E3'};
    border-left: ${({ gridIndex }) =>
      gridIndex % 2 === 0 ? '1px solid #DDE0E3' : '0px'};
    border-top: ${({ gridIndex, md }) =>
      gridIndex < rowGrid(md) ? '1px solid #DDE0E3' : '0px'};
  }
  @media screen and (min-width: ${sizes.large.min}) {
    border-top: ${({ gridIndex, lg }) =>
      gridIndex < rowGrid(lg) ? '1px solid #DDE0E3' : '0px'};
  }
  @media screen and (min-width: ${sizes.large.max}) {
    border-top: ${({ gridIndex, xl }) =>
      gridIndex < rowGrid(xl) ? '1px solid #DDE0E3' : '0px'};
  }
`

export const StyledFullGridItem3x3 = styled(StyledFullGridItem)`
  @media screen and (max-width: ${sizes.medium.max}) {
    border-left: 0px;
    border-top: 0px;
  }

  @media screen and (max-width: ${sizes.xsmall.max}) {
    border-top: ${({ gridIndex }) =>
      gridIndex === 0 ? '1px solid #DDE0E3' : '0px'};
  }
`

export const TimelineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 24px;
`

export const Line = styled.span`
  position: absolute;
  height: 2px;
  background: ${colors.bg.border};
  top: 50%;
  left: 24px;
  right: 24px;
  transform: translateY(-50%);
  z-index: ${zIndex.base};
`

export const Dot = styled.div`
  width: 32px;
  height: 32px;
  font-size: 16px;
  color: ${props => (props.active ? colors.brand.default : colors.text.alt)};
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 2px solid
    ${props => (props.active ? colors.brand.default : colors.bg.border)};
  box-shadow: 0 0 0 4px #fff;
  font-weight: 700;
  z-index: ${zIndex.base + 1};
  position: relative;
`

export const DotListItem = styled.li``
export const StyledIconUl = styled.ul`
  display: block;
  position: relative;
  list-style: none;
  margin: 0 0 40px;
  padding-left: 0;
`
export const StyledUl = styled.ul`
  display: block;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
`

export const StyledUlBulleted = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-bottom: 0px;
  padding-left: 20px;

  & li {
    list-style-type: default;
  }
`
export const Li = styled.li`
  display: block;
  font-size: 18px;
  line-height: 28px;
  margin: 0px;
  padding: 11px 0px 11px 0px;
  position: relative;

  &img {
    display: block;
    position: absolute;
    left: 0px;
    top: 10px;
    width: auto;
    height: auto;
    max-width: 32px;
    max-height: 32px;
    z-index: 0;
  }
`
export const IconLi = styled.li`
  display: block;
  font-size: 18px;
  line-height: 28px;
  margin: 0px;
  padding: 11px 0px 11px 46px;
  position: relative;

  & div {
    display: block;
    position: absolute;
    left: 0px;
    top: 10px;
    width: auto;
    height: auto;
    max-width: 32px;
    max-height: 32px;
    z-index: 0;
  }
`

export const DotUl = styled(StyledUl)`
  & li {
    margin: 30px 0px;
    padding: 12px 0px 12px 16px;
    display: block;
    position: relative;
  }
`
export const DotList = styled.div`
  display: block;
  margin: 50px 0px;
  width: 100%;
`

export const StyledInfiniteList = styled(FixedSizeList)``

export const Tag = styled(H3)`
  font-size: 14px;
  line-height: 18px;
  color: ${({ titleColor }) => (titleColor ? colors[titleColor] : '#b4a06e')};
  text-transform: uppercase;
  letter-spacing: 1.71px;
  display: block;
  padding-bottom: 10px;
`

export const DotListTextContainer = styled.div`
  position: relative;
  display: inline-block;
  border-left: 1.28px solid #b4b8be;
`
