// From lodash:
// https://github.com/lodash/lodash/blob/750067f42d3aa5f927604ece2c6df0ff2b2e9d72/findKey.js
exports.findKey = (object, predicate) => {
  let result
  if (object === null) {
    return result
  }
  Object.keys(object).some(key => {
    const value = object[key]
    if (predicate(value, key, object)) {
      result = key
      return true
    }
    return false
  })
  return result
}
exports.hasTabbedRoutes = contentfulModules =>
  contentfulmodules?.filter(m => m['__typename'] === 'ContentfulModuleTabs')
    .length > 0

exports.isPage = (targetId, contentfulPageId) => contentfulPageId === targetId

exports.removeTrailingSlash = path =>
  path === `/` ? path : path.replace(/\/$/, ``)

exports.localizedSlug = ({ isDefault, locale, slug }) =>
  isDefault ? `/${slug}` : `/${locale}/${slug}`
// speedy logging function to print progress to terminal
exports.log = (msg, section) =>
  console.info(`\n\x1b[36m${msg} \x1b[4m${section}\x1b[0m\x1b[0m\n`)

exports.buildPaginatedPath = (index, pathPrefix) => {
  return index > 1 ? `${pathPrefix}/page/${index}` : `/${pathPrefix}`
}

exports.findParentPath = (page, nodeLocale) =>
  page.nodes.find(p => p.node_locale === nodeLocale).slug

exports.renderParentPathIfCategory = (parentPath, parentCategory) =>
  parentCategory ? `${parentPath}/${parentCategory}` : `${parentPath}`

exports.renderLocalizedSlug = (
  slug,
  nodeLocale,
  parentPath,
  parentCategory
) => {
  const defaultLocale = 'en-US'
  const homePageSlug = 'home'
  if (slug?.[0] === '/') slug = slug.slice(1)
  slug = slug === homePageSlug ? '' : slug

  if (parentPath) {
    const parentPathAndCategory = parentCategory
      ? `${parentPath}/${parentCategory}`
      : `${parentPath}`

    slug = parentPathAndCategory ? `${parentPathAndCategory}/${slug}` : slug
  }

  const localeIsDefaultLocale = nodeLocale === defaultLocale
  slug = slug?.toLowerCase()
  const localizedSlug = localeIsDefaultLocale
    ? `/${slug}`
    : `/${nodeLocale}/${slug}`

  return localizedSlug
}

exports.renderLocaleJSONKey = nodeLocale => {
  switch (nodeLocale) {
    case 'en-US':
      return 'en'
    case 'en-GB':
      return 'enGB'
    default:
      return nodeLocale
  }
}

exports.defaultAuthors = [
  {
    name: 'Paul Hastings Professional',
    id: '123',
    firstName: 'Paul Hastings',
    lastName: 'Professional',
  },
]

exports.displayOnSitesToNodeLocale = {
  'American English': 'en-US',
  'British English': 'en-GB',
  Korean: 'ko',
  Japanese: 'ja',
  French: 'fr',
  Chinese: 'zh',
  German: 'de',
}
exports.nodeLocaleToEnglishLanguage = {
  'en-US': 'American English',
  'en-GB': 'British English',
  ko: 'Korean',
  ja: 'Japanese',
  fr: 'French',
  zh: 'Chinese',
  de: 'German',
}

exports.translateAllToNodeLocale = {
  'en-US': 'All',
  'en-GB': 'All',
  ko: '모두',
  ja: 'すべて',
  fr: 'Tout',
  zh: '一切',
  de: 'Alles',
}

exports.shouldNotRenderLocalizedPageBasedOnCms = (arr, localeName) =>
  arr.includes(localeName) === false

exports.filterSampleEntries = (entries, useNodes = false) => {
  const regex = new RegExp(/^\[sample\]/i)
  if (!entries) return entries
  if (!useNodes)
    return entries.filter(
      ({ node }) => node && node.title && !node.title.match(regex)
    )
  return entries.filter(({ title }) => title && !title.match(regex))
}

exports.addNodeToArrOfObjects = arr => arr.map(obj => ({ node: { ...obj } }))

exports.getOfficeSlugsByLocale = locale => {
  switch (locale) {
    case 'zh':
      return ['beijing', 'shanghai', 'hong-kong']
    case 'de':
      return ['frankfurt']
    case 'ja':
      return ['tokyo']
    case 'ko':
      return ['seoul']
    case 'fr':
      return ['paris', 'brussels']
    case 'en-GB':
      return ['london']
    case 'en-US':
    default:
      return [
        'san-francisco',
        'century-city',
        'new-york',
        'houston',
        'washington-dc',
        'palo-alto',
        'los-angeles',
        'chicago',
        'san-diego',
        'atlanta',
        'orange-county',
        'sao-paulo',
      ]
  }
}
