import React from 'react'
import {
  StyledH2,
  StyledDescription,
  StyledButton,
  StyledCTAText,
} from './style'
import A from '@components/A'

const FeaturedSection = ({ featuredSection }) => {
  return (
    <div
      className={`box-border flex flex-col max-w-[165px] w-full h-full pr-5 ${
        featuredSection
          ? `border-r border-t-0 border-l-0 border-b-0 border-solid border-ph-border`
          : null
      }`}
    >
      {featuredSection && (
        <>
          {featuredSection?.title && (
            <StyledH2>{featuredSection?.title}</StyledH2>
          )}
          {featuredSection?.description && (
            <StyledDescription>
              {featuredSection?.description?.description}
            </StyledDescription>
          )}
          {(featuredSection?.ctaLink?.slug ||
            featuredSection?.ctaLink?.url) && (
            <A
              to={featuredSection?.ctaLink?.slug}
              contentType={featuredSection?.ctaLnk?.__typename}
              href={featuredSection?.ctaLink?.url}
            >
              <StyledButton>
                <StyledCTAText>{featuredSection?.ctaText}</StyledCTAText>
              </StyledButton>
            </A>
          )}
        </>
      )}
    </div>
  )
}

FeaturedSection.propTypes = {}

export default FeaturedSection
