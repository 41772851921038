// Only one item MUST have the "default: true" key

module.exports = {
  'en-US': {
    default: true,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Paul Hastings LLP`,
    defaultDescription: `Paul Hastings is a leading international law firm that provides innovative legal solutions to many of the world's top financial institutions and Fortune Global 500 companies.`,
  },
  de: {
    path: `de`,
    locale: `de`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `de`,
    ogLanguage: `de_DE`,
    defaultTitle: `Paul Hastings LLP`,
    defaultDescription: `Paul Hastings ist eine führende internationale Anwaltskanzlei, die innovative Rechtslösungen für viele der weltweit führenden Finanzinstitute und Fortune Global 500-Unternehmen anbietet.`,
  },
  zh: {
    path: `zh`,
    locale: `zh`,
    dateFormat: `YYYY.MM.DD`,
    siteLanguage: `zh`,
    ogLanguage: `zh`,
    defaultTitle: `Paul Hastings 主页`,
    defaultDescription: `保罗·黑斯廷斯（Paul Hastings）是一家领先的国际律师事务所，为许多世界顶级金融机构和《财富》全球500强公司提供创新的法律解决方案。`,
  },
  fr: {
    path: `fr`,
    locale: `fr`,
    dateFormat: `DD-MM-YYYY`,
    siteLanguage: `fr`,
    ogLanguage: `fr`,
    defaultTitle: `Paul Hastings LLP Accueil`,
    defaultDescription: `Paul Hastings est un cabinet d’avocats international de premier plan qui propose des solutions juridiques innovantes à de nombreuses grandes institutions financières et sociétés du classement Fortune Global 500.`,
  },
  ja: {
    path: `ja`,
    locale: `ja`,
    dateFormat: `YYYY.MM.DD`,
    siteLanguage: `ja`,
    ogLanguage: `ja`,
    defaultTitle: `Paul Hastings LLP`,
    defaultDescription: `ポール・ヘイスティングスは、世界の大手金融機関およびフォーチュン・グローバル500企業の多くに革新的な法律ソリューションを提供する国際的な大手法律事務所です。`,
  },
  ko: {
    path: `ko`,
    locale: `ko`,
    dateFormat: `YYYY.MM.DD`,
    siteLanguage: `ko`,
    ogLanguage: `ko`,
    defaultTitle: `Paul Hastings LLP`,
    defaultDescription: `Paul Hastings는 세계 최고의 금융 기관 및 Fortune Global 500 대 기업에 혁신적인 법률 솔루션을 제공하는 선도적 인 국제 법률 회사입니다.`,
  },
  'en-GB': {
    path: `en-GB`,
    locale: `en-GB`,
    dateFormat: `DD MMMM YYYY`,
    siteLanguage: `en-GB`,
    ogLanguage: `en-GB`,
    defaultTitle: `Paul Hastings LLP`,
    defaultDescription: `Paul Hastings is a leading international law firm that provides innovative legal solutions to many of the world's top financial institutions and Fortune Global 500 companies.`,
  },
}
