import React from 'react'
import { StyledModalImageDiv, StyledThumbnail } from '../style'
import VideoModal from './VideoModal'

const ToggledModal = ({
  toggle,
  align,
  margin,
  wrapText,
  thumbnail,
  handleModal,
  videoSrc,
}) => {
  return (
    <StyledModalImageDiv
      onMouseUp={handleModal}
      align={align}
      wrapText={wrapText}
      margin={margin}
      data-testid="toggled"
    >
      <VideoModal
        toggle={toggle}
        url={videoSrc}
        aria-labelledby="video-modal"
      />
      <StyledThumbnail onMouseUp={handleModal} src={thumbnail} />
    </StyledModalImageDiv>
  )
}

export default ToggledModal
