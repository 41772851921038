import { useState, useEffect } from 'react'
import { partitionList } from '@utils'

const useMasonryGrid = ({ items, refs, initOrder, initialGroups, colRefs }) => {
  const [cardList, setCardList] = useState(initialGroups)

  useEffect(() => {
    setTimeout(() => {
      const createBlock = {}
      const createColBlock = []
      if (refs?.current) {
        refs.current.forEach(r => {
          if (r) {
            createBlock[r.id] = {
              height: r?.getBoundingClientRect().height,
              element: r,
              id: r.id,
            }
          }
        })
      }

      if (colRefs?.current) {
        colRefs.current.forEach(r => {
          if (r) {
            createColBlock.push({
              height: r?.getBoundingClientRect().height,
              element: r,
              id: r.id,
            })
          }
        })
      }
      const bottomEntries =
        initOrder?.length > 3 && initOrder.slice(initOrder.length - 3)
      const restOfEntries =
        bottomEntries && initOrder.slice(0, initOrder.length - 3)
      let newOrderList = restOfEntries

      if (bottomEntries) {
        bottomEntries.forEach((b, i) => {
          createColBlock[i] = {
            ...createColBlock[i],
            height: createColBlock[i]?.height - createBlock[b]?.height,
          }
        })

        const bottomList = createColBlock?.map(m => [])

        bottomEntries.forEach(b => {
          let lowestSumIndex = 0

          createColBlock.forEach((c, i) => {
            const minHeight =
              createColBlock[lowestSumIndex]?.height + createBlock[b]?.height
            if (c.height + createBlock[b]?.height < minHeight) {
              lowestSumIndex = i
            }
          })

          createColBlock[lowestSumIndex] = {
            ...createColBlock[lowestSumIndex],
            height:
              createColBlock[lowestSumIndex]?.height + createBlock[b]?.height,
          }
          bottomList[lowestSumIndex].push(b)
        })

        const firstThird = partitionList(restOfEntries)
        newOrderList = firstThird?.map((f, i) => f.concat(bottomList[i]))
      }

      const newGridList =
        (!restOfEntries && initialGroups) ||
        newOrderList?.map(o => {
          const groups = o?.map(groupItem =>
            items.find(item => item.id === groupItem)
          )
          return groups
        })

      setCardList(newGridList)
    }, 500)
  }, [])

  return { cardList }
}

export default useMasonryGrid
export { useMasonryGrid }
