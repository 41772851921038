import moment from 'moment-timezone'
import dayjs from 'dayjs'

export const timezone2tzid = {
  '(-11) Niue': 'Pacific/Niue',
  '(-8) Los Angeles': 'US/Pacific',
  '(-7) Denver': 'US/Mountain',
  '(-6) Chicago': 'US/Central',
  '(-5) New York': 'US/Eastern',
  '(-3) Buenos Aires': 'America/Buenos_Aires',
  '(-2) South Georgia': 'Atlantic/South_Georgia',
  '(+0) London': 'Europe/London',
  '(+1) Berlin': 'Europe/Berlin',
  '(+2) Tripoli': 'Africa/Tripoli',
  '(+8) Macau': 'Asia/Macau',
  '(+9) Tokyo': 'Asia/Tokyo',
}

const utcTimezoneAbbr = {
  'America/Buenos_Aires': 'ART',
  'Pacific/Niue': 'NUT',
  'Atlantic/South_Georgia': 'GST',
}

const convert24to12display = timeStamp => {
  timeStamp = timeStamp.split('T')[1].slice(0, 5)

  let [hrs, mins] = timeStamp.split(':')
  let suffix = hrs >= 12 ? 'PM' : 'AM'

  if (hrs > 12) hrs = (Number(hrs) - 12).toString()
  if (hrs === '00') hrs = '12'

  return `${hrs}:${mins} ${suffix}`
}

export const getTime = (timeStamp, timeZone) => {
  const time = convert24to12display(timeStamp)
  const zone = timeZone && timeZone.substr(timeZone.indexOf(' ') + 1)
  return `${time} ${zone}`
}

export const getTimeWithTimezone = (timeStamp, timeZone) => {
  const time = convert24to12display(timeStamp)
  const tzid = timezone2tzid?.[timeZone]

  if (!tzid) return time

  // some timezone abbreviates to UTC offset because that's the world IANA standard used by that country, manually attach their timezone
  const tzidWithConstructedAbbr = utcTimezoneAbbr?.[tzid]

  const zone = moment()
    ?.tz(tzid)
    ?.zoneName()

  if (!zone && !tzidWithConstructedAbbr) return time

  return `${time} ${tzidWithConstructedAbbr || zone}`
}

export const timestampToTooltip = timestamp => {
  const dateArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  // const date = new Date(timestamp)
  let date = timestamp.slice(0, 10)
  const arr = date.split('-')
  const month = parseInt(arr[1], 10) - 1
  const day = arr[2]
  // date = new Date(year, month, newDate);
  // const day = date.getDate()
  // const month = date.getMonth()
  return `${dateArray[month]} ${day}`
}

export const getDay = timestamp => {
  const date = moment(timestamp)
  const day = date.day()
  const week = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  return week[day]
}

export const timeString = (date, year) => {
  if (date && year) {
    return `${date}, ${year}`
  } else {
    return ''
  }
}

export const createDate = date => {
  const parseDate = date && timestampToTooltip(date)
  const parseYear =
    date &&
    date
      .split('')
      .slice(0, 4)
      .join('')
  return date && timeString(parseDate, parseYear)
}

// default endTime to startTime + 1hr if null
const getEventEnd = (eventEnd, eventStart) => {
  return eventEnd
    ? moment(nonZuluTime(eventEnd)).format('YYYYMMDDTHHmmss')
    : moment(nonZuluTime(eventStart))
        .add(1, 'h')
        .format('YYYYMMDDTHHmmss')
}

export const createContact = pros => {
  let description = ''
  pros.forEach(p => {
    if (p.active) {
      description += `${p.name || ''}\\n${p.email || ''}\\n${p.phone ||
        ''}\\n\\n`
    }
  })
  return description
}

const nonZuluTime = time => {
  if (!time) return null
  const checkForZuluTime = time?.[time.length - 1] === 'Z'
  return checkForZuluTime ? time.slice(0, time.length - 1) : time
}

export function createEvent({
  eventTimes,
  title,
  associatedProfessionals,
  eventStart,
  eventEnd,
  street,
  city,
  state,
  location,
  timeZone,
}) {
  if (!eventTimes?.length && !eventStart && !eventEnd) return null

  const allEventTimes = eventTimes?.length
    ? eventTimes
    : [{ eventStart, eventEnd, timeZone }]

  let icsString = `BEGIN:VCALENDAR\nVERSION:2.0\n`
  const associatedProfString = associatedProfessionals?.length
    ? `DESCRIPTION:${createContact(associatedProfessionals)}\n`
    : ''
  const locationString =
    street && city && state
      ? `LOCATION:${street}\\, ${city}\\, ${state}\n`
      : `LOCATION:${location || ''}\n`

  allEventTimes.map(({ eventStart, eventEnd, timeZone }, i) => {
    const tzid = timeZone && timezone2tzid[timeZone]
    const startTime = moment(nonZuluTime(eventStart)).format('YYYYMMDDTHHmmss')
    const endTime = getEventEnd(eventEnd, eventStart)
    const singleEventIcs = `BEGIN:VEVENT\nDTSTART;TZID=${tzid}:${startTime}\nDTEND;TZID=${tzid}:${endTime}\nSUMMARY:${title}\n${associatedProfString}${locationString}PRIORITY:3\nEND:VEVENT\n`
    icsString = icsString.concat(singleEventIcs)
  })

  icsString = icsString.concat('END:VCALENDAR')

  return icsString
}

export const intToMonth = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
}

export const eventDuration = (eventStart, eventEnd) => {
  if (!eventStart) return ''

  const currentYear = dayjs().year()
  const sanitizedEventStart = dayjs(eventStart)
  const sanitizedEventEnd = dayjs(eventEnd)
  const eventStartMonth = sanitizedEventStart?.month()
  const eventEndMonth = sanitizedEventEnd?.month()
  const eventStartDay = sanitizedEventStart?.date()
  const eventEndDay = sanitizedEventEnd?.date()
  const eventStartYear = sanitizedEventStart?.year()
  const eventEndYear = sanitizedEventEnd?.year()

  if (!eventEnd && eventStartYear !== currentYear)
    return `${intToMonth[eventStartMonth]} ${eventStartDay}, ${eventStartYear}`
  else if (!eventEnd) return `${intToMonth[eventStartMonth]} ${eventStartDay}`

  if (eventStartYear === eventEndYear && eventStartMonth === eventEndMonth)
    return `${intToMonth[eventStartMonth]} ${
      eventStartDay === eventEndDay
        ? `${eventStartDay}`
        : `${eventStartDay} - ${eventEndDay}`
    }`
  else if (eventStartYear === eventEndYear && eventStartYear === currentYear)
    return `${intToMonth[eventStartMonth]} ${eventStartDay} - ${intToMonth[eventEndMonth]} ${eventEndDay}`
  else
    return `${intToMonth[eventStartMonth]} ${eventStartDay}, ${eventStartYear} - ${intToMonth[eventEndMonth]} ${eventEndDay}, ${eventEndYear}`
}

export const formatDate = (utc, format = 'MMMM DD, YYYY') =>
  moment.utc(utc).format(format)

export const handleEventTime = ({ eventStart, eventEnd, timeZone }) => {
  let eventStartTime = null
  if (eventStart) {
    eventStartTime = getTimeWithTimezone(eventStart.slice(eventStart), timeZone)
  }

  let eventEndTime = null
  let getEndWeekDay = null
  let endWeekDay = null
  let getEndMonth = null

  if (eventEnd) {
    eventEndTime = getTimeWithTimezone(eventEnd.slice(eventEnd), timeZone)
    getEndWeekDay = eventEnd
      .split('')
      .slice(0, 10)
      .join('')
    endWeekDay = getDay(getEndWeekDay)
    getEndMonth = timestampToTooltip(eventEnd)
  }

  // parsing for year
  const eventYear =
    eventStart &&
    eventStart
      .split('')
      .slice(0, 4)
      .join('')

  const eventEndYear =
    eventEnd &&
    eventEnd
      .split('')
      .slice(0, 4)
      .join('')

  // formatting the correct day of the week
  const getStartWeekDay =
    eventStart &&
    eventStart
      .split('')
      .slice(0, 10)
      .join('')

  const weekDay = getStartWeekDay && getDay(getStartWeekDay)

  // provides you with month and day
  const getStartMonth = eventStart && timestampToTooltip(eventStart)

  const isSameDay =
    weekDay === endWeekDay &&
    getStartMonth === getEndMonth &&
    eventYear === eventEndYear

  // formulating correct date for events.
  const timeString = () => {
    if (isSameDay) {
      return `${weekDay}, ${getStartMonth} ${eventStartTime} to ${eventEndTime}`
    } else if (weekDay && endWeekDay) {
      return `${weekDay}, ${getStartMonth}, ${eventYear} - ${endWeekDay}, ${getEndMonth}, ${eventEndYear}`
    } else if (weekDay) {
      return `${weekDay}, ${getStartMonth}, ${eventYear} - N/A`
    } else {
      return ''
    }
  }

  return {
    eventString: timeString(),
    timeDurationString: !isSameDay && `${eventStartTime} - ${eventEndTime}`,
  }
}

export const handleEventTimes = eventTimes => {
  if (!eventTimes?.length) return []
  return eventTimes?.map(eventTime => handleEventTime(eventTime))
}
