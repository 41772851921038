import React from 'react'
import {
  // FooterNavCol,
  SocialList,
  SocialButton,
  SRASpacingContainer,
  SocialAndSRAContainer,
} from '../style'
import LinkedinIcon from '@assets/svg/LinkedInNew.svg'
import InstagramIcon from '@assets/svg/InstagramNew.svg'

const SRADigitalBadge = () => {
  // for more information re: uk requirements
  // https://www.yoshki.com/sra/
  // use full color english clickable logo

  return (
    <SRASpacingContainer>
      <div className="inner-sra-required">
        <iframe
          referrerPolicy="origin"
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
          src="https://cdn.yoshki.com/iframe/55845r.html"
        />
      </div>
    </SRASpacingContainer>
  )
}

const FooterSocial = ({ displaySRA = false }) => {
  return (
    <SocialAndSRAContainer>
      <SocialList>
        <SocialButton
          href="https://www.linkedin.com/company/paul-hastings/"
          rel="noreferrer"
          target="_blank"
        >
          <LinkedinIcon width="21" height="21" />
        </SocialButton>
        <SocialButton
          href="https://www.instagram.com/paulhastingslaw/"
          rel="noreferrer"
          target="_blank"
        >
          <InstagramIcon width="20" height="20" />
        </SocialButton>
      </SocialList>
      {displaySRA && <SRADigitalBadge />}
    </SocialAndSRAContainer>
  )
}

export default FooterSocial
