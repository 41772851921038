import { isPreviewPage } from '@utils'
import { featureFlags, isFeatureEnabled } from '@featureflags'

export const filterSampleEntries = (entries = []) => {
  if (isFeatureEnabled(featureFlags.SAMPLE_PAGES)) return entries

  const regex = new RegExp(/^\[sample\]/i)
  if (!entries) return entries
  if (isPreviewPage()) return entries
  return entries?.filter(({ title }) => title && !title?.match(regex))
}

export const getOptions = (entries, field, fieldKeyforOptionValue) => {
  const options = entries?.reduce((acc, curr) => {
    const currField = curr?.[field]

    if (!Array.isArray(currField)) {
      return !currField
        ? acc
        : [...new Set([...acc, currField?.[fieldKeyforOptionValue]])]
    }

    const flattenedOptions = currField?.reduce(
      (acc, curr) => [...acc, curr?.[fieldKeyforOptionValue]],
      []
    )
    return !flattenedOptions ? acc : [...new Set([...acc, ...flattenedOptions])]
  }, [])

  return options.sort()
}

export const filterAssociated = (data, displayLocale) => {
  return filterSampleEntries(data)?.filter(node =>
    node?.displayOnSites.includes(displayLocale)
  )
}
