import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '@assets/svg/usertools/info.svg'
import CompressIcon from '@assets/svg/usertools/compress.svg'
import { Div } from '@components/StyledTags'

const Toggler = ({ expanded, onClick = () => {} }) => {
  return (
    <Div
      display="flex"
      justifyContent="center"
      p="5px 6px"
      bg="purple"
      borderRadius="5px"
      onClick={onClick}
      cursor="pointer"
      title={expanded ? 'Collapse Info Tools' : 'Expand Info Tools'}
    >
      {expanded ? (
        <CompressIcon height="20px" width="20px" />
      ) : (
        <InfoIcon height="20px" width="20px" />
      )}
    </Div>
  )
}

Toggler.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Toggler
