import styled from 'styled-components'
import { sizes, colors } from '@theme'
import { H2, H3, BodyCopy, fontStack } from '../Globals'
import { NoFormattingA } from '../Button'

export const AddressLocationName = styled.div`
  ${fontStack};
  display: flex;
  margin: 0px 0px 15px;
  color: ${({ color }) => (color ? colors.text[color] : 'inherit')};
  text-transform: ${({ textNormalCase }) =>
    textNormalCase ? 'none' : 'uppercase'};
  font-size: ${({ locationNameSize }) => locationNameSize || '22px'};
  font-weight: bold;
  line-height: 24px;
  padding: 0;
  letter-spacing: ${({ offices }) => (offices ? '0px' : '1.3px')};

  &:hover {
    text-decoration: ${({ underlineHover }) =>
      underlineHover ? 'none' : 'underline'};
  }
`

export const Address = styled.address`
  font-style: normal;
  font-family: 'Helvetica Neue', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
`

export const AddressWrapper = styled.div`
  border-left: ${({ borderLeft }) =>
    borderLeft ? '1px solid #DDE0E3' : 'none'};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  background-color: ${({ theme }) =>
    theme === 'dark' ? colors['ph-blue'] : 'transparent'};
  color: ${({ theme }) => (theme === 'dark' ? 'white' : 'currentColor')};
  text-align: left;
  width: ${({ width }) => width || '100%'};
  padding: ${({ padding }) => padding || '0px 0px 28px 8px'};

  @media (max-width: 992px) {
    width: 100%;
  }
`

export const AddressFooter = styled.div`
  padding-top: 8px;
`

export const StyledTag = styled(H3)`
  font-size: ${({ homepage }) => (homepage ? '18px' : '12px')};
  line-height: ${({ homepage }) => (homepage ? 'normal' : '18px')};
  font-weight: 500;
  color: ${({ titleColor }) => (titleColor ? colors[titleColor] : '#b4a06e')};
  text-transform: uppercase;
  letter-spacing: ${({ homepage }) => (homepage ? '1.8px' : '1.71px')};
  display: block;
  margin-bottom: ${({ homepage }) => (homepage ? '24px' : '10px')};
`

export const StyledDate = styled(BodyCopy)`
  color: ${({ theme, color }) =>
    theme === 'dark' ? colors['gold'] : '#5F6062'};
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.4px;
`

export const StyledCard = styled(NoFormattingA)`
  color: ${({ theme }) => (theme === 'dark' ? colors['gold'] : '#5F6062')};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  width: 100%;
`
export const StyledTitle = styled(H2)`
  margin: ${({ titleMargin }) => titleMargin || '0px 0px 15px'};
  font-size: ${({ titleSize }) => titleSize || '22px'};
  color: ${({ titleColor }) => (titleColor ? colors[titleColor] : '#27251F')};
  text-decoration: none;
  font-weight: ${({ homepage }) => homepage && '700'};
  line-height: ${({ homepage }) => (homepage ? '24px' : '1.625rem')}
  letter-spacing: 0.42px;

  display: block;
  ${StyledCard}:hover & {
    color: ${({ titleColor }) =>
      titleColor ? colors[titleColor] : colors['gold75']};
    text-decoration: underline;
  }

  @media (max-width: 991px) {
    font-size: 20px;
    letter-spacing: 0.3px;
    line-height: 24px;
  }
`

export const StyledProfileCardGrid = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection ? 'column' : 'row')};
  width: 100%;
  ${({ height }) => height && `height: ${height}`};
  padding: ${({ padding }) => padding || '20px 0px;'};
  & a {
    text-decoration: none;
  }
`

export const StyledProfileCardGridMd = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 240px;
  padding: 20px 15px;
  height: 100%;
  @media (max-width: 575px) {
    max-width: 100%;
  }

  & a {
    text-decoration: none;
  }
  & img {
    height: 100%;
    margin: 0px 0px 25px;
    width: 100%;
  }
`

export const StyledProfileImage = styled.div`
  padding: 0px 15px 0px 0px;
  line-height: 16px;
  letter-spacing: 0.57px;
  margin: 0px 15px 10px 0px;
  @media (max-width: 764px) {
    margin: 0 0 10px;
  }
`

export const StyledProfileDetails = styled.div`
  font-style: normal;
  font-family: 'Helvetica Neue', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  line-height: 26px;
  padding: ${({ padding }) => padding || '0px;'};
  color: ${colors.default};

  @media (max-width: 576px) {
    margin: 0 auto;
  }
`

export const ProfileName = styled(NoFormattingA)`
  margin: 0px 0px 5px;
  text-transform: uppercase;
  display: block;
  font-size: ${({ fontSize }) => fontSize || '16px;'};
  line-height: 16px;
  font-weight: 800;
  letter-spacing: 1.5px;
  color: ${colors.gold};
  text-decoration: none;
  width: 100%;

  &:hover {
    color: ${colors.navy};
    cursor: pointer;
    text-decoration: underline;
  }
`

export const ProfileNameNoLink = styled.span`
  margin: 0px 0px 5px;
  text-transform: uppercase;
  display: block;
  font-size: ${({ fontSize }) => fontSize || '16px;'};
  line-height: 16px;
  font-weight: 800;
  letter-spacing: 1.5px;
  color: ${colors.gold};
  text-decoration: none;
  width: 100%;
`

export const RecruitingProfileName = styled(NoFormattingA)`
  display: block;
  font-size: 16px;
  letter-spacing: 0.57px;
  line-height: 24px;
  color: ${colors.gold};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const ContactLink = styled(NoFormattingA)`
  color: ${({ color }) => (color ? colors[color] : colors.gold)};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  word-break: break-all;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const StyledCategory = styled(BodyCopy)`
  color: ${colors.navy};
  font-weight: bold;
  letter-spaceing: 0.51px;
  font-size: 16px;
`

export const StyledImageContainer = styled.div`
  width: 100%;
  margin: 0 auto 25px;

  @media (max-width: 575px) {
    height: 508px;
  }

  @media (max-width: 415px) {
    height: 419.97px;
  }

  @media (max-width: 375px) {
    height: 376px;
  }

  @media (max-width: 360px) {
    height: 358px;
  }

  @media (max-width: 320px) {
    height: 311px;
  }

  @media (min-width: ${sizes.small.min}) {
    width: 220px;
    height: 263.22px;
    margin: 0 0 25px;
  }

  @media (min-width: ${sizes.medium.min}) {
    width: 180px;
    height: 215.36px;
  }

  @media (min-width: 1200px) {
    width: 230px;
    height: 275px;
  }

  & img {
    height: 100%;
    width: 100%;
  }
`

export const Container = styled.div`
  margin: 0 10px 20px 0;
`

export const Title = styled.p`
  margin: ${({ titleMargin }) => titleMargin || '0px 0px 15px'};
  font-size: ${({ titleSize }) => titleSize || '16px'};
  line-height: 26px;
  color: ${colors.gray};
  text-decoration: none;

  display: block;
  ${StyledCard}:hover & {
    color: ${({ titleColor }) =>
      titleColor ? colors[titleColor] : colors['gold75']};
    text-decoration: underline;
  }
`

export const StyledOffice = styled.p`
  color: ${colors.navy};
`
export const StyledOfficeWrapper = styled.div`
  width: fit-content;
  margin-bottom: 1rem;
`
