import React from 'react'
import theme from '@theme'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

// Providers that do not depend on current page props
const RootElementWrapper = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
    </ThemeProvider>
  )
}

export default RootElementWrapper
