/* eslint-disable camelcase */
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchCPAEntry } from '@utils'
import { CONTENTFUL_NAVIGATION_IDS, CONTENTFUL_PAGE_IDS } from '@constants'
import { debugFlags, debugWrapper } from '@featureflags'
import FooterLayout from '@components/Footer/FooterLayout'

const FooterCPAContainer = ({ node_locale: locale, ...props }) => {
  const { isLoading: isLoadingNav, error: errorNav, data: dataNav } = useQuery(
    ['Footer Navigation', locale],
    () =>
      fetchCPAEntry({
        id: CONTENTFUL_NAVIGATION_IDS.footer,
        contentType: 'navigation',
        locale,
        include: 4,
      })
  )

  const {
    isLoading: isLoadingGlobalPrivacy,
    error: errorGlobalPrivacy,
    data: dataGlobalPrivacy,
  } = useQuery(['Footer Global Privacy', locale], () =>
    fetchCPAEntry({
      id: CONTENTFUL_PAGE_IDS.globalPrivacy,
      contentType: 'page',
      locale,
      include: 2,
    })
  )

  const {
    isLoading: isLoadingTermsAndConditions,
    error: errorTermsAndConditions,
    data: dataTermsAndConditions,
  } = useQuery(['Footer Terms and Condition', locale], () =>
    fetchCPAEntry({
      id: CONTENTFUL_PAGE_IDS.termsAndConditions,
      contentType: 'page',
      locale,
      include: 1,
    })
  )

  const isLoading =
    isLoadingNav || isLoadingGlobalPrivacy || isLoadingTermsAndConditions
  const error = errorNav || errorGlobalPrivacy || errorTermsAndConditions
  const dataMessage =
    dataNav?.message ||
    dataGlobalPrivacy?.message ||
    dataTermsAndConditions?.message

  if (isLoading) return null
  if (error || dataMessage) {
    debugWrapper(debugFlags.NAVIGATION, () => {
      console.groupCollapsed('Footer Error')
      console.info(error || dataMessage)
      console.groupEnd()
    })
  }

  // TODO: handle sitemap

  const footerLinks = dataNav?.items?.[0]?.navItems
  const globalPrivacy = dataGlobalPrivacy?.items?.[0]
  const termsAndConditions = dataTermsAndConditions?.items?.[0]

  return (
    <FooterLayout
      {...props}
      items={footerLinks}
      globalPrivacy={globalPrivacy}
      termsAndConditions={termsAndConditions}
    />
  )
}

export default FooterCPAContainer
