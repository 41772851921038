import React from 'react'
import A from '@components/A'
import PropTypes from 'prop-types'

const NonPreviewLink = ({ originalSlug, subpageId, contentType, category }) => {
  return (
    <A
      to={originalSlug}
      contentType={contentType}
      category={category?.originalSlug}
      subpageId={subpageId}
    >
      View Built Page
    </A>
  )
}

NonPreviewLink.propTypes = {
  originalSlug: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  category: PropTypes.shape({
    originalSlug: PropTypes.string.isRequired,
  }),
}

export default NonPreviewLink
