import styled from 'styled-components'
import { animated } from 'react-spring'
import Icon from '../Icon'
import { colors } from '@theme'
import { Container } from '../Globals'

export const AccordionPanel = styled.div`
  position: relative;
  text-overflow: ellipsis;
  white-space: ${({ whiteSpace }) => (whiteSpace ? 'wrap' : 'nowrap')}
  overflow-x: hidden;
  vertical-align: middle;
  fill:${colors.gold};
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: none;
  border-width: 0 0 1px 0;
`

export const AccordionSummary = styled(Container)`
  vertical-align: middle;
  display: flex;
  transition: all 0.3s ease-out;
  font-weight: ${props => (props.active ? '700' : '400')};
  font-family: 'Helvetica Neue', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  color: ${props => (props.active ? colors['navy'] : 'currentColor')};
  justify-content: ${({ iconOrder }) =>
    iconOrder === 2 ? 'space-between' : 'flex-start'};
  padding: 0.3rem 0 0.2rem;
  padding: ${({ maxWidth }) => maxWidth && '20px 0px 20px 25px'};

  @media (min-width: 576px) {
    max-width: ${({ maxWidth }) => maxWidth && '540px'};
    padding: ${({ maxWidth }) => maxWidth && '25px 0px 25px 25px'};
  }
  @media (min-width: 768px) {
    max-width: ${({ maxWidth }) => maxWidth && '720px'};
  }
  @media (min-width: 992px) {
    max-width: ${({ maxWidth }) => maxWidth && '960px'};
  }
  @media (min-width: 1200px) {
    max-width: ${({ maxWidth }) => maxWidth && '1180px'};
  }
`

export const AccordionContent = styled(animated.div)`
  will-change: transform, opacity, height;
  margin-left: ${props => (props.isList ? '12px' : '0')};
  overflow: hidden;
  margin: ${({ isOpen, contentMargin }) =>
    isOpen && contentMargin ? contentMargin : '0'};
`

export const toggle = {
  width: '1em',
  height: '1em',
  marginRight: 10,
  cursor: 'pointer',
  verticalAlign: 'middle',
}

export const StyledIcon = styled(Icon)`
  order: ${({ iconOrder }) => iconOrder};
  width: ${({ iconXl }) => (iconXl ? '35px' : '70%')};
  height: ${({ iconXl }) => (iconXl ? '50px' : '70%')};
  padding: ${({ iconXl }) => (iconXl ? '17px 0px 0px' : null)};
  margin: ${({ iconXl }) => (iconXl ? '0px 15px 0px 0px' : '4px 0px 0px')};
  fill: ${({ fill }) => fill || colors.gold};
`
export const StyledSummary = styled.div`
  order: ${({ iconOrder }) => (iconOrder === 1 ? 2 : 1)};
`

export const StyledRCIcon = styled(Icon)`
  width: 70%;
  margin: 22px 0 0 7px;
  fill: ${colors.gold};
  @media (max-width: 765px) {
    width: 80%;
    margin: 24px 0 0 5px;
  }
`
