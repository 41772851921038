import styled from 'styled-components'
import { colors } from '@theme'
import { H2 } from '../Globals'

export const StyledLanguageWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  &:focus {
    outline: 0;
    background-color: ${colors.primary.brand};
    border-radius: 15;
  }
`

export const LanguageTitle = styled.div`
  padding: 0px 0px 0px 6px;
  font-size: 14px;
  color: inherit;
`

export const LanguageLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const NavH2 = styled(H2)`
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
`

export const StyledDownCaret = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 5px;
  margin-right: 2px;
  border-color: #5f6062;
  transition: transform 0.3s ease-out;
`
