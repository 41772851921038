import React from 'react'
import { animated } from 'react-spring'

import { SlideWrapper } from '../style'

const Slide = ({ children, style, type }) => {
  const top = type === 'fullwidth' ? '30px' : '0px'
  return (
    <SlideWrapper style={{ top }}>
      <animated.div style={{ ...style, height: '100%' }}>
        {children}
      </animated.div>
    </SlideWrapper>
  )
}

export default Slide
