/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import styled from 'styled-components'
import Icon from '../Icon'
import { H3, fontStack } from '../Globals'

import { colors } from '@theme'

// export const StyledCol = styled(Col)`
//   background-color: ${({ bg, entriesBased }) =>
//     entriesBased ? 'transparent' : colors[bg]};
//   padding: ${({ entriesBased }) => (entriesBased ? '0px' : '60px 0px')};
//   opacity: ${({ visible }) => (visible ? 1 : 0)};
// `

export const StyledSidebar = styled.ul`
  display: flex;
  border: 0;
  flex-direction: column;

  &:last-child {
    border-bottom: ${({ theme }) =>
      theme === 'transparent'
        ? '1px solid #B4B8BE'
        : '1px solid rgba(255,255,255,0.2)'};
  }
`

// order is important, sidebar title icon must be defined first
export const SidebarTitleIcon = styled(Icon)`
  fill: ${({ active, theme }) =>
    theme === 'transparent'
      ? active
        ? colors.black
        : colors.bgGray
      : active
      ? colors.white
      : colors.navy};
`

export const SidebarTitle = styled.li`
  vertical-align: middle;
  text-align: left;
  justify-content: space-between;
  display: flex;
  transition: all 0.1s ease-out;
  font-weight: 400;
  color: ${({ active, activeColor }) =>
    active ? colors[activeColor] : colors.gold};
  font-family: 'Helvetica Neue', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  border: ${({ theme }) =>
    theme === 'transparent'
      ? '1px solid #B4B8BE'
      : '1px solid rgba(255,255,255,0.2)'};
  border-bottom: none;
  border-left: none;
  border-right: none;
  align-items: center;

  &:hover {
    color: ${({ activeColor }) => colors[activeColor]};
    cursor: pointer;
  }
  &:hover ${SidebarTitleIcon} {
    fill: ${({ activeColor }) => colors[activeColor]};
  }
`

export const SidebarTitleText = styled.span`
  color: inherit;
  border-width: 1px 0px;
  border-radius: 0px;
  font-size: 16px;
  line-height: 28px;
  padding: ${({ theme }) => (theme === 'transparent' ? '7px 0px' : '7px 20px')};
  position: relative;
`

export const StyledTransparentTitle = styled(H3)`
  margin-bottom: 1px;
  border-color: #27251f;
  color: #27251f;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 26px;
  padding-bottom: 4px;
  border-bottom: 1px solid #000;
  text-transform: uppercase;
  background: #f3f3f3;
`

export const StyledSidebarPanel = styled.div`
  transition: opacity 0.15s linear;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  border: none;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: relative;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${({ theme }) =>
    theme === 'transparent' ? 'transparent' : '#fff'};
  background-clip: border-box;
  border-radius: 0.25rem;
`

export const StyledSidebarPanelBody = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opactity: 1;
    }
  }

  flex: ${({ entriesBased }) => (entriesBased ? '' : '1 1 auto')};
  display: ${({ entriesBased }) => (entriesBased ? 'flex' : '')};
  flex-wrap: ${({ entriesBased }) => (entriesBased ? 'wrap' : '')};
  flex-direction: ${({ entriesBased }) => (entriesBased ? 'row' : '')};
  padding: ${({ entriesBased }) => (entriesBased ? '0' : '0px 30px')};
  background-color: ${({ entriesBased }) => (entriesBased ? '#F3F3F3' : '')};
  ${({ fadeInContent }) =>
    fadeInContent && 'animation: fadeIn .25s ease-in 0s'};
`

// mobile
export const StyledSidebarMobileContainer = styled.div`
  ${fontStack};
  color: white;
  background-color: ${colors.purple};
  margin: ${({ theme }) => (theme === 'transparent' ? '40px 20px 0px' : '0')};
  padding: 0px;

  @media (min-width: 576px) {
    max-width: ${({ theme }) => theme === 'transparent' && '540px'};
    margin: ${({ theme }) => theme === 'transparent' && '40px auto'};
  }
`
export const StyledSidebarMobileBody = styled.div`
  background-color: ${({ theme }) =>
    theme === 'default' ? 'white' : '#f3f3f3'};
  color: black;
  padding: 30px 20px;
`
export const StyledSidebarMobileTitle = styled.div`
  // padding: 0px 0px 0px 10px;
  padding: 0.6rem 1.25rem;
  margin: 0px;
  font-size: 16px;
`

// sidebar dropdown
export const StyledSidebarDropdownMobileContainer = styled.div`
  color: #5f6062;
  border: ${({ border }) => border || 'none'};
  background-color: ${({ background }) => background || 'white'};

  ${({ maxWidth }) => maxWidth && 'max-width: 540px'};
  ${({ maxWidth }) => maxWidth && 'width: 100%'};
  ${({ maxWidth }) => maxWidth && 'margin: 20px auto'};

  @media (max-width: 576px) {
    ${({ maxWidth }) => maxWidth && 'width: 90%'};
  }
`

export const StyledSidebarDropdownMobileHeader = styled.div`
  padding: 6px 0px 10px 10px;
  color: white;
  font-size: 13px;
  letter-spacing: 1.12px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
`

export const StyledSidebarDropdownMobile = styled.li`
  list-style-type: none;
  width: 100%;
  margin: 0;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`

export const StyledSideBarTitleDiv = styled.div`
  background: #f3f3f3;
  padding: 0 0 38px 0;
  @media (min-width: 768px) {
    padding: 45px 0 0;
    margin: 45px 0 0;
  }
`
