import React from 'react'
import AddressCardWrapper from './components/AddressCard'
import ProfileCardWrapper from './components/ProfileCard'
import DefaultCard from './components/DefaultCard'
import GridCard from './components/GridCard'

const Card = ({ type, ...props }) => {
  switch (type) {
    case 'address':
      return <AddressCardWrapper {...props} />
    case 'profile':
      return <ProfileCardWrapper {...props} />
    case 'grid':
      return <GridCard {...props} />
    default:
      return <DefaultCard {...props} />
  }
}

export default Card
export const AddressCard = props => <Card type="address" {...props} />
export const ProfileCard = props => <Card type="profile" {...props} />
