/* eslint-disable camelcase */
import React from 'react'
// import HeaderLayout from '@components/Header/HeaderLayout'
import { useQuery } from '@tanstack/react-query'
import { fetchCPAEntry, shapeNavigation } from '@utils'
import { CONTENTFUL_NAVIGATION_IDS, CONTENTFUL_PAGE_IDS } from '@constants'
import { debugFlags, debugWrapper } from '@featureflags'
import AppBar from '@components/AppBar'

const HeaderCPAContainer = ({ node_locale: locale, ...props }) => {
  const { isLoading, error, data } = useQuery(
    ['Header Navigation', locale],
    () =>
      fetchCPAEntry({
        id: CONTENTFUL_NAVIGATION_IDS.header,
        contentType: 'navigation',
        locale,
        include: 5,
      })
  )

  if (isLoading) return null
  if (error || data?.message) {
    debugWrapper(debugFlags.NAVIGATION, () => {
      console.groupCollapsed('Header Error')
      console.info(error || data?.message)
      console.groupEnd()
    })
  }

  const navLinks = data?.items?.[0]?.navItems

  const shapedNavLinks = shapeNavigation(navLinks, locale)

  const homeSlug = `preview?contentType=page&id=${CONTENTFUL_PAGE_IDS.home}`

  return <AppBar items={shapedNavLinks} {...props} homeSlug={homeSlug} />
}

export default HeaderCPAContainer
