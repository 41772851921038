import React from 'react'
import { StyledContainer, StyledRow } from './style'
import { richTextToComponents } from '../../utils/gatsby/richTextToComponents'
import { Block, Col, Container } from '../Globals'
import { Row, Column } from '@components/Common'
import Html from '../Html'

const TextBlock = ({ imageBackground, id, columns, padding, previewMode }) => {
  if (imageBackground) {
    return (
      <Block
        data-testid="background"
        imgUrl={imageBackground}
        key={id}
        padding="90px 0px"
      >
        <Container mobilePadding>
          <Row className="justify-between">
            {columns?.map(column => (
              <Col
                xs={12}
                sm={12}
                md={
                  (column.columnWidth &&
                    Math.round((column.columnWidth * 12) / 100)) ||
                  12
                }
                lg={
                  (column.columnWidth &&
                    Math.round((column.columnWidth * 12) / 100)) ||
                  12
                }
                xl={
                  (column.columnWidth &&
                    Math.round((column.columnWidth * 12) / 100)) ||
                  12
                }
                key={column.id}
              >
                {column?.contentHtml?.useHTMLField === 'true' ? (
                  <Html {...column} />
                ) : (
                  richTextToComponents(column.content, {
                    optionalId: column?.sys?.id,
                    optionalType: column?.__typename,
                  })
                )}
              </Col>
            ))}
          </Row>
        </Container>
      </Block>
    )
  }
  return (
    <StyledContainer key={id} mobilePadding>
      <StyledRow between>
        {columns?.map((column, i) => (
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={
              (column.columnWidth &&
                Math.round((column.columnWidth * 12) / 100)) ||
              12
            }
            xl={
              (column.columnWidth &&
                Math.round((column.columnWidth * 12) / 100)) ||
              12
            }
            key={`col${i + 1}`}
            data-testid="column"
          >
            {column?.contentHtml?.useHTMLField === 'true' ? (
              <Html {...column} />
            ) : (
              richTextToComponents(column.content, {
                optionalId: column?.sys?.id,
                optionalType: column?.__typename,
              })
            )}
          </Col>
        ))}
      </StyledRow>
    </StyledContainer>
  )
}

export default TextBlock
