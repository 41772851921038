import React from 'react'
import PropTypes from 'prop-types'
import { StyledPageWithSubpagesHtml } from './style'

const PageWithSubpagesHTML = ({ html, ...rest }) => {
  return (
    <StyledPageWithSubpagesHtml
      {...rest}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

PageWithSubpagesHTML.propTypes = {}

export default PageWithSubpagesHTML
