import styled from 'styled-components'
import { colors } from '@theme'
import A from '@components/A'

export const Crumb = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  text-decoration: none;
  width: 100%;
  left: ${({ left }) => left || '-8px'};
  text-decoration: none;
  color: #ffffff;
  margin-bottom: 8px;

  head @media (max-width: 550px) {
    left: 3px;
  }

  &:hover {
    text-decoration: none;
    fill: gold;
    color: ${colors.gold};

    & svg {
      fill: ${({ hoverColor }) => hoverColor || colors.gold};
    }
    & a {
      color: ${colors.gold};
    }
  }

  & svg {
    fill: ${({ fill }) => fill || 'white'};

    &:hover {
      fill: ${({ hoverColor }) => hoverColor || colors.gold};
    }
  }
`

export const CrumbLink = styled(A)`
  display: inline !important;
  text-align: left;
  position: relative;
  left: -5px;
  bottom: -1px;
  width: fit-content;
  font-size: 14px;
  text-decoration: none;
  color: ${({ color }) => color || 'white'};
  text-transform: capitalize;

  &:hover {
    text-decoration: ${({ underline }) => underline && 'underline'};
    color: ${({ hoverColor }) => hoverColor};
  }
`
