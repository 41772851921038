import React from 'react'
import Debug from '@components/Debug'
import { AssetHyperLink, EntryHyperLink } from '@richtext'

const HyperlinkSubContainer = ({ linkType, ...rest }) => {
  switch (linkType) {
    case 'Entry':
      return <EntryHyperLink {...rest} />
    case 'Asset':
      return <AssetHyperLink {...rest} />
    default: {
      return (
        <Debug
          label={`Hyperlink not yet handled for linkType: "${linkType}"`}
          jsonDump={rest}
        />
      )
    }
  }
}

export default HyperlinkSubContainer
