/* eslint-disable react/display-name */
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import {
  BodyCopy,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Quote,
  HR,
  StyledOl,
  StyledUl,
} from './style'
import EmbeddedEntryContainer from '@containers/EmbeddedEntryContainer'
import HyperlinkContainer from '@containers/HyperlinkContainer'
import { HyperLink } from '@richtext'

const defaultParser = options => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <BodyCopy>{children}</BodyCopy>,
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>,
    [BLOCKS.UL_LIST]: (node, children) => <StyledUl>{children}</StyledUl>,
    [BLOCKS.OL_LIST]: (node, children) => <StyledOl>{children}</StyledOl>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    [BLOCKS.HR]: () => <HR />,
    [INLINES.HYPERLINK]: (node, children) => (
      <HyperLink node={node}>{children}</HyperLink>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <HyperlinkContainer node={node} {...options}>
        {children}
      </HyperlinkContainer>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => (
      <HyperlinkContainer node={node} {...options}>
        {children}
      </HyperlinkContainer>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedEntryContainer node={node} {...options}>
        {children}
      </EmbeddedEntryContainer>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedEntryContainer node={node} {...options}>
        {children}
      </EmbeddedEntryContainer>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
      <EmbeddedEntryContainer node={node} {...options}>
        {children}
      </EmbeddedEntryContainer>
    ),
  },
})

export const renderRichText = (
  richText,
  options,
  customParser = defaultParser
) => {
  if (!richText) return null

  // Case for preview
  // Need to manually query for refererences
  if (!richText?.raw)
    return documentToReactComponents(richText, customParser(options))

  // Case where richText is in the shape {raw, references}
  // Gatsby-source-contentful generates `raw` and `references`
  return documentToReactComponents(
    JSON.parse(richText?.raw),
    customParser({ references: richText?.references, ...options })
  )
}
