import styled from 'styled-components'
import { StyledFullWidthDiv } from '../Banner/style'
import { Container, H1 } from '../Globals'
import { sizes, colors } from '@theme'

export const StyledHr = styled.hr`
  border: 0;
  height: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 25px;
  margin-top: 25px;
`

export const StyledProStackedContainer = styled.div`
  position: relative;
  z-index: 3;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  color: white;
  width: 100%;
  padding: 0;
  margin: 0 auto;

  @media (max-width: 1200px) {
    max-width: 960px;
    width: 100%;
  }
  @media (max-width: 992px) {
    max-width: 720px;
  }

  @media (max-width: 764px) {
    max-width: 540px;
  }

  @media (max-width: 575px) {
    max-width: 100%;
    margin: 0px 20px;
    width: 90%;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
    width: 100%;
    padding: 0;
  }
`
export const StyledProFullWidthDiv = styled(StyledFullWidthDiv)`
  @media (min-width: 992px) {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    max-width: 990px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  @media (max-width: 764px) {
    max-width: 760px;
    width: 100%;
  }

  @media print {
    max-width: 8.5in;
  }
`

export const StyledProContentContainer = styled.div`
  padding: 50px 70px 50px 70px;
  background-color: #ffffff;
  margin: -90px 0 0px 0;
  position: relative;
  z-index: 1;
  flex-direction: ${({ flexDirection }) => (flexDirection ? 'column' : 'row')};
  ${({ justifyContent }) =>
    justifyContent && { 'justify-content': 'space-between' }};
  display: flex;
  flex: 0 0 100%;
  max-width: 1200px;
  width: 100%;

  @media (max-width: ${sizes.large.max}) {
    width: 100%;
    padding: 30px 40px 20px;
  }

  @media (max-width: ${sizes.small.max}) {
    width: 100%;
    padding: 30px;
  }

  @media (max-width: ${sizes.xsmall.max}) {
    width: 100%;
    padding: 20px;
  }

  @media (min-width: 1200px) {
    width: 100%;
    padding: 50px 70px 30px;
  }

  @media print {
    max-width: 8.5in;
    padding: 0;
    margin-top: 1cm;

    img {
      display: block !important;
      max-width: 180px !important;
      height: auto !important;
    }

    & > [class*='StyledStackedBannerContainer'] {
      max-width: 8.5in;

      & > [class*='Row'] {
        width: 100%;
        max-width: 8.5in;
        display: grid;
        grid-template-columns: 25% 75%;
        grid-column-gap: 24pt;

        & [class*='Col']:nth-last-child(1) [class*='Row']:nth-last-child(1) {
          flex-wrap: nowrap;
        }

        &
          [class*='Col']:nth-last-child(1)
          [class*='Row']:nth-last-child(1)
          [class*='Col']:nth-last-child(1) {
          display: none;
        }
      }
    }
  }
`

export const WideProContainer = styled(Container)`
  width: 1200px;
  padding: 0;

  @media (max-width: 1200px) {
    max-width: 960px;
    width: 100%;
  }
  @media (max-width: 992px) {
    max-width: 720px;
  }

  @media (max-width: 764px) {
    max-width: 540px;
  }

  @media (max-width: 575px) {
    max-width: 100%;
    padding: 0px 20px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
    width: 100%;
    padding: 0;
  }

  @media print {
    width: 100%;
    max-width: 8.5in;
    padding: 0;

    & > div[class*='Globals__Row'] {
      width: 100%;
      max-width: 8.5in;
      display: block;

      div[class*='Globals__Col'] {
        float: left !important;

        &:nth-child(1) {
          width: 70% !important;
          padding-left: 0 !important;
          padding-right: 24pt !important;
        }

        &:nth-child(2) {
          width: 30% !important;
          padding: 0 !important;
        }
      }
    }
  }
`

export const StyledTabEventTitle = styled(H1)`
  font-family: 'Helvetica Neue', 'Arial', 'Verdana', '-apple-system',
    BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 0.4px;
  line-height: ${({ lineHeight }) => lineHeight || '1.3'};
  padding: 0;
  text-align: inherit;
  margin: ${({ margin }) => margin || '0px'};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '0px'};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
  @media (max-width: 576px) {
    font-size: ${({ smallerText }) =>
      smallerText ? `${smallerText}rem` : '3rem'};
    line-height: 1.2;
  }
  @media (max-width: ${sizes.small.max}) {
    font-size: 2rem;
  }
  @media (max-width: ${sizes.xsmall.max}) {
    font-size: 1.5rem;
  }
`

export const StyledImg = styled.img`
  @media (min-width: 100px) {
    width: 180px;
    height: 215.36px;
  }
  @media (min-width: 1200px) {
    width: 220px;
    height: 263px;
  }
`

export const StyledTabH1 = styled(H1)`
  font-size: 2rem;
  @media (max-width: ${sizes.small.max}) {
    font-size: 2rem;
  }

  @media (max-width: ${sizes.xsmall.max}) {
    font-size: 1.5rem;
  }
`
export const StyledListContainer = styled.div`
  ul {
    list-style: initial;
    line-height: 1.6;
    margin-bottom: 1rem;
    li::marker {
      color: black;
    }
  }
  ul ul li {
    list-style: circle;
  }
  ul ul {
    margin-bottom: 0;
  }
  li {
    margin-left: 1rem;
  }
`

export const StyledUL = styled.ul``

export const StyledLI = styled.li``

export const StyledSpanTitle = styled.span`
  font-weight: 700;
  color: ${({ color }) => colors?.[color] || '#5a3462'};
  &:hover {
    color: ${({ hover }) => (hover ? colors?.[hover] : null)};
    text-decoration: underline;
  }
`
