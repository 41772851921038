import React from 'react'
import PropTypes from 'prop-types'
import { StyledInput } from '../style'

const Email = ({ isValid, ...props }) => {
  return (
    <>
      <StyledInput type="email" isValid={isValid} {...props} />
    </>
  )
}

Email.propTypes = {
  isValid: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
}

export default Email
