import styled from 'styled-components'
import {
  space,
  position,
  color,
  typography,
  border,
  layout,
  flexbox,
  grid,
  variant,
  shadow,
  background,
  system,
} from 'styled-system'
import theme from '@theme'

const getColorValue = color => {
  if (color?.startsWith('#') || color?.includes('rgb')) return color

  if (color?.includes('.')) {
    const [level, value] = color?.split('.')
    return theme?.colors?.[level]?.[value]
  }

  return color
}

const colorHover = ({ colorHover }) => {
  const colorValue = getColorValue(colorHover)
  return colorValue && `color: ${colorValue};`
}

const bgHover = ({ bgHover }) => {
  const bgColorValue = getColorValue(bgHover)
  return bgColorValue && `background-color: ${bgColorValue};`
}

const borderColorHover = ({ borderColorHover }) => {
  const bgColorValue = getColorValue(borderColorHover)
  return bgColorValue && `border-color: ${bgColorValue};`
}

const svgPathFill = ({ svgPathFill }) => {
  const colorValue = getColorValue(svgPathFill)
  return colorValue && `fill: ${colorValue};`
}

const svgBg = ({ svgBg }) => {
  const colorValue = getColorValue(svgBg)
  return colorValue && `fill: ${colorValue};`
}

const svgFillHover = ({ svgFillHover }) => {
  const colorValue = getColorValue(svgFillHover)
  return colorValue && `fill: ${colorValue};`
}

const svgPathFillHover = ({ svgPathFillHover }) => {
  const colorValue = getColorValue(svgPathFillHover)
  return colorValue && `fill: ${colorValue};`
}

const boxShadowHover = ({ boxShadowHover }) => {
  const boxShadowValue = getColorValue(boxShadowHover)
  return boxShadowValue && `box-shadow: ${boxShadowValue};`
}

const enableHoverUnderline = ({ enableHoverUnderline }) => {
  return (
    enableHoverUnderline &&
    `
    text-decoration: underline;
    text-underline-offset: 2px;
  `
  )
}

const dim85Hover = ({ dim85Hover }) => {
  return dim85Hover && `filter: brightness(85%);`
}

const commonCustomProps = system({
  gap: true,
  filter: true,
  transform: true,
  cursor: true,
  textTransform: true,
  transition: true,
  pointerEvents: true,
  visibility: true,
  wordBreak: true,
  whiteSpace: true,
})

export const StyledDiv = styled.div`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}

  svg {
    ${svgBg}
    path {
      transition: fill 0.2s ease-in-out;
      ${svgPathFill}
    }
  }
  
  ${({ pointer }) =>
    pointer &&
    `
    cursor: pointer;
    `}

  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
    
    svg {
      ${svgFillHover}
      path {
        ${svgPathFillHover}
      }
    }

    ${({ hoverBlack }) =>
      hoverBlack &&
      `
      color: black;
      text-decoration: underline;
      text-underline-offset: 2px;
    `}
  }
`

export const StyledH1 = styled.h1`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
  
  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
  }
`

export const StyledH2 = styled.h2`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
  
  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
  }
`

export const StyledH3 = styled.h3`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
  
  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
  }
`

export const StyledH4 = styled.h4`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
  
  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
  }
`

export const StyledP = styled.p`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
  
  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
  }
`

export const StyledLabel = styled.label`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
    
  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
  }
`

export const StyledForm = styled.form`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
`

export const StyledInput = styled.input`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
  ::-webkit-input-placeholder { /* Edge */
  color: ${props => props.placeholderColor || 'rgba(0, 0, 0, 0.6)'};
  }

  ::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${props => props.placeholderColor || 'rgba(0, 0, 0, 0.6)'};
  }

  ::placeholder {
    color: ${props => props.placeholderColor || 'rgba(0, 0, 0, 0.6)'};
  }
    
  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
  }
`

export const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  resize: vertical;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
  ::-webkit-input-placeholder { /* Edge */
  color: ${props => props.placeholderColor || 'rgba(0, 0, 0, 0.6)'};
  }

  ::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${props => props.placeholderColor || 'rgba(0, 0, 0, 0.6)'};
  }

  ::placeholder {
    color: ${props => props.placeholderColor || 'rgba(0, 0, 0, 0.6)'};
  }
    
  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
  }
`

export const StyledButton = styled.button`
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}

  svg {
    ${svgBg}
    path {
      transition: fill 0.2s ease-in-out;
      ${svgPathFill}
    }
  }  

  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${enableHoverUnderline}
    ${dim85Hover}

    
    svg {
      ${svgFillHover}
      path {
        ${svgPathFillHover}
      }
    }
  }
`

export const StyledSpan = styled.span`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}
`

export const StyledUl = styled.ul`
  list-style: initial;
  line-height: 1.6;
  margin-bottom: 1rem;

  ul {
    margin-bottom: 0;

    li {
      list-style: circle;
    }
  }

  li {
    margin-left: 1rem;

    &::marker {
      color: black;
    }
  }
`

export const StyledOl = styled.ol`
  list-style: decimal;
  line-height: 1.6;
  margin-bottom: 1rem;

  ol {
    margin-bottom: 0;
  }

  li {
    margin-left: 1rem;

    &::marker {
      color: black;
    }
  }
`

export const StyledResetUl = styled.ul`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}

  svg {
    ${svgBg}
    path {
      transition: fill 0.2s ease-in-out;
      ${svgPathFill}
    }
  }  

  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    
    svg {
      ${svgFillHover}
      path {
        ${svgPathFillHover}
      }
    }
  }
`

export const StyledLi = styled.li``
export const StyledResetLi = styled.li`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}

  ${({ listStyle }) => ({ listStyle })}

  ::marker {
    color: ${({ markerColor }) => markerColor || 'black'};
  }

  svg {
    ${svgBg}
    path {
      transition: fill 0.2s ease-in-out;
      ${svgPathFill}
    }
  }  

  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    
    svg {
      ${svgFillHover}
      path {
        ${svgPathFillHover}
      }
    }
  }
`

export const StyledPre = styled.pre`
  box-sizing: border-box;
  ${background}
  ${position}
  ${flexbox}
  ${grid}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
  ${variant}
  ${shadow}
  ${commonCustomProps}

  code {
    white-space: pre-wrap;
  }

  &:hover {
    ${bgHover}
    ${colorHover}
    ${borderColorHover}
    ${boxShadowHover}
    ${enableHoverUnderline}
  }
`

export const StyledHTML = styled(StyledDiv)`
  max-width: 100%;
  img,
  embed,
  table,
  iframe {
    max-width: 100%;
  }

  iframe {
    max-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  p:last-child {
    margin-bottom: 0;
  }

  hr {
    margin-bottom: 1rem;
  }

  img {
    margin-bottom: 1rem;
  }

  embed {
    margin-bottom: 1rem;
  }

  iframe {
    margin-bottom: 1rem;
  }

  ol {
    list-style: decimal;
    color: black;
    margin-bottom: 1rem;
  }

  ul {
    list-style: initial;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  ul ul li {
    list-style: circle;
  }

  ul ul {
    margin-bottom: 0;
  }

  ul ul li {
    list-style: circle;
  }

  ul ul {
    margin-bottom: 0;
  }

  td {
    border: 1px solid #999;
    padding: 3px;
  }

  th {
    border: 1px solid #999;
    padding: 3px;
  }

  table {
    border: 1px solid #999;
    border-spacing: 0px;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    color: black;
    line-height: 28px;
  }

  div {
    color: black;
  }

  li {
    margin-left: 1rem;
    color: black;
    line-height: 28px;
  }

  a {
    color: ${theme.colors.primary.red};
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: ${theme.colors.primary.red};
    text-decoration: underline;
  }

  .paddedAnchor {
    position: relative;
  }

  .paddedAnchor > a:first-child {
    position: absolute;
    top: -75px;
  }
`
