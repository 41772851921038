/* eslint-disable camelcase */
import React from 'react'
import { translatedJson } from './translation'
import { LocaleProvider, TranslatedTermsProvider } from '@hooks'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'

// Providers that depend on current page props
const PageElementWrapper = ({ children, pageContext, location, ...props }) => {
  // /pages don't have pageContext, default to en-US in these cases.
  const locale = pageContext?.node_locale || 'en-US'
  return (
    <LocaleProvider locale={locale}>
      <TranslatedTermsProvider locale={locale} translatedJson={translatedJson}>
        <ContentfulLivePreviewProvider locale={locale}>
          {children}
        </ContentfulLivePreviewProvider>
      </TranslatedTermsProvider>
    </LocaleProvider>
  )
}

export default PageElementWrapper
