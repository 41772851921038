import styled from 'styled-components'
import { colors } from '@theme'

export const StyledDropdownMenu = styled.div`
  position: absolute;
  top: 91%;
  left: 0px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 6rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 0.25rem 0;
  border: none;
  border-radius: 0px;
  float: none;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

  @media (min-width: 992px) {
    position: absolute;
  }

  @media (max-width: 1420px) {
    left: auto;
    right: 0px;
  }
`

export const DropdownItem = styled.div`
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  padding: 0.1rem 0.6rem;

  &:hover {
    background-color: ${colors.grayLt};
  }
`

export const StyledMenu = styled.div`
  max-height: calc(100% - 96px);
  webkit-overflow-scrolling: touch;
`

export const StyledMenuItem = styled.div`
  min-height: 48;
  box-sizing: border-box;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  padding: 4px 16px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  &:focus {
  }
  &: selected;
  &:selected:hover: {
  }
  &:disabled: {
    opacity: 0.5;
  }
`

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const Select = styled.select`
  padding: 8px 40px 8px 16px;
  border: none;
  border: 1px solid ${colors.bg.border};
  border-radius: 32px;
  overflow: hidden;
  box-shadow: none;
  background: ${colors.bg.default};
  background-image: none;
  -webkit-appearance: none;
  font-weight: 600;
  font-size: 15px;
  color: ${colors.text.default};
  text-align: center;
  text-align-last: center;
  outline: 0;

  option {
    text-align: left;
  }

  &:hover {
    cursor: pointer;
    background: ${colors.bg.divider};
    outline: 0;
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.bg.default}, 0 0 0 4px ${colors.bg.border};
    transition: box-shadow 0.2s ease-in-out;
    outline: 0;
  }

  &:active {
    transition: box-shadow 0.2s ease-in-out;
    outline: 0;
  }
`
