import React from 'react'
import InfiniteLoader from 'react-window-infinite-loader'
import { useMediaQuery } from '@hooks'
import { sizes, colors } from '@theme'
import { BodyCopy } from '../../Globals'
import { generateRandomKey } from '@utils'
import { StyledInfiniteList } from '../style'

const InfiniteList = ({
  children,
  rowHeights,
  maxWidth = '100%',
  maxHeight = 300,
  noDataMessage,
  numItemsPerLoad = 100,
  disableHeightShrink = false,
  hasNextPage = false,
  loadMoreItems = () => {},
}) => {
  /* Example of dynamic rowHeights
   * const rowHeights = {
   *   xs: 200,
   *   sm: 200,
   *   md: 140,
   *   lg: 110,
   *   xl: 110,
   * }
   */

  const xs = useMediaQuery(`(max-width: ${sizes.xsmall.max})`)
  const sm = useMediaQuery(`(max-width: ${sizes.small.max})`)
  const md = useMediaQuery(`(max-width: ${sizes.medium.max})`)
  const lg = useMediaQuery(`(max-width: ${sizes.large.max})`)

  const rowHeight =
    (xs && rowHeights.xs) ||
    (sm && rowHeights.sm) ||
    (md && rowHeights.md) ||
    (lg && rowHeights.lg) ||
    rowHeights.xl

  const itemsTotalHeight = children?.length * rowHeight
  const adjustedHeight =
    !disableHeightShrink && itemsTotalHeight < maxHeight
      ? itemsTotalHeight
      : maxHeight

  const isItemLoaded = index => index < children?.length
  const itemCount = hasNextPage ? children?.length + 1 : children?.length

  const Row = ({ index, style }) => {
    const loading = index === children?.length
    return loading ? (
      <div key={generateRandomKey()} style={style}>
        <BodyCopy
          style={{
            paddingTop: '20px',
            textAlign: 'center',
            color: colors.grayMd,
          }}
        >
          Loading...
        </BodyCopy>
      </div>
    ) : (
      <div style={style}>{children[index]}</div>
    )
  }

  if (!children?.length) return null

  return children?.length > 0 ? (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={Math.ceil(0.8 * numItemsPerLoad)}
      minimumBatchSize={numItemsPerLoad}
    >
      {({ onItemsRendered, ref }) => (
        <StyledInfiniteList
          className="List"
          height={adjustedHeight + 20}
          itemCount={itemCount}
          itemSize={rowHeight}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width={maxWidth}
        >
          {Row}
        </StyledInfiniteList>
      )}
    </InfiniteLoader>
  ) : (
    <BodyCopy style={{ textAlign: 'center', paddingTop: '20px' }}>
      {noDataMessage}
    </BodyCopy>
  )
}

export default InfiniteList
