import React from 'react'
import Icon from '../../../Icon'
import Search from '../../../Search'
import locales from '../../../../../config/i18n'
import { navigate } from 'gatsby'
import MobileDropdown from './MobileDropdown'
import { generateRandomKey } from '@utils'

import {
  StyledNavLink,
  StyledSearch,
  LanguageLink,
  LanguageTitle,
  FlagItem,
} from '../../style'
import { useLocale, useTranslatedTerms } from '@hooks'

const MobileSitesAndSearch = props => {
  const { locale } = useLocale()
  const { getTranslatedTerm } = useTranslatedTerms()
  const languages = Object.values(locales)
  const items = [
    {
      id: 'login-item-1',
      title: 'Alumni',
      slug: 'https://alumni.paulhastings.com/login/',
      testingClassName: 'qtph-globalmenu-login-alumni-link',
    },
    {
      id: 'login-item-3',
      title: 'Paul Hastings',
      slug: '/mlogin',
      testingClassName: 'qtph-globalmenu-login-paulhastingslogin-link',
    },
  ]

  const handleSubmit = e => {
    e.preventDefault()
    const value = e?.target?.querySelector('input')?.value || ''
    const url =
      locale !== 'en-US'
        ? `/${locale}/search?search=${value}`
        : `/search?search=${value}`
    navigate(url, {
      state: { query: value },
    })
  }
  return (
    <>
      <MobileDropdown
        title={getTranslatedTerm('Login')}
        to={false}
        className="qtph-globalmenu-login-loginmenu"
      >
        {items.map(({ title, id, slug, testingClassName }) => (
          <FlagItem key={id}>
            <a href={slug} className={testingClassName}>
              <LanguageTitle>{getTranslatedTerm(title)}</LanguageTitle>
            </a>
          </FlagItem>
        ))}
      </MobileDropdown>
      <MobileDropdown
        title={<Icon glyph={locales[locale].locale} />}
        to={false}
        className="qtph-globalmenu-sites-sitesmenu"
      >
        {languages.map(
          (
            { locale, path, siteLanguage, langPicker, navTestingClassName },
            i
          ) => (
            <FlagItem key={`${locale}+${generateRandomKey()}`}>
              <LanguageLink
                className={navTestingClassName}
                href={path === 'en' ? '/' : `/${path}`}
                hrefLang={siteLanguage}
              >
                <Icon glyph={locale} />
                <LanguageTitle>{langPicker}</LanguageTitle>
              </LanguageLink>
            </FlagItem>
          )
        )}
      </MobileDropdown>
      <StyledSearch>
        <StyledNavLink>
          <Search medium handleSubmit={handleSubmit} />
        </StyledNavLink>
      </StyledSearch>
    </>
  )
}

export default MobileSitesAndSearch
