import React from 'react'
import { useMediaQuery } from '@hooks'
import AppBarDesktop from './AppBarDesktop'
import AppBarMobile from './AppBarMobile'

const AppBar = ({ items, shrink, ...rest }) => {
  const matches = useMediaQuery('(max-width: 991px)')

  if (matches) return <AppBarMobile navItems={items} {...rest} />
  if (matches === false)
    return <AppBarDesktop shrink={shrink} navItems={items} {...rest} />
  return null
}

export default AppBar
