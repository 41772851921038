import React from 'react'
import {
  StyledProfileCardGrid,
  StyledProfileImage,
  ProfileName,
  ProfileNameNoLink,
  StyledProfileDetails,
  ContactLink,
  StyledProfileCardGridMd,
  StyledImageContainer,
  RecruitingProfileName,
  Container,
} from '../style'
import { BodyCopy } from '../../Globals'
import ProfileCardXl from './FlexProfileCard'
import Icon from '../../Icon'

const ProfileImage = ({ name, src, showImage, width, height }) => {
  if (!showImage || !src) {
    return (
      <Icon
        data-testid="person-avatar"
        glyph="person"
        height={height || 105}
        width={width}
      />
    )
  } else {
    return (
      <img
        src={src}
        width={`${width}`}
        height={`${height}`}
        alt={`Image: ${name}`}
      />
    )
  }
}

const ProfileCard = ({
  children,
  media,
  image,
  displayContact = false,
  displaySocial = false,
  displayDepartment = false,
  hasProfessionalDetail = true,
  displayName = true,
  displayAvatar = true,
  displayTitle = true,
  contactUs = false,
  flexDirection = false,
  recruiting = false,
  xl,
  md,
  noGrid,
  socialProfiles,
  float,
  person,
  person: {
    name,
    title,
    company,
    overrideCompany,
    email,
    phone,
    department,
    office,
    slug,
    photo,
    __typename,
  },
  padding,
  emailFontSize = '16px',
  phoneFontSize = '16px',
  defaultFontSize = 14,
  letterSpacing = '0px',
  lineHeight = 1.6,
  height = '100%',
  ...rest
}) => {
  const shouldLinkToEntry = typename =>
    typename === 'ContentfulProfessional' || typename === 'professional'
  const linkToEntry = person.__typename && shouldLinkToEntry(__typename)

  // professional detail is an xl
  if (xl) {
    return (
      <ProfileCardXl
        person={person}
        socialProfiles={socialProfiles}
        {...rest}
      />
    )
  }
  // leadership page - partners
  if (md) {
    return (
      <StyledProfileCardGridMd
        to={person?.slug}
        contentType="ContentfulProfessional"
      >
        <StyledImageContainer>
          {person && person.image && person.image.fluid && (
            <img
              src={`https:${person.image.fluid.src}`}
              width="220px"
              height="263px"
              alt={`Image: ${name}`}
            />
          )}
          {person && person.image && person.image.url && (
            <img
              src={person.image.url}
              width="220px"
              height="263px"
              alt={`Image: ${name}`}
            />
          )}
        </StyledImageContainer>
        <StyledProfileDetails>
          {linkToEntry ? (
            <ProfileName
              to={hasProfessionalDetail && person?.slug}
              contentType="ContentfulProfessional"
            >
              {name}
            </ProfileName>
          ) : (
            <ProfileNameNoLink>{name}</ProfileNameNoLink>
          )}
          {displayDepartment ? department : title}
        </StyledProfileDetails>
      </StyledProfileCardGridMd>
    )
  }
  const renderSocial = () => {
    let socialLink =
      person &&
      person.links &&
      person.links.filter(({ type }) => type === 'Twitter')
    socialLink = socialLink && socialLink[0]
    if (socialLink && socialLink.link && !socialLink.link.includes('@'))
      socialLink.link = `@${socialLink.link}`
    return (
      socialLink && (
        <ContactLink href={socialLink.url}>{socialLink.link}</ContactLink>
      )
    )
  }
  const renderContactDetails = () => (
    <>
      {!email && !phone && 'N/A'}
      {email && (
        <ContactLink
          fontSize={emailFontSize}
          href={`mailto:${email}`}
          className="qtph-profprofile-email-txt"
        >
          Email
        </ContactLink>
      )}
      {phone && (
        <ContactLink
          color="default"
          fontSize={phoneFontSize}
          href={`tel:${phone}`}
        >
          {office && `${office}: `} {phone}
        </ContactLink>
      )}
    </>
  )
  const renderImage = person => {
    if (!person || !person.image) {
      return [false, false]
    }
    if (person?.image?.url) {
      return [true, person.image.url]
    }
    if (person?.image?.file?.url) {
      return [true, person.image.file.url]
    } else {
      return [true, person.image.fluid.src]
    }
  }

  const [showImage, imgSrc] = renderImage(person)

  if (contactUs) {
    return (
      <StyledProfileCardGrid
        flexDirection={flexDirection}
        padding={padding}
        noGrid={noGrid}
        height={height}
      >
        {displayAvatar && (
          <Container>
            <ProfileImage
              src={imgSrc}
              name={name}
              showImage={showImage}
              width={75}
            />
          </Container>
        )}
        <StyledProfileDetails padding={padding}>
          {displayName && linkToEntry && (
            <ProfileName
              to={hasProfessionalDetail && person?.slug}
              contentType="ContentfulProfessional"
              href={!hasProfessionalDetail && `mailto:${email}`}
            >
              {name}
            </ProfileName>
          )}
          {displayName && !linkToEntry && (
            <ProfileNameNoLink>{name}</ProfileNameNoLink>
          )}
          <BodyCopy
            lineHeight={lineHeight}
            letterSpacing={letterSpacing}
            fontSize={defaultFontSize}
          >
            {(displayDepartment && department) || (displayTitle && title)}
          </BodyCopy>
          {displayContact && renderContactDetails()}
          {displaySocial &&
            (person.socialProfiles || person.links) &&
            renderSocial()}
        </StyledProfileDetails>
      </StyledProfileCardGrid>
    )
  }
  // recruiting calendar
  if (recruiting) {
    return (
      <StyledProfileCardGrid padding="0 0 10px" noGrid={noGrid} height={height}>
        <StyledProfileDetails padding={padding}>
          {displayName && (
            <RecruitingProfileName
              to={hasProfessionalDetail && person?.slug}
              contentType="ContentfulProfessional"
              href={!hasProfessionalDetail && `mailto:${email}`}
            >
              {name}
            </RecruitingProfileName>
          )}
          <ContactLink
            color="default"
            fontSize={phoneFontSize}
            href={`tel:${phone}`}
          >
            {phone}
          </ContactLink>
          <ContactLink
            className="qtph-profprofile-email-txt"
            fontSize={emailFontSize}
            href={`mailto:${email}`}
          >
            {email}
          </ContactLink>
        </StyledProfileDetails>
      </StyledProfileCardGrid>
    )
  }

  return (
    <StyledProfileCardGrid padding={padding} noGrid={noGrid} height={height}>
      {displayAvatar && (
        <StyledProfileImage>
          <ProfileImage
            src={imgSrc}
            name={name}
            showImage={showImage}
            width={50}
            height={60}
          />
        </StyledProfileImage>
      )}
      <StyledProfileDetails padding={padding}>
        {displayName && linkToEntry && (
          <ProfileName
            to={hasProfessionalDetail && person?.slug}
            contentType="ContentfulProfessional"
            href={!hasProfessionalDetail && `mailto:${email}`}
          >
            {name}
          </ProfileName>
        )}
        {displayName && !linkToEntry && (
          <ProfileNameNoLink>{name}</ProfileNameNoLink>
        )}
        <BodyCopy
          lineHeight={lineHeight}
          letterSpacing={letterSpacing}
          fontSize={defaultFontSize}
        >
          {(displayDepartment && department) || (displayTitle && title)}
        </BodyCopy>
        {displayContact && renderContactDetails()}
        {displaySocial &&
          (person.socialProfiles || person.links) &&
          renderSocial()}
      </StyledProfileDetails>
    </StyledProfileCardGrid>
  )
}

export default ProfileCard
