import React from 'react'
import { H2 } from '../../Globals'
import A from '@components/A'
import { StyledSecondSubNavItemContainer } from './style'
import { useLocale } from '@hooks'

const FooterItem = ({
  displayText,
  slug,
  url,
  __typename,
  subNavigationGroups,
}) => {
  const { displayLocale } = useLocale()

  const filteredSubNavigationGroups = subNavigationGroups?.filter(
    ({ displayOnSites }) => displayOnSites?.includes(displayLocale)
  )

  return (
    <>
      <A to={slug} href={url} contentType={__typename}>
        <H2 fontSize="24px" normalCase color="navy">
          {displayText}
        </H2>
      </A>
      {filteredSubNavigationGroups?.map(({ subNavigationItems }) =>
        subNavigationItems?.map(
          ({ id, displayText, navigationLink, subNavigation }) => (
            <React.Fragment key={id}>
              <A
                fontSize={14}
                to={navigationLink?.slug}
                href={navigationLink?.url}
                contentType={navigationLink?.__typename}
              >
                {displayText}
              </A>
              {subNavigation?.subNavigationItems?.map(
                ({ id, displayText, navigationLink }) => (
                  <StyledSecondSubNavItemContainer key={id}>
                    <A
                      fontSize={14}
                      to={navigationLink?.slug}
                      href={navigationLink?.url}
                      contentType={navigationLink?.__typename}
                    >
                      {displayText}
                    </A>
                  </StyledSecondSubNavItemContainer>
                )
              )}
            </React.Fragment>
          )
        )
      )}
    </>
  )
}

FooterItem.propTypes = {}

export default FooterItem
