import React from 'react'
import { StyledH3 } from './style'
import A from '@components/A'
import PropTypes from 'prop-types'
import SecondLevelSubNavigationItem from './SecondLevelSubNavigationItem'

const SubNavigationItem = ({
  id,
  displayText,
  slug,
  url,
  subNavigation,
  __typename,
}) => {
  const subNavigationItems = subNavigation?.subNavigationItems || []
  return (
    <div className="mt-[15px] flex flex-col w-full">
      <A to={slug} href={url} contentType={__typename} subpageId={id}>
        <StyledH3>{displayText}</StyledH3>
      </A>
      {subNavigationItems &&
        subNavigationItems?.map((item, i) => (
          <SecondLevelSubNavigationItem key={`${item?.id}+${i}`} {...item} />
        ))}
    </div>
  )
}

SubNavigationItem.propTypes = {
  id: PropTypes.string,
  displayText: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
  subNavigation: PropTypes.object,
  __typename: PropTypes.string,
}

export default SubNavigationItem
