import React from 'react'
import EmbeddedEntryCDAContainer from './EmbeddedEntryCDAContainer'
import EmbeddedEntryCPAContainer from './EmbeddedEntryCPAContainer'
import { isPreviewPage } from '@utils'

const EmbeddedEntryContainer = props => {
  return isPreviewPage() ? (
    <EmbeddedEntryCPAContainer {...props} />
  ) : (
    <EmbeddedEntryCDAContainer {...props} />
  )
}

export default EmbeddedEntryContainer
