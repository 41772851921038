import styled from 'styled-components'
import { H2 } from '../../../Globals'

export const StyledH2 = styled(H2)`
  font-size: 16px;
  text-transform: initial;
`
export const StyledDescription = styled.p`
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.4;
  color: #5f6062;
`

export const StyledCTAText = styled.p`
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  color: white;
  font-weight: bold;
  line-height: normal;
`

export const StyledButton = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  min-height: 40px;
  padding: 10px;
  border: 1px transparent solid;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  background-color: #b4a06e;
  &:hover {
    background-color: #a99564;
  }
`

export const StyledH3 = styled.h3`
  font-size: 15px;
  font-weight: normal;
  &:hover {
    color: #b4a06e;
  }
`

export const StyledNavigationItemContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledH4 = styled.h4`
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  &:hover {
    color: #b4a06e;
  }
`
