import React from 'react'
import { StyledTabContent } from '../style'

const TabPanel = ({ index, tab, setTab, label, children, ...rest }) => {
  return (
    <StyledTabContent
      role="tab-content"
      aria-labelledby={label || ''}
      visible={tab === index}
      style={{ padding: '0' }}
    >
      {children}
    </StyledTabContent>
  )
}

export default TabPanel
