/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import { useHover } from '@hooks'
import SubNavigationDesktop from './SubNavDesktopComponents/SubNavigationDesktop'
import { DropDownMenu } from '../style'

const SubNavDropdownDesktop = ({
  setHover,
  isHoveredParent,
  subNavigationGroups,
  featuredSection,
  shrink,
}) => {
  const [hoverRefDropDown, isHoveredChild] = useHover()
  useEffect(() => {
    setHover(isHoveredChild)
  }, [isHoveredChild])

  if (!subNavigationGroups && !featuredSection) return null
  return (
    <DropDownMenu
      ref={hoverRefDropDown}
      visible={isHoveredParent || isHoveredChild}
      shrink={shrink}
      className="qtph-globalmenu-navigation-dropdown"
    >
      <SubNavigationDesktop
        subNavigationGroups={subNavigationGroups}
        featuredSection={featuredSection}
      />
    </DropDownMenu>
  )
}

export default SubNavDropdownDesktop
