import React from 'react'
import PropTypes from 'prop-types'
import { StyledBaseA } from './styles'

const ExternalLink = ({ href, children, ...rest }) => {
  return (
    <StyledBaseA
      href={href}
      target="__blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </StyledBaseA>
  )
}

ExternalLink.propTypes = {
  url: PropTypes.string,
}

export default ExternalLink
