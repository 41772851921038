import React from 'react'
import { StyledNavList } from '../style'
import NavItemMobile from './NavItemMobile'
import MobileSitesAndSearch from './SubNavMobileComponents/MobileSitesAndSearch'
const NavItemsMobile = ({ navItems }) => {
  return (
    <StyledNavList>
      {navItems?.map(({ id, navigationLink, ...rest }) => (
        <NavItemMobile key={id} {...navigationLink} {...rest} />
      ))}
      <MobileSitesAndSearch />
    </StyledNavList>
  )
}

export default NavItemsMobile
