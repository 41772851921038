import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledSlideContainerFullWidth,
  StyledSlideFullWidthTitleDefault,
  StyledSlideFullWidthButtonDefault,
  FullWidthCarouselInner,
  StyledSlideFullWidthExternalLinkDefault,
} from '../style'
import defaultBg from '@assets/images/homebg.jpg'
import A from '@components/A'

const TitleLinkComponent = ({ content, title, pageSlugs }) => {
  const linkType =
    content?.__typename === 'ContentfulLink' || content?.__typename === 'Link'
  return linkType ? (
    <a href={content?.url}>{title}</a>
  ) : (
    <A
      to={content?.slug}
      contentType={content?.__typename}
      category={content?.category?.slug}
    >
      {title}
    </A>
  )
}

const SubLinkComponent = ({ content, title, fontSize, color, pageSlugs }) => {
  const linkType =
    content?.__typename === 'ContentfulLink' || content?.__typename === 'Link'

  return linkType ? (
    <StyledSlideFullWidthExternalLinkDefault
      color={color}
      fontSize={fontSize}
      href={content?.url}
      title={title}
    >
      {title}
    </StyledSlideFullWidthExternalLinkDefault>
  ) : (
    <StyledSlideFullWidthButtonDefault
      to={content?.slug}
      contentType={content?.__typename}
      category={content?.category?.slug}
      fontSize={fontSize}
      color={color}
    >
      {title}
    </StyledSlideFullWidthButtonDefault>
  )
}

const FullWidthSlide = ({ slide, mobileHeight, desktopHeight, tag }) => {
  const slideBackgroundImage = slide?.image?.fluid
    ? `https:${slide.image.fluid.src}`
    : slide?.image?.fixed
    ? `https:${slide.image.fixed.src}`
    : slide?.image?.file?.url || slide?.image?.url || defaultBg

  return (
    <StyledSlideContainerFullWidth
      mobileHeight={mobileHeight}
      desktopHeight={desktopHeight}
      bg={slideBackgroundImage}
    >
      <FullWidthCarouselInner>
        <StyledSlideFullWidthTitleDefault
          fontSize={slide?.fontSizeTitle}
          color={slide?.fontColorTitle}
          title={slide?.title}
        >
          <TitleLinkComponent
            content={slide?.contentDestination?.[0]}
            title={slide?.title}
          />
        </StyledSlideFullWidthTitleDefault>
        <SubLinkComponent
          content={slide.contentDestination?.[0]}
          title={slide.linkTitle || 'Read More'}
          fontSize={slide?.fontSizeLinkTitle}
          color={slide?.fontColorLinkTitle}
        />
      </FullWidthCarouselInner>
    </StyledSlideContainerFullWidth>
  )
}

FullWidthSlide.propTypes = {
  slide: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  }),
  tag: PropTypes.string,
}

FullWidthSlide.defaultProps = {
  tag: 'Featured Insight',
}

export default FullWidthSlide
