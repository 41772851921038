/* eslint-disable camelcase */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import FooterLayout from '@components/Footer/FooterLayout'

const FooterCDAContainer = ({ node_locale = 'en-US', ...props }) => {
  const data = useStaticQuery(graphql`
    query FooterQueryV2 {
      globalPrivacy: allContentfulPage(
        filter: { contentful_id: { eq: "3qrPxUziHF6kr0kMAWT1P8" } }
      ) {
        nodes {
          slug
          node_locale
          title
        }
      }
      termsAndCondition: allContentfulPage(
        filter: { contentful_id: { eq: "3Bt8L3oqNrHaUXdWzfbYxc" } }
      ) {
        nodes {
          slug
          node_locale
          title
        }
      }
      allContentfulModuleNavigation(filter: { placement: { eq: "Footer" } }) {
        nodes {
          ...navigationFields
        }
      }
    }
  `)

  const footerLinks = data?.allContentfulModuleNavigation?.nodes?.find(
    nodes => nodes?.node_locale === node_locale
  )?.navItems

  const globalPrivacy = data?.globalPrivacy?.nodes?.find(
    nodes => nodes?.node_locale === node_locale
  )

  const termsAndConditions = data?.termsAndCondition?.nodes?.find(
    nodes => nodes?.node_locale === node_locale
  )

  return (
    <FooterLayout
      {...props}
      items={footerLinks}
      globalPrivacy={globalPrivacy}
      termsAndConditions={termsAndConditions}
    />
  )
}

export default FooterCDAContainer
