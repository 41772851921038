import React from 'react'
import PropTypes from 'prop-types'
import Accordion from './Accordion'
import { NakedA } from '../../Button/index'
import { generateRandomKey } from '@utils'

const AccordionGroup = ({
  accordionItems,
  isList,
  isBig,
  isOpen,
  divider,
  SummaryComponent,
  DetailsComponent,
  border,
  iconOpen,
  iconClosed,
  title,
  slug,
  smallerIcon = false,
  itemClassName,
  groupClassName,
  iconClassName,
  ...rest
}) => {
  if (smallerIcon) {
    return (
      <Accordion
        summary={title}
        SummaryComponent={SummaryComponent}
        isList={isList}
        isBig={isBig}
        divider={divider}
        border={border}
        iconOpen={iconOpen}
        iconClosed={iconClosed}
        title={title}
        slug={slug}
        height="40px"
        smallerIcon={smallerIcon}
        defaultClose
        className={groupClassName}
        iconClassName={iconClassName}
      >
        {accordionItems &&
          accordionItems.map(tag => (
            <NakedA
              className={itemClassName}
              style={{ padding: '20px 0 0 32px' }}
              fontSize={14}
              underline
              hoverColor="gold"
              to={tag?.slug}
              contentType="ContentfulPracticeArea"
              key={generateRandomKey()}
            >
              {tag && (tag.title || tag.Name)}
            </NakedA>
          ))}
      </Accordion>
    )
  }
  return (
    <>
      {accordionItems &&
        accordionItems.map((panel, i) => (
          <Accordion
            summary={panel && panel.title}
            SummaryComponent={SummaryComponent}
            DetailsComponent={DetailsComponent}
            id={panel && panel.id}
            key={panel && panel.id}
            defaultOpen={isOpen && i === 0}
            isList={isList}
            isBig={isBig}
            divider={divider}
            border={border}
            iconOpen={iconOpen}
            iconClosed={iconClosed}
            iconClassName={iconClassName}
          >
            {panel && panel.details}
          </Accordion>
        ))}
    </>
  )
}

AccordionGroup.propTypes = {
  accordionItems: PropTypes.arrayOf(
    PropTypes.shape({
      summary: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string,
      ]),
      details: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string,
      ]),
      id: PropTypes.string,
    })
  ).isRequired,
  isList: PropTypes.bool,
}

export default AccordionGroup
