import React from 'react'
import A from '@components/A'
import { Container } from '@components/Common'

const typeObj = {
  ContentfulNews: 'NEWS',
  ContentfulEvent: 'EVENT',
  ContentfulPracticeArea: 'PRACTICE AREA',
  ContentfulOffice: 'OFFICE',
  ContentfulArticleCategory: 'BLOG',
  ContentfulProfessional: 'PROFESSIONAL',
}

const TwoColumnSlide = ({ slides }) => {
  return (
    <Container className="px-6 md:px-4 lg:px-8 xl:px-0">
      <div className="relative z-10 grid grid-cols-1 md:grid-cols-2 gap-16 pt-4 pb-12">
        {slides.map(slide => {
          const contentDestination = slide?.contentDestination?.[0]
          const slug = contentDestination?.slug
          const typeName = contentDestination?.__typename
          const category = contentDestination?.category?.slug
          const categoryTitle = contentDestination?.category?.title

          const subtitle =
            typeName === 'ContentfulArticle'
              ? categoryTitle
              : typeObj[typeName] || ''

          return (
            <div key={slide?.id} className="flex flex-col items-start">
              {slug && (
                <>
                  <A to={slug} category={category} contentType={typeName}>
                    <h2 className="text-[24px] leading-[32px] text-ph-blue font-medium mb-2 tracking-wide items-start hover:underline hover:underline-offset-2">
                      {(slide?.title && slide?.title) ||
                        contentDestination?.title}
                    </h2>
                  </A>
                  <p className="text-[18px] text-black uppercase font-medium">
                    {subtitle && subtitle}
                  </p>
                </>
              )}
            </div>
          )
        })}
      </div>
    </Container>
  )
}

export default TwoColumnSlide
