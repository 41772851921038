import React from 'react'
import PropTypes from 'prop-types'
import { StyledSubmit } from '../style'

const Submit = ({ ...props }) => (
  <>
    <StyledSubmit type="submit" {...props} />
  </>
)

Submit.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  dataCy: PropTypes.string,
  fullWidth: PropTypes.bool,
  center: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Submit
