import React, { useState } from 'react'
import { useWindowSize, useBreakpoints } from '@hooks'
import { Div } from '@components/StyledTags'

const BreakpointDisplay = () => {
  const breakpoint = useBreakpoints()
  const [showWindowSize, setShowWindowSize] = useState(false)
  const { width, height } = useWindowSize()

  return (
    <Div
      height="30px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      px={showWindowSize ? '10px' : '6px'}
      width="fit-content"
      bg="purple"
      color="white"
      fontSize="14px"
      fontWeight="700"
      borderRadius="5px"
      onClick={() => setShowWindowSize(show => !show)}
      cursor="pointer"
      pb={1}
      minWidth="32px"
      title="Current Viewport Size"
    >
      {breakpoint} {showWindowSize && `:  ${width} x ${height}`}
    </Div>
  )
}

export default BreakpointDisplay
