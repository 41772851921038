import React from 'react'
import {
  HeaderCDAContainer,
  HeaderCPAContainer,
} from '@containers/HeaderContainer'

const Header = ({ preview, ...rest }) => {
  const HeaderContainer = preview ? HeaderCPAContainer : HeaderCDAContainer

  const loginItems = [
    {
      id: 'qtph-globalmenu-login-alumni-link',
      title: 'Alumni',
      url: 'https://alumni.paulhastings.com/login/',
    },
    {
      id: 'qtph-globalmenu-login-paulhastingslogin-link',
      title: 'Paul Hastings',
      slug: '/mlogin',
    },
  ]

  return <HeaderContainer preview={preview} loginItems={loginItems} {...rest} />
}

export default Header
