import React from 'react'
import {
  StyledBannerOverlayContentContainer,
  Text,
  BannerContainerAndGradientOverlay,
  BannerImageBackground,
  StackedBackgroundImage,
  BackgroundImage,
  TextContainer,
} from '../style'
import Breadcrumbs from '../../Breadcrumbs'

const TextContent = ({
  width,
  crumbLabel,
  to,
  children,
  breadcrumbs,
  align,
}) => {
  return (
    <>
      <Text width={width} textAlign={align}>
        {breadcrumbs && <Breadcrumbs crumbLabel={crumbLabel} to={to} />}
        {children}
      </Text>
    </>
  )
}

const Banner = ({
  children,
  height = 'xs',
  heroImage,
  gradient,
  padding,
  ...props
}) => {
  return (
    <BannerContainerAndGradientOverlay
      height={height}
      bg={heroImage && heroImage.fluid.src}
      gradient={gradient}
      padding={padding}
    >
      {children && <TextContent {...props}>{children}</TextContent>}
    </BannerContainerAndGradientOverlay>
  )
}

const Background = ({
  align,
  children,
  height = 'xs',
  heroBg = false,
  heroImage,
  padding,
  gradient,
  top,
}) => {
  return (
    <BannerContainerAndGradientOverlay
      height={height}
      bg={heroImage && heroImage.fluid.src}
      gradient={gradient}
      padding={padding}
    >
      <BannerImageBackground
        align={align}
        height={height}
        gradient={gradient}
        bg={heroImage && heroImage.fluid && heroImage.fluid.src}
        style={{ maxHeight: '475px' }}
        alt="background-image"
      >
        {heroBg ? (
          <StackedBackgroundImage
            fluid={heroImage && heroImage.fluid}
            height={height}
            top={top}
            alt="banner-image"
          />
        ) : (
          <BackgroundImage
            alt="banner-image"
            fluid={heroImage && heroImage.fluid}
            height={height}
          />
        )}
        {children}
      </BannerImageBackground>
    </BannerContainerAndGradientOverlay>
  )
}

const Content = ({
  align,
  heroBg,
  children,
  width,
  breadcrumbs,
  crumbLabel,
  to,
}) => {
  return (
    <StyledBannerOverlayContentContainer mobilePadding textAlign={align}>
      {heroBg ? (
        <TextContainer width={width}>
          {breadcrumbs && <Breadcrumbs crumbLabel={crumbLabel} to={to} />}
          {children}
        </TextContainer>
      ) : (
        <>{children}</>
      )}
    </StyledBannerOverlayContentContainer>
  )
}

export const HeroBannerWithOverlay = ({
  align,
  crumbLabel,
  to,
  breadcrumbs,
  width,
  children,
  heroBg,
  ...props
}) => {
  return (
    <Background heroBg={heroBg} {...props}>
      <Content
        align={align}
        breadcrumbs={breadcrumbs}
        crumbLabel={crumbLabel}
        to={to}
        width={width}
        heroBg={heroBg}
      >
        {children}
      </Content>
    </Background>
  )
}

Banner.propTypes = {}

export default Banner
