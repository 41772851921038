import React from 'react'
import PropTypes from 'prop-types'
import PageElementWrapper from './PageElementWrapper'
import RootElementWrapper from './RootElementWrapper'
import UserTools from '@components/UserTools'
import { isPreviewPage } from '@utils'
import Header from '@components/Header'
import Footer from '@components/Footer'
import Cookies from '@components/Cookies'
import { Flex, GlobalStyle } from '@components/Globals'
import { BodyWrapper } from '@components/Layout/style'
// import { GetInTouch } from '@components/Banner'

const Layout = ({
  children,
  isPHIgnite = false,
  hideContactUs = false,
  ...rest
}) => {
  return (
    <>
      <GlobalStyle />
      {!isPHIgnite && <Header {...rest} />}
      <BodyWrapper {...rest}>
        <Flex direction="column" shrink="0" grow="1" vAlign="stretch">
          {children}
        </Flex>
      </BodyWrapper>
      {/* {!isPHIgnite && !hideContactUs && <GetInTouch {...rest} />} */}
      <Footer {...rest} />
      <Cookies />
      {!isPreviewPage() && <UserTools {...rest} />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
export { PageElementWrapper, RootElementWrapper }
