import React from 'react'
import PropTypes from 'prop-types'
import { SvgWrapper, InlineSvg, GoldInlineSvg, GoldA } from './style'
import { BodyCopy } from '../Globals'
import Glyph from './Glyph'
import FlagUS from '@assets/svg/flag_usa.svg'

const Icon = ({
  viewBox = '0 0 32 32',
  onClick,
  preserveAspectRatio = 'xMidYMid meet',
  active,
  glyph,
  alt,
  fill = 'currentColor',
  height = 32,
  width = 32,
  margin,
  title,
  pointer = false,
  id,
  tabIndex,
  ...rest
}) => {
  if (glyph === 'minus') {
    viewBox = '0 0 22 10'
  }
  if (glyph === 'plus') {
    viewBox = '0 0 20 20'
  }
  if (glyph === 'en-US') {
    viewBox = '0 0 56 28'
  }
  if (glyph === 'ja') {
    viewBox = '0 0 48.33 32'
  }
  if (glyph === 'zh') {
    viewBox = '0 0 32 21.3'
  }
  if (glyph === 'de') {
    viewBox = '0 0 32 19.2'
  }
  if (glyph === 'fr') {
    viewBox = '0 0 32 21.3'
  }
  if (glyph === 'ko') {
    viewBox = '0 0 32.89 21.93'
  }
  if (glyph === 'en-GB') {
    viewBox = '0 0 60 32'
  }

  // Temporary fix for csp no data: issue. Will be cleaned up in reskin
  if (glyph === 'en-US') {
    return <FlagUS width={width} height={height} />
  }

  return (
    <SvgWrapper
      height={height}
      width={width}
      className="icon"
      onClick={onClick}
      data-testid="svg-wrapper"
      {...rest}
      title={title}
      pointer={pointer}
      tabIndex={tabIndex}
      id={id}
    >
      <InlineSvg
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="1.414"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
        viewBox={viewBox}
        preserveAspectRatio={preserveAspectRatio}
        fit
        fill={fill}
        alt={alt}
        id={glyph}
        height={height}
        width={width}
        {...rest}
      >
        <title id="title">{title || glyph}</title>
        <Glyph glyph={glyph} {...rest} fill={fill} />
      </InlineSvg>
    </SvgWrapper>
  )
}

export const GoldSocialLinks = ({
  viewBox = '0 0 24 26',
  onClick,
  className,
  active,
  glyph,
  alt,
  fill = 'currentColor',
  height = 26,
  width = 28,
  link,
  download,
  target,
  rel,
  ...rest
}) => {
  if (glyph === 'minus') {
    viewBox = '0 0 22 10'
  }
  if (glyph === 'plus') {
    viewBox = '0 0 20 20'
  }
  if (glyph === 'en-US') {
    viewBox = '0 0 56 28'
  }
  if (glyph === 'ja') {
    viewBox = '0 0 48.33 32'
  }
  if (glyph === 'zh') {
    viewBox = '0 0 32 21.3'
  }
  if (glyph === 'de') {
    viewBox = '0 0 32 19.2'
  }
  if (glyph === 'fr') {
    viewBox = '0 0 32 21.3'
  }
  if (glyph === 'ko') {
    viewBox = '0 0 32.89 21.93'
  }
  if (glyph === 'en-GB') {
    viewBox = '0 0 60 32'
  }

  const renderIcon = () => (
    <>
      <SvgWrapper
        height={height}
        width={width}
        className="icon"
        onClick={onClick}
      >
        <GoldInlineSvg
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="1.414"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title"
          viewBox={viewBox}
          preserveAspectRatio="xMidYMid meet"
          fit
          fill={fill}
          alt={alt}
          id={glyph}
          goldSocial={glyph}
        >
          <title id="title">{glyph}</title>
          <Glyph glyph={glyph} {...rest} />
        </GoldInlineSvg>
      </SvgWrapper>
      {(glyph === 'blog' || glyph === 'vcard' || glyph === 'pdf') && (
        <BodyCopy
          style={{
            textTransform: 'uppercase',
            fontSize: '10px',
            fontWeight: '800',
          }}
          color="gold"
        >
          {glyph}
        </BodyCopy>
      )}
    </>
  )

  return (
    <GoldA
      className={className}
      href={link}
      download={download}
      target={target}
      rel={rel}
      glyph={glyph}
    >
      {renderIcon()}
    </GoldA>
  )
}

Icon.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  alt: PropTypes.string,
  glyph: PropTypes.string.isRequired,
  fill: PropTypes.string,
}

export default Icon
