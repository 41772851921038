import React from 'react'
import { StyledProfessionalBlockContainer } from '../../Globals'
import Cards from '../index'
import { ProfileCard } from '../../Card'
import { findEmailLength } from '@utils'

const ProfessionalsBlock = ({
  professionals,
  title,
  displaySocial,
  id,
  displayTitle,
  displayDepartment,
  displayContact,
  displayChair,
  imageSize,
  padding,
  layout,
  ...rest
}) => {
  if (displayChair) {
    professionals = professionals?.map(p => ({
      ...p?.professional?.[0],
      title: p?.chairTitle,
    }))
  }
  let color = ''
  const lengthOfEmail =
    professionals && professionals.length > 1 && findEmailLength(professionals)
  if (
    title === 'Partners' ||
    title === 'Department Chairs' ||
    title === 'Office Chairs'
  ) {
    color = 'black'
  }
  professionals =
    professionals &&
    professionals.filter(
      p =>
        p &&
        (p.__typename === 'ContentfulStaff' ||
          p.__typename === 'ContentType3G402RzTniwCtAAtQoJfAe' ||
          p.__typename === '3G402rzTNIWCtAAtQoJfAE' ||
          p.active)
    )

  if (imageSize === 'Large') {
    return (
      professionals && (
        <StyledProfessionalBlockContainer mobilePadding>
          <Cards
            padding={padding}
            title={title}
            key={id}
            numCols={4}
            titleDividerColor={color}
            gapWidth={0.2}
            mobilePadding="0px 0px 30px"
            withoutDividers
            allColWrap
            imageSize={imageSize}
          >
            {professionals &&
              professionals.map(p => (
                <ProfileCard
                  person={p}
                  id={p.id}
                  displaySocial
                  displayTitle={displayTitle}
                  displayDepartment={displayDepartment}
                  displayContact={displayContact}
                  displayAvatar={p.photo}
                  key={p.id}
                  md
                />
              ))}
          </Cards>
        </StyledProfessionalBlockContainer>
      )
    )
  }

  return (
    professionals && (
      <StyledProfessionalBlockContainer mobilePadding {...rest}>
        <Cards
          padding={padding}
          title={title}
          key={id}
          numCols={layout ? 1 : 2}
          gapWidth={0.2}
          mobilePadding="0px 0px 60px"
          color="#000000"
          titleDividerColor={color}
          emailLength={displayContact && lengthOfEmail}
        >
          {professionals?.map(p => (
            <ProfileCard
              person={p}
              id={p.id}
              displaySocial
              displayTitle={displayTitle}
              displayDepartment={displayDepartment}
              displayContact={displayContact}
              displayAvatar={p.photo}
              key={p.id}
            />
          ))}
        </Cards>
      </StyledProfessionalBlockContainer>
    )
  )
}

export default ProfessionalsBlock
