import React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { StyledTab } from '../style'
import { useMediaQuery, useLocale } from '@hooks'
import { H3 } from '../../Globals'
import { Button } from '../../Button'
import TabMobile from './TabMobile'

const Tab = ({
  index,
  activeTab,
  slug,
  label,
  width,
  height,
  border,
  borderRadius,
  padding,
  tab,
  setTab,
  pro,
  hideRightBorder,
  className,
  ...rest
}) => {
  const matches = useMediaQuery('(max-width: 764px)')
  const active = activeTab ? activeTab === index : tab === index
  const { getLocalizedSlug } = useLocale()

  const handleClick = index => {
    if (slug && index !== activeTab) {
      if (slug.indexOf('http://') === 0 || slug.indexOf('https://') === 0) {
        let win = window.open(slug, '_blank')
        win.focus()
      } else {
        const url = getLocalizedSlug(slug)
        navigate(url)
      }
    } else {
      setTab(index)
    }
  }
  if (matches) {
    return (
      <TabMobile
        tab={tab}
        setTab={handleClick}
        index={index}
        active={active}
        borderRadius={borderRadius}
        label={label || ''}
        className={className}
        {...rest}
      >
        {label}
      </TabMobile>
    )
  }

  return (
    <StyledTab pro={pro}>
      <Button
        active={active}
        id={`active-tab-${index}`}
        data-toggle="tab"
        role="tab"
        aria-labelledby={`${label}-tab`}
        aria-controls={`active-tab-${index}`}
        aria-selected={tab === index ? 'true' : 'false'}
        width={width}
        border={border}
        borderRaduis={borderRadius}
        padding={padding}
        hideRightBorder={hideRightBorder}
        onClick={() => handleClick(index)}
        className={className}
      >
        <H3 active="white">{label}</H3>
      </Button>
    </StyledTab>
  )
}

Tab.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
}

export default Tab
