import { useMediaQuery } from './useMediaQuery'

const useMobile = maxWidth => {
  const isMobileLayout = useMediaQuery(
    `${maxWidth ? `(max-width: ${maxWidth})` : `(max-width: 991px)`}`
  )
  return [isMobileLayout]
}

export default useMobile
export { useMobile }
