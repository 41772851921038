import React from 'react'
import List from '../List'
import A from '@components/A'
import {
  MapSection,
  MapTitle,
  StyledOfficeContainer,
  StyledOfficesMap,
  RegionTitle,
  OfficesCol,
  OfficeTitle,
  MapContainer,
} from './style'

const Region = ({ region, locations, colNum }) => {
  return (
    <OfficesCol colNum={colNum}>
      <RegionTitle className="qtph-home-continent-txt" color="gold">
        {region}
      </RegionTitle>
      <List width="100%">
        {locations &&
          locations.map(location => (
            <OfficeTitle key={location.id}>
              <A
                to={location?.slug}
                contentType={location?.__typename}
                className="qtph-home-city-link"
              >
                {location.title}
              </A>
            </OfficeTitle>
          ))}
      </List>
    </OfficesCol>
  )
}

Region.propTypes = {}

const OfficesMap = ({ title = 'Offices', locations }) => {
  const regions = ['americas', 'europe', 'asia']

  return (
    <MapSection>
      <StyledOfficeContainer className="qtph-home-map">
        <MapTitle>{title}</MapTitle>
        <MapContainer>
          <StyledOfficesMap>
            {regions &&
              regions.map((region, index) => (
                <Region
                  key={region}
                  region={region}
                  locations={
                    locations &&
                    locations.filter(
                      location => location.region?.toLowerCase() === region
                    )
                  }
                  colNum={index + 1}
                />
              ))}
          </StyledOfficesMap>
        </MapContainer>
      </StyledOfficeContainer>
    </MapSection>
  )
}

export default OfficesMap
