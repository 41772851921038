// Only one item MUST have the "default: true" key

module.exports = {
  'en-US': {
    default: true,
    path: `en`,
    language: 'American English',
    languageTranslatedToEnglish: 'American English',
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en-US`,
    ogLanguage: `en_US`,
    title: `Paul Hastings LLP`,
    description: `Paul Hastings is a leading international law firm that provides innovative legal solutions to many of the world's top financial institutions and Fortune Global 500 companies.`,
    langPicker: 'USA',
    region: 'Americas',
    navTestingClassName: 'qtph-globalmenu-sites-usa-link',
  },
  de: {
    path: `de`,
    language: 'Deutsch',
    languageTranslatedToEnglish: 'German',
    locale: `de`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `de`,
    ogLanguage: `de_DE`,
    title: `Paul Hastings LLP`,
    description: `Paul Hastings ist eine führende internationale Anwaltskanzlei, die innovative Rechtslösungen für viele der weltweit führenden Finanzinstitute und Fortune Global 500-Unternehmen anbietet.`,
    langPicker: 'DEU',
    region: 'Europe',
    navTestingClassName: 'qtph-globalmenu-sites-german-link',
  },
  zh: {
    path: `zh`,
    language: '繁體中文',
    languageTranslatedToEnglish: 'Chinese',
    locale: `zh`,
    dateFormat: `YYYY.MM.DD`,
    siteLanguage: `zh`,
    ogLanguage: `zh`,
    title: `Paul Hastings 主页`,
    description: `保罗·黑斯廷斯（Paul Hastings）是一家领先的国际律师事务所，为许多世界顶级金融机构和《财富》全球500强公司提供创新的法律解决方案。`,
    langPicker: 'CHN',
    region: 'Asia',
    navTestingClassName: 'qtph-globalmenu-sites-china-link',
  },
  fr: {
    path: `fr`,
    language: 'Français',
    languageTranslatedToEnglish: 'French',
    locale: `fr`,
    dateFormat: `DD-MM-YYYY`,
    siteLanguage: `fr`,
    ogLanguage: `fr`,
    title: `Paul Hastings LLP Accueil`,
    description: `Paul Hastings est un cabinet d’avocats international de premier plan qui propose des solutions juridiques innovantes à de nombreuses grandes institutions financières et sociétés du classement Fortune Global 500.`,
    langPicker: 'FRA',
    region: 'Europe',
    navTestingClassName: 'qtph-globalmenu-sites-france-link',
  },
  ja: {
    path: `ja`,
    language: '日本語',
    languageTranslatedToEnglish: 'Japanese',
    locale: `ja`,
    dateFormat: `YYYY.MM.DD`,
    siteLanguage: `ja`,
    ogLanguage: `ja`,
    title: `Paul Hastings LLP`,
    description: `ポール・ヘイスティングスは、世界の大手金融機関およびフォーチュン・グローバル500企業の多くに革新的な法律ソリューションを提供する国際的な大手法律事務所です。`,
    langPicker: 'JPN',
    region: 'Asia',
    navTestingClassName: 'qtph-globalmenu-sites-japan-link',
  },
  ko: {
    path: `ko`,
    language: '한국어',
    languageTranslatedToEnglish: 'Korean',
    locale: `ko`,
    dateFormat: `YYYY.MM.DD`,
    siteLanguage: `ko`,
    ogLanguage: `ko`,
    title: `Paul Hastings LLP`,
    description: `Paul Hastings는 세계 최고의 금융 기관 및 Fortune Global 500 대 기업에 혁신적인 법률 솔루션을 제공하는 선도적 인 국제 법률 회사입니다.`,
    langPicker: 'KOR',
    region: 'Asia',
    navTestingClassName: 'qtph-globalmenu-sites-korean-link',
  },
  'en-GB': {
    path: `en-GB`,
    language: 'British English',
    languageTranslatedToEnglish: 'British English',
    locale: `en-GB`,
    dateFormat: `DD MMMM YYYY`,
    siteLanguage: `en-GB`,
    ogLanguage: `en-GB`,
    title: `Paul Hastings LLP`,
    description: `Paul Hastings is a leading international law firm that provides innovative legal solutions to many of the world's top financial institutions and Fortune Global 500 companies.`,
    langPicker: 'UK',
    region: 'Europe',
    navTestingClassName: 'qtph-globalmenu-sites-uk-link',
  },
}
