import React from 'react'
import { StyledTransparentTitle, StyledSideBarTitleDiv } from '../style'

const SidebarTitle = ({ title }) => (
  <StyledSideBarTitleDiv>
    <StyledTransparentTitle>{title}</StyledTransparentTitle>
  </StyledSideBarTitleDiv>
)

export default SidebarTitle
