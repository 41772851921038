import styled from 'styled-components'
import { Container } from '../Globals'
import { sizes, colors } from '@theme'

export const StyledTab = styled.li`
  margin: 0 10px 10px 0;
  margin: ${({ pro }) => (pro ? '0px' : '0 10px 10px 0')};
`
export const StyledTabMobile = styled.li`
  list-style-type: none;
  width: 100%;
  margin: 0;
  text-align: center;
  border-top: 1px solid #b4b8be;
`

export const StyledTabsContainer = styled(Container)`
  background-color: ${({ color }) => color || 'white'};
  margin: ${({ margin }) => margin || '0px auto'};
  padding: ${({ pro }) => (pro ? '0' : '25px 15px 15px 15px')};
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${sizes.small.max}) {
    max-width: 540px;
    padding: 25px 15px 15px 15px;
  }

  @media (min-width: ${sizes.medium.min}) {
    max-width: 720px;
    padding: 25px 0 25px;
  }

  @media (min-width: ${sizes.medium.max}) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`

// included color - text transform
export const MobileTabHeader = styled.div`
  padding: 7px 0px 9px 10px;
  color: #5f6062;
  font-size: 13px;
  letter-spacing: 1.12px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
`

export const StyledTabContent = styled.div`
  transition: all 0.15s linear;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  justify-content: space-between !important;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  background-color: ${colors.bgGray};
  padding: 38px 0px;
`

export const StyledTabsMobileContainer = styled(Container)`
  color: #5f6062;
  border: ${({ border }) => border || '.25px solid #B4A06E'};
  background-color: ${({ background }) => background || 'white'};
  max-width: 540px;
  width: ${({ width }) => width || '90% !important'};
  margin: 20px auto;
`
