import React from 'react'
import {
  HoverGalleryContainer,
  DescriptionContainer,
  Description,
  HoverGalleryItem,
} from './style'
import CtaLink from './CtaLink'
import { H2 } from '../Globals'
import { Row } from '@components/Common'
import { OutlineButton } from '../Button'

const DescriptionGrid = ({ children, title, pageSlugs }) => {
  const width = `${(1 / children.length) * 100}%`
  return (
    <HoverGalleryContainer
      style={{ padding: '60px 30px', background: 'white' }}
    >
      <H2 color="purple" marginBottom={20}>
        {title}
      </H2>
      <Row>
        {children?.map(
          ({ id, cardInfo, linksTo, externalLink, image, ...rest }) => (
            <DescriptionContainer width={width} key={id}>
              <HoverGalleryItem width="100%" bg={image?.fluid?.src} key={id}>
                <CtaLink
                  pageSlugs={pageSlugs}
                  linksTo={linksTo}
                  externalLink={externalLink}
                  {...rest}
                />
              </HoverGalleryItem>
              <Description>{cardInfo?.description}</Description>
              <OutlineButton
                bg="white"
                color="purple"
                href={externalLink}
                to={linksTo?.[0]?.slug}
                contentType={linksTo?.[0]?.__typename}
              >
                Read Our Report
              </OutlineButton>
            </DescriptionContainer>
          )
        )}
      </Row>
    </HoverGalleryContainer>
  )
}

export default DescriptionGrid
