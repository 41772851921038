import React from 'react'
import RichTextBlock from '@richtext'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { HTML } from '@components/StyledTags'
import config from './gatsby/config'
import { removeStyleHTML } from '@utils'

export function renderShareUrl(platform, title, location) {
  switch (platform) {
    case 'facebook':
      return `https://www.facebook.com/sharer.php?u=${location}`
    case 'linkedIn':
      return `https://www.linkedin.com/shareArticle?mini=true&url=${location}&title=${config.siteTitle} : ${title}`
    case 'twitter':
      return `https://twitter.com/share?text=${config.siteTitle}: ${title} &url=${location}&hashtags=${config.hashtags}`
    default:
      return `https://${platform}.com/share?text=${config.siteTitle} &url=https://www.paulhastings.com/`
  }
}

export const renderMargin = (wrapText, align, margin) => {
  if (wrapText !== 'Tight' && align === 'Left') return `0 ${margin}% 0 0`
  else if (wrapText !== 'Tight' && align === 'Right') return `0 0 0 ${margin}%`
  return `${margin}`
}

export const renderJustifyContent = align => {
  if (align === 'Right') {
    return 'flex-end'
  } else if (align === 'Center') return 'center'

  return 'flex-start'
}

export function createMarkup(html) {
  return { __html: html }
}

export const generateRandomKey = () => {
  const max = 89123748912347
  return Math.floor(Math.random() * Math.floor(max))
}

export const renderHtmlOrRichtext = (html, richtext) => {
  if (!html && !richtext) return null

  return html?.useHTMLField === 'true' ? (
    <HTML dangerouslySetInnerHTML={{ __html: html?.html }} />
  ) : (
    <RichTextBlock richtext={richtext} />
  )
}

export const convertHtmlOrRichtextToPlainText = (html, richtext) => {
  if (!html && !richtext) return null

  const useHtml = html?.useHTMLField

  if (useHtml && typeof document !== 'undefined') {
    const tempHtml = document.createElement('div')
    tempHtml.innerHTML = removeStyleHTML(html?.html)
    tempHtml.innerHTML =
      tempHtml?.innerHTML?.replace(/&nbsp;/g, ' ') || tempHtml?.innerHTML
    const plainText =
      tempHtml?.querySelector('p')?.innerText || tempHtml?.innerText || ''
    return plainText?.replace(/[\n\r]/g, ' ')
  }

  const parsedRichtext =
    (richtext?.raw && JSON.parse(richtext?.raw)) || richtext
  const plainText = documentToPlainTextString(parsedRichtext) || ''
  return plainText?.replace(/[\n\r]/g, ' ')
}

export const calculateResponsiveFontSize = (text, defaultFontSize) => {
  const length = text.length

  let fontSize
  switch (true) {
    case length <= 50:
      fontSize = defaultFontSize
      break
    case length <= 100:
      fontSize = defaultFontSize - 2
      break
    case length <= 125:
      fontSize = defaultFontSize - 4
      break
    case length <= 150:
      fontSize = defaultFontSize - 6
      break
    case length <= 175:
      fontSize = defaultFontSize - 8
      break
    case length <= 200:
      fontSize = defaultFontSize - 10
      break
    case length <= 225:
      fontSize = defaultFontSize - 14
      break
    default:
      fontSize = defaultFontSize - 18
  }

  // // Ensure font size stays within reasonable bounds
  // const minSize = Math.max(16, Math.round(defaultFontSize * 0.9))
  // fontSize = Math.max(minSize, fontSize)

  return fontSize
}
