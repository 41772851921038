import styled from 'styled-components'
import { H2 } from '../Globals'
import { colors } from '@theme'
import A from '@components/A'

// export const StyledEntriesContainer = styled(Container)``

export const StyledEntriesTitle = styled(H2)`
  color: ${colors.burgundy};
  padding: 64px 0px 32px;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  ${({ titleMarginTop }) => titleMarginTop && `margin-top: ${titleMarginTop}`};
`

export const StyledViewMoreContainer = styled.div`
  width: 100%;
  text-align: center;
  display: block;
  padding: 32px 0;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  text-decoration: none;
`

export const StyledViewMoreSpan = styled(A)`
  color: ${({ color }) => (color ? colors[color] : colors.gold)};
  font-size: 18px;
  letter-spacing: 1.8px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  text-decoration: none;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`
