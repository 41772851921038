import React from 'react'
import PropTypes from 'prop-types'
import { StyledAsyncSimpleSelect } from '../style'

const AsyncSimpleSelect = ({
  options,
  widthPercentage,
  placeholder,
  onChange,
  minWidth,
  isMulti,
  cacheOptions,
  defaultOptions,
  loadOptions,
  components,
  styles,
  staticIndicators,
  value = null,
  isSearchable = true,
}) => (
  <StyledAsyncSimpleSelect
    components={components}
    styles={styles}
    isMulti={isMulti}
    cacheOptions={cacheOptions}
    defaultOptions={defaultOptions}
    loadOptions={loadOptions}
    options={options}
    placeholder={placeholder}
    widthPercentage={widthPercentage}
    minWidth={minWidth}
    onChange={onChange}
    classNamePrefix="react-select"
    className="react-select-container qtph-search-txtfield"
    data-testid="search"
    isValid
    isSearchable={isSearchable}
    staticIndicators={staticIndicators}
    value={value}
    cursor={isSearchable && 'text'}
  />
)

AsyncSimpleSelect.propTypes = {
  options: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  widthPercentage: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default AsyncSimpleSelect
